<template>
    <OverlayScrollbarsComponent
        ref="scrollbarObject"
        class="size-full"
        :options="{
            scrollbars: {
                autoHide: props.autoHide,
                theme: 'os-theme-custom',
            },
        }"
        defer
    >
        <slot></slot>
    </OverlayScrollbarsComponent>
</template>

<script setup lang="ts">
import { OverlayScrollbarsComponent } from 'overlayscrollbars-vue';
import 'overlayscrollbars/overlayscrollbars.css';
import { PropType } from 'vue';

const props = defineProps({
    autoHide: {
        type: String as PropType<string>,
        required: false,
        default: 'leave',
    },
});

const isVisible = () => {
    return 'AHOJ';
};
</script>
