/* tslint:disable */
/* eslint-disable */
/**
 * API_Xdent_external
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
 */
export interface APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idChatMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idSender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'messageText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'dateSend'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'hasAttachment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'isFromXdent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idCompanyRecipient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'senderName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'senderLocationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'idLabTicketExternalState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal
     */
    'dateRead'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
 */
export interface APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idLabLaboratoryRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idLabLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idLabCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idXCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idXLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'ico'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'dic'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idInvoicePartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idPricelist'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'status'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'clicked'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'acceptDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'xdentWeb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idXdentLocation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'isUnpairedFromXdent'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'unpairedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest
     */
    'idUnpairNoticeStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedLabinoCoreEntityLayerLabTicket
 */
export interface APIContextGeneratedLabinoCoreEntityLayerLabTicket {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idInvoicePartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'finishDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idTicketState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idXLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idInternalState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idExternalState'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'isRead'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'isInternal'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'laboratoryNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'doctorNote'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'isImportant'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idInvoicePartnerStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'summaryInvoiceState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'officeDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'isHistoryBatchConfirmed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'locationStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'locationEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'locationPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'locationRegNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'locationVatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerRegNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerVatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerStaffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'clientPersonalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'mainLaborants'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'invoicePartnerStaffPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'historyBatchConfirmDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'isHistoryBatchOutdated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'historyBatchGeneratedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'lockedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'idPricelistValidity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'complaintData'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'isComplaint'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicket
     */
    'externalPartners'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections
 */
export interface APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections
     */
    'idLabTicketInspection'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections
     */
    'dateInspection'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAddress
 */
export interface APIContextGeneratedXdentCoreEntityLayerAddress {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'idAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAddress
     */
    'companyName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetApplications
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetApplications {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'applicationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'loweredApplicationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'applicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetMembership>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'aspnetMembershipList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetMembership> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetPaths>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'aspnetPathsList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetPaths> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetRoles>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'aspnetRolesList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetRoles> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetUsers>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetApplications
     */
    'aspnetUsersList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetUsers> | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetMembership
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetMembership {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'applicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'password'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'passwordFormat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'passwordSalt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'mobilePIN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'loweredEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'passwordQuestion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'passwordAnswer'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'isApproved'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'isLockedOut'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'createDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'lastLoginDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'lastPasswordChangedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'lastLockoutDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'failedPasswordAttemptCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'failedPasswordAttemptWindowStart'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'failedPasswordAnswerAttemptCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'failedPasswordAnswerAttemptWindowStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'comment'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetApplications}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'aspnetApplicationsFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetApplications;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetUsers}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetMembership
     */
    'aspnetUsersFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetUsers;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetPaths
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetPaths {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'applicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'pathId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'loweredPath'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetApplications}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'aspnetApplicationsFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetApplications;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'aspnetPersonalizationallusersList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPaths
     */
    'aspnetPersonalizationperuserList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser> | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers
     */
    'pathId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers
     */
    'pageSettings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers
     */
    'lastUpdatedDate'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetPaths}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationallusers
     */
    'aspnetPathsFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetPaths;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'pathId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'pageSettings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'lastUpdatedDate'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetPaths}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'aspnetPathsFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetPaths;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetUsers}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser
     */
    'aspnetUsersFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetUsers;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetProfile
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetProfile {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetProfile
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetProfile
     */
    'propertyNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetProfile
     */
    'propertyValuesString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetProfile
     */
    'propertyValuesBinary'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetProfile
     */
    'lastUpdatedDate'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetUsers}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetProfile
     */
    'aspnetUsersFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetUsers;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetRoles
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetRoles {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'applicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'roleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'roleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'loweredRoleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'description'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetApplications}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'aspnetApplicationsFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetApplications;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetRoles
     */
    'aspnetUsersinrolesList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles> | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetUsers
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetUsers {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'applicationId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'loweredUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'mobileAlias'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'isAnonymous'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'lastActivityDate'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetApplications}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'aspnetApplicationsFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetApplications;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetMembership>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'aspnetMembershipList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetMembership> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'aspnetPersonalizationperuserList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetPersonalizationperuser> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetProfile>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'aspnetProfileList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetProfile> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'aspnetUsersinrolesList'?: Array<APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerUsers>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsers
     */
    'usersList'?: Array<APIContextGeneratedXdentCoreEntityLayerUsers> | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles
 */
export interface APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles
     */
    'roleId'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetRoles}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles
     */
    'aspnetRolesFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetRoles;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetUsers}
     * @memberof APIContextGeneratedXdentCoreEntityLayerAspnetUsersinroles
     */
    'aspnetUsersFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetUsers;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
 */
export interface APIContextGeneratedXdentCoreEntityLayerClientDentalCategory {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idClientDentalCategory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idClientDentalCategoryPrev'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idProsthetics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idEndodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idPeriodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idOrthodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idImplantology'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerClientDentalCategory
     */
    'created'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerCompany
 */
export interface APIContextGeneratedXdentCoreEntityLayerCompany {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'businessType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'website'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'currency'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'displayAddress'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'registrationType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'paypalEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'enablePayments'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'contactPersonTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'contactPersonFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'contactPersonLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'showWorkTimeOnly'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'emailNotify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'smsNotify'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'prefixWebsite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'codeBooking'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'isSubscripted'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'expirationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'subscriptionCancelReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'subscriptionCancelComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'externalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'miniWebsite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'showAddress'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'ntfRegistration'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'facebookSite'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'twitterSite'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'cancelPending'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'cancelReqDatetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'cancelConfDatetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'regNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'supportContact'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'idCRM'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'idUOL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'idBusinessMan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'isNewLabinoEnabled'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'contractNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'contractAddendumDevicesNumber'?: string | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerInvoiceSettings>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'invoiceSettingsList'?: Array<APIContextGeneratedXdentCoreEntityLayerInvoiceSettings> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerLocations>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'locationsList'?: Array<APIContextGeneratedXdentCoreEntityLayerLocations> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerCompany
     */
    'medicalReportPhraseList'?: Array<APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase> | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
 */
export interface APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'idOUPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'idPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'kodPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'odborneZameranieOU'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'druhUtvaru'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS
     */
    'druhUtvaruDisplayName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
 */
export interface APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'idPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'corporateBodyFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'municipality'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'streetName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'buildingNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'propertyRegistrationNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS
     */
    'ico'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerForms
 */
export interface APIContextGeneratedXdentCoreEntityLayerForms {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idFormType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'formSchema'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'model'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'published'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'expirationDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'isFavorite'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'idTemplate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'definitionV2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'modelV2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerForms
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerGeocache
 */
export interface APIContextGeneratedXdentCoreEntityLayerGeocache {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGeocache
     */
    'idAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGeocache
     */
    'idMap'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGeocache
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGeocache
     */
    'latitude'?: number | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerGlobal
 */
export interface APIContextGeneratedXdentCoreEntityLayerGlobal {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'idGlobal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'tableName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'valid'?: boolean | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerInvoiceSettings>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'invoiceSettingsList'?: Array<APIContextGeneratedXdentCoreEntityLayerInvoiceSettings> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerLocations>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'locationsList'?: Array<APIContextGeneratedXdentCoreEntityLayerLocations> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase>}
     * @memberof APIContextGeneratedXdentCoreEntityLayerGlobal
     */
    'medicalReportPhraseList'?: Array<APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase> | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
 */
export interface APIContextGeneratedXdentCoreEntityLayerInvoiceSettings {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'idInvoiceSettings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'fiscalYear'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'useStore'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'autoPayment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'invoiceItemAddEdit'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'printMacroDetail'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'idInvoiceTypeDefault'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'invoiceEetSumType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'invoiceEetForce'?: number | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerCompany}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'companyFk'?: APIContextGeneratedXdentCoreEntityLayerCompany;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerGlobal}
     * @memberof APIContextGeneratedXdentCoreEntityLayerInvoiceSettings
     */
    'globalFk'?: APIContextGeneratedXdentCoreEntityLayerGlobal;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
 */
export interface APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'idLabLaboratoryRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'idLabLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'idLabCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'idXCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'idXLaboratory'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'status'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'clicked'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'acceptDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'labLaboratoryName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'isUnpairedFromLabino'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'unpairedDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'clickedUnpaired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest
     */
    'idUnpairedXLocation'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerLanguage
 */
export interface APIContextGeneratedXdentCoreEntityLayerLanguage {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLanguage
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLanguage
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLanguage
     */
    'position'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLanguage
     */
    'idPhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLanguage
     */
    'flag'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerLocations
 */
export interface APIContextGeneratedXdentCoreEntityLayerLocations {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idAddressOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'regNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'vatNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'icz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'accountNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'bankCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'swift'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'writtenBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'web'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'facebook'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'twitter'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'onlineAvalaible'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'position'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'vozp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'webOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'emailOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'phoneOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetLocationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetDeviceName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetCertificateName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetActive'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eetStartDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'invoicePayVAT'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eReceptCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eReceptPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eReceptID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eReceptCertificateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'ideReceptPhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eReceptPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'uolDatabaseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'uolLoginToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idLogoOriginal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idLogoBooking'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idLogoBox'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'idExpertiseType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'invoiceSeriesActive'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'invoiceSeriesNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'invoiceCustomDue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'forcePaymentTypePick'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'eReceptAutoRenewDisable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insurenceCertificatePassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceCompanyNotUsed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceCertificateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceBankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceBankCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceForceAltBankAccount'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'customColors'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'ereceptPayment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'insuranceVariableSymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'b2BValidFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'fixedCurrency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'ezdravieJson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'instagram'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'isInvoiceItemsMerged'?: boolean | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerCompany}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'companyFk'?: APIContextGeneratedXdentCoreEntityLayerCompany;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerGlobal}
     * @memberof APIContextGeneratedXdentCoreEntityLayerLocations
     */
    'globalFk'?: APIContextGeneratedXdentCoreEntityLayerGlobal;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
 */
export interface APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'idPhrase'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'isInsuranceCodesCombined'?: boolean | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerCompany}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'companyFk'?: APIContextGeneratedXdentCoreEntityLayerCompany;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerGlobal}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMedicalReportPhrase
     */
    'globalFk'?: APIContextGeneratedXdentCoreEntityLayerGlobal;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerMutation
 */
export interface APIContextGeneratedXdentCoreEntityLayerMutation {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMutation
     */
    'idGlobal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMutation
     */
    'idMutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMutation
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMutation
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMutation
     */
    'anotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerMutation
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
 */
export interface APIContextGeneratedXdentCoreEntityLayerSetOpeningHours {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
     */
    'idOpenHour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
     */
    'idDayType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
     */
    'enable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
     */
    'startDatetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
     */
    'endDatetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerSetOpeningHours
     */
    'idEntity'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerUserLog
 */
export interface APIContextGeneratedXdentCoreEntityLayerUserLog {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUserLog
     */
    'idUserLog'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUserLog
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUserLog
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUserLog
     */
    'jsonLog'?: string | null;
}
/**
 * 
 * @export
 * @interface APIContextGeneratedXdentCoreEntityLayerUsers
 */
export interface APIContextGeneratedXdentCoreEntityLayerUsers {
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'dob'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'icp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idExpertiseCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idExpertise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'insuranceAccess'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idProfilePhoto'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'idUserExport'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'diagnoseCode'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAspnetUsers}
     * @memberof APIContextGeneratedXdentCoreEntityLayerUsers
     */
    'aspnetUsersFk'?: APIContextGeneratedXdentCoreEntityLayerAspnetUsers;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
 */
export interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'isIKlientShared'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'isXdentShared'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'serverIp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'idMaster'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'systemFileTags'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'isFileEmailSent'?: number;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
 */
export interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idLabLaboratoryRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idLabLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idXdentLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idLabCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idXCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idXLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'labinoName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'idInvoicePartner'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
 */
export interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'idLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'idAddressOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'idLogoBooking'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'regNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'isClientsOnly'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'focusAreas'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'isVisibleByAddress'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'visibilityCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult
     */
    'map'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult
 */
export interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult
     */
    'idLaboratoryLabel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult
     */
    'changedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult
     */
    'idLabLaboratoryLabelHistory'?: string;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
 */
export interface APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idInvoicePartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoiceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'finishDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idTicketState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'ticketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idInternalState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idExternalState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idXLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'laboratoryNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'doctorNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerPhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerRegNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerVatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idInvoicePartnerStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffPhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStaffEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerPost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoicePartnerNameAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'mainEmployees'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'internalState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'externalState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoiceState'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'isValid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'officeDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'isOutdated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoiceType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'invoiceNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idLabLaboratoryRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'isLocked'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idLockedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'lockedByFullname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'lockedByRole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idPricelistValidity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'complaintData'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'isComplaint'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'idComplaintLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'complaintTicketNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult
     */
    'externalPartners'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult
     */
    'idClientAlert'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult
     */
    'groupName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult
     */
    'idAnamnesis'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'allDay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'label'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'recurrenceInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'reminderInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idResource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'eventType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'confirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'onlineBooked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'noShow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'newClient'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'lastUpdateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'clientRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idApplicationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'isPayment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'planIdService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'partitionColumn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'cancelCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'cancelCodeText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'valid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'idWaitingClients'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'appColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult
     */
    'staff'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult
     */
    'personalId'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult
     */
    'idPlan'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult
     */
    'isDispensarization'?: boolean;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'pendingInvoicesCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'overdueInvoicesCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'unconfirmedNewsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'unreadSmsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'storeNotifyCount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'bonus'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'waitingCLientsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'totalFileSize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'totalFileSizeLimit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'invoiceCreditRequestCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'pendingFormRequestCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'pendingDocRequestCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult
     */
    'laboratoryNotificationsCount'?: number | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'staff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'locationPosition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'invoiceIncomePayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'invoiceIncomeDebit'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'insuranceIncome'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'appointmentsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'appointmentsDone'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'countAppointmentCreated'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'countAppointmentDeletedToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult
     */
    'countAppointmentDeleted'?: number;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'idMedicalReportOverview'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'pi'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'bop'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'api'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'bi'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'pbi'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'cpi'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult
     */
    'lastUpdate'?: string;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'idDoctorPass'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'fullClientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'clientDOB'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'leaveAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'privateVisit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'returnAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'treatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'passDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult
     */
    'staff'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'idDocumentRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'idDocument'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'idDocumentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'documentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'idFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'clientFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult
     */
    'clientLastname'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'idProfilePhoto'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'insuranceAccess'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'icp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'locationPosition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'rolesDelimited'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'idUserPrivilegesProfile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'lastActivityDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'lastLoginDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'createDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'expertiseCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'calendarName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'userName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'isValid'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'isDeleted'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'profileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult
     */
    'onlineAvalaible'?: number | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'idRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'dateLastTry'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'tryCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'processed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'isPaused'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'isSuccess'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult
     */
    'retryStopRequested'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idFormRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idFormBatch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idDocumentPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idFormType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'documentFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idDocumentFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'formSchema'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'formName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'clientFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'clientLastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'formType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'isFavorite'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult
     */
    'isFormV2'?: boolean;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idFormRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idFormBatch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idDocumentPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idFormType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'expirationDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'documentFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idDocumentFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'formSchema'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'model'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'formName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'clientFullname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'formType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult
     */
    'staffFullname'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'isIKlientShared'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'isLabinoShared'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'serverIp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'idMaster'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'systemFileTags'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result
     */
    'isFileEmailSent'?: number;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
     */
    'startDatetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
     */
    'endDatetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
     */
    'idEntity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
     */
    'dayType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
     */
    'enable'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult
     */
    'enableBySettings'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'idCompanyPremiumService'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'dateBillingStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'dateBillingEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'vatRate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult {
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'staffCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'staffCountNew'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'locationCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'eetLocationCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'gallerySize'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'gallerySizeLimit'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'isAnalyticsActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'isAnalyticsShow'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'averageSMSQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'lastMonthSMSMarketing'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'lastMonthSMSReminder'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'cbSmsPaymentFix'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'cbLocationPaymentFix'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'cbSubscriptionSale'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'cbSubPaymentFix'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'eetPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'codeCurrentPricelist'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'idCurrentPricelist'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'yearlyPayment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'isSMSNotifyActive'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'lastPricelistChange'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult
     */
    'countStaffNoCalendar'?: number | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSEFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSEFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSEFORMResult
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSEFORMResult
     */
    'naz'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSESEARCHGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSESEARCHGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSESEARCHGRIDResult
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSESEARCHGRIDResult
     */
    'naz'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'customCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'idInsuranceCodeType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'bod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'priceInsurance'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'priceLaboratory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'priceClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'idInsurancePaymentType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'isLocalizationRequired'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'ageStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'ageFinish'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'codeTypeTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'paymentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'nameLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'idLaboratoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'labColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'idInvoiceEet'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'locationEetName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'deviceName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'onlineType'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'certificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'certificateName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sum'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'vatNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'dateEet'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'firstSend'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'idInvoice'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'fik'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'bkp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'pkp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'storno'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'invoiceEetNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'urcenoCerpZuct'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'cerpZuct'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'invoiceEetSumType'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'invoicePayVAT'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sumVAT'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sumBaseForVAT'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sumVATLow1'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sumBaseForVATLow1'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sumVATLow2'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'sumBaseForVATLow2'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult
     */
    'erorStatus'?: number | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'locationPosition'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult
     */
    'isStore'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'idLaboratoryLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'clientFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'labelNumberLab'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'dateDelivery'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'priceTotal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'labColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'generatedBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult
     */
    'labinoValid'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'idLaboratoryLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'labelNumberLab'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'dateDelivery'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'priceTotal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'clientFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'clientLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'clientDegree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'clientDegreeAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'locationPosition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'labColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult
     */
    'generatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult
     */
    'idMagistralis'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult
     */
    'medicamentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult
     */
    'medicamentCreation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult
     */
    'administer'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'idMagistralisSubstance'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'idMagistralis'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'amout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'unit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'substanceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult
     */
    'substanceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'idMedicalExaminationReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerAddressStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerAddressCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerAddressPost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerAddressCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult
     */
    'partnerAddressState'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'idDiagnosis'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'idInvoiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'position'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'pricePerMinute'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'priceLaboratory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'codeTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'lot'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'exp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'idCurrency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult
     */
    'currencyCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'idMedicalReportExtern'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'externPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult
     */
    'ownerName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'lastUpdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idMedicalReportPrev'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idStaffInsurance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'isLockedReport'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idExternAuthor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'created'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'valid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffLastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffInsuranceFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffInsuranceLastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffInsuranceEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffInsuranceEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffLocationRegNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffLocationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffLocationStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffLocationCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffLocationPost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'idSupervisor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'staffInsuranceCodes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'toothLocalizations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'insuranceToothLocalizations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult
     */
    'reportType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'lastUpdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idMedicalReportPrev'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idStaffInsurance'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'isLocked'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'isLockedReport'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'isGallery'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'isDentalChart'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'isDigitalSign'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'medicalPrescriptionsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'laboratoryLabelsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'insuranceRegistrationListsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'medicalReportExternsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'medicalExaminationReportsCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'transportOrderCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'doctorPassCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'intakeReportCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'dismissalReportCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idCovidTest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'created'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'valid'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'staffInsuranceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'toothLocalizations'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'insuranceReportedCodes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'insuranceDiagnoses'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'diagnosisCodes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idClientDentalCategory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idEndodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idImplantology'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idOrthodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idPeriodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'idProsthetics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'reportColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult
     */
    'reportType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'idSetCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'codeLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'languageTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'dateFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'dateFormatTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'timeFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'timeFormatTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'currencyTile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'currencySymbolPosition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'currencySymbolPositionTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'currencyAnotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'numberFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'numberFormatTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'idPhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'phonePrefixTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'googleSync'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'isTacAgreed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'isMarketingAgreed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'isPaymentTerminal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'isNewLabinoEnabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'isCentralStoreEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'miniWebsite'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'multicurrency'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'newFeatures'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'disabledClientDropdownOptions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'idRegion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'regionTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'regionShortCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'analyticsLastGenerated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'businessType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult
     */
    'businessTypeCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult {
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult
     */
    'debit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult
     */
    'credit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult
     */
    'income'?: number | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult
     */
    'idItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult
     */
    'lot'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult
     */
    'exp'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult
     */
    'quantity'?: number | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
     */
    'skupina'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
     */
    'popis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
     */
    'doporuceni'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
     */
    'critical'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult
     */
    'hide'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult
     */
    'idTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult
     */
    'idTag'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult
     */
    'isSystemTag'?: number;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'idTaskMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'idTaskBatch'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'idAuthor'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'body'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'sent'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'authorLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'authorFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'authorEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult
     */
    'authorEducationAfter'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idTransportOrder'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idInsurance'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'clientPID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'clientFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'staff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'mainDiagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'mainDiagnoseText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'diagnose1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'diagnose1Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'diagnose2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'diagnose2Text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'transportReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'fromCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'fromStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'fromPost'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'fromPaidByInsurance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'toCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'toStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'toPost'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'toPaidByInsurance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'szzCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'szzStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'szzPost'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'szzPaidByInsurance'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'escortReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'dateOfTransport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult
     */
    'crewInstructions'?: string;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'formDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'dob'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'icp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idExpertise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insuranceAccess'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'deleteReason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'hiddenDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'recallsCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'valid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'roleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'roleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'blocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'emailNotify'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'smsNotify'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'marketing'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insuranceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idInsuranceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'codeInsuranceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'alerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'medicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'formAlerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'formMedicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'lastPrintPosition'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'codeGender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'codeInsurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'codePhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idExternal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insuranceRegistrationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insuranceICP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insuranceIdStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'badgeColor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'isInsuranceRegistered'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'citizenshipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idPartnership'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'partnershipCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'lastCovidStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'insuranceExternalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult
     */
    'countryCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'insurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'citizenshipCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'rn'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult
     */
    'badgeColor'?: string | null;
}
/**
 * 
 * @export
 * @interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
 */
export interface APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult {
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'idGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'schortcut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'idProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'locationPosition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'color1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'color2'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'customName'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'groups'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult
     */
    'xdentServicesMandatory'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APISharedAddAppointmenX2Dto
 */
export interface APISharedAddAppointmenX2Dto {
    /**
     * 
     * @type {Array<APISharedCalendarAppointmentX2Dto>}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'items'?: Array<APISharedCalendarAppointmentX2Dto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddAppointmenX2Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedAddClientDto
 */
export interface APISharedAddClientDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'gender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientDto
     */
    'marketing'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'realibility'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'dob'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientDto
     */
    'blocked'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientDto
     */
    'emailNotify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientDto
     */
    'smsNotify'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'insurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'idInsuranceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'alerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'medicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'insuranceExternalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'hiddenDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientDto
     */
    'insuranceRegistrationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedAddClientInternal
 */
export interface APISharedAddClientInternal {
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    '_idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'gender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientInternal
     */
    'marketing'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'realibility'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'dob'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientInternal
     */
    'blocked'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientInternal
     */
    'emailNotify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedAddClientInternal
     */
    'smsNotify'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'insurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'idInsuranceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'alerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'medicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'insuranceExternalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'hiddenDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientInternal
     */
    'insuranceRegistrationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedAddClientRelationCustomTagDto
 */
export interface APISharedAddClientRelationCustomTagDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientRelationCustomTagDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientRelationCustomTagDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddClientRelationCustomTagDto
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedAddRelationCustomTagDto
 */
export interface APISharedAddRelationCustomTagDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedAddRelationCustomTagDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAddRelationCustomTagDto
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedAppointmentClientDto
 */
export interface APISharedAppointmentClientDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'educationBefore'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentClientDto
     */
    'educationAfter'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedAppointmentDto
 */
export interface APISharedAppointmentDto {
    /**
     * 
     * @type {APISharedCalendarAppointmentDto}
     * @memberof APISharedAppointmentDto
     */
    'appointment'?: APISharedCalendarAppointmentDto;
    /**
     * 
     * @type {APISharedAppointmentClientDto}
     * @memberof APISharedAppointmentDto
     */
    'client'?: APISharedAppointmentClientDto;
    /**
     * 
     * @type {APISharedAppointmentStaffDto}
     * @memberof APISharedAppointmentDto
     */
    'staff'?: APISharedAppointmentStaffDto;
}
/**
 * 
 * @export
 * @interface APISharedAppointmentStaffDto
 */
export interface APISharedAppointmentStaffDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentStaffDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentStaffDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentStaffDto
     */
    'idFoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentStaffDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedAppointmentStaffDto
     */
    'color2'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedAppointmentWrapperDto
 */
export interface APISharedAppointmentWrapperDto {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult>}
     * @memberof APISharedAppointmentWrapperDto
     */
    'openingHours'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGETOPENINGHOURSSCHEDULERResult> | null;
    /**
     * 
     * @type {Array<APISharedAppointmentDto>}
     * @memberof APISharedAppointmentWrapperDto
     */
    'appointments'?: Array<APISharedAppointmentDto> | null;
}
/**
 * 
 * @export
 * @interface APISharedAppointmentWrapperX2Dto
 */
export interface APISharedAppointmentWrapperX2Dto {
    /**
     * 
     * @type {Array<APISharedCalendarAppointmentX2DtoHolder>}
     * @memberof APISharedAppointmentWrapperX2Dto
     */
    'cacheDay'?: Array<APISharedCalendarAppointmentX2DtoHolder> | null;
}
/**
 * 
 * @export
 * @interface APISharedAppointmentsAndOpenHoursDurationWrapperX2Dto
 */
export interface APISharedAppointmentsAndOpenHoursDurationWrapperX2Dto {
    /**
     * 
     * @type {Array<APISharedStaffDayDuration>}
     * @memberof APISharedAppointmentsAndOpenHoursDurationWrapperX2Dto
     */
    'staffDay'?: Array<APISharedStaffDayDuration> | null;
}
/**
 * 
 * @export
 * @interface APISharedCacheDtoHolder
 */
export interface APISharedCacheDtoHolder {
    /**
     * 
     * @type {string}
     * @memberof APISharedCacheDtoHolder
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCacheDtoHolder
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCacheDtoHolder
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCacheDtoHolder
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCacheDtoHolder
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCacheDtoHolder
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCacheDtoHolder
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCalendarAppointmentDto
 */
export interface APISharedCalendarAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idPattern'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'isRecurrent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentDto
     */
    'recurrenceIndex'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'isHoliday'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'promoCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'findUs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'custom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'clientAnswer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentDto
     */
    'allDay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentDto
     */
    'label'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'recurrenceInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'reminderInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idResource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentDto
     */
    'eventType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'confirmed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'onlineBooked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'noShow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'newClient': boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'lastUpdateDate': string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'clientRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'idApplicationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentDto
     */
    'isPayment': boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentDto
     */
    'planIdService'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCalendarAppointmentX2Dto
 */
export interface APISharedCalendarAppointmentX2Dto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'recurrenceInfoXML'?: string | null;
    /**
     * 
     * @type {APISharedRecurrenceInfoDto}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'recurrenceInfoParsed'?: APISharedRecurrenceInfoDto;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'recurrenceInfoRRULE'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'originalKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'updatedKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'updateTransactionId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'isHoliday'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'allDay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'label'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'recurrenceInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'reminderInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idResource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'eventType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'confirmed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'onlineBooked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'noShow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'newClient': boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'lastUpdateDate': string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'clientRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'idApplicationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'isPayment': boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2Dto
     */
    'planIdService'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCalendarAppointmentX2DtoHolder
 */
export interface APISharedCalendarAppointmentX2DtoHolder {
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2DtoHolder
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2DtoHolder
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2DtoHolder
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX2DtoHolder
     */
    'expiration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarAppointmentX2DtoHolder
     */
    'global'?: boolean;
    /**
     * 
     * @type {Array<APISharedCalendarAppointmentX2Dto>}
     * @memberof APISharedCalendarAppointmentX2DtoHolder
     */
    'appointments'?: Array<APISharedCalendarAppointmentX2Dto> | null;
}
/**
 * 
 * @export
 * @interface APISharedCalendarAppointmentX3Dto
 */
export interface APISharedCalendarAppointmentX3Dto {
    /**
     * 
     * @type {Array<APISharedCalendarAppointmentX2Dto>}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'items'?: Array<APISharedCalendarAppointmentX2Dto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarAppointmentX3Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCalendarClientsX3Dto
 */
export interface APISharedCalendarClientsX3Dto {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult>}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'items'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTX2CLIENTGRIDResult> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarClientsX3Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCalendarFetch
 */
export interface APISharedCalendarFetch {
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedCalendarFetch
     */
    'idStaff'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarFetch
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarFetch
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCalendarOpenHourX2Dto
 */
export interface APISharedCalendarOpenHourX2Dto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'recurrenceInfoXML'?: string | null;
    /**
     * 
     * @type {APISharedRecurrenceInfoDto}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'recurrenceInfoParsed'?: APISharedRecurrenceInfoDto;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'recurrenceInfoRRULE'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'activeOverride'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'weekType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'eventType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'orderItem'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'allDay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'location'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'label'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'recurrenceInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'reminderInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idResource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'confirmed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'onlineBooked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'noShow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'newClient': boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'lastUpdateDate': string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'clientRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'idApplicationType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'isPayment': boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2Dto
     */
    'planIdService'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCalendarOpenHourX2DtoHolder
 */
export interface APISharedCalendarOpenHourX2DtoHolder {
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2DtoHolder
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2DtoHolder
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2DtoHolder
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHourX2DtoHolder
     */
    'expiration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCalendarOpenHourX2DtoHolder
     */
    'global'?: boolean;
    /**
     * 
     * @type {Array<APISharedCalendarOpenHourX2Dto>}
     * @memberof APISharedCalendarOpenHourX2DtoHolder
     */
    'appointments'?: Array<APISharedCalendarOpenHourX2Dto> | null;
}
/**
 * 
 * @export
 * @interface APISharedCalendarOpenHoursX3Dto
 */
export interface APISharedCalendarOpenHoursX3Dto {
    /**
     * 
     * @type {Array<APISharedCalendarOpenHourX2Dto>}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'items'?: Array<APISharedCalendarOpenHourX2Dto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarOpenHoursX3Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCalendarServicesX3Dto
 */
export interface APISharedCalendarServicesX3Dto {
    /**
     * 
     * @type {Array<APISharedServiceWithRelation>}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'items'?: Array<APISharedServiceWithRelation> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarServicesX3Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCalendarStaffsX3Dto
 */
export interface APISharedCalendarStaffsX3Dto {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult>}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'items'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCalendarStaffsX3Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCallEventDTO
 */
export interface APISharedCallEventDTO {
    /**
     * 
     * @type {APISharedCallHeaderDTO}
     * @memberof APISharedCallEventDTO
     */
    'callHeader'?: APISharedCallHeaderDTO;
    /**
     * 
     * @type {Array<APISharedCallItemDTO>}
     * @memberof APISharedCallEventDTO
     */
    'callItemList'?: Array<APISharedCallItemDTO> | null;
    /**
     * 
     * @type {Array<APISharedCallUserDTO>}
     * @memberof APISharedCallEventDTO
     */
    'callStaffList'?: Array<APISharedCallUserDTO> | null;
    /**
     * 
     * @type {Array<APISharedCallUserDTO>}
     * @memberof APISharedCallEventDTO
     */
    'callClientList'?: Array<APISharedCallUserDTO> | null;
}
/**
 * 
 * @export
 * @interface APISharedCallHeaderDTO
 */
export interface APISharedCallHeaderDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedCallHeaderDTO
     */
    'uniqueId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallHeaderDTO
     */
    'phoneFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallHeaderDTO
     */
    'phoneTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallHeaderDTO
     */
    'statusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallHeaderDTO
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallHeaderDTO
     */
    'pbxTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCallItemDTO
 */
export interface APISharedCallItemDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedCallItemDTO
     */
    'idEventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallItemDTO
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallItemDTO
     */
    'eventDate'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCallUserDTO
 */
export interface APISharedCallUserDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedCallUserDTO
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallUserDTO
     */
    'idUserPhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallUserDTO
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallUserDTO
     */
    'dob'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallUserDTO
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCallUserDTO
     */
    'badgeColor'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCancelOnlineBookingAppointmentDto
 */
export interface APISharedCancelOnlineBookingAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCancelOnlineBookingAppointmentDto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCancelOnlineBookingAppointmentDto
     */
    'idCompany'?: string;
}
/**
 * 
 * @export
 * @interface APISharedCartOrderDTO
 */
export interface APISharedCartOrderDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedCartOrderDTO
     */
    'idCart'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCartOrderDTO
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCartOrderDTO
     */
    'ownerFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCartOrderDTO
     */
    'ownerLastname'?: string | null;
    /**
     * 
     * @type {Array<APISharedCartOrderItemDTO>}
     * @memberof APISharedCartOrderDTO
     */
    'items'?: Array<APISharedCartOrderItemDTO> | null;
}
/**
 * 
 * @export
 * @interface APISharedCartOrderItemDTO
 */
export interface APISharedCartOrderItemDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedCartOrderItemDTO
     */
    'idShopCartItem'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCartOrderItemDTO
     */
    'idOwner'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedCartOrderItemDTO
     */
    'itemId'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedCartOrderItemDTO
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface APISharedChiroLoginDto
 */
export interface APISharedChiroLoginDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedChiroLoginDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedChiroLoginDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedChiroLoginDto
     */
    'fireBaseDeviceToken'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedClientGeneralDataDto
 */
export interface APISharedClientGeneralDataDto {
    /**
     * 
     * @type {number}
     * @memberof APISharedClientGeneralDataDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedClientGeneralDataDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedClientGeneralDataDto
     */
    'period'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedClientGeneralDataDto
     */
    'validOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedCodelistDto
 */
export interface APISharedCodelistDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'idCodelist'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'idCodelistType'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCodelistDto
     */
    'list'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCodelistDto
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCommunityCommunityPlatformMetadataDto
 */
export interface APISharedCommunityCommunityPlatformMetadataDto {
    /**
     * 
     * @type {APISharedCommunityPlatformUserMetadataDto}
     * @memberof APISharedCommunityCommunityPlatformMetadataDto
     */
    'user'?: APISharedCommunityPlatformUserMetadataDto;
    /**
     * 
     * @type {APISharedCommunityPlatformCompanyMetadataDto}
     * @memberof APISharedCommunityCommunityPlatformMetadataDto
     */
    'company'?: APISharedCommunityPlatformCompanyMetadataDto;
    /**
     * 
     * @type {APISharedCommunityPlatformLocationMetadataDto}
     * @memberof APISharedCommunityCommunityPlatformMetadataDto
     */
    'location'?: APISharedCommunityPlatformLocationMetadataDto;
}
/**
 * 
 * @export
 * @interface APISharedCommunityPlatformAdressMetadataDto
 */
export interface APISharedCommunityPlatformAdressMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformAdressMetadataDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformAdressMetadataDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformAdressMetadataDto
     */
    'zip'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCommunityPlatformCompanyMetadataDto
 */
export interface APISharedCommunityPlatformCompanyMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformCompanyMetadataDto
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformCompanyMetadataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformCompanyMetadataDto
     */
    'ico'?: string | null;
    /**
     * 
     * @type {APISharedCommunityPlatformAdressMetadataDto}
     * @memberof APISharedCommunityPlatformCompanyMetadataDto
     */
    'adress'?: APISharedCommunityPlatformAdressMetadataDto;
}
/**
 * 
 * @export
 * @interface APISharedCommunityPlatformLocationMetadataDto
 */
export interface APISharedCommunityPlatformLocationMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformLocationMetadataDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformLocationMetadataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformLocationMetadataDto
     */
    'icz'?: string | null;
    /**
     * 
     * @type {APISharedCommunityPlatformAdressMetadataDto}
     * @memberof APISharedCommunityPlatformLocationMetadataDto
     */
    'adress'?: APISharedCommunityPlatformAdressMetadataDto;
}
/**
 * 
 * @export
 * @interface APISharedCommunityPlatformUserMetadataDto
 */
export interface APISharedCommunityPlatformUserMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'idUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCommunityPlatformUserMetadataDto
     */
    'educationAfter'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCreateAppointmentDto
 */
export interface APISharedCreateAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedCreateAppointmentDto
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'serviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreateAppointmentDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedCreateOrderDTO
 */
export interface APISharedCreateOrderDTO {
    /**
     * 
     * @type {APISharedCartOrderDTO}
     * @memberof APISharedCreateOrderDTO
     */
    'cart'?: APISharedCartOrderDTO;
    /**
     * 
     * @type {APISharedLocationData}
     * @memberof APISharedCreateOrderDTO
     */
    'billingLocation'?: APISharedLocationData;
    /**
     * 
     * @type {APISharedLocationData}
     * @memberof APISharedCreateOrderDTO
     */
    'shippingLocation'?: APISharedLocationData;
    /**
     * 
     * @type {APISharedDeliveryInfo}
     * @memberof APISharedCreateOrderDTO
     */
    'deliveryInfo'?: APISharedDeliveryInfo;
}
/**
 * 
 * @export
 * @interface APISharedCreatePartnershipRequestDto
 */
export interface APISharedCreatePartnershipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedCreatePartnershipRequestDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreatePartnershipRequestDto
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreatePartnershipRequestDto
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedCreatePartnershipRequestDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedCreatePartnershipRequestDto
     */
    'sendSms'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOCurrenciesFixedCurrency
 */
export interface APISharedDTOCurrenciesFixedCurrency {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOCurrenciesFixedCurrency
     */
    'idCurrency'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOCurrenciesFixedCurrency
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOCurrenciesFixedCurrency
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOLocationLocationCustomSettingsDto
 */
export interface APISharedDTOLocationLocationCustomSettingsDto {
    /**
     * 
     * @type {Array<APISharedDTOCurrenciesFixedCurrency>}
     * @memberof APISharedDTOLocationLocationCustomSettingsDto
     */
    'fixedCurrency'?: Array<APISharedDTOCurrenciesFixedCurrency> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOLocationLocationCustomSettingsDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOLocationLocationCustomSettingsDto
     */
    'customDue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOLocationLocationCustomSettingsDto
     */
    'forcePaymentTypePick'?: boolean;
    /**
     * 
     * @type {Array<APISharedDTOLocationLocationPaymentTypeSettingDto>}
     * @memberof APISharedDTOLocationLocationCustomSettingsDto
     */
    'paymentTypes'?: Array<APISharedDTOLocationLocationPaymentTypeSettingDto> | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOLocationLocationPaymentTypeSettingDto
 */
export interface APISharedDTOLocationLocationPaymentTypeSettingDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOLocationLocationPaymentTypeSettingDto
     */
    'idLocationsPaymentTypes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOLocationLocationPaymentTypeSettingDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOLocationLocationPaymentTypeSettingDto
     */
    'idPaymentType'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOLocationLocationPaymentTypeSettingDto
     */
    'position'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOLocationLocationPaymentTypeSettingDto
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingBookingDetailSettingDto
 */
export interface APISharedDTOOnlineBookingBookingDetailSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'isEmailRequired'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'isPhoneRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'htmlMsgBeforeBook'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'htmlScript'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'htmlScriptPre'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'bookToFuture'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'idScheme'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'indexBookingPage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'calendarSelectOption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'termsAndConditionsUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'showCookieBar'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'paymentGatewayEnabled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'autoApprove'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'isNewBookingDetail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingBookingDetailSettingDto
     */
    'gdprPersonalDataUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingConfigDto
 */
export interface APISharedDTOOnlineBookingConfigDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingConfigDto
     */
    'ietfLanguageTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingConfigDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingConfigDto
     */
    'currency'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingLanguageDto
 */
export interface APISharedDTOOnlineBookingLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLanguageDto
     */
    'lngTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLanguageDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingLanguageDto
     */
    'isGlobalLng'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLanguageDto
     */
    'phonePrefixTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLanguageDto
     */
    'flag'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingLocationItemHelpDto
 */
export interface APISharedDTOOnlineBookingLocationItemHelpDto {
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingOtherInfoItemDto>}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'otherInfo'?: Array<APISharedDTOOnlineBookingOtherInfoItemDto> | null;
    /**
     * 
     * @type {APISharedDTOOnlineBookingBookingDetailSettingDto}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'bookingDetailSetting'?: APISharedDTOOnlineBookingBookingDetailSettingDto;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'web'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'facebook'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'twitter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'instagram'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'logo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'position'?: number;
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingServiceItemDto>}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'services'?: Array<APISharedDTOOnlineBookingServiceItemDto> | null;
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingWorkingHourItemDto>}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'workHourList'?: Array<APISharedDTOOnlineBookingWorkingHourItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'logoSquare'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerGeocache}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'geoCache'?: APIContextGeneratedXdentCoreEntityLayerGeocache;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'isBookingDashboard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'isBookingDashboardLastAppointment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingLocationItemHelpDto
     */
    'isBookingDashboardRequestAppointment'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingOnlineBookingAppointmentDto
 */
export interface APISharedDTOOnlineBookingOnlineBookingAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'staff'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'cancelBeforeStartTime'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'onlineBooked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'canBeCancelled'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingOnlineBookingAppointmentDto
     */
    'isAlreadyCancelled'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingOnlineBookingMetadataDto
 */
export interface APISharedDTOOnlineBookingOnlineBookingMetadataDto {
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingLocationItemHelpDto>}
     * @memberof APISharedDTOOnlineBookingOnlineBookingMetadataDto
     */
    'clinics'?: Array<APISharedDTOOnlineBookingLocationItemHelpDto> | null;
    /**
     * 
     * @type {APISharedDTOOnlineBookingConfigDto}
     * @memberof APISharedDTOOnlineBookingOnlineBookingMetadataDto
     */
    'config'?: APISharedDTOOnlineBookingConfigDto;
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingLanguageDto>}
     * @memberof APISharedDTOOnlineBookingOnlineBookingMetadataDto
     */
    'languages'?: Array<APISharedDTOOnlineBookingLanguageDto> | null;
    /**
     * 
     * @type {{ [key: string]: Array<APISharedCodelistDto>; }}
     * @memberof APISharedDTOOnlineBookingOnlineBookingMetadataDto
     */
    'codelists'?: { [key: string]: Array<APISharedCodelistDto>; } | null;
    /**
     * 
     * @type {APISharedDTOOnlineBookingOnlineBookingAppointmentDto}
     * @memberof APISharedDTOOnlineBookingOnlineBookingMetadataDto
     */
    'appointment'?: APISharedDTOOnlineBookingOnlineBookingAppointmentDto;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingOtherInfoItemDto
 */
export interface APISharedDTOOnlineBookingOtherInfoItemDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOtherInfoItemDto
     */
    'idInfoType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOtherInfoItemDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOtherInfoItemDto
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingOtherInfoItemDto
     */
    'instruction'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingOtherInfoItemDto
     */
    'position'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingOtherInfoItemDto
     */
    'isRequired'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingServiceGroupDto
 */
export interface APISharedDTOOnlineBookingServiceGroupDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceGroupDto
     */
    'idServiceGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceGroupDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingServiceGroupDto
     */
    'position'?: number | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingServiceItemDto
 */
export interface APISharedDTOOnlineBookingServiceItemDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'nameXdent'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'priceText'?: string | null;
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingStaffItemDto>}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'staffs'?: Array<APISharedDTOOnlineBookingStaffItemDto> | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'deposit'?: number | null;
    /**
     * 
     * @type {Array<APISharedDTOOnlineBookingServiceGroupDto>}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'serviceGroups'?: Array<APISharedDTOOnlineBookingServiceGroupDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'requiresAuth'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingServiceItemDto
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingStaffItemDto
 */
export interface APISharedDTOOnlineBookingStaffItemDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingStaffItemDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingStaffItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingStaffItemDto
     */
    'foto'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTOOnlineBookingWorkingHourItemDto
 */
export interface APISharedDTOOnlineBookingWorkingHourItemDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingWorkingHourItemDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingWorkingHourItemDto
     */
    'start'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTOOnlineBookingWorkingHourItemDto
     */
    'finish'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTOOnlineBookingWorkingHourItemDto
     */
    'opened'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedDTOOnlineBookingWorkingHourItemDto
     */
    'dayType'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDTORatingRatingConfigurationDto
 */
export interface APISharedDTORatingRatingConfigurationDto {
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDTORatingRatingConfigurationDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTORatingRatingConfigurationDto
     */
    'minDaysAfterInstall'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTORatingRatingConfigurationDto
     */
    'minDaysBeforeRemind'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedDTORatingRatingConfigurationDto
     */
    'minLaunchTimes'?: number;
}
/**
 * 
 * @export
 * @interface APISharedDashboardNotesInsertUpdateDTO
 */
export interface APISharedDashboardNotesInsertUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesInsertUpdateDTO
     */
    'noteContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesInsertUpdateDTO
     */
    'idNote'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDashboardNotesInsertUpdateDTO
     */
    'isComplete'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APISharedDashboardNotesListDTO
 */
export interface APISharedDashboardNotesListDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesListDTO
     */
    'idNote'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesListDTO
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesListDTO
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesListDTO
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesListDTO
     */
    'noteContent'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDashboardNotesListDTO
     */
    'isComplete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedDashboardNotesListDTO
     */
    'dateCompleted'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDashboardNotesListDTO
     */
    'position'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedDashboardNotesListDTO
     */
    'rowNumber'?: number | null;
}
/**
 * 
 * @export
 * @interface APISharedDashobardDto
 */
export interface APISharedDashobardDto {
    /**
     * 
     * @type {Array<APISharedHeaderBlockHelper>}
     * @memberof APISharedDashobardDto
     */
    'progressBars'?: Array<APISharedHeaderBlockHelper> | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedDashobardDto
     */
    'hasNotifications'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APISharedDashobardDto
     */
    'notificationsCount'?: number | null;
}
/**
 * 
 * @export
 * @interface APISharedDeleteAppointment
 */
export interface APISharedDeleteAppointment {
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment
     */
    'idAppointmentCancel'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDeleteAppointment2Dto
 */
export interface APISharedDeleteAppointment2Dto {
    /**
     * 
     * @type {Array<APISharedDeleteAppointmentItem2Dto>}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'items'?: Array<APISharedDeleteAppointmentItem2Dto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointment2Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedDeleteAppointmentItem2Dto
 */
export interface APISharedDeleteAppointmentItem2Dto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointmentItem2Dto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointmentItem2Dto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointmentItem2Dto
     */
    'idAppointmentCancel'?: string;
}
/**
 * 
 * @export
 * @interface APISharedDeleteAppointmentX2Dto
 */
export interface APISharedDeleteAppointmentX2Dto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointmentX2Dto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeleteAppointmentX2Dto
     */
    'idAppointmentCancel'?: string;
}
/**
 * 
 * @export
 * @interface APISharedDeliveryInfo
 */
export interface APISharedDeliveryInfo {
    /**
     * 
     * @type {string}
     * @memberof APISharedDeliveryInfo
     */
    'contactPersonFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeliveryInfo
     */
    'contactPersonLastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeliveryInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeliveryInfo
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDeliveryInfo
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedDentalCategoryColorsDto
 */
export interface APISharedDentalCategoryColorsDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedDentalCategoryColorsDto
     */
    'idColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedDentalCategoryColorsDto
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedEditAppointmentDto
 */
export interface APISharedEditAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'datetime'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedEditAppointmentDto
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'serviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedEditAppointmentValuesX2Dto
 */
export interface APISharedEditAppointmentValuesX2Dto {
    /**
     * 
     * @type {Array<APISharedEditAppointmentX2Dto>}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'items'?: Array<APISharedEditAppointmentX2Dto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentValuesX2Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedEditAppointmentX2Dto
 */
export interface APISharedEditAppointmentX2Dto {
    /**
     * 
     * @type {string}
     * @memberof APISharedEditAppointmentX2Dto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof APISharedEditAppointmentX2Dto
     */
    'values'?: { [key: string]: any; } | null;
}
/**
 * 
 * @export
 * @interface APISharedEditClientDto
 */
export interface APISharedEditClientDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'gender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientDto
     */
    'marketing'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'realibility'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'dob'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientDto
     */
    'blocked'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientDto
     */
    'emailNotify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientDto
     */
    'smsNotify'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'insurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'idInsuranceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'alerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'medicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'insuranceExternalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'hiddenDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientDto
     */
    'insuranceRegistrationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedEditClientInternal
 */
export interface APISharedEditClientInternal {
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'idReturned'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'gender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientInternal
     */
    'marketing'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'realibility'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'dob'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientInternal
     */
    'blocked'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientInternal
     */
    'emailNotify'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedEditClientInternal
     */
    'smsNotify'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'insurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'idInsuranceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'salutation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'alerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'medicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'insuranceExternalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'hiddenDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedEditClientInternal
     */
    'insuranceRegistrationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedErrorDto
 */
export interface APISharedErrorDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'codeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'innerDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'stack'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'innerStack'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedErrorDto
     */
    'innerExceptions'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedExtendBearerDto
 */
export interface APISharedExtendBearerDto {
    /**
     * Auth token
     * @type {string}
     * @memberof APISharedExtendBearerDto
     */
    'bearerToken'?: string | null;
    /**
     * One time use refresh token. Bearer that has been generated with its refresh pair equivalent has to be provided
     * @type {string}
     * @memberof APISharedExtendBearerDto
     */
    'refreshToken'?: string | null;
    /**
     * Firebase token for apps that use it. Can be null
     * @type {string}
     * @memberof APISharedExtendBearerDto
     */
    'fireBaseDeviceToken'?: string | null;
    /**
     * Device os description (for firebase purposes) can be null
     * @type {string}
     * @memberof APISharedExtendBearerDto
     */
    'os'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedExtendedBaseModel
 */
export interface APISharedExtendedBaseModel {
    /**
     * 
     * @type {string}
     * @memberof APISharedExtendedBaseModel
     */
    'fileContent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedExtendedBaseModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedExtendedBaseModel
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedExtendedBaseModel
     */
    'mimeType'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedFormRequest
 */
export interface APISharedFormRequest {
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerForms}
     * @memberof APISharedFormRequest
     */
    'form': APIContextGeneratedXdentCoreEntityLayerForms;
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult}
     * @memberof APISharedFormRequest
     */
    'client': APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerUsers}
     * @memberof APISharedFormRequest
     */
    'doctor'?: APIContextGeneratedXdentCoreEntityLayerUsers;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormRequest
     */
    'idOwner'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedFormTemplateDto
 */
export interface APISharedFormTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'formType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idPartnerFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'signatureLoginToken'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedFormTemplateDto
     */
    'hasSignedArea'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedFormTemplateDto
     */
    'isStaticForm'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedFormTemplateDto
     */
    'isFilled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idFormType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'form'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'formSchema'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'model'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedFormTemplateDto
     */
    'published'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedFormTemplateDto
     */
    'expirationDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedFormTemplateDto
     */
    'isFavorite'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'idTemplate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'definitionV2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'modelV2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedFormTemplateDto
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedGetLaboratoryLabelListDTO
 */
export interface APISharedGetLaboratoryLabelListDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'idLaboratoryLabel'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'labelNumberLab'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'dateDelivery'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'priceTotal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'locationPosition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'labColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'orderNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetLaboratoryLabelListDTO
     */
    'generatedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedGetReservationsPerDayDTO
 */
export interface APISharedGetReservationsPerDayDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'client'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'staff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'confirmed'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'onlineBooked'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetReservationsPerDayDTO
     */
    'debt'?: number | null;
}
/**
 * 
 * @export
 * @interface APISharedGetStoreTransactionsPerDayDTO
 */
export interface APISharedGetStoreTransactionsPerDayDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'idStoreTransaction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'staff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'idTransactionType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'quantityBefore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'quantityAfter'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'transactionTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'lot'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'expiration'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'quantityNotify'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'itemCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'itemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'transactionInfo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'quantityMovement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'storeDocumentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'storeUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'storeLocationName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedGetStoreTransactionsPerDayDTO
     */
    'priceInvoice'?: number | null;
}
/**
 * 
 * @export
 * @interface APISharedGlobalXdentConfigCmpanyDto
 */
export interface APISharedGlobalXdentConfigCmpanyDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedGlobalXdentConfigCmpanyDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGlobalXdentConfigCmpanyDto
     */
    'businessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGlobalXdentConfigCmpanyDto
     */
    'idLgo'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedGlobalXdentConfigLocationDto
 */
export interface APISharedGlobalXdentConfigLocationDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedGlobalXdentConfigLocationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedGlobalXdentConfigLocationDto
     */
    'idLogo'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedGlobalXdentConfigurationDto
 */
export interface APISharedGlobalXdentConfigurationDto {
    /**
     * 
     * @type {{ [key: string]: Array<APISharedCodelistDto>; }}
     * @memberof APISharedGlobalXdentConfigurationDto
     */
    'codeLists'?: { [key: string]: Array<APISharedCodelistDto>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedGlobalXdentConfigurationDto
     */
    'roles'?: Array<string> | null;
    /**
     * 
     * @type {Array<APISharedDentalCategoryColorsDto>}
     * @memberof APISharedGlobalXdentConfigurationDto
     */
    'dentalCategoryColors'?: Array<APISharedDentalCategoryColorsDto> | null;
    /**
     * 
     * @type {APISharedGlobalXdentConfigCmpanyDto}
     * @memberof APISharedGlobalXdentConfigurationDto
     */
    'company'?: APISharedGlobalXdentConfigCmpanyDto;
    /**
     * 
     * @type {APISharedGlobalXdentConfigLocationDto}
     * @memberof APISharedGlobalXdentConfigurationDto
     */
    'location'?: APISharedGlobalXdentConfigLocationDto;
}
/**
 * 
 * @export
 * @interface APISharedGlobalXdentConfigurationWrapperDto
 */
export interface APISharedGlobalXdentConfigurationWrapperDto {
    /**
     * 
     * @type {APISharedGlobalXdentConfigurationDto}
     * @memberof APISharedGlobalXdentConfigurationWrapperDto
     */
    'configuration'?: APISharedGlobalXdentConfigurationDto;
    /**
     * 
     * @type {APISharedUserXdentDto}
     * @memberof APISharedGlobalXdentConfigurationWrapperDto
     */
    'user'?: APISharedUserXdentDto;
}
/**
 * 
 * @export
 * @interface APISharedHeaderBlockHelper
 */
export interface APISharedHeaderBlockHelper {
    /**
     * 
     * @type {string}
     * @memberof APISharedHeaderBlockHelper
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedHeaderBlockHelper
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedHeaderBlockHelper
     */
    'number'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedHeaderBlockHelper
     */
    'progress'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedHeaderBlockHelper
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedHeaderBlockHelper
     */
    'statusTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedHeaderBlockHelper
     */
    'statusText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedHeaderBlockHelper
     */
    'dashboardDisplayType'?: string | null;
}
/**
 * Payload for getting locked MR
 * @export
 * @interface APISharedInsuranceInvoiceGetLockedReportsModel
 */
export interface APISharedInsuranceInvoiceGetLockedReportsModel {
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceGetLockedReportsModel
     */
    'datePeriod'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceGetLockedReportsModel
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceGetLockedReportsModel
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceGetLockedReportsModel
     */
    'insuranceExternalId'?: string | null;
}
/**
 * Payload sent to APICORE
 * @export
 * @interface APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
 */
export interface APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel {
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'dateStart'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'locations'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'icps'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'insuranceCompanies'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'insuranceTypes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'care'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'insuranceInvoiceTypes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedInsuranceRulesInsuranceCheckAddCode
 */
export interface APISharedInsuranceRulesInsuranceCheckAddCode {
    /**
     * 
     * @type {APISharedInsuranceRulesInsuranceCode}
     * @memberof APISharedInsuranceRulesInsuranceCheckAddCode
     */
    'code'?: APISharedInsuranceRulesInsuranceCode;
    /**
     * 
     * @type {Array<APISharedInsuranceRulesInsuranceCode>}
     * @memberof APISharedInsuranceRulesInsuranceCheckAddCode
     */
    'history'?: Array<APISharedInsuranceRulesInsuranceCode> | null;
}
/**
 * 
 * @export
 * @interface APISharedInsuranceRulesInsuranceCheckAdviseCode
 */
export interface APISharedInsuranceRulesInsuranceCheckAdviseCode {
    /**
     * 
     * @type {Array<APISharedInsuranceRulesInsuranceCode>}
     * @memberof APISharedInsuranceRulesInsuranceCheckAdviseCode
     */
    'code'?: Array<APISharedInsuranceRulesInsuranceCode> | null;
    /**
     * 
     * @type {Array<APISharedInsuranceRulesInsuranceCode>}
     * @memberof APISharedInsuranceRulesInsuranceCheckAdviseCode
     */
    'history'?: Array<APISharedInsuranceRulesInsuranceCode> | null;
}
/**
 * 
 * @export
 * @interface APISharedInsuranceRulesInsuranceClient
 */
export interface APISharedInsuranceRulesInsuranceClient {
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'personalNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'age'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'male'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'validPersonalNumber'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'registrationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'registrationIcp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceClient
     */
    'registrationIcz'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedInsuranceRulesInsuranceCode
 */
export interface APISharedInsuranceRulesInsuranceCode {
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'idReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'issueDate'?: string;
    /**
     * 
     * @type {APISharedInsuranceRulesInsuranceStaff}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'staff'?: APISharedInsuranceRulesInsuranceStaff;
    /**
     * 
     * @type {APISharedInsuranceRulesInsuranceClient}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'client'?: APISharedInsuranceRulesInsuranceClient;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'diagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceCode
     */
    'localization'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedInsuranceRulesInsuranceStaff
 */
export interface APISharedInsuranceRulesInsuranceStaff {
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceStaff
     */
    'certificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceStaff
     */
    'expertise'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceStaff
     */
    'icp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedInsuranceRulesInsuranceStaff
     */
    'icz'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedJsonWebToken
 */
export interface APISharedJsonWebToken {
    /**
     * 
     * @type {string}
     * @memberof APISharedJsonWebToken
     */
    'bearerToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedJsonWebToken
     */
    'refreshToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedJsonWebToken
     */
    'refreshExpiration'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedJsonWebToken
     */
    'bearerExpiration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedJsonWebToken
     */
    'active'?: boolean | null;
}
/**
 * Adresa - obecný předek
 * @export
 * @interface APISharedLibsAdresa
 */
export interface APISharedLibsAdresa {
    [key: string]: any;

    /**
     * Kód státu (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'kodStatu'?: string;
    /**
     * Název státu
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevStatu'?: string;
    /**
     * Kód kraje
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodKraje'?: number;
    /**
     * Název kraje
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevKraje'?: string;
    /**
     * Kód okresu
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodOkresu'?: number;
    /**
     * Název okresu
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevOkresu'?: string;
    /**
     * Kód obce
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodObce'?: number;
    /**
     * Název obce
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevObce'?: string;
    /**
     * Kód správního obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodSpravnihoObvodu'?: number;
    /**
     * Název správního obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevSpravnihoObvodu'?: string;
    /**
     * Kód městského obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodMestskehoObvodu'?: number;
    /**
     * Název městského obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevMestskehoObvodu'?: string;
    /**
     * Kód městské části statutárního města
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodMestskeCastiObvodu'?: number;
    /**
     * Kód ulice, veřejného prostranství ze zdroje
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodUlice'?: number;
    /**
     * Název městské části statutárního města
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevMestskeCastiObvodu'?: string;
    /**
     * Název ulice, veřejného prostranství
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevUlice'?: string;
    /**
     * Číslo domovní
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'cisloDomovni'?: number;
    /**
     * Doplňující informace adresního popisu
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'doplnekAdresy'?: string;
    /**
     * Kód časti obce
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodCastiObce'?: number;
    /**
     * Číslo orientační - číselná část
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'cisloOrientacni'?: number;
    /**
     * Číslo orientační - písmenná část
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'cisloOrientacniPismeno'?: string;
    /**
     * Název části obce
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'nazevCastiObce'?: string;
    /**
     * Kód adresního místa
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'kodAdresnihoMista'?: number;
    /**
     * Poštovní směrovací číslo adresní pošty
     * @type {number}
     * @memberof APISharedLibsAdresa
     */
    'psc'?: number;
    /**
     * Nestrukturovaná adresa (formátovaná adresa)
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'textovaAdresa'?: string;
    /**
     * Nestrukturované číslo/a použíté v adrese
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'cisloDoAdresy'?: string;
    /**
     * Typ čísla domu (ciselnikKod: TypCislaDomovniho)
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'typCisloDomovni'?: string;
    /**
     * Stav standardizace adresy dle RÚIAN
     * @type {boolean}
     * @memberof APISharedLibsAdresa
     */
    'standardizaceAdresy'?: boolean;
    /**
     * Psč zahraničních nebo nestandardně definovaných čísel
     * @type {string}
     * @memberof APISharedLibsAdresa
     */
    'pscTxt'?: string;
}
/**
 * Adresa s platností
 * @export
 * @interface APISharedLibsAdresaAres
 */
export interface APISharedLibsAdresaAres {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsAdresaAresSidlo}
     * @memberof APISharedLibsAdresaAres
     */
    'sidlo'?: APISharedLibsAdresaAresSidlo;
    /**
     * Primární záznam
     * @type {boolean}
     * @memberof APISharedLibsAdresaAres
     */
    'primarniZaznam'?: boolean;
    /**
     * Platnost údaje od data
     * @type {string}
     * @memberof APISharedLibsAdresaAres
     */
    'platnostOd'?: string;
    /**
     * Platnost údaje od data
     * @type {string}
     * @memberof APISharedLibsAdresaAres
     */
    'platnostDo'?: string;
}
/**
 * @type APISharedLibsAdresaAresSidlo
 * Sídlo
 * @export
 */
export type APISharedLibsAdresaAresSidlo = APISharedLibsAdresa | APISharedLibsAdresaCeu | APISharedLibsAdresaRcns | APISharedLibsAngazovanySubjektAdresa | APISharedLibsEkonomickySubjektRzpAdresa;

/**
 * 
 * @export
 * @interface APISharedLibsAdresaCeu
 */
export interface APISharedLibsAdresaCeu {
    [key: string]: any;

    /**
     * Typ adresy
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'typAdresy'?: string;
    /**
     * Kód státu (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodStatu'?: string;
    /**
     * Název státu
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevStatu'?: string;
    /**
     * Kód kraje
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodKraje'?: number;
    /**
     * Název kraje
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevKraje'?: string;
    /**
     * Kód okresu
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodOkresu'?: number;
    /**
     * Název okresu
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevOkresu'?: string;
    /**
     * Kód obce
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodObce'?: number;
    /**
     * Název obce
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevObce'?: string;
    /**
     * Kód správního obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodSpravnihoObvodu'?: number;
    /**
     * Název správního obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevSpravnihoObvodu'?: string;
    /**
     * Kód městského obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodMestskehoObvodu'?: number;
    /**
     * Název městského obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevMestskehoObvodu'?: string;
    /**
     * Kód městské části statutárního města
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodMestskeCastiObvodu'?: number;
    /**
     * Kód ulice, veřejného prostranství ze zdroje
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodUlice'?: number;
    /**
     * Název městské části statutárního města
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevMestskeCastiObvodu'?: string;
    /**
     * Název ulice, veřejného prostranství
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevUlice'?: string;
    /**
     * Číslo domovní
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'cisloDomovni'?: number;
    /**
     * Doplňující informace adresního popisu
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'doplnekAdresy'?: string;
    /**
     * Kód časti obce
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodCastiObce'?: number;
    /**
     * Číslo orientační - číselná část
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'cisloOrientacni'?: number;
    /**
     * Číslo orientační - písmenná část
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'cisloOrientacniPismeno'?: string;
    /**
     * Název části obce
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'nazevCastiObce'?: string;
    /**
     * Kód adresního místa
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'kodAdresnihoMista'?: number;
    /**
     * Poštovní směrovací číslo adresní pošty
     * @type {number}
     * @memberof APISharedLibsAdresaCeu
     */
    'psc'?: number;
    /**
     * Nestrukturovaná adresa (formátovaná adresa)
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'textovaAdresa'?: string;
    /**
     * Nestrukturované číslo/a použíté v adrese
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'cisloDoAdresy'?: string;
    /**
     * Typ čísla domu (ciselnikKod: TypCislaDomovniho)
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'typCisloDomovni'?: string;
    /**
     * Stav standardizace adresy dle RÚIAN
     * @type {boolean}
     * @memberof APISharedLibsAdresaCeu
     */
    'standardizaceAdresy'?: boolean;
    /**
     * Psč zahraničních nebo nestandardně definovaných čísel
     * @type {string}
     * @memberof APISharedLibsAdresaCeu
     */
    'pscTxt'?: string;
}
/**
 * Adresa doručovací dle vyhlášky 359/2011 sb.
 * @export
 * @interface APISharedLibsAdresaDorucovaci
 */
export interface APISharedLibsAdresaDorucovaci {
    [key: string]: any;

    /**
     * 1. řádek doručovací adresy
     * @type {string}
     * @memberof APISharedLibsAdresaDorucovaci
     */
    'radekAdresy1'?: string;
    /**
     * 2. řádek doručovací adresy
     * @type {string}
     * @memberof APISharedLibsAdresaDorucovaci
     */
    'radekAdresy2'?: string;
    /**
     * 3. řádek doručovací adresy
     * @type {string}
     * @memberof APISharedLibsAdresaDorucovaci
     */
    'radekAdresy3'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAdresaRcns
 */
export interface APISharedLibsAdresaRcns {
    [key: string]: any;

    /**
     * Platnost adresy od data
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'platnostOd'?: string;
    /**
     * Platnost adresy do data
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'platnostDo'?: string;
    /**
     * Typ adresy  - kód (ciselnikKod: TypAdresy, zdroj: rcns)
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'typAdresy'?: string;
    /**
     * Kód státu (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodStatu'?: string;
    /**
     * Název státu
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevStatu'?: string;
    /**
     * Kód kraje
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodKraje'?: number;
    /**
     * Název kraje
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevKraje'?: string;
    /**
     * Kód okresu
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodOkresu'?: number;
    /**
     * Název okresu
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevOkresu'?: string;
    /**
     * Kód obce
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodObce'?: number;
    /**
     * Název obce
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevObce'?: string;
    /**
     * Kód správního obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodSpravnihoObvodu'?: number;
    /**
     * Název správního obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevSpravnihoObvodu'?: string;
    /**
     * Kód městského obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodMestskehoObvodu'?: number;
    /**
     * Název městského obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevMestskehoObvodu'?: string;
    /**
     * Kód městské části statutárního města
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodMestskeCastiObvodu'?: number;
    /**
     * Kód ulice, veřejného prostranství ze zdroje
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodUlice'?: number;
    /**
     * Název městské části statutárního města
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevMestskeCastiObvodu'?: string;
    /**
     * Název ulice, veřejného prostranství
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevUlice'?: string;
    /**
     * Číslo domovní
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'cisloDomovni'?: number;
    /**
     * Doplňující informace adresního popisu
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'doplnekAdresy'?: string;
    /**
     * Kód časti obce
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodCastiObce'?: number;
    /**
     * Číslo orientační - číselná část
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'cisloOrientacni'?: number;
    /**
     * Číslo orientační - písmenná část
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'cisloOrientacniPismeno'?: string;
    /**
     * Název části obce
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'nazevCastiObce'?: string;
    /**
     * Kód adresního místa
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'kodAdresnihoMista'?: number;
    /**
     * Poštovní směrovací číslo adresní pošty
     * @type {number}
     * @memberof APISharedLibsAdresaRcns
     */
    'psc'?: number;
    /**
     * Nestrukturovaná adresa (formátovaná adresa)
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'textovaAdresa'?: string;
    /**
     * Nestrukturované číslo/a použíté v adrese
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'cisloDoAdresy'?: string;
    /**
     * Typ čísla domu (ciselnikKod: TypCislaDomovniho)
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'typCisloDomovni'?: string;
    /**
     * Stav standardizace adresy dle RÚIAN
     * @type {boolean}
     * @memberof APISharedLibsAdresaRcns
     */
    'standardizaceAdresy'?: boolean;
    /**
     * Psč zahraničních nebo nestandardně definovaných čísel
     * @type {string}
     * @memberof APISharedLibsAdresaRcns
     */
    'pscTxt'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazmaRcns
 */
export interface APISharedLibsAngazmaRcns {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsAngazovanySubjektRcns}
     * @memberof APISharedLibsAngazmaRcns
     */
    'osobaPravnicka'?: APISharedLibsAngazovanySubjektRcns;
    /**
     * 
     * @type {APISharedLibsAngazovanaOsobaRcns}
     * @memberof APISharedLibsAngazmaRcns
     */
    'osobaFyzicka'?: APISharedLibsAngazovanaOsobaRcns;
}
/**
 * Osoba v angažmá ekonomického subjektu
 * @export
 * @interface APISharedLibsAngazovanaOsoba
 */
export interface APISharedLibsAngazovanaOsoba {
    [key: string]: any;

    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsoba
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsoba
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsoba
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsoba
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsoba
     */
    'datumNarozeni'?: string;
}
/**
 * Tabulka angažovaných osob
 * @export
 * @interface APISharedLibsAngazovanaOsobaCeu
 */
export interface APISharedLibsAngazovanaOsobaCeu {
    [key: string]: any;

    /**
     * Jméno, příjmení správce
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaCeu
     */
    'jmenoPrijmeni'?: string;
    /**
     * Typ angažmá
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaCeu
     */
    'typAngazma'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazovanaOsobaNrpzs
 */
export interface APISharedLibsAngazovanaOsobaNrpzs {
    [key: string]: any;

    /**
     * Typ angažmá osoby  - kód (ciselnikKod: TypAngazma, zdroj: nrpzs)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaNrpzs
     */
    'typAngazma'?: string;
    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaNrpzs
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaNrpzs
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaNrpzs
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaNrpzs
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaNrpzs
     */
    'datumNarozeni'?: string;
}
/**
 * Název vykonávané statutární(církevní) funkce
 * @export
 * @interface APISharedLibsAngazovanaOsobaRcns
 */
export interface APISharedLibsAngazovanaOsobaRcns {
    [key: string]: any;

    /**
     * Platnost angažované osoby od data
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'platnostOd'?: string;
    /**
     * Platnost angažované osoby od data
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'platnostDo'?: string;
    /**
     * Název orgánu
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'nazevAngazma'?: string;
    /**
     * Dodatečná informace jako \'funkce neobsazena\'
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'poznamka'?: string;
    /**
     * Typ angažmá osoby  - kód (ciselnikKod: TypAngazma, zdroj: rcns)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'typAngazma'?: string;
    /**
     * Název orgánu
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'nazevOrganu'?: string;
    /**
     * Kód státu  (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'kodStatu'?: string;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'pravniForma'?: string;
    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRcns
     */
    'datumNarozeni'?: string;
}
/**
 * Identifikace fyzické osoby statutárního orgánu subjektu.
 * @export
 * @interface APISharedLibsAngazovanaOsobaRpsh
 */
export interface APISharedLibsAngazovanaOsobaRpsh {
    [key: string]: any;

    /**
     * Název fukce
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'funkce'?: string;
    /**
     * Platnost záznamu od data
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'platnostOd'?: string;
    /**
     * Platnost záznamu do data
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'platnostDo'?: string;
    /**
     * adresa angažované osoby
     * @type {APISharedLibsAdresa | APISharedLibsAngazovanySubjektAdresa | APISharedLibsEkonomickySubjektRzpAdresa | APISharedLibsAdresaRcns | APISharedLibsAdresaCeu}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'adresa'?: APISharedLibsAdresa | APISharedLibsAngazovanySubjektAdresa | APISharedLibsEkonomickySubjektRzpAdresa | APISharedLibsAdresaRcns | APISharedLibsAdresaCeu;
    /**
     * Kód fukce
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'kod'?: string;
    /**
     * Typ angažmá osoby  - kód (ciselnikKod: TypAngazma)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'typAngazma'?: string;
    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRpsh
     */
    'datumNarozeni'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazovanaOsobaRs
 */
export interface APISharedLibsAngazovanaOsobaRs {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRs
     */
    'typAngazma'?: string;
    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRs
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRs
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRs
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRs
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRs
     */
    'datumNarozeni'?: string;
}
/**
 * Angažovaná osoba zdroje RŽP
 * @export
 * @interface APISharedLibsAngazovanaOsobaRzp
 */
export interface APISharedLibsAngazovanaOsobaRzp {
    [key: string]: any;

    /**
     * Platnost angažmá osoby od data
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'platnostOd'?: string;
    /**
     * Platnost angažmá osoby do data
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'platnostDo'?: string;
    /**
     * Státní občanství osoby - kód (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'statniObcanstvi'?: string;
    /**
     * Typ angažmá osoby  - kód (ciselnikKod: TypAngazma, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'typAngazma'?: string;
    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaRzp
     */
    'datumNarozeni'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazovanaOsobaSzrZaklad
 */
export interface APISharedLibsAngazovanaOsobaSzrZaklad {
    [key: string]: any;

    /**
     * Typ angažmá osoby  - kód (ciselnikKod: TypAngazma)
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaSzrZaklad
     */
    'typAngazma'?: string;
    /**
     * Jméno fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaSzrZaklad
     */
    'jmeno'?: string;
    /**
     * Příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaSzrZaklad
     */
    'prijmeni'?: string;
    /**
     * Titul před jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaSzrZaklad
     */
    'titulPredJmenem'?: string;
    /**
     * Titul za jménem fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaSzrZaklad
     */
    'titulZaJmenem'?: string;
    /**
     * Datum narození fyzické osoby
     * @type {string}
     * @memberof APISharedLibsAngazovanaOsobaSzrZaklad
     */
    'datumNarozeni'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazovanySubjektAdresa
 */
export interface APISharedLibsAngazovanySubjektAdresa {
    [key: string]: any;

    /**
     * Platnost adresy od data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'platnostOd'?: string;
    /**
     * Platnost adresy do data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'platnostDo'?: string;
    /**
     * Kód státu (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodStatu'?: string;
    /**
     * Název státu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevStatu'?: string;
    /**
     * Kód kraje
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodKraje'?: number;
    /**
     * Název kraje
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevKraje'?: string;
    /**
     * Kód okresu
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodOkresu'?: number;
    /**
     * Název okresu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevOkresu'?: string;
    /**
     * Kód obce
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodObce'?: number;
    /**
     * Název obce
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevObce'?: string;
    /**
     * Kód správního obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodSpravnihoObvodu'?: number;
    /**
     * Název správního obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevSpravnihoObvodu'?: string;
    /**
     * Kód městského obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodMestskehoObvodu'?: number;
    /**
     * Název městského obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevMestskehoObvodu'?: string;
    /**
     * Kód městské části statutárního města
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodMestskeCastiObvodu'?: number;
    /**
     * Kód ulice, veřejného prostranství ze zdroje
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodUlice'?: number;
    /**
     * Název městské části statutárního města
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevMestskeCastiObvodu'?: string;
    /**
     * Název ulice, veřejného prostranství
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevUlice'?: string;
    /**
     * Číslo domovní
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'cisloDomovni'?: number;
    /**
     * Doplňující informace adresního popisu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'doplnekAdresy'?: string;
    /**
     * Kód časti obce
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodCastiObce'?: number;
    /**
     * Číslo orientační - číselná část
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'cisloOrientacni'?: number;
    /**
     * Číslo orientační - písmenná část
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'cisloOrientacniPismeno'?: string;
    /**
     * Název části obce
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'nazevCastiObce'?: string;
    /**
     * Kód adresního místa
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'kodAdresnihoMista'?: number;
    /**
     * Poštovní směrovací číslo adresní pošty
     * @type {number}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'psc'?: number;
    /**
     * Nestrukturovaná adresa (formátovaná adresa)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'textovaAdresa'?: string;
    /**
     * Nestrukturované číslo/a použíté v adrese
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'cisloDoAdresy'?: string;
    /**
     * Typ čísla domu (ciselnikKod: TypCislaDomovniho)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'typCisloDomovni'?: string;
    /**
     * Stav standardizace adresy dle RÚIAN
     * @type {boolean}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'standardizaceAdresy'?: boolean;
    /**
     * Psč zahraničních nebo nestandardně definovaných čísel
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektAdresa
     */
    'pscTxt'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazovanySubjektRcns
 */
export interface APISharedLibsAngazovanySubjektRcns {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRcns>}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'statutarniOrgany'?: Array<APISharedLibsAngazovanaOsobaRcns>;
    /**
     * 
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'ico'?: string;
    /**
     * DIČ
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'dic'?: string;
    /**
     * Platnost záznamu od data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'platnostOd'?: string;
    /**
     * Platnost záznamu do data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'platnostDo'?: string;
    /**
     * 
     * @type {APISharedLibsAdresaRcns}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'sidlo'?: APISharedLibsAdresaRcns;
    /**
     * Obchodní jméno subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'obchodniJmeno'?: string;
    /**
     * Kód státu  (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'kodStatu'?: string;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcns
     */
    'pravniForma'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsAngazovanySubjektRcnsZaklad
 */
export interface APISharedLibsAngazovanySubjektRcnsZaklad {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'ico'?: string;
    /**
     * DIČ
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'dic'?: string;
    /**
     * Platnost záznamu od data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'platnostOd'?: string;
    /**
     * Platnost záznamu do data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'platnostDo'?: string;
    /**
     * 
     * @type {APISharedLibsAdresaRcns}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'sidlo'?: APISharedLibsAdresaRcns;
    /**
     * Obchodní jméno subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * Kód státu  (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'kodStatu'?: string;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRcnsZaklad
     */
    'pravniForma'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RŠ
 * @export
 * @interface APISharedLibsAngazovanySubjektRs
 */
export interface APISharedLibsAngazovanySubjektRs {
    [key: string]: any;

    /**
     * IČO
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'ico'?: string;
    /**
     * Jméno subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'obchodniJmeno'?: string;
    /**
     * Zkrácené jméno subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'obchodniJmenoZkracene'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'typAngazma'?: string;
    /**
     * 
     * @type {APISharedLibsAngazovanySubjektRsAdresa}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'adresa'?: APISharedLibsAngazovanySubjektRsAdresa;
    /**
     * Právní forma
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'pravniForma'?: string;
    /**
     * Datum vzniku
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRs
     */
    'datumZaniku'?: string;
}
/**
 * @type APISharedLibsAngazovanySubjektRsAdresa
 * Adresa angažovaného subjektu
 * @export
 */
export type APISharedLibsAngazovanySubjektRsAdresa = APISharedLibsAdresa | APISharedLibsAdresaCeu | APISharedLibsAdresaRcns | APISharedLibsAngazovanySubjektAdresa | APISharedLibsEkonomickySubjektRzpAdresa;

/**
 * Právnické osoby v angažmá ekonomického subjektu.
 * @export
 * @interface APISharedLibsAngazovanySubjektRzp
 */
export interface APISharedLibsAngazovanySubjektRzp {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRzp>}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'osobyZastupce'?: Array<APISharedLibsAngazovanaOsobaRzp>;
    /**
     * Typ angažmá osoby u subjektu ve zdroji RŽP (Registr živnostenského podnikání) - kód (ciselnikKod: TypAngazma, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'typAngazma'?: string;
    /**
     * Platnost angažmá subjektu od data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'platnostOd'?: string;
    /**
     * Platnost angažmá subjektu do data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'platnostDo'?: string;
    /**
     * Ičo angažovaného subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'ico'?: string;
    /**
     * obchodní jméno angažovaného subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsAngazovanySubjektAdresa}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'sidlo'?: APISharedLibsAngazovanySubjektAdresa;
    /**
     * Typ subjektu - kód (ciselnikKod: TypSubjektu, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'typSubjektu'?: string;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj:com, res)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'pravniForma'?: string;
    /**
     * Kód státu (ciselnikKod: Stat, zdroj:com)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzp
     */
    'kodStatu'?: string;
}
/**
 * Právnické osoby v angažmá ekonomického subjektu.
 * @export
 * @interface APISharedLibsAngazovanySubjektRzpZaklad
 */
export interface APISharedLibsAngazovanySubjektRzpZaklad {
    [key: string]: any;

    /**
     * Typ angažmá osoby u subjektu ve zdroji RŽP (Registr živnostenského podnikání) - kód (ciselnikKod: TypAngazma, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'typAngazma'?: string;
    /**
     * Platnost angažmá subjektu od data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'platnostOd'?: string;
    /**
     * Platnost angažmá subjektu do data
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'platnostDo'?: string;
    /**
     * Ičo angažovaného subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'ico'?: string;
    /**
     * obchodní jméno angažovaného subjektu
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsAngazovanySubjektAdresa}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'sidlo'?: APISharedLibsAngazovanySubjektAdresa;
    /**
     * Typ subjektu - kód (ciselnikKod: TypSubjektu, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'typSubjektu'?: string;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj:com, res)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'pravniForma'?: string;
    /**
     * Kód státu (ciselnikKod: Stat, zdroj:com)
     * @type {string}
     * @memberof APISharedLibsAngazovanySubjektRzpZaklad
     */
    'kodStatu'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsClenSvazu
 */
export interface APISharedLibsClenSvazu {
    [key: string]: any;

    /**
     * IČO člana svazu
     * @type {string}
     * @memberof APISharedLibsClenSvazu
     */
    'ico'?: string;
    /**
     * Platnost členství od data
     * @type {string}
     * @memberof APISharedLibsClenSvazu
     */
    'platnostOd'?: string;
    /**
     * Platnost členství do data
     * @type {string}
     * @memberof APISharedLibsClenSvazu
     */
    'platnostDo'?: string;
}
/**
 * Základní informace o ekonomickém subjektu
 * @export
 * @interface APISharedLibsEkonomickySubjekt
 */
export interface APISharedLibsEkonomickySubjekt {
    [key: string]: any;

    /**
     * Ičo ekonomického subjektu, pokud je ičo přidělené. Id ekonomického subjektu, pokud je ičo nepřidělené.
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'icoId'?: string;
    /**
     * 
     * @type {APISharedLibsAdresaDorucovaci}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'adresaDorucovaci'?: APISharedLibsAdresaDorucovaci;
    /**
     * 
     * @type {APISharedLibsSeznamRegistraci}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'seznamRegistraci'?: APISharedLibsSeznamRegistraci;
    /**
     * Identifikace primárního zdroje dat.
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'primarniZdroj'?: string;
    /**
     * 
     * @type {Array<APISharedLibsEkonomickySubjektDalsiUdaje>}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'dalsiUdaje'?: Array<APISharedLibsEkonomickySubjektDalsiUdaje>;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'czNace'?: Array<string>;
    /**
     * Indeftifikátor sub-registru zdroje SZR - kód (ciselnikKod: SubRegistrSzr, zdroj:com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'subRegistrSzr'?: string;
    /**
     * Daňové identifikační číslo skupiny plátce DPH ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'dicSkDph'?: string;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjekt
     */
    'dic'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsEkonomickySubjektCeuZaklad
 */
export interface APISharedLibsEkonomickySubjektCeuZaklad {
    [key: string]: any;

    /**
     * Typ subjektu  (ciselnikKod: TypSubjektu, zdroj: Ceu)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'typSubjektu'?: string;
    /**
     * 
     * @type {Array<APISharedLibsAdresaCeu>}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'adresy'?: Array<APISharedLibsAdresaCeu>;
    /**
     * Jméno, příjmení fyzické osoby
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'jmenoPrijmeni'?: string;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektCeuZaklad
     */
    'dic'?: string;
}
/**
 * Další údaje o ekonomickém subjektu
 * @export
 * @interface APISharedLibsEkonomickySubjektDalsiUdaje
 */
export interface APISharedLibsEkonomickySubjektDalsiUdaje {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsObchodniJmenoAres>}
     * @memberof APISharedLibsEkonomickySubjektDalsiUdaje
     */
    'obchodniJmeno'?: Array<APISharedLibsObchodniJmenoAres>;
    /**
     * 
     * @type {Array<APISharedLibsAdresaAres>}
     * @memberof APISharedLibsEkonomickySubjektDalsiUdaje
     */
    'sidlo'?: Array<APISharedLibsAdresaAres>;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektDalsiUdaje
     */
    'pravniForma'?: string;
    /**
     * Aktuální spisová značka ve tvaru ODDIL xx/SOUD (např. B 100/MSPH) - poskytováno pouze pro zdroj: Veřejné rejstříky
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektDalsiUdaje
     */
    'spisovaZnacka'?: string;
    /**
     * Identifikace primárního zdroje dat - kód (ciselnikKod: TypZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektDalsiUdaje
     */
    'datovyZdroj'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsEkonomickySubjektEzpZaklad
 */
export interface APISharedLibsEkonomickySubjektEzpZaklad {
    [key: string]: any;

    /**
     * URL odkaz na záznam zdrojového IS.
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'odkaz'?: string;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektEzpZaklad
     */
    'dic'?: string;
}
/**
 * Vazební tabulka adres
 * @export
 * @interface APISharedLibsEkonomickySubjektRzpAdresa
 */
export interface APISharedLibsEkonomickySubjektRzpAdresa {
    [key: string]: any;

    /**
     * Platnost adresy od data
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'platnostOd'?: string;
    /**
     * Platnost adresy do data
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'platnostDo'?: string;
    /**
     * Typ adresy  - kód (ciselnikKod: TypAdresy, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'typAdresy'?: string;
    /**
     * Kód státu (ciselnikKod: Stat)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodStatu'?: string;
    /**
     * Název státu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevStatu'?: string;
    /**
     * Kód kraje
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodKraje'?: number;
    /**
     * Název kraje
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevKraje'?: string;
    /**
     * Kód okresu
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodOkresu'?: number;
    /**
     * Název okresu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevOkresu'?: string;
    /**
     * Kód obce
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodObce'?: number;
    /**
     * Název obce
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevObce'?: string;
    /**
     * Kód správního obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodSpravnihoObvodu'?: number;
    /**
     * Název správního obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevSpravnihoObvodu'?: string;
    /**
     * Kód městského obvodu Prahy
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodMestskehoObvodu'?: number;
    /**
     * Název městského obvodu Prahy
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevMestskehoObvodu'?: string;
    /**
     * Kód městské části statutárního města
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodMestskeCastiObvodu'?: number;
    /**
     * Kód ulice, veřejného prostranství ze zdroje
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodUlice'?: number;
    /**
     * Název městské části statutárního města
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevMestskeCastiObvodu'?: string;
    /**
     * Název ulice, veřejného prostranství
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevUlice'?: string;
    /**
     * Číslo domovní
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'cisloDomovni'?: number;
    /**
     * Doplňující informace adresního popisu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'doplnekAdresy'?: string;
    /**
     * Kód časti obce
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodCastiObce'?: number;
    /**
     * Číslo orientační - číselná část
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'cisloOrientacni'?: number;
    /**
     * Číslo orientační - písmenná část
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'cisloOrientacniPismeno'?: string;
    /**
     * Název části obce
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'nazevCastiObce'?: string;
    /**
     * Kód adresního místa
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'kodAdresnihoMista'?: number;
    /**
     * Poštovní směrovací číslo adresní pošty
     * @type {number}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'psc'?: number;
    /**
     * Nestrukturovaná adresa (formátovaná adresa)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'textovaAdresa'?: string;
    /**
     * Nestrukturované číslo/a použíté v adrese
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'cisloDoAdresy'?: string;
    /**
     * Typ čísla domu (ciselnikKod: TypCislaDomovniho)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'typCisloDomovni'?: string;
    /**
     * Stav standardizace adresy dle RÚIAN
     * @type {boolean}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'standardizaceAdresy'?: boolean;
    /**
     * Psč zahraničních nebo nestandardně definovaných čísel
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektRzpAdresa
     */
    'pscTxt'?: string;
}
/**
 * Základní informace o ekonomickém subjektu - obecný předek
 * @export
 * @interface APISharedLibsEkonomickySubjektZaklad
 */
export interface APISharedLibsEkonomickySubjektZaklad {
    [key: string]: any;

    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsEkonomickySubjektZaklad
     */
    'dic'?: string;
}
/**
 * @type APISharedLibsEkonomickySubjektZakladSidlo
 * Sídlo ekonomického subjektu
 * @export
 */
export type APISharedLibsEkonomickySubjektZakladSidlo = APISharedLibsAdresa | APISharedLibsAdresaCeu | APISharedLibsAdresaRcns | APISharedLibsAngazovanySubjektAdresa | APISharedLibsEkonomickySubjektRzpAdresa;

/**
 * Události v rámci insolvenčního řízení
 * @export
 * @interface APISharedLibsInsolvencniRizeni
 */
export interface APISharedLibsInsolvencniRizeni {
    [key: string]: any;

    /**
     * Datum zápisu události
     * @type {string}
     * @memberof APISharedLibsInsolvencniRizeni
     */
    'datumZapisu'?: string;
    /**
     * Datum zplatnění události
     * @type {string}
     * @memberof APISharedLibsInsolvencniRizeni
     */
    'platnostOd'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsKontakty
 */
export interface APISharedLibsKontakty {
    [key: string]: any;

    /**
     * Email zdravotnického zařízení
     * @type {string}
     * @memberof APISharedLibsKontakty
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedLibsKontakty
     */
    'fax'?: string;
    /**
     * Telefoní kontakt
     * @type {string}
     * @memberof APISharedLibsKontakty
     */
    'telefon'?: string;
    /**
     * WWW zdravotnického zařízení
     * @type {string}
     * @memberof APISharedLibsKontakty
     */
    'www'?: string;
}
/**
 * Kontakty subjektu
 * @export
 * @interface APISharedLibsKontaktyRs
 */
export interface APISharedLibsKontaktyRs {
    [key: string]: any;

    /**
     * kontaktní telefon
     * @type {string}
     * @memberof APISharedLibsKontaktyRs
     */
    'telefon'?: string;
    /**
     * číslo faxu
     * @type {string}
     * @memberof APISharedLibsKontaktyRs
     */
    'fax'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedLibsKontaktyRs
     */
    'email'?: Array<string>;
    /**
     * webová adresa ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsKontaktyRs
     */
    'www'?: string;
}
/**
 * Obchodní jméno s platností
 * @export
 * @interface APISharedLibsObchodniJmenoAres
 */
export interface APISharedLibsObchodniJmenoAres {
    [key: string]: any;

    /**
     * Platnost údaje od data
     * @type {string}
     * @memberof APISharedLibsObchodniJmenoAres
     */
    'platnostOd'?: string;
    /**
     * Platnost údaje do data
     * @type {string}
     * @memberof APISharedLibsObchodniJmenoAres
     */
    'platnostDo'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsObchodniJmenoAres
     */
    'obchodniJmeno'?: string;
    /**
     * Primární záznam
     * @type {boolean}
     * @memberof APISharedLibsObchodniJmenoAres
     */
    'primarniZaznam'?: boolean;
}
/**
 * Oprávnění k výkonu zvláštních práv církve
 * @export
 * @interface APISharedLibsPravoZvlastni
 */
export interface APISharedLibsPravoZvlastni {
    [key: string]: any;

    /**
     * Začátek platnosti zvláštního práva subjektu
     * @type {string}
     * @memberof APISharedLibsPravoZvlastni
     */
    'platnostOd'?: string;
    /**
     * Konec platnosti zvláštního práva subjektu
     * @type {string}
     * @memberof APISharedLibsPravoZvlastni
     */
    'platnostDo'?: string;
    /**
     * Oprávnění pro výkon církve - kód (ciselnikKod: Pravo, zdroj: rcns)
     * @type {number}
     * @memberof APISharedLibsPravoZvlastni
     */
    'kodPravo'?: number;
}
/**
 * 
 * @export
 * @interface APISharedLibsProvozovna
 */
export interface APISharedLibsProvozovna {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsZivnostOborCinnosti>}
     * @memberof APISharedLibsProvozovna
     */
    'oboryCinnosti'?: Array<APISharedLibsZivnostOborCinnosti>;
    /**
     * 
     * @type {APISharedLibsProvozovnaZakladSidloProvozovny}
     * @memberof APISharedLibsProvozovna
     */
    'sidloProvozovny'?: APISharedLibsProvozovnaZakladSidloProvozovny;
    /**
     * Umístění provozovny
     * @type {string}
     * @memberof APISharedLibsProvozovna
     */
    'umisteniProvozovny'?: string;
    /**
     * Platnost provozovny od data
     * @type {string}
     * @memberof APISharedLibsProvozovna
     */
    'platnostOd'?: string;
    /**
     * Platnost provozovny do data
     * @type {string}
     * @memberof APISharedLibsProvozovna
     */
    'platnostDo'?: string;
    /**
     * 
     * @type {Array<APISharedLibsZivnostProvozovnaPozastaveni>}
     * @memberof APISharedLibsProvozovna
     */
    'pozastaveniProvozovny'?: Array<APISharedLibsZivnostProvozovnaPozastaveni>;
    /**
     * Typ provozovny - kód (ciselnikKod: TypProvozovny)
     * @type {string}
     * @memberof APISharedLibsProvozovna
     */
    'typProvozovny'?: string;
    /**
     * Název provozovny
     * @type {string}
     * @memberof APISharedLibsProvozovna
     */
    'nazev'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsProvozovnaZaklad
 */
export interface APISharedLibsProvozovnaZaklad {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsProvozovnaZakladSidloProvozovny}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'sidloProvozovny'?: APISharedLibsProvozovnaZakladSidloProvozovny;
    /**
     * Umístění provozovny
     * @type {string}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'umisteniProvozovny'?: string;
    /**
     * Platnost provozovny od data
     * @type {string}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'platnostOd'?: string;
    /**
     * Platnost provozovny do data
     * @type {string}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'platnostDo'?: string;
    /**
     * 
     * @type {Array<APISharedLibsZivnostProvozovnaPozastaveni>}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'pozastaveniProvozovny'?: Array<APISharedLibsZivnostProvozovnaPozastaveni>;
    /**
     * Typ provozovny - kód (ciselnikKod: TypProvozovny)
     * @type {string}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'typProvozovny'?: string;
    /**
     * Název provozovny
     * @type {string}
     * @memberof APISharedLibsProvozovnaZaklad
     */
    'nazev'?: string;
}
/**
 * @type APISharedLibsProvozovnaZakladSidloProvozovny
 * Adresa sídla provozovny
 * @export
 */
export type APISharedLibsProvozovnaZakladSidloProvozovny = APISharedLibsAdresa | APISharedLibsAdresaCeu | APISharedLibsAdresaRcns | APISharedLibsAngazovanySubjektAdresa | APISharedLibsEkonomickySubjektRzpAdresa;

/**
 * 
 * @export
 * @interface APISharedLibsProvozovnyRzpStav
 */
export interface APISharedLibsProvozovnyRzpStav {
    [key: string]: any;

    /**
     * Celkový počet provozoven
     * @type {number}
     * @memberof APISharedLibsProvozovnyRzpStav
     */
    'pocetCelkem'?: number;
    /**
     * Celkový počet zaniklých provozoven
     * @type {number}
     * @memberof APISharedLibsProvozovnyRzpStav
     */
    'pocetZaniklych'?: number;
    /**
     * Celkový počet aktivnich provozoven
     * @type {number}
     * @memberof APISharedLibsProvozovnyRzpStav
     */
    'pocetAktivnich'?: number;
    /**
     * Celkový počet zaniklých provozoven
     * @type {number}
     * @memberof APISharedLibsProvozovnyRzpStav
     */
    'pocetPozastavenych'?: number;
}
/**
 * 
 * @export
 * @interface APISharedLibsRegistraceRcns
 */
export interface APISharedLibsRegistraceRcns {
    [key: string]: any;

    /**
     * Pro subjekt typu církev a svaz církví je zde uvedeno registrační číslo pod kterým byl daný subjekt zaregistrován na MK. Pro subjekty typu právnická osoba je zde uvedeno evidenční číslo pod kterým byl daný subjekt zaevidován na MK.
     * @type {string}
     * @memberof APISharedLibsRegistraceRcns
     */
    'cisloRegistrace'?: string;
    /**
     * Datum zrušení registrace, evidence subjektu na MK
     * @type {string}
     * @memberof APISharedLibsRegistraceRcns
     */
    'cisloZruseniRegistrace'?: string;
    /**
     * Datum vyhlášení likvidace subjektu
     * @type {string}
     * @memberof APISharedLibsRegistraceRcns
     */
    'datumLikvidace'?: string;
    /**
     * Datum vyhlášení konkurzu subjektu
     * @type {string}
     * @memberof APISharedLibsRegistraceRcns
     */
    'datumKonkurzu'?: string;
    /**
     * Datum jmenování opatrovníka
     * @type {string}
     * @memberof APISharedLibsRegistraceRcns
     */
    'datumOpatrovnik'?: string;
    /**
     * Datum zrušení registrace, evidence subjektu na MK
     * @type {string}
     * @memberof APISharedLibsRegistraceRcns
     */
    'datumZruseniRegistrace'?: string;
}
/**
 * Seznam registrací ekonomického subjektu v jednotlivých zdrojích
 * @export
 * @interface APISharedLibsSeznamRegistraci
 */
export interface APISharedLibsSeznamRegistraci {
    [key: string]: any;

    /**
     * Stav ekonomického subjektu ve zdroji VR (Veřejné rejstříky) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeVr'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji RES (Registr ekonomických subjektů) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeRes'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji RŽP (Registr živnostenského podnikání) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeRzp'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji NRPZS (Národní registr poskytovatelů zdrovotnických služeb) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeNrpzs'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji RPSH (Registr politických stran a hnutí) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeRpsh'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji RCNS(Registr církví a náboženských společenství) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeRcns'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji  SZR(Společný zemědělský registr) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeSzr'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji DPH(Registr plátců daně s přidané hodnoty) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeDph'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji SD(Registr plátců spotřební daně) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeSd'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji ISIR(Insolvenční rejstřík) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeIr'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji CEÚ(Centrální evidence úpadců) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeCeu'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji RŠ(Registr škol) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeRs'?: string;
    /**
     * Stav ekonomického subjektu ve zdroji RED(Registr evidence dotací) - kód (ciselnikKod: StavZdroje, zdroj: com)
     * @type {string}
     * @memberof APISharedLibsSeznamRegistraci
     */
    'stavZdrojeRed'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsSkolaZarizeni
 */
export interface APISharedLibsSkolaZarizeni {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsKontaktyRs}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'kontakty'?: APISharedLibsKontaktyRs;
    /**
     * IZO - resortní identifikátor
     * @type {string}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'izo'?: string;
    /**
     * Jméno subjektu
     * @type {string}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'obchodniJmeno'?: string;
    /**
     * Zkrácené jméno subjektu
     * @type {string}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'obchodniJmenoZkracene'?: string;
    /**
     * Typ školy
     * @type {string}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'typSkoly'?: string;
    /**
     * 
     * @type {APISharedLibsAngazovanySubjektRsAdresa}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'adresa'?: APISharedLibsAngazovanySubjektRsAdresa;
    /**
     * Datum vzniku
     * @type {string}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku
     * @type {string}
     * @memberof APISharedLibsSkolaZarizeni
     */
    'datumZaniku'?: string;
}
/**
 * Statistické informace o subjektu
 * @export
 * @interface APISharedLibsStatistickeUdaje
 */
export interface APISharedLibsStatistickeUdaje {
    [key: string]: any;

    /**
     * Institucinální sektor dle ESA2010 - kód (ciselnikKod: FceVladnichInstituci)
     * @type {string}
     * @memberof APISharedLibsStatistickeUdaje
     */
    'institucionalniSektor2010'?: string;
    /**
     * Kategorie dle počtu pracovníků - kód (ciselnikKod: KategoriePoctuPracovniku, zdroj: res)
     * @type {string}
     * @memberof APISharedLibsStatistickeUdaje
     */
    'kategoriePoctuPracovniku'?: string;
}
/**
 * Informace o způsobu jednání právnické osoby.
 * @export
 * @interface APISharedLibsStatutarniOrgan
 */
export interface APISharedLibsStatutarniOrgan {
    [key: string]: any;

    /**
     * Platnost záznamu od data
     * @type {string}
     * @memberof APISharedLibsStatutarniOrgan
     */
    'platnostOd'?: string;
    /**
     * Texty způsobu jednání právnické osoby.
     * @type {string}
     * @memberof APISharedLibsStatutarniOrgan
     */
    'popis'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsStatutatniOrganRcns
 */
export interface APISharedLibsStatutatniOrganRcns {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsSubjektCirkev}
     * @memberof APISharedLibsStatutatniOrganRcns
     */
    'subjektCirkev'?: APISharedLibsSubjektCirkev;
    /**
     * 
     * @type {APISharedLibsSubjektSvazCirkvi}
     * @memberof APISharedLibsStatutatniOrganRcns
     */
    'subjektSvazCirkvi'?: APISharedLibsSubjektSvazCirkvi;
    /**
     * 
     * @type {APISharedLibsSubjektPravnickaOsoba}
     * @memberof APISharedLibsStatutatniOrganRcns
     */
    'subjektPravnickaOsoba'?: APISharedLibsSubjektPravnickaOsoba;
}
/**
 * Stav, ve kterém se subjekt nachází, s udáním počátku platnosti.
 * @export
 * @interface APISharedLibsStavSubjektu
 */
export interface APISharedLibsStavSubjektu {
    [key: string]: any;

    /**
     * Popis stavu subjektu
     * @type {string}
     * @memberof APISharedLibsStavSubjektu
     */
    'popis'?: string;
    /**
     * Platnost záznamu od data
     * @type {string}
     * @memberof APISharedLibsStavSubjektu
     */
    'platnostOd'?: string;
    /**
     * Stav ekonomického subjektu - kód (ciselnikKod: StavSubjektu(zdroj:rpsh)
     * @type {string}
     * @memberof APISharedLibsStavSubjektu
     */
    'stavSubjektu'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsSubjektCirkev
 */
export interface APISharedLibsSubjektCirkev {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanySubjektRcns>}
     * @memberof APISharedLibsSubjektCirkev
     */
    'organy'?: Array<APISharedLibsAngazovanySubjektRcns>;
    /**
     * Datum registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkev
     */
    'datumRegistracePrav'?: string;
    /**
     * Číslo registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkev
     */
    'cisloRegistracePrav'?: string;
    /**
     * IČO nástupce zrušené právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkev
     */
    'icoNastupce'?: string;
    /**
     * Datum zrušení registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkev
     */
    'datumZruseniRegistracePrav'?: string;
    /**
     * 
     * @type {Array<APISharedLibsPravoZvlastni>}
     * @memberof APISharedLibsSubjektCirkev
     */
    'pravoZvlastni'?: Array<APISharedLibsPravoZvlastni>;
    /**
     * Číslo zrušení registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkev
     */
    'cisloZruseniRegistracePrav'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsSubjektCirkevZaklad
 */
export interface APISharedLibsSubjektCirkevZaklad {
    [key: string]: any;

    /**
     * Datum registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkevZaklad
     */
    'datumRegistracePrav'?: string;
    /**
     * Číslo registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkevZaklad
     */
    'cisloRegistracePrav'?: string;
    /**
     * IČO nástupce zrušené právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkevZaklad
     */
    'icoNastupce'?: string;
    /**
     * Datum zrušení registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkevZaklad
     */
    'datumZruseniRegistracePrav'?: string;
    /**
     * 
     * @type {Array<APISharedLibsPravoZvlastni>}
     * @memberof APISharedLibsSubjektCirkevZaklad
     */
    'pravoZvlastni'?: Array<APISharedLibsPravoZvlastni>;
    /**
     * Číslo zrušení registrace práv dané církvi.
     * @type {string}
     * @memberof APISharedLibsSubjektCirkevZaklad
     */
    'cisloZruseniRegistracePrav'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsSubjektPravnickaOsoba
 */
export interface APISharedLibsSubjektPravnickaOsoba {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRcns>}
     * @memberof APISharedLibsSubjektPravnickaOsoba
     */
    'organy'?: Array<APISharedLibsAngazovanaOsobaRcns>;
    /**
     * IČO zřizovatele právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektPravnickaOsoba
     */
    'icoZrizovatel'?: string;
    /**
     * IČO nástupce zrušené právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektPravnickaOsoba
     */
    'icoNastupce'?: string;
    /**
     * Způsob zruseni registrace - kód
     * @type {number}
     * @memberof APISharedLibsSubjektPravnickaOsoba
     */
    'zpusobZruseniRegistrace'?: number;
}
/**
 * 
 * @export
 * @interface APISharedLibsSubjektPravnickaOsobaZaklad
 */
export interface APISharedLibsSubjektPravnickaOsobaZaklad {
    [key: string]: any;

    /**
     * IČO zřizovatele právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektPravnickaOsobaZaklad
     */
    'icoZrizovatel'?: string;
    /**
     * IČO nástupce zrušené právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektPravnickaOsobaZaklad
     */
    'icoNastupce'?: string;
    /**
     * Způsob zruseni registrace - kód
     * @type {number}
     * @memberof APISharedLibsSubjektPravnickaOsobaZaklad
     */
    'zpusobZruseniRegistrace'?: number;
}
/**
 * 
 * @export
 * @interface APISharedLibsSubjektSvazCirkvi
 */
export interface APISharedLibsSubjektSvazCirkvi {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanySubjektRcns>}
     * @memberof APISharedLibsSubjektSvazCirkvi
     */
    'organy'?: Array<APISharedLibsAngazovanySubjektRcns>;
    /**
     * 
     * @type {Array<APISharedLibsClenSvazu>}
     * @memberof APISharedLibsSubjektSvazCirkvi
     */
    'clenoveSvazu'?: Array<APISharedLibsClenSvazu>;
    /**
     * IČO nástupce zrušené právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektSvazCirkvi
     */
    'icoNastupce'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsSubjektSvazCirkviZaklad
 */
export interface APISharedLibsSubjektSvazCirkviZaklad {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsClenSvazu>}
     * @memberof APISharedLibsSubjektSvazCirkviZaklad
     */
    'clenoveSvazu'?: Array<APISharedLibsClenSvazu>;
    /**
     * IČO nástupce zrušené právnické osoby.
     * @type {string}
     * @memberof APISharedLibsSubjektSvazCirkviZaklad
     */
    'icoNastupce'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsUpadekCeu
 */
export interface APISharedLibsUpadekCeu {
    [key: string]: any;

    /**
     * Podnět
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'podnet'?: string;
    /**
     * Spisová značka
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'spisZn'?: string;
    /**
     * Datum
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'datum'?: string;
    /**
     * Odkaz na detail úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'detail'?: string;
    /**
     * Odkaz na usnesení
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'usneseni'?: string;
    /**
     * Akce úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'akceUpadek'?: string;
    /**
     * Typ úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'typUpadek'?: string;
    /**
     * Soud rozhodující o úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeu
     */
    'soudUpadek'?: string;
}
/**
 * Tabulka úpadků
 * @export
 * @interface APISharedLibsUpadekCeuVerejny
 */
export interface APISharedLibsUpadekCeuVerejny {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsAngazovanaOsobaCeu}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'spravceUpadku'?: APISharedLibsAngazovanaOsobaCeu;
    /**
     * Podnět
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'podnet'?: string;
    /**
     * Spisová značka
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'spisZn'?: string;
    /**
     * Datum
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'datum'?: string;
    /**
     * Odkaz na detail úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'detail'?: string;
    /**
     * Odkaz na usnesení
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'usneseni'?: string;
    /**
     * Akce úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'akceUpadek'?: string;
    /**
     * Typ úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'typUpadek'?: string;
    /**
     * Soud rozhodující o úpadku
     * @type {string}
     * @memberof APISharedLibsUpadekCeuVerejny
     */
    'soudUpadek'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje CEÚ
 * @export
 * @interface APISharedLibsZaznamCeu
 */
export interface APISharedLibsZaznamCeu {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsUpadekCeuVerejny>}
     * @memberof APISharedLibsZaznamCeu
     */
    'upadek'?: Array<APISharedLibsUpadekCeuVerejny>;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamCeu
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamCeu
     */
    'dic'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZaznamNrpzs
 */
export interface APISharedLibsZaznamNrpzs {
    [key: string]: any;

    /**
     * Pořadové číslo zařízení
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'poradoveCisloZar'?: string;
    /**
     * Pořadové číslo pracovistě
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'poradoveCisloPrac'?: string;
    /**
     * Typ ekonomického subjektu - forma hospodaření - kód (ciselnikKod: TypSubjektu, zdroj: nrpzs)
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'typSubjektu'?: string;
    /**
     * Druh zdravotnického zařízení - kód (ciselnikKod: DruhZarizeni, zdroj: nrpzs)
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'druhZarizeni'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'primarniZaznam'?: boolean;
    /**
     * Typ zřizovatele zdravotnického zařízení - kód (ciselnikKod: Zrizovatel, zdroj: nrpzs)
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'typZrizovatele'?: string;
    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaNrpzs>}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'angazovaneOsoby'?: Array<APISharedLibsAngazovanaOsobaNrpzs>;
    /**
     * 
     * @type {APISharedLibsKontakty}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'kontakty'?: APISharedLibsKontakty;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamNrpzs
     */
    'dic'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RCNS
 * @export
 * @interface APISharedLibsZaznamRcns
 */
export interface APISharedLibsZaznamRcns {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsStatutatniOrganRcns}
     * @memberof APISharedLibsZaznamRcns
     */
    'statutarniOrgan'?: APISharedLibsStatutatniOrganRcns;
    /**
     * 
     * @type {Array<APISharedLibsAngazmaRcns>}
     * @memberof APISharedLibsZaznamRcns
     */
    'likvidatori'?: Array<APISharedLibsAngazmaRcns>;
    /**
     * 
     * @type {Array<APISharedLibsAngazmaRcns>}
     * @memberof APISharedLibsZaznamRcns
     */
    'konkurzniSpravci'?: Array<APISharedLibsAngazmaRcns>;
    /**
     * 
     * @type {Array<APISharedLibsAngazmaRcns>}
     * @memberof APISharedLibsZaznamRcns
     */
    'opatrovnici'?: Array<APISharedLibsAngazmaRcns>;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRcns
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRcns
     */
    'dic'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZaznamRcnsZaklad
 */
export interface APISharedLibsZaznamRcnsZaklad {
    [key: string]: any;

    /**
     * Typ subjektu - kód (ciselnikKod: TypSubjektuRcns)
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'typSubjektu'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'stat'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'primarniZaznam'?: boolean;
    /**
     * 
     * @type {APISharedLibsRegistraceRcns}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'registrace'?: APISharedLibsRegistraceRcns;
    /**
     * 
     * @type {Array<APISharedLibsZpusobJednani>}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'zpusobJednani'?: Array<APISharedLibsZpusobJednani>;
    /**
     * 
     * @type {Array<APISharedLibsAdresaRcns>}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'adresySubjektu'?: Array<APISharedLibsAdresaRcns>;
    /**
     * Identifikátor záznamu ve zdrojovém registru.
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'zdrojId'?: string;
    /**
     * Platnost subjekt v registru od
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'platnostOd'?: string;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRcnsZaklad
     */
    'dic'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RES
 * @export
 * @interface APISharedLibsZaznamRes
 */
export interface APISharedLibsZaznamRes {
    [key: string]: any;

    /**
     * Právní forma ROS - kód (ciselnikKod: PravniFormaRos, zdroj:res)
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'pravniFormaRos'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedLibsZaznamRes
     */
    'czNace'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedLibsZaznamRes
     */
    'doplnkovePravniFormy'?: Array<string>;
    /**
     * 
     * @type {APISharedLibsStatistickeUdaje}
     * @memberof APISharedLibsZaznamRes
     */
    'statistickeUdaje'?: APISharedLibsStatistickeUdaje;
    /**
     * Základní územní jednotka sídla organizace - kód (ciselnikKod: ZakladniUzemniJednotka)
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'zakladniUzemniJednotka'?: string;
    /**
     * Logická informace o primárním(hlavním) záznamu ekonomického subjektu.
     * @type {boolean}
     * @memberof APISharedLibsZaznamRes
     */
    'primarniZaznam'?: boolean;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRes
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRes
     */
    'dic'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RPSH
 * @export
 * @interface APISharedLibsZaznamRpsh
 */
export interface APISharedLibsZaznamRpsh {
    [key: string]: any;

    /**
     * Číslo registrace
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'cisloRegistrace'?: string;
    /**
     * Typ subjektu - strana/hnutí - kód (ciselnikKod: TypSubjektuRpsh)
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'typSubjektu'?: string;
    /**
     * Obchodní jméno zkrácené
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'obchodniJmenoZkratka'?: string;
    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRpsh>}
     * @memberof APISharedLibsZaznamRpsh
     */
    'angazovaneOsoby'?: Array<APISharedLibsAngazovanaOsobaRpsh>;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedLibsZaznamRpsh
     */
    'primarniZaznam'?: boolean;
    /**
     * 
     * @type {Array<APISharedLibsZmenaStanov>}
     * @memberof APISharedLibsZaznamRpsh
     */
    'zmenaStanov'?: Array<APISharedLibsZmenaStanov>;
    /**
     * 
     * @type {Array<APISharedLibsStatutarniOrgan>}
     * @memberof APISharedLibsZaznamRpsh
     */
    'statutarniOrgan'?: Array<APISharedLibsStatutarniOrgan>;
    /**
     * 
     * @type {Array<APISharedLibsStavSubjektu>}
     * @memberof APISharedLibsZaznamRpsh
     */
    'stavSubjektu'?: Array<APISharedLibsStavSubjektu>;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRpsh
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRpsh
     */
    'dic'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZaznamRs
 */
export interface APISharedLibsZaznamRs {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanySubjektRs>}
     * @memberof APISharedLibsZaznamRs
     */
    'angazovanySubjekt'?: Array<APISharedLibsAngazovanySubjektRs>;
    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRs>}
     * @memberof APISharedLibsZaznamRs
     */
    'angazovanaOsoba'?: Array<APISharedLibsAngazovanaOsobaRs>;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRs
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRs
     */
    'dic'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RŠ
 * @export
 * @interface APISharedLibsZaznamRsZaklad
 */
export interface APISharedLibsZaznamRsZaklad {
    [key: string]: any;

    /**
     * REDIZO - resortní identifikátor
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'redizo'?: string;
    /**
     * Zkrácené obchodní jméno
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'obchodniJmenoZkracene'?: string;
    /**
     * 
     * @type {APISharedLibsKontaktyRs}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'kontakty'?: APISharedLibsKontaktyRs;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'primarniZaznam'?: boolean;
    /**
     * 
     * @type {Array<APISharedLibsSkolaZarizeni>}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'skolyAZarizeni'?: Array<APISharedLibsSkolaZarizeni>;
    /**
     * Typ subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'typSubjektu'?: string;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRsZaklad
     */
    'dic'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RŽP
 * @export
 * @interface APISharedLibsZaznamRzp
 */
export interface APISharedLibsZaznamRzp {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsAngazovanySubjektRzp>}
     * @memberof APISharedLibsZaznamRzp
     */
    'souvisejiciSubjekty'?: Array<APISharedLibsAngazovanySubjektRzp>;
    /**
     * 
     * @type {APISharedLibsAngazovanaOsobaRzp}
     * @memberof APISharedLibsZaznamRzp
     */
    'osobaPodnikatel'?: APISharedLibsAngazovanaOsobaRzp;
    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRzp>}
     * @memberof APISharedLibsZaznamRzp
     */
    'angazovaneOsoby'?: Array<APISharedLibsAngazovanaOsobaRzp>;
    /**
     * 
     * @type {Array<APISharedLibsZivnost>}
     * @memberof APISharedLibsZaznamRzp
     */
    'zivnosti'?: Array<APISharedLibsZivnost>;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRzp
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRzp
     */
    'dic'?: string;
}
/**
 * Detailní informace o ekonomickém subjektu ze zdroje RŽP
 * @export
 * @interface APISharedLibsZaznamRzpZaklad
 */
export interface APISharedLibsZaznamRzpZaklad {
    [key: string]: any;

    /**
     * 
     * @type {APISharedLibsInsolvencniRizeni}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'insolvencniRizeni'?: APISharedLibsInsolvencniRizeni;
    /**
     * Datum doručení prvního výpisu sloužícího jako průkaz živnostenského podnikání,
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'datumDoruceniVypisu'?: string;
    /**
     * 
     * @type {Array<APISharedLibsEkonomickySubjektRzpAdresa>}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'adresySubjektu'?: Array<APISharedLibsEkonomickySubjektRzpAdresa>;
    /**
     * Typ subjektu - kód (ciselnikKod: TypSubjektu, zdroj: rzp)
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'typSubjektu'?: string;
    /**
     * Živnostenský úřadu - kód
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'zivnostenskyUrad'?: string;
    /**
     * Název organizační složky
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'organizacniSlozka'?: string;
    /**
     * 
     * @type {APISharedLibsZivnostiRzpStav}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'zivnostiStav'?: APISharedLibsZivnostiRzpStav;
    /**
     * Datum zápisu do Obchodního nebo podobného rejstříku
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'datumZapisuVr'?: string;
    /**
     * 
     * @type {APISharedLibsProvozovnyRzpStav}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'provozovnyStav'?: APISharedLibsProvozovnyRzpStav;
    /**
     * Identifikace primárního záznamu  pozn.: U multiplicitních registrací je pouze jedna registrace primární
     * @type {boolean}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'primarniZaznam'?: boolean;
    /**
     * Kód státu  (ciselnikKod: Stat, zdroj:com)
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'kodStatu'?: string;
    /**
     * Identifikační číslo osoby - IČO
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'ico'?: string;
    /**
     * Obchodní jméno ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'obchodniJmeno'?: string;
    /**
     * 
     * @type {APISharedLibsEkonomickySubjektZakladSidlo}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'sidlo'?: APISharedLibsEkonomickySubjektZakladSidlo;
    /**
     * Právní forma - kód (ciselnikKod: PravniForma, zdroj: res, com)
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'pravniForma'?: string;
    /**
     * Správně příslušný finanční úřad - kód (ciselnikKod: FinancniUrad, zdroj:ufo)
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'financniUrad'?: string;
    /**
     * Datum vzniku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku ekonomického subjektu
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'datumZaniku'?: string;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Daňové identifikační číslo ve formátu CZNNNNNNNNNN
     * @type {string}
     * @memberof APISharedLibsZaznamRzpZaklad
     */
    'dic'?: string;
}
/**
 * Živnosti, které ekonomický subjekt provozuje nebo provozoval ve zdroji RŽP
 * @export
 * @interface APISharedLibsZivnost
 */
export interface APISharedLibsZivnost {
    [key: string]: any;

    /**
     * 
     * @type {Array<APISharedLibsZivnostOborCinnosti>}
     * @memberof APISharedLibsZivnost
     */
    'oboryCinnosti'?: Array<APISharedLibsZivnostOborCinnosti>;
    /**
     * 
     * @type {Array<APISharedLibsAngazovanaOsobaRzp>}
     * @memberof APISharedLibsZivnost
     */
    'odpovedniZastupci'?: Array<APISharedLibsAngazovanaOsobaRzp>;
    /**
     * 
     * @type {Array<APISharedLibsProvozovna>}
     * @memberof APISharedLibsZivnost
     */
    'provozovny'?: Array<APISharedLibsProvozovna>;
    /**
     * Datum vzniku živnosti
     * @type {string}
     * @memberof APISharedLibsZivnost
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku živnosti
     * @type {string}
     * @memberof APISharedLibsZivnost
     */
    'datumZaniku'?: string;
    /**
     * Platnost živnosti do data
     * @type {string}
     * @memberof APISharedLibsZivnost
     */
    'platnostDo'?: string;
    /**
     * Předmět podnikáni živnosti
     * @type {string}
     * @memberof APISharedLibsZivnost
     */
    'predmetPodnikani'?: string;
    /**
     * 
     * @type {Array<APISharedLibsZivnostProvozovnaPozastaveni>}
     * @memberof APISharedLibsZivnost
     */
    'pozastaveniZivnosti'?: Array<APISharedLibsZivnostProvozovnaPozastaveni>;
    /**
     * 
     * @type {Array<APISharedLibsZivnostPodminky>}
     * @memberof APISharedLibsZivnost
     */
    'podminkyProvozovaniZivnosti'?: Array<APISharedLibsZivnostPodminky>;
    /**
     * 
     * @type {Array<APISharedLibsZivnostPreruseni>}
     * @memberof APISharedLibsZivnost
     */
    'preruseniZivnosti'?: Array<APISharedLibsZivnostPreruseni>;
    /**
     * 
     * @type {Array<APISharedLibsZivnostBezOZ>}
     * @memberof APISharedLibsZivnost
     */
    'zivnostBezOz'?: Array<APISharedLibsZivnostBezOZ>;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZivnost
     */
    'datumAktualizace'?: string;
    /**
     * Druh živnosti - kód (ciselnikKod: DruhZivnosti)
     * @type {string}
     * @memberof APISharedLibsZivnost
     */
    'druhZivnosti'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZivnostBezOZ
 */
export interface APISharedLibsZivnostBezOZ {
    [key: string]: any;

    /**
     * Zahájení vykonávání živnosti bez odpovědného zástupce od data
     * @type {string}
     * @memberof APISharedLibsZivnostBezOZ
     */
    'platnostOd'?: string;
    /**
     * Ukončení vykonávání živnosti bez odpovědného zástupce od data
     * @type {string}
     * @memberof APISharedLibsZivnostBezOZ
     */
    'platnostDo'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZivnostOborCinnosti
 */
export interface APISharedLibsZivnostOborCinnosti {
    [key: string]: any;

    /**
     * Platnost záznamu od data
     * @type {string}
     * @memberof APISharedLibsZivnostOborCinnosti
     */
    'platnostOd'?: string;
    /**
     * Platnost záznamu do data
     * @type {string}
     * @memberof APISharedLibsZivnostOborCinnosti
     */
    'platnostDo'?: string;
    /**
     * Název oboru
     * @type {string}
     * @memberof APISharedLibsZivnostOborCinnosti
     */
    'oborNazev'?: string;
}
/**
 * Podmínky pro vykonávání živnosti
 * @export
 * @interface APISharedLibsZivnostPodminky
 */
export interface APISharedLibsZivnostPodminky {
    [key: string]: any;

    /**
     * Platnost podmínky od data
     * @type {string}
     * @memberof APISharedLibsZivnostPodminky
     */
    'platnostOd'?: string;
    /**
     * Platnost podmínky do data
     * @type {string}
     * @memberof APISharedLibsZivnostPodminky
     */
    'platnostDo'?: string;
    /**
     * Text podmínky provozování živnosti
     * @type {string}
     * @memberof APISharedLibsZivnostPodminky
     */
    'podminka'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZivnostPreruseni
 */
export interface APISharedLibsZivnostPreruseni {
    [key: string]: any;

    /**
     * Platnost přerušení živnosti od data
     * @type {string}
     * @memberof APISharedLibsZivnostPreruseni
     */
    'platnostOd'?: string;
    /**
     * Platnost přerušení živnosti do data
     * @type {string}
     * @memberof APISharedLibsZivnostPreruseni
     */
    'platnostDo'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZivnostProvozovnaPozastaveni
 */
export interface APISharedLibsZivnostProvozovnaPozastaveni {
    [key: string]: any;

    /**
     * Platnost pozastavení živnosti od data
     * @type {string}
     * @memberof APISharedLibsZivnostProvozovnaPozastaveni
     */
    'platnostOd'?: string;
    /**
     * Platnost pozastavení živnosti do data
     * @type {string}
     * @memberof APISharedLibsZivnostProvozovnaPozastaveni
     */
    'platnostDo'?: string;
    /**
     * Popis rozsahu pozastavení živnosti
     * @type {string}
     * @memberof APISharedLibsZivnostProvozovnaPozastaveni
     */
    'rozsahPozastaveni'?: string;
}
/**
 * Živnosti, které ekonomický subjekt provozuje nebo provozoval
 * @export
 * @interface APISharedLibsZivnostZaklad
 */
export interface APISharedLibsZivnostZaklad {
    [key: string]: any;

    /**
     * Datum vzniku živnosti
     * @type {string}
     * @memberof APISharedLibsZivnostZaklad
     */
    'datumVzniku'?: string;
    /**
     * Datum zániku živnosti
     * @type {string}
     * @memberof APISharedLibsZivnostZaklad
     */
    'datumZaniku'?: string;
    /**
     * Platnost živnosti do data
     * @type {string}
     * @memberof APISharedLibsZivnostZaklad
     */
    'platnostDo'?: string;
    /**
     * Předmět podnikáni živnosti
     * @type {string}
     * @memberof APISharedLibsZivnostZaklad
     */
    'predmetPodnikani'?: string;
    /**
     * 
     * @type {Array<APISharedLibsZivnostProvozovnaPozastaveni>}
     * @memberof APISharedLibsZivnostZaklad
     */
    'pozastaveniZivnosti'?: Array<APISharedLibsZivnostProvozovnaPozastaveni>;
    /**
     * 
     * @type {Array<APISharedLibsZivnostPodminky>}
     * @memberof APISharedLibsZivnostZaklad
     */
    'podminkyProvozovaniZivnosti'?: Array<APISharedLibsZivnostPodminky>;
    /**
     * 
     * @type {Array<APISharedLibsZivnostPreruseni>}
     * @memberof APISharedLibsZivnostZaklad
     */
    'preruseniZivnosti'?: Array<APISharedLibsZivnostPreruseni>;
    /**
     * 
     * @type {Array<APISharedLibsZivnostBezOZ>}
     * @memberof APISharedLibsZivnostZaklad
     */
    'zivnostBezOz'?: Array<APISharedLibsZivnostBezOZ>;
    /**
     * Datum aktualizace záznamu
     * @type {string}
     * @memberof APISharedLibsZivnostZaklad
     */
    'datumAktualizace'?: string;
    /**
     * Druh živnosti - kód (ciselnikKod: DruhZivnosti)
     * @type {string}
     * @memberof APISharedLibsZivnostZaklad
     */
    'druhZivnosti'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLibsZivnostiRzpStav
 */
export interface APISharedLibsZivnostiRzpStav {
    [key: string]: any;

    /**
     * Celkový počet aktivních živností
     * @type {number}
     * @memberof APISharedLibsZivnostiRzpStav
     */
    'pocetAktivnich'?: number;
    /**
     * Celkový počet zaniklých živností
     * @type {number}
     * @memberof APISharedLibsZivnostiRzpStav
     */
    'pocetZaniklych'?: number;
    /**
     * Celkový počet zaniklých živností
     * @type {number}
     * @memberof APISharedLibsZivnostiRzpStav
     */
    'pocetPozastavenych'?: number;
    /**
     * Celkový počet zaniklých živností
     * @type {number}
     * @memberof APISharedLibsZivnostiRzpStav
     */
    'pocetPrerusenych'?: number;
    /**
     * Celkový počet živností
     * @type {number}
     * @memberof APISharedLibsZivnostiRzpStav
     */
    'pocetCelkem'?: number;
}
/**
 * Seznam datumů změn stanov.
 * @export
 * @interface APISharedLibsZmenaStanov
 */
export interface APISharedLibsZmenaStanov {
    [key: string]: any;

    /**
     * Datum změny stanov.
     * @type {string}
     * @memberof APISharedLibsZmenaStanov
     */
    'datumZmeny'?: string;
}
/**
 * tabulka způsobů jednání subjektu
 * @export
 * @interface APISharedLibsZpusobJednani
 */
export interface APISharedLibsZpusobJednani {
    [key: string]: any;

    /**
     * popis způsobu jednání
     * @type {string}
     * @memberof APISharedLibsZpusobJednani
     */
    'popis'?: string;
    /**
     * počátek platnosti způsobu jednání dle popisu
     * @type {string}
     * @memberof APISharedLibsZpusobJednani
     */
    'platnostOd'?: string;
    /**
     * konec platnosti způsobu jednání dle popisu
     * @type {string}
     * @memberof APISharedLibsZpusobJednani
     */
    'platnostDo'?: string;
}
/**
 * 
 * @export
 * @interface APISharedLocaleDto
 */
export interface APISharedLocaleDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'defaultLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'currencyPosition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'dateFormat'?: string | null;
    /**
     * DatePicker uses proprietary culture/locale token map for dates -_-
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'datePickerDateFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'timeFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'numberFormat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocaleDto
     */
    'defaultPhonePrefix'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedLocationData
 */
export interface APISharedLocationData {
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'regNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'vatNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLocationData
     */
    'post'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedLoginBookingDto
 */
export interface APISharedLoginBookingDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginBookingDto
     */
    'idXdentCompanyRestriction'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginBookingDto
     */
    'emailOrUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginBookingDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginBookingDto
     */
    'os'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginBookingDto
     */
    'fireBaseDeviceToken'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedLoginDto
 */
export interface APISharedLoginDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginDto
     */
    'emailOrUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginDto
     */
    'os'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedLoginDto
     */
    'fireBaseDeviceToken'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedMedicalReportDetailDto
 */
export interface APISharedMedicalReportDetailDto {
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult}
     * @memberof APISharedMedicalReportDetailDto
     */
    'medicalReport'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult;
    /**
     * 
     * @type {Array<APISharedMedicalReportInsuranceDto>}
     * @memberof APISharedMedicalReportDetailDto
     */
    'insurance'?: Array<APISharedMedicalReportInsuranceDto> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult>}
     * @memberof APISharedMedicalReportDetailDto
     */
    'diagnose'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult> | null;
}
/**
 * 
 * @export
 * @interface APISharedMedicalReportInsuranceDto
 */
export interface APISharedMedicalReportInsuranceDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedMedicalReportInsuranceDto
     */
    'codeDiagnosis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedMedicalReportInsuranceDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedMedicalReportInsuranceDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {Array<APISharedMedicalReportInsuranceItemDto>}
     * @memberof APISharedMedicalReportInsuranceDto
     */
    'items'?: Array<APISharedMedicalReportInsuranceItemDto> | null;
}
/**
 * 
 * @export
 * @interface APISharedMedicalReportInsuranceItemDto
 */
export interface APISharedMedicalReportInsuranceItemDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'performed'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'localization'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'diagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'paymentTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'amount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'patiencePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'insurancePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedMedicalReportInsuranceItemDto
     */
    'laboratoryPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem
 */
export interface APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem {
    /**
     * 
     * @type {string}
     * @memberof APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem
     */
    'date'?: string | null;
    /**
     * 
     * @type {Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenHourItem>}
     * @memberof APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem
     */
    'openHours'?: Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenHourItem> | null;
}
/**
 * 
 * @export
 * @interface APISharedModelsOnlineBookingOnlineBookingDtoOpenHourItem
 */
export interface APISharedModelsOnlineBookingOnlineBookingDtoOpenHourItem {
    /**
     * 
     * @type {boolean}
     * @memberof APISharedModelsOnlineBookingOnlineBookingDtoOpenHourItem
     */
    'afternoon'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedModelsOnlineBookingOnlineBookingDtoOpenHourItem
     */
    'dateTime'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedOnlineBookingResultDto
 */
export interface APISharedOnlineBookingResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof APISharedOnlineBookingResultDto
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingResultDto
     */
    'idAppointment'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedOnlineBookingSendByMiniWebsiteDto
 */
export interface APISharedOnlineBookingSendByMiniWebsiteDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'idResource'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'idService'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'selectedYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'selectedMonth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'selectedDay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'selectedHour'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'selectedMinute'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'promo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'clientRequest'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'optIn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'findUs'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'custom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'serviceName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'isPayment'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'marketing'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'dateOfBirth'?: string | null;
    /**
     * IdBudgetPlanService z iklientInfo EP pro OB
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'planIdService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedOnlineBookingSendByMiniWebsiteDto
     */
    'idPartnership'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedOpenHourWrapperX2Dto
 */
export interface APISharedOpenHourWrapperX2Dto {
    /**
     * 
     * @type {Array<APISharedCalendarOpenHourX2DtoHolder>}
     * @memberof APISharedOpenHourWrapperX2Dto
     */
    'cacheDay'?: Array<APISharedCalendarOpenHourX2DtoHolder> | null;
}
/**
 * 
 * @export
 * @interface APISharedOptionsServiceAvailabilityOptions
 */
export interface APISharedOptionsServiceAvailabilityOptions {
    /**
     * 
     * @type {APISharedOptionsXdentOptions}
     * @memberof APISharedOptionsServiceAvailabilityOptions
     */
    'xdent'?: APISharedOptionsXdentOptions;
}
/**
 * 
 * @export
 * @interface APISharedOptionsXdentOptions
 */
export interface APISharedOptionsXdentOptions {
    /**
     * 
     * @type {boolean}
     * @memberof APISharedOptionsXdentOptions
     */
    'formAnamnesisHistory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedOptionsXdentOptions
     */
    'formV1Anamnesis'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedProductFiltersDTO
 */
export interface APISharedProductFiltersDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedProductFiltersDTO
     */
    'filter'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedProductFiltersDTO
     */
    'priceRangeFrom'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedProductFiltersDTO
     */
    'priceRangeTo'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof APISharedProductFiltersDTO
     */
    'tags'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof APISharedProductFiltersDTO
     */
    'categories'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof APISharedProductFiltersDTO
     */
    'attributeTerms'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedProductFiltersDTO
     */
    'orderType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedProductFiltersDTO
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedProductFiltersDTO
     */
    'endIndex'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedProductFiltersDTO
     */
    'nestedCategories'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedReadMessagesDto
 */
export interface APISharedReadMessagesDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedReadMessagesDto
     */
    'idChatMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedRecurrenceInfoDto
 */
export interface APISharedRecurrenceInfoDto {
    /**
     * 
     * @type {any}
     * @memberof APISharedRecurrenceInfoDto
     */
    'id'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedRecurrenceInfoDto
     */
    'timeZoneId'?: string | null;
    /**
     * 0 - Sunday, 1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday
     * @type {string}
     * @memberof APISharedRecurrenceInfoDto
     */
    'firstDayOfWeek'?: APISharedRecurrenceInfoDtoFirstDayOfWeekEnum;
    /**
     * 
     * @type {string}
     * @memberof APISharedRecurrenceInfoDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedRecurrenceInfoDto
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedRecurrenceInfoDto
     */
    'duration'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedRecurrenceInfoDto
     */
    'allDay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'range'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'periodicity'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'weekDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'dayNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'weekOfMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedRecurrenceInfoDto
     */
    'occurrenceCount'?: number;
}

export const APISharedRecurrenceInfoDtoFirstDayOfWeekEnum = {
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday'
} as const;

export type APISharedRecurrenceInfoDtoFirstDayOfWeekEnum = typeof APISharedRecurrenceInfoDtoFirstDayOfWeekEnum[keyof typeof APISharedRecurrenceInfoDtoFirstDayOfWeekEnum];

/**
 * 
 * @export
 * @interface APISharedSMSAnswersDTO
 */
export interface APISharedSMSAnswersDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'color'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedSMSAnswersDTO
     */
    'confirmed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'contentAnswer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'contentOriginal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'dateReceived'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'idAnswer'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedSMSAnswersDTO
     */
    'idAnswerOriginal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'idResource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'idSMSOriginal'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedSMSAnswersDTO
     */
    'isNotify'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'phone'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedSMSAnswersDTO
     */
    'rowNumber'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedSMSAnswersDTO
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedSMSAnswersDTO
     */
    'staff'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedSendMessageDTO
 */
export interface APISharedSendMessageDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedSendMessageDTO
     */
    'idUser'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedSendMessageDTO
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedSendMessageDTO
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedSendMessageDTO
     */
    'customerMessage'?: boolean;
    /**
     * 0 - Message, 1 - Log, 2 - OrderAccept
     * @type {string}
     * @memberof APISharedSendMessageDTO
     */
    'messageType'?: APISharedSendMessageDTOMessageTypeEnum;
}

export const APISharedSendMessageDTOMessageTypeEnum = {
    Message: 'Message',
    Log: 'Log',
    OrderAccept: 'OrderAccept'
} as const;

export type APISharedSendMessageDTOMessageTypeEnum = typeof APISharedSendMessageDTOMessageTypeEnum[keyof typeof APISharedSendMessageDTOMessageTypeEnum];

/**
 * 
 * @export
 * @interface APISharedServiceWithRelation
 */
export interface APISharedServiceWithRelation {
    /**
     * 
     * @type {string}
     * @memberof APISharedServiceWithRelation
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServiceWithRelation
     */
    'caption'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServiceWithRelation
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServiceWithRelation
     */
    'colorIndex'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServiceWithRelation
     */
    'position'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServiceWithRelation
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServiceWithRelation
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServiceWithRelation
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServiceWithRelation
     */
    'cost'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedServiceWithRelation
     */
    'visibility'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServiceWithRelation
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServiceWithRelation
     */
    'reminderAfterPeriod'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedServiceWithRelation
     */
    'idResources'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface APISharedServicesCurrencyRate
 */
export interface APISharedServicesCurrencyRate {
    /**
     * 
     * @type {number}
     * @memberof APISharedServicesCurrencyRate
     */
    'fixedRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServicesCurrencyRate
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServicesCurrencyRate
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServicesCurrencyRate
     */
    'rate'?: number;
}
/**
 * 
 * @export
 * @interface APISharedServicesCurrencyRateInternal
 */
export interface APISharedServicesCurrencyRateInternal {
    /**
     * 
     * @type {string}
     * @memberof APISharedServicesCurrencyRateInternal
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedServicesCurrencyRateInternal
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedServicesCurrencyRateInternal
     */
    'rate'?: number;
}
/**
 * 
 * @export
 * @interface APISharedStaffDayDuration
 */
export interface APISharedStaffDayDuration {
    /**
     * 
     * @type {string}
     * @memberof APISharedStaffDayDuration
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedStaffDayDuration
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedStaffDayDuration
     */
    'openHoursDuration'?: number;
}
/**
 * 
 * @export
 * @interface APISharedTreatmentPlanDto
 */
export interface APISharedTreatmentPlanDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'idPlan'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'idAuthor'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedTreatmentPlanDto
     */
    'isFileSigned'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'authorFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'authorDegree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedTreatmentPlanDto
     */
    'authorDegreeAfter'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedUnifiedNotification
 */
export interface APISharedUnifiedNotification {
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'titleMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'subTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'dateStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'idGlobal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedUnifiedNotification
     */
    'confirmed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUnifiedNotification
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedUpdateClientCategoriesDto
 */
export interface APISharedUpdateClientCategoriesDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesDto
     */
    'idProsthetics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesDto
     */
    'idEndodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesDto
     */
    'idPeriodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesDto
     */
    'idOrthodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesDto
     */
    'idImplantology'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedUpdateClientCategoriesInternal
 */
export interface APISharedUpdateClientCategoriesInternal {
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idProsthetics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idEndodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idPeriodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idOrthodontics'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdateClientCategoriesInternal
     */
    'idImplantology'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedUpdatedAppointmentValuesX2Dto
 */
export interface APISharedUpdatedAppointmentValuesX2Dto {
    /**
     * 
     * @type {Array<APISharedCalendarAppointmentX2Dto>}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'items'?: Array<APISharedCalendarAppointmentX2Dto> | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'controller'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'cacheContext'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'invoker'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'expiration'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedUpdatedAppointmentValuesX2Dto
     */
    'idGlobal'?: string;
}
/**
 * 
 * @export
 * @interface APISharedUploadGalleryFileInternal
 */
export interface APISharedUploadGalleryFileInternal {
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'idMaster'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'idGallery'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'idServer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'path'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'isUpdate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'fileContent'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APISharedUploadGalleryFileInternal
     */
    'systemTags'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface APISharedUserDto
 */
export interface APISharedUserDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedUserDto
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'nameIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'profilePhotoId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'phonePrefixId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserDto
     */
    'registrationDate'?: string | null;
    /**
     * 
     * @type {APISharedLocaleDto}
     * @memberof APISharedUserDto
     */
    'localeSettings'?: APISharedLocaleDto;
    /**
     * Logic, is from new iklient inverted.
     * @type {boolean}
     * @memberof APISharedUserDto
     */
    'isFromOldIklient'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedUserXdentDto
 */
export interface APISharedUserXdentDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'dob'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'icp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'idExpertise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedUserXdentDto
     */
    'insuranceAccess'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedUserXdentDto
     */
    'idProfilePhoto'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedWidgetDTO
 */
export interface APISharedWidgetDTO {
    /**
     * 
     * @type {string}
     * @memberof APISharedWidgetDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {APISharedWidgetDetailDTO}
     * @memberof APISharedWidgetDTO
     */
    'detail'?: APISharedWidgetDetailDTO;
}
/**
 * 
 * @export
 * @interface APISharedWidgetDetailDTO
 */
export interface APISharedWidgetDetailDTO {
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetDetailDTO
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetDetailDTO
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetDetailDTO
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetDetailDTO
     */
    'height'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedWidgetDetailDTO
     */
    'visible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetDetailDTO
     */
    'originalW'?: number;
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetDetailDTO
     */
    'originalH'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedWidgetDetailDTO
     */
    'filterToggled'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedWidgetLayoutMobileDto
 */
export interface APISharedWidgetLayoutMobileDto {
    /**
     * 
     * @type {number}
     * @memberof APISharedWidgetLayoutMobileDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharedWidgetLayoutMobileDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedWidgetLayoutMobileDto
     */
    'visible'?: boolean;
}
/**
 * 
 * @export
 * @interface APISharedXdentFormDto
 */
export interface APISharedXdentFormDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'idFormType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'languageTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'formTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedXdentFormDto
     */
    'published'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharedXdentFormDto
     */
    'isFavourite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {number}
     * @memberof APISharedXdentFormDto
     */
    'expirationDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentFormDto
     */
    'idFormXdent'?: string | null;
}
/**
 * 
 * @export
 * @interface APISharedXdentLoginDto
 */
export interface APISharedXdentLoginDto {
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentLoginDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentLoginDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APISharedXdentLoginDto
     */
    'fireBaseDeviceToken'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentControllersSafeSignaturesInitDevice
 */
export interface APIXdentControllersSafeSignaturesInitDevice {
    /**
     * 
     * @type {string}
     * @memberof APIXdentControllersSafeSignaturesInitDevice
     */
    'json'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentControllersSafeSignaturesSafeSignaturesCurrentCertsDto
 */
export interface APIXdentControllersSafeSignaturesSafeSignaturesCurrentCertsDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentControllersSafeSignaturesSafeSignaturesCurrentCertsDto
     */
    'safeSignaturesPublicCertBase64'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentControllersSafeSignaturesSafeSignaturesCurrentCertsDto
     */
    'thirdPartySealCertificateBase64'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentControllersXdentFormRequestActionDto
 */
export interface APIXdentControllersXdentFormRequestActionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentControllersXdentFormRequestActionDto
     */
    'idRequest'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOAnalyticsANALYTICSAGECLIENTS
 */
export interface APIXdentDTOAnalyticsANALYTICSAGECLIENTS {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'idAnalyticsAgeClients'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uSix'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uFifteen'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uEighteen'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uThirty'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uForty'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uFifty'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uSixty'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAGECLIENTS
     */
    'uMax'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
 */
export interface APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'idAnalyticsAppointments'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'appointmentsDone'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'appointmentsNotDone'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'appointmentsOnline'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS
     */
    'appointmentsXdent'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOAnalyticsANALYTICSDURATIONS
 */
export interface APIXdentDTOAnalyticsANALYTICSDURATIONS {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'idAnalyticsDurations'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'appointmentsDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSDURATIONS
     */
    'openHoursDuration'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
 */
export interface APIXdentDTOAnalyticsANALYTICSNEWCLIENTS {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'idAnalyticsNewClients'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'newClients'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSNEWCLIENTS
     */
    'newActiveClients'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOAnalyticsANALYTICSPAYMENTS
 */
export interface APIXdentDTOAnalyticsANALYTICSPAYMENTS {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'idAnalyticsPayment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'payment'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAnalyticsANALYTICSPAYMENTS
     */
    'paymentType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOAresAresSearchResult
 */
export interface APIXdentDTOAresAresSearchResult {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'regNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'district'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOAresAresSearchResult
     */
    'cityFull'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOBudgetPlanBudgetPlanDto
 */
export interface APIXdentDTOBudgetPlanBudgetPlanDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanBudgetPlanDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanBudgetPlanDto
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanBudgetPlanDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanBudgetPlanDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOBudgetPlanBudgetPlanDto
     */
    'isDispensarization'?: boolean;
    /**
     * 
     * @type {Array<APIXdentDTOBudgetPlanServiceDto>}
     * @memberof APIXdentDTOBudgetPlanBudgetPlanDto
     */
    'services'?: Array<APIXdentDTOBudgetPlanServiceDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOBudgetPlanDcRegionDto
 */
export interface APIXdentDTOBudgetPlanDcRegionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanDcRegionDto
     */
    'idItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanDcRegionDto
     */
    'idRegion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanDcRegionDto
     */
    'shortCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanDcRegionDto
     */
    'codelistCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanDcRegionDto
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOBudgetPlanServiceDto
 */
export interface APIXdentDTOBudgetPlanServiceDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'idBudgetPlan'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'dateVisit'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'isDateVisitLocked'?: boolean;
    /**
     * 
     * @type {APIXdentDTOBudgetPlanSomeOtherServiceDto}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'service'?: APIXdentDTOBudgetPlanSomeOtherServiceDto;
    /**
     * 
     * @type {Array<APIXdentDTOBudgetPlanServiceItemDto>}
     * @memberof APIXdentDTOBudgetPlanServiceDto
     */
    'items'?: Array<APIXdentDTOBudgetPlanServiceItemDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOBudgetPlanServiceItemDto
 */
export interface APIXdentDTOBudgetPlanServiceItemDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idBudgetPlanService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idLaboratoryLabel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'laboratoryName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'laboratoryPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'sorting'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'level'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<APIXdentDTOBudgetPlanServiceItemDto>}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'items'?: Array<APIXdentDTOBudgetPlanServiceItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idPhrase'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'phraseTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceDiagnoseCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceServiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceServiceCustomCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceIdLaboratoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceServiceName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insurancePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceAgeStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceAgeFinish'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceIsLocalizationRequired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceTypeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceODB'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insurancePaymentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceIdPaymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insurancePaymentTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insuranceLaboratoryCodeType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'insurancePriceClientAdd'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idInvoiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceCustomCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceCodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceMaterialCodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceUnitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoiceDuration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'invoicePricePerMinute'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idOperation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idConstruction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'idMaterial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcOperationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcConstructionCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcMaterialCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcOperationTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcConstructionTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcMaterialTitle'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOBudgetPlanDcRegionDto>}
     * @memberof APIXdentDTOBudgetPlanServiceItemDto
     */
    'dcRegions'?: Array<APIXdentDTOBudgetPlanDcRegionDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOBudgetPlanSomeOtherServiceDto
 */
export interface APIXdentDTOBudgetPlanSomeOtherServiceDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanSomeOtherServiceDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanSomeOtherServiceDto
     */
    'caption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanSomeOtherServiceDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOBudgetPlanSomeOtherServiceDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOBudgetPlanSomeOtherServiceDto
     */
    'duration'?: number | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOCallSetCallStatusDto
 */
export interface APIXdentDTOCallSetCallStatusDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOCallSetCallStatusDto
     */
    'uniqueId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOCallSetCallStatusDto
     */
    'phoneFrom'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOCallSetCallStatusDto
     */
    'phoneTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOCallSetCallStatusDto
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientClientMagistralisDto
 */
export interface APIXdentDTOClientClientMagistralisDto {
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult}
     * @memberof APIXdentDTOClientClientMagistralisDto
     */
    'info'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult>}
     * @memberof APIXdentDTOClientClientMagistralisDto
     */
    'magistralisDetail'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISSUBSTANCEGRIDResult> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientDetailGeneralDrugDto
 */
export interface APIXdentDTOClientDetailGeneralDrugDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'dosage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'packaging'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'ammount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'idDrug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'idMagistralis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'dop'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'dodavky'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralDrugDto
     */
    'iskupina'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientDetailGeneralInvoiceClientInfoDto
 */
export interface APIXdentDTOClientDetailGeneralInvoiceClientInfoDto {
    /**
     * 
     * @type {Array<APIXdentDTOClientDetailGeneralInvoiceGridInfoDto>}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceClientInfoDto
     */
    'clientInvoiceList'?: Array<APIXdentDTOClientDetailGeneralInvoiceGridInfoDto> | null;
    /**
     * 
     * @type {APIXdentDTOClientDetailGeneralUserCreditInfoDto}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceClientInfoDto
     */
    'creditInfo'?: APIXdentDTOClientDetailGeneralUserCreditInfoDto;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
 */
export interface APIXdentDTOClientDetailGeneralInvoiceGridInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'dateIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'dateLastChange'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'supervisorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'nameSupervisorChange'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'locationSupervisorChange'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'locationPositionSupervisorChange'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idBillingUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'billingName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'billingSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'billingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idInvoiceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'invoiceTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'invoiceTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idBillingType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'billingTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idDiscountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'discountTypeCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'deposit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'credit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'total'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'priceService'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'priceMaterial'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'priceDiscount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'paid'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'toPay'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'pricePayments'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'pricePaymentsDate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'eetLock'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'eetInvoiceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'lockCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'summaryLock'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idSupervisor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'emailSent'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'iklientShared'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'emailDelivered'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'emailDeliveredDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralInvoiceGridInfoDto
     */
    'idPartner'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientDetailGeneralPrescriptionDto
 */
export interface APIXdentDTOClientDetailGeneralPrescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'idEreceptFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'idMedicalPrescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'dateIss'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'insuranceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'diagnose'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOClientDetailGeneralDrugDto>}
     * @memberof APIXdentDTOClientDetailGeneralPrescriptionDto
     */
    'drugs'?: Array<APIXdentDTOClientDetailGeneralDrugDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientDetailGeneralReminderDto
 */
export interface APIXdentDTOClientDetailGeneralReminderDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'idReminder'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'service'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'statusText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'dateSend'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralReminderDto
     */
    'idAppointment'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientDetailGeneralUserCreditInfoDto
 */
export interface APIXdentDTOClientDetailGeneralUserCreditInfoDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOClientDetailGeneralUserCreditInfoDto
     */
    'credit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientDetailGeneralUserCreditInfoDto
     */
    'currency'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOClientFilteredDrugsDto
 */
export interface APIXdentDTOClientFilteredDrugsDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientFilteredDrugsDto
     */
    'idMagistralis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientFilteredDrugsDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientFilteredDrugsDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientFilteredDrugsDto
     */
    'dop'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientFilteredDrugsDto
     */
    'dodavky'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOClientFilteredDrugsDto
     */
    'iskupina'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODashboardAppointmentNotification
 */
export interface APIXdentDTODashboardAppointmentNotification {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'dateStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'duration'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'confirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardAppointmentNotification
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODashboardDashboardNotificationWrapper
 */
export interface APIXdentDTODashboardDashboardNotificationWrapper {
    /**
     * 
     * @type {Array<APIXdentDTODashboardNewsNotification>}
     * @memberof APIXdentDTODashboardDashboardNotificationWrapper
     */
    'news'?: Array<APIXdentDTODashboardNewsNotification> | null;
    /**
     * 
     * @type {Array<APIXdentDTODashboardSmsAnswerNotification>}
     * @memberof APIXdentDTODashboardDashboardNotificationWrapper
     */
    'smsAnswers'?: Array<APIXdentDTODashboardSmsAnswerNotification> | null;
    /**
     * 
     * @type {Array<APIXdentDTODashboardStoreItemsNotification>}
     * @memberof APIXdentDTODashboardDashboardNotificationWrapper
     */
    'storeItems'?: Array<APIXdentDTODashboardStoreItemsNotification> | null;
    /**
     * 
     * @type {Array<APIXdentDTODashboardAppointmentNotification>}
     * @memberof APIXdentDTODashboardDashboardNotificationWrapper
     */
    'appointments'?: Array<APIXdentDTODashboardAppointmentNotification> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODashboardNewsNotification
 */
export interface APIXdentDTODashboardNewsNotification {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardNewsNotification
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardNewsNotification
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardNewsNotification
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardNewsNotification
     */
    'link'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTODashboardNewsNotification
     */
    'confirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTODashboardSmsAnswerNotification
 */
export interface APIXdentDTODashboardSmsAnswerNotification {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'contentAnswer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'dateReceived'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'confirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'dateStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardSmsAnswerNotification
     */
    'dateEnd'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODashboardStoreItemsNotification
 */
export interface APIXdentDTODashboardStoreItemsNotification {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardStoreItemsNotification
     */
    'idInvoiceCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardStoreItemsNotification
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardStoreItemsNotification
     */
    'titleMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardStoreItemsNotification
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODashboardV2TaskDto
 */
export interface APIXdentDTODashboardV2TaskDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'idTask'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'idMaster'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'idSlave'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'taskMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'taskStatusCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'taskTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'dateFinish'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'idBatch'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'taskPriorityCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'taskStatusLocalized'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'taskPriorityLocalized'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'unreadcount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'dateStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'masterUsername'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODashboardV2TaskDto
     */
    'slaveUsername'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalDentalChartItemData
 */
export interface APIXdentDTODentalDentalChartItemData {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idDentalChartItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idDentalChart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idLocalizationList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'localizationCodeList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idOperation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'operationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idConstruction'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'constructionCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idMaterial'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'materialCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idTeethSet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'teethSetCode'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idRegion'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'regionCode'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'lastUpdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'medicalReportDateTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTODentalDentalChartItemData
     */
    'postData'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalDhHistory
 */
export interface APIXdentDTODentalDhHistory {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult>}
     * @memberof APIXdentDTODentalDhHistory
     */
    'history'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDHHISTORYGRIDResult> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTODentalDhHistory
     */
    'indexes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalGetPeridontalChartIndicesValueDataDto
 */
export interface APIXdentDTODentalGetPeridontalChartIndicesValueDataDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalGetPeridontalChartIndicesValueDataDto
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalGetPeridontalChartIndicesValueDataDto
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalGetPeridontalChartIndicesValueDataDto
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalGetPeridontalChartIndicesValueDataDto
     */
    'dateTime'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalGetPeridontalChartIndicesValueResponseDto
 */
export interface APIXdentDTODentalGetPeridontalChartIndicesValueResponseDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof APIXdentDTODentalGetPeridontalChartIndicesValueResponseDto
     */
    'pC_CPI'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof APIXdentDTODentalGetPeridontalChartIndicesValueResponseDto
     */
    'pC_PBI'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalPeriodontalChartItemData
 */
export interface APIXdentDTODentalPeriodontalChartItemData {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idPeriodontalChart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idPeriodontalChartItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idTeethSet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'teethSetCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'lastUpdate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'medicalReportDateTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'postData'?: boolean;
    /**
     * 
     * @type {Array<APIXdentDTODentalPeriodontalChartItemDataIndices>}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'indices'?: Array<APIXdentDTODentalPeriodontalChartItemDataIndices> | null;
    /**
     * 
     * @type {Array<APIXdentDTODentalPeriodontalChartItemDataRegions>}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'regions'?: Array<APIXdentDTODentalPeriodontalChartItemDataRegions> | null;
    /**
     * 
     * @type {APIXdentDTODentalPeriodontalIndexes}
     * @memberof APIXdentDTODentalPeriodontalChartItemData
     */
    'periodontalComputedIndexes'?: APIXdentDTODentalPeriodontalIndexes;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalPeriodontalChartItemDataIndices
 */
export interface APIXdentDTODentalPeriodontalChartItemDataIndices {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataIndices
     */
    'idPeriodontalChartItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataIndices
     */
    'idOperation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataIndices
     */
    'idOperationValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataIndices
     */
    'operationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataIndices
     */
    'operationValue'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalPeriodontalChartItemDataRegions
 */
export interface APIXdentDTODentalPeriodontalChartItemDataRegions {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'idPeriodontalChartItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'idOperation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'idRegion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'idRegionValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'regionValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'regionCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalChartItemDataRegions
     */
    'operationCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTODentalPeriodontalIndexes
 */
export interface APIXdentDTODentalPeriodontalIndexes {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTODentalPeriodontalIndexes
     */
    'cpitn'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTODentalPeriodontalIndexes
     */
    'pbi'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTODentalPeriodontalIndexes
     */
    'avg'?: number | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieClientSkDto
 */
export interface APIXdentDTOEZdravieClientSkDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieClientSkDto
     */
    'idZp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieClientSkDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieClientSkDto
     */
    'jeNeplatic'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieClientSkDto
     */
    'jruzId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieClientSkDto
     */
    'idClient'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieDiagnosisDto
 */
export interface APIXdentDTOEZdravieDiagnosisDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'codeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'shortcut'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'complementarySign'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'fullCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'additionalInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'lifeThreateningDiagnosis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'diagnosisFiveDigitCodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'diagnosisFiveDigitCodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'diagnosisFourDigitCodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'diagnosisFourDigitCodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'diagnosisThreeDigitCodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'diagnosisThreeDigitCodeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'subcategoryCodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'subcategoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'categoryCodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'categoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'chapterCodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'chapterName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'subcategory2CodeValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieDiagnosisDto
     */
    'subcategory2Name'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieEZdravieInsuranceContextDto
 */
export interface APIXdentDTOEZdravieEZdravieInsuranceContextDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'idZP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'poistovneShortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'eReceptPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'eReceptLogin'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'eReceptPasswordSet'?: boolean;
    /**
     * 0 - None, 24 - DOVERA, 25 - VSZP, 27 - UNION
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'poistovne'?: APIXdentDTOEZdravieEZdravieInsuranceContextDtoPoistovneEnum;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieEZdravieInsuranceContextDto
     */
    'credentialsSet'?: boolean;
}

export const APIXdentDTOEZdravieEZdravieInsuranceContextDtoPoistovneEnum = {
    None: 'None',
    Dovera: 'DOVERA',
    Vszp: 'VSZP',
    Union: 'UNION'
} as const;

export type APIXdentDTOEZdravieEZdravieInsuranceContextDtoPoistovneEnum = typeof APIXdentDTOEZdravieEZdravieInsuranceContextDtoPoistovneEnum[keyof typeof APIXdentDTOEZdravieEZdravieInsuranceContextDtoPoistovneEnum];

/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieEZdravieUserDto
 */
export interface APIXdentDTOEZdravieEZdravieUserDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'idUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'zPr_Id'?: string | null;
    /**
     * KodPredpisujuciLekar
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'zPr_Kod'?: string | null;
    /**
     * KodNZ
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'pzS_Kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'pzS_OU_ID'?: string | null;
    /**
     * ICOHlavnaZlozka
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'pzS_ICO'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'zPr_SpecExtension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'zPr_SpecDisplayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieEZdravieUserDto
     */
    'zPr_SpecVersion'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieExtEnquequeRequestQueueDto
 */
export interface APIXdentDTOEZdravieExtEnquequeRequestQueueDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'request'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'requestType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'resultType'?: string | null;
    /**
     * 0 - Sync, 1 - Async
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'callType'?: APIXdentDTOEZdravieExtEnquequeRequestQueueDtoCallTypeEnum;
    /**
     * 0 - Nczi, 1 - Poistovna, 2 - eObjednanieGW
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'router'?: APIXdentDTOEZdravieExtEnquequeRequestQueueDtoRouterEnum;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'insuranceCompany'?: APIXdentDTOEZdravieExtEnquequeRequestQueueDtoInsuranceCompanyEnum;
    /**
     * 0 - None, 1 - BOK, 2 - NOBOK, 3 - VYZVA, 4 - OWN
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'ppcType'?: APIXdentDTOEZdravieExtEnquequeRequestQueueDtoPpcTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'shouldRetry'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtEnquequeRequestQueueDto
     */
    'retryUntil'?: string | null;
}

export const APIXdentDTOEZdravieExtEnquequeRequestQueueDtoCallTypeEnum = {
    Sync: 'Sync',
    Async: 'Async'
} as const;

export type APIXdentDTOEZdravieExtEnquequeRequestQueueDtoCallTypeEnum = typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoCallTypeEnum[keyof typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoCallTypeEnum];
export const APIXdentDTOEZdravieExtEnquequeRequestQueueDtoRouterEnum = {
    Nczi: 'Nczi',
    Poistovna: 'Poistovna',
    EObjednanieGw: 'eObjednanieGW'
} as const;

export type APIXdentDTOEZdravieExtEnquequeRequestQueueDtoRouterEnum = typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoRouterEnum[keyof typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoRouterEnum];
export const APIXdentDTOEZdravieExtEnquequeRequestQueueDtoInsuranceCompanyEnum = {
    None: 'None',
    Dovera: 'DOVERA',
    Vszp: 'VSZP',
    Union: 'UNION'
} as const;

export type APIXdentDTOEZdravieExtEnquequeRequestQueueDtoInsuranceCompanyEnum = typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoInsuranceCompanyEnum[keyof typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoInsuranceCompanyEnum];
export const APIXdentDTOEZdravieExtEnquequeRequestQueueDtoPpcTypeEnum = {
    None: 'None',
    Bok: 'BOK',
    Nobok: 'NOBOK',
    Vyzva: 'VYZVA',
    Own: 'OWN'
} as const;

export type APIXdentDTOEZdravieExtEnquequeRequestQueueDtoPpcTypeEnum = typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoPpcTypeEnum[keyof typeof APIXdentDTOEZdravieExtEnquequeRequestQueueDtoPpcTypeEnum];

/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieExtLogRequestDto
 */
export interface APIXdentDTOEZdravieExtLogRequestDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'healthProfessionalIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'jruzIdClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'request'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'requestRaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'resultRaw'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'error'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'requestType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'resultType'?: string | null;
    /**
     * 0 - Sync, 1 - Async
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'callType'?: APIXdentDTOEZdravieExtLogRequestDtoCallTypeEnum;
    /**
     * 0 - Nczi, 1 - Poistovna, 2 - eObjednanieGW
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'router'?: APIXdentDTOEZdravieExtLogRequestDtoRouterEnum;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'insuranceCompany'?: APIXdentDTOEZdravieExtLogRequestDtoInsuranceCompanyEnum;
    /**
     * 0 - None, 1 - BOK, 2 - NOBOK, 3 - VYZVA, 4 - OWN
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'ppcType'?: APIXdentDTOEZdravieExtLogRequestDtoPpcTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'shouldRetry'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLogRequestDto
     */
    'errorMessage'?: string | null;
}

export const APIXdentDTOEZdravieExtLogRequestDtoCallTypeEnum = {
    Sync: 'Sync',
    Async: 'Async'
} as const;

export type APIXdentDTOEZdravieExtLogRequestDtoCallTypeEnum = typeof APIXdentDTOEZdravieExtLogRequestDtoCallTypeEnum[keyof typeof APIXdentDTOEZdravieExtLogRequestDtoCallTypeEnum];
export const APIXdentDTOEZdravieExtLogRequestDtoRouterEnum = {
    Nczi: 'Nczi',
    Poistovna: 'Poistovna',
    EObjednanieGw: 'eObjednanieGW'
} as const;

export type APIXdentDTOEZdravieExtLogRequestDtoRouterEnum = typeof APIXdentDTOEZdravieExtLogRequestDtoRouterEnum[keyof typeof APIXdentDTOEZdravieExtLogRequestDtoRouterEnum];
export const APIXdentDTOEZdravieExtLogRequestDtoInsuranceCompanyEnum = {
    None: 'None',
    Dovera: 'DOVERA',
    Vszp: 'VSZP',
    Union: 'UNION'
} as const;

export type APIXdentDTOEZdravieExtLogRequestDtoInsuranceCompanyEnum = typeof APIXdentDTOEZdravieExtLogRequestDtoInsuranceCompanyEnum[keyof typeof APIXdentDTOEZdravieExtLogRequestDtoInsuranceCompanyEnum];
export const APIXdentDTOEZdravieExtLogRequestDtoPpcTypeEnum = {
    None: 'None',
    Bok: 'BOK',
    Nobok: 'NOBOK',
    Vyzva: 'VYZVA',
    Own: 'OWN'
} as const;

export type APIXdentDTOEZdravieExtLogRequestDtoPpcTypeEnum = typeof APIXdentDTOEZdravieExtLogRequestDtoPpcTypeEnum[keyof typeof APIXdentDTOEZdravieExtLogRequestDtoPpcTypeEnum];

/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieExtLoginDto
 */
export interface APIXdentDTOEZdravieExtLoginDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLoginDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtLoginDto
     */
    'certHash'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieExtRequestDto
 */
export interface APIXdentDTOEZdravieExtRequestDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'idRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'healthProfessionalIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'jruzIdClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'request'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'requestType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'result'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'resultType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'callType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'router'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'insuranceCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'ppcType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'processed'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'shouldRetry'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'tryCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'dateLastTry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtRequestDto
     */
    'isPaused'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieExtRequestResultDto
 */
export interface APIXdentDTOEZdravieExtRequestResultDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'idRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'rawRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'rawResponse'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'jsonResult'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'retryStopRequested'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'error'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieExtRequestResultDto
     */
    'shouldPause'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieGenerateEZdravieIdDto
 */
export interface APIXdentDTOEZdravieGenerateEZdravieIdDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGenerateEZdravieIdDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGenerateEZdravieIdDto
     */
    'oid'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieGetDataByIdOUPZSDto
 */
export interface APIXdentDTOEZdravieGetDataByIdOUPZSDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdOUPZSDto
     */
    'idOUPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdOUPZSDto
     */
    'idZPr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdOUPZSDto
     */
    'kodZPr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdOUPZSDto
     */
    'zdravotnickaOdbornost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdOUPZSDto
     */
    'givenName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdOUPZSDto
     */
    'familyName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieGetDataByIdZPrDto
 */
export interface APIXdentDTOEZdravieGetDataByIdZPrDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'idZPr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'givenName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'familyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'kodZPr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'zdravotnickaOdbornost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'idOUPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'kodPZS'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieGetDataByIdZPrDto
     */
    'idPZS'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieIndicationLimitationDto
 */
export interface APIXdentDTOEZdravieIndicationLimitationDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieIndicationLimitationDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieIndicationLimitationDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieIndicationLimitationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieIndicationLimitationDto
     */
    'atc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieIndicationLimitationDto
     */
    'po'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieIndicationLimitationDto
     */
    'po2'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieInsuranceSettingsDto
 */
export interface APIXdentDTOEZdravieInsuranceSettingsDto {
    /**
     * 
     * @type {Array<APIXdentDTOEZdravieEZdravieInsuranceContextDto>}
     * @memberof APIXdentDTOEZdravieInsuranceSettingsDto
     */
    'insurances'?: Array<APIXdentDTOEZdravieEZdravieInsuranceContextDto> | null;
    /**
     * 
     * @type {APIXdentDTOEZdravieEZdravieUserDto}
     * @memberof APIXdentDTOEZdravieInsuranceSettingsDto
     */
    'user'?: APIXdentDTOEZdravieEZdravieUserDto;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieMedicalAidToolDto
 */
export interface APIXdentDTOEZdravieMedicalAidToolDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'toolId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'sizeAndType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'purpose'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'amountInPackageSpecified'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'amountInPackage'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'isBaseFunctionalType'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'isBaseFunctionalTypeSpecified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'usageGuide'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'isTailored'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'medicalClass'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'isSpecializedMaterial'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'manufacturer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'recipeValidityUnit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'recipeValidityValue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'isCategoryInfoSpecified'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'maxPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'maxPriceUnit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'insurancePayPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicalAidToolDto
     */
    'insurancePayUnit'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieMedicamentDto
 */
export interface APIXdentDTOEZdravieMedicamentDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'medicamentId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'isAntibioticChemotherapy'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'additionalName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'isGenericPrescription'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'isVaccine'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'suklCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'isRegistrationInfoSpecified'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'packagingSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'packagingUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'atcCodelistCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'atcCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'atcName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'isCategoryInfoSpecified'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'intake'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'opl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'lpDependency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'medicamentForm'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'medicamentAmountSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'medicamentAmountUnit'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'medicamentSpecified'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'recipeExpirationSize'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'recipeExpirationUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'vsrAvailability'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'pharmacyPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'pharmacyUnit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'surchargePrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'surchargeUnit'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'isMultiplePrescriptionAllowed'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'insurancePayPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'insurancePayUnit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'lpDependencyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'medicamentNames'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'indicationLimitations'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOEZdravieIndicationLimitationDto>}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'indicationLimitationList'?: Array<APIXdentDTOEZdravieIndicationLimitationDto> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'po'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'po2'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'poList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'po2List'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieMedicamentDto
     */
    'atcDoplnok'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
 */
export interface APIXdentDTOEZdravieUlozPreskripcnyZaznamDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'idPreskripcnyZaznam'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'idClient'?: string;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'preskripcnyZaznam'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'preskripcnyZaznamVystup'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'medicamentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEZdravieUlozPreskripcnyZaznamDto
     */
    'pzSection'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptCopyPrescriptionDto
 */
export interface APIXdentDTOEreceptCopyPrescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'idMedicalPrescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'idMedicalPrescriptionPrev'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'dateIssue'?: string;
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSEFORMResult}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'diagnoseData'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSEFORMResult;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'diagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'idInsurance'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'insuranceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'akutni'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'family'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'uhrada'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOEreceptPrescriptionDrugDto>}
     * @memberof APIXdentDTOEreceptCopyPrescriptionDto
     */
    'drugs'?: Array<APIXdentDTOEreceptPrescriptionDrugDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptDrugDto
 */
export interface APIXdentDTOEreceptDrugDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'dosage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'packaging'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'ammount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'idDrug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'idMagistralis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'dop'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'dodavky'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptDrugDto
     */
    'iskupina'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptEreceptError
 */
export interface APIXdentDTOEreceptEreceptError {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptEreceptError
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptEreceptError
     */
    'skupina'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptEreceptError
     */
    'popis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptEreceptError
     */
    'doporuceni'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptMagistralisSaveDataDto
 */
export interface APIXdentDTOEreceptMagistralisSaveDataDto {
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'valid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'idMagistralisSubstance'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'idMagistralis'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'amout'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'substanceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptMagistralisSaveDataDto
     */
    'substanceCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptMagistralisSaveDto
 */
export interface APIXdentDTOEreceptMagistralisSaveDto {
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult}
     * @memberof APIXdentDTOEreceptMagistralisSaveDto
     */
    'magistralisData'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult;
    /**
     * 
     * @type {Array<APIXdentDTOEreceptMagistralisSaveDataDto>}
     * @memberof APIXdentDTOEreceptMagistralisSaveDto
     */
    'magistralisDetailData'?: Array<APIXdentDTOEreceptMagistralisSaveDataDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptMagistralisSaveResponseDto
 */
export interface APIXdentDTOEreceptMagistralisSaveResponseDto {
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult}
     * @memberof APIXdentDTOEreceptMagistralisSaveResponseDto
     */
    'info'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMAGISTRALISFORMResult;
    /**
     * 
     * @type {Array<APIXdentDTOEreceptMagistralisSaveDataDto>}
     * @memberof APIXdentDTOEreceptMagistralisSaveResponseDto
     */
    'magistralisDetail'?: Array<APIXdentDTOEreceptMagistralisSaveDataDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptPrescriptionDetailDto
 */
export interface APIXdentDTOEreceptPrescriptionDetailDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'idDrug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'dosage'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'idMagistralis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'iskupina'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'packaging'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDetailDto
     */
    'amountText'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptPrescriptionDrugDto
 */
export interface APIXdentDTOEreceptPrescriptionDrugDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'amount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'dosage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'idDrug'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'idMagistralis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'dop'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'dodavky'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'iskupina'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'packaging'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOEreceptPrescriptionDrugDto
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptPrescriptionDto
 */
export interface APIXdentDTOEreceptPrescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'idEreceptFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'idMedicalPrescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'dateIss'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'insuranceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'diagnose'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOEreceptDrugDto>}
     * @memberof APIXdentDTOEreceptPrescriptionDto
     */
    'drugs'?: Array<APIXdentDTOEreceptDrugDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptSavePrescriptionDto
 */
export interface APIXdentDTOEreceptSavePrescriptionDto {
    /**
     * 
     * @type {APIXdentDTOEreceptPrescriptionDto}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'prescriptionData'?: APIXdentDTOEreceptPrescriptionDto;
    /**
     * 
     * @type {Array<APIXdentDTOEreceptPrescriptionDetailDto>}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'prescriptionDetailData'?: Array<APIXdentDTOEreceptPrescriptionDetailDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'eRecept'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'notifyTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'uhradaIn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'family'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptSavePrescriptionDto
     */
    'akutni'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptSavePrescriptionResponseDto
 */
export interface APIXdentDTOEreceptSavePrescriptionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'idPrescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'idPrescriptionRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'prescriptionCmdLine'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'xmlStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'iSkupina1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'iSkupina2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'hash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSavePrescriptionResponseDto
     */
    'digestValue'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptSendSignedElectronicPrescriptionDto
 */
export interface APIXdentDTOEreceptSendSignedElectronicPrescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSendSignedElectronicPrescriptionDto
     */
    'idRequest'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSendSignedElectronicPrescriptionDto
     */
    'digestValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSendSignedElectronicPrescriptionDto
     */
    'signatureValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSendSignedElectronicPrescriptionDto
     */
    'x509SubjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSendSignedElectronicPrescriptionDto
     */
    'x509Certificate'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
 */
export interface APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'resultMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'file'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'iD_Dokladu'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOEreceptEreceptError>}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'errors'?: Array<APIXdentDTOEreceptEreceptError> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'errorsCompleteMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {APIXdentDTOEreceptTempValidSignature}
     * @memberof APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto
     */
    'tempTest'?: APIXdentDTOEreceptTempValidSignature;
}
/**
 * 
 * @export
 * @interface APIXdentDTOEreceptTempValidSignature
 */
export interface APIXdentDTOEreceptTempValidSignature {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptTempValidSignature
     */
    'signatureValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptTempValidSignature
     */
    'x509SubjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOEreceptTempValidSignature
     */
    'x509Certificate'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryEditGalleryFileMetadataDto
 */
export interface APIXdentDTOGalleryEditGalleryFileMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryEditGalleryFileMetadataDto
     */
    'idExistingFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryEditGalleryFileMetadataDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryEditGalleryFileMetadataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOGalleryTagDto>}
     * @memberof APIXdentDTOGalleryEditGalleryFileMetadataDto
     */
    'tagList'?: Array<APIXdentDTOGalleryTagDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
 */
export interface APIXdentDTOGalleryGalleryFilesGrid2WithContentDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'galleryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'size'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'isIKlientShared'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'idMaster'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'imageContent'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOGalleryTagDto>}
     * @memberof APIXdentDTOGalleryGalleryFilesGrid2WithContentDto
     */
    'tags'?: Array<APIXdentDTOGalleryTagDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryGetFilesGridDto
 */
export interface APIXdentDTOGalleryGetFilesGridDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOGalleryGetFilesGridDto
     */
    'masterList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOGalleryGetFilesGridDto
     */
    'tagList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOGalleryGetFilesGridDto
     */
    'systemTagList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGetFilesGridDto
     */
    'mimeType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryGroupedGalleryDto
 */
export interface APIXdentDTOGalleryGroupedGalleryDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryGroupedGalleryDto
     */
    'dateCreated'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOGalleryGalleryFilesGrid2WithContentDto>}
     * @memberof APIXdentDTOGalleryGroupedGalleryDto
     */
    'items'?: Array<APIXdentDTOGalleryGalleryFilesGrid2WithContentDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryGroupedGalleryWrapperDto
 */
export interface APIXdentDTOGalleryGroupedGalleryWrapperDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOGalleryGroupedGalleryWrapperDto
     */
    'totalLength'?: number | null;
    /**
     * 
     * @type {Array<APIXdentDTOGalleryGroupedGalleryDto>}
     * @memberof APIXdentDTOGalleryGroupedGalleryWrapperDto
     */
    'folders'?: Array<APIXdentDTOGalleryGroupedGalleryDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGallerySaveProfilePhotoRequestDto
 */
export interface APIXdentDTOGallerySaveProfilePhotoRequestDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGallerySaveProfilePhotoRequestDto
     */
    'idFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGallerySaveProfilePhotoRequestDto
     */
    'idClient'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryTagDto
 */
export interface APIXdentDTOGalleryTagDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryTagDto
     */
    'idTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryTagDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryTagDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOGalleryTagDto
     */
    'isAdd'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOGalleryUpdateClientBadgeColorRequestDto
 */
export interface APIXdentDTOGalleryUpdateClientBadgeColorRequestDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryUpdateClientBadgeColorRequestDto
     */
    'hex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOGalleryUpdateClientBadgeColorRequestDto
     */
    'idClient'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceClientPartnerFilterHelpDto
 */
export interface APIXdentDTOInvoiceClientPartnerFilterHelpDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'birthNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'regNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'insuranceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'itemClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceClientPartnerFilterHelpDto
     */
    'badgeColor'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceCodeLocalizationDto
 */
export interface APIXdentDTOInvoiceInvoiceCodeLocalizationDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodeLocalizationDto
     */
    'idInvoiceCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodeLocalizationDto
     */
    'idLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodeLocalizationDto
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodeLocalizationDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodeLocalizationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodeLocalizationDto
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceCodesItemDto
 */
export interface APIXdentDTOInvoiceInvoiceCodesItemDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idCodeParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idCodeMacro'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idCodeStoreRecipe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idPriceLevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'priceLevelName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'codeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'pricePerMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'priceLaboratory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'priceAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'laboratoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'finalPriceStr'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'finalPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idMaterialType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'materialCodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'materialTypeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'quantityStore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'quantityNotify'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'externalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idInvoiceCodeStoreExternalLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'idVATRate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'vatRate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'laboratoryColor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'searchActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'show'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'maxBuyPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'expirationNotifyBefore'?: number | null;
    /**
     * 
     * @type {Array<APIXdentDTOInvoiceInvoiceCodeLocalizationDto>}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemDto
     */
    'localizations'?: Array<APIXdentDTOInvoiceInvoiceCodeLocalizationDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceCodesItemHelpDto
 */
export interface APIXdentDTOInvoiceInvoiceCodesItemHelpDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idCodeParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idCodeMacro'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idCodeStoreRecipe'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idPriceLevel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'priceLevelName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'codeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'price'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'pricePerMin'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'priceLaboratory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'priceAverage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'laboratoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'finalPriceStr'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'finalPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idMaterialType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'materialCodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'materialTypeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'quantityStore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'quantityNotify'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'externalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idInvoiceCodeStoreExternalLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'idVatRate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'vatRate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'laboratoryColor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'searchActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'show'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'maxBuyPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'expirationNotifyBefore'?: number | null;
    /**
     * 
     * @type {Array<APIXdentDTOInvoiceInvoiceCodeLocalizationDto>}
     * @memberof APIXdentDTOInvoiceInvoiceCodesItemHelpDto
     */
    'localizations'?: Array<APIXdentDTOInvoiceInvoiceCodeLocalizationDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceDataDto
 */
export interface APIXdentDTOInvoiceInvoiceDataDto {
    /**
     * 
     * @type {APIXdentDTOInvoiceInvoiceInfoDto}
     * @memberof APIXdentDTOInvoiceInvoiceDataDto
     */
    'info'?: APIXdentDTOInvoiceInvoiceInfoDto;
    /**
     * 
     * @type {APIXdentDTOInvoiceInvoicePrintInfoDto}
     * @memberof APIXdentDTOInvoiceInvoiceDataDto
     */
    'printinfo'?: APIXdentDTOInvoiceInvoicePrintInfoDto;
    /**
     * 
     * @type {Array<APIXdentDTOInvoiceInvoiceItemDto>}
     * @memberof APIXdentDTOInvoiceInvoiceDataDto
     */
    'items'?: Array<APIXdentDTOInvoiceInvoiceItemDto> | null;
    /**
     * 
     * @type {Array<APIXdentDTOInvoiceInvoicePaymentItemDto>}
     * @memberof APIXdentDTOInvoiceInvoiceDataDto
     */
    'payments'?: Array<APIXdentDTOInvoiceInvoicePaymentItemDto> | null;
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult}
     * @memberof APIXdentDTOInvoiceInvoiceDataDto
     */
    'eet'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICEEETFORMResult;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceExportConfigDto
 */
export interface APIXdentDTOInvoiceInvoiceExportConfigDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOInvoiceInvoiceExportConfigDto
     */
    'ids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceExportConfigDto
     */
    'format'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceExportRangeConfigDto
 */
export interface APIXdentDTOInvoiceInvoiceExportRangeConfigDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceExportRangeConfigDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceExportRangeConfigDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceExportRangeConfigDto
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceExportRangeConfigDto
     */
    'format'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceGridInfoDto
 */
export interface APIXdentDTOInvoiceInvoiceGridInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'dateIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'dateLastChange'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'supervisorName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'nameSupervisorChange'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'locationSupervisorChange'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'locationPositionSupervisorChange'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idBillingUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'billingName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'billingSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'billingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idInvoiceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'invoiceTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'invoiceTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idBillingType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'billingTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idDiscountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'discountTypeCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'deposit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'credit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'total'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'priceService'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'priceMaterial'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'priceDiscount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'paid'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'toPay'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'pricePayments'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'pricePaymentsDate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'eetLock'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'eetInvoiceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'lockCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'summaryLock'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idSupervisor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'emailSent'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'iklientShared'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'emailDelivered'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'emailDeliveredDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceGridInfoDto
     */
    'idPartner'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceInfoDto
 */
export interface APIXdentDTOInvoiceInvoiceInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idOwner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idSupervisor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idBillingType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idInvoiceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idDiscountType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idCurrencyTotal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'currencyTotalCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'currencyRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'idLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'dateIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'dateDue'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'dateTax'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'deposit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'total'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'credit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingPost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingRegNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'billingVatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'paid'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'toPay'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'clientCredit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'pricePayments'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'report'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'storeStatus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'lockCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'invoicePayVAT'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'emailSent'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceInfoDto
     */
    'iklientShared'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceItemDto
 */
export interface APIXdentDTOInvoiceInvoiceItemDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idDiagnosis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idInvoiceDetail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idInvoiceDetailGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idParentGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idInvoiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'codeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'materialCodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'idCurrency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'pricePerMinute'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'priceLaboratory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'codeTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'localizationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'lot'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceItemDto
     */
    'exp'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoicePaymentItemDto
 */
export interface APIXdentDTOInvoiceInvoicePaymentItemDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'idInvoicePayment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'supervisorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'idBillingType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'billingTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'billingTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'datePayment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'datePaymentStr'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'pricePayment'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'eetLock'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'lockCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'idPaymentRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'idPaymentTerminal'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'paymentTerminalDeviceID'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof APIXdentDTOInvoiceInvoicePaymentItemDto
     */
    'paymentTerminalDeviceName'?: any | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoicePrintInfoDto
 */
export interface APIXdentDTOInvoiceInvoicePrintInfoDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'invoiceNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'invoiceTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officeStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officeCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officePost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officeCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officeWeb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officeEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'officePhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'regNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'accountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'bankCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'providerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'providerSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'providerDegree'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'providerDegreeAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'issuerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'issuerSurname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'issuerEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'issuerEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'billingTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'invoiceTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'discountTypeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoicePrintInfoDto
     */
    'printMacroDetail'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceReturnInfoDto
 */
export interface APIXdentDTOInvoiceInvoiceReturnInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceReturnInfoDto
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceReturnInfoDto
     */
    'invoiceNumber'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceSettingsDto
 */
export interface APIXdentDTOInvoiceInvoiceSettingsDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'fiscalYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'useStore'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'autoPayment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'invoiceItemAddEdit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'printMacroDetail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'idInvoiceTypeDefault'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'invoiceEetSumType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'invoiceEetForce'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'isCentralStoreEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceSettingsDto
     */
    'idStoreLocation'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceInvoiceTransactionInfoDto
 */
export interface APIXdentDTOInvoiceInvoiceTransactionInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idInvoicePayment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idInvoice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'invoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'datePayment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'dateIssue'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'staffFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'staffEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'staffEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idSupervisor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'supervisorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'supervisorEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'supervisorEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'supervisorLocation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'supervisorLocationPosition'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'clientFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'clientEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'clientEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idPartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'partnerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idBillingType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'billingTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'pricePayment'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'currencySymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'invoiceCodeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idInvoiceLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'idStaffLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'invoiceLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'staffLocation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'eetLock'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceInvoiceTransactionInfoDto
     */
    'lockCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceNoteInvoiceNoteDto
 */
export interface APIXdentDTOInvoiceNoteInvoiceNoteDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceNoteInvoiceNoteDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceNoteInvoiceNoteDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceNoteInvoiceNoteDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceSaveInvoiceDto
 */
export interface APIXdentDTOInvoiceSaveInvoiceDto {
    /**
     * 
     * @type {APIXdentDTOInvoiceInvoiceInfoDto}
     * @memberof APIXdentDTOInvoiceSaveInvoiceDto
     */
    'info'?: APIXdentDTOInvoiceInvoiceInfoDto;
    /**
     * 
     * @type {Array<APIXdentDTOInvoiceInvoiceItemDto>}
     * @memberof APIXdentDTOInvoiceSaveInvoiceDto
     */
    'items'?: Array<APIXdentDTOInvoiceInvoiceItemDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceSaveInvoiceDto
     */
    'payment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceSaveInvoiceDto
     */
    'delTransaction'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOInvoiceSaveInvoiceDto
     */
    'updateStore'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSaveInvoiceDto
     */
    'idReport'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOInvoiceSummaryInvoiceDto
 */
export interface APIXdentDTOInvoiceSummaryInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'idSummaryInvoice'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'summaryInvoiceNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'idCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'idInvoicePartner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'clientName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'issuerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'partnerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'dateTo'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'idIssuer'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'invoicesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOInvoiceSummaryInvoiceDto
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOLabinoReadLabRequestsNotificationDto
 */
export interface APIXdentDTOLabinoReadLabRequestsNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLabinoReadLabRequestsNotificationDto
     */
    'idLabLaboratoryRequest'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOLegacySearchAvailableTimeDto
 */
export interface APIXdentDTOLegacySearchAvailableTimeDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLegacySearchAvailableTimeDto
     */
    'start'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOLocationLocationDto
 */
export interface APIXdentDTOLocationLocationDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idAddressOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idLogo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'regNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'vatNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'icz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'accountNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'bankCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'swift'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'writtenBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'web'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'facebook'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'twitter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'instagram'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'onlineAvalaible'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'position'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'vozp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'webOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'emailOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'phoneOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetLocationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetDeviceName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetCertificateName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetActive'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eetStartDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'invoicePayVAT'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eReceptCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eReceptPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eReceptID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eReceptCertificateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'ideReceptPhonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eReceptPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'uolDatabaseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'uolLoginToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idLogoOriginal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idLogoBooking'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idLogoBox'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idExpertiseType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'invoiceSeriesActive'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'invoiceSeriesNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'invoiceCustomDue'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'forcePaymentTypePick'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'eReceptAutoRenewDisable'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceCertificate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insurenceCertificatePassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceCompanyNotUsed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceCertificateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceBankAccountNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceBankCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceForceAltBankAccount'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'customColors'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'ereceptPayment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'insuranceVariableSymbol'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerCompany}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'companyFk'?: APIContextGeneratedXdentCoreEntityLayerCompany;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerGlobal}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'globalFk'?: APIContextGeneratedXdentCoreEntityLayerGlobal;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerAddress}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'address'?: APIContextGeneratedXdentCoreEntityLayerAddress;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'logoImageContent'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOLocationLocationWorkingHourItemDto>}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'openingHours'?: Array<APIXdentDTOLocationLocationWorkingHourItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'idHcfLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'miniWebsite'?: string | null;
    /**
     * 
     * @type {APIXdentDTOLocationLocationGeocacheDto}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'geocache'?: APIXdentDTOLocationLocationGeocacheDto;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationDto
     */
    'post'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOLocationLocationGeocacheDto
 */
export interface APIXdentDTOLocationLocationGeocacheDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationGeocacheDto
     */
    'idEntity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationGeocacheDto
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationGeocacheDto
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationGeocacheDto
     */
    'map'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOLocationLocationWorkingHourItemDto
 */
export interface APIXdentDTOLocationLocationWorkingHourItemDto {
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerSetOpeningHours}
     * @memberof APIXdentDTOLocationLocationWorkingHourItemDto
     */
    'openingHours'?: APIContextGeneratedXdentCoreEntityLayerSetOpeningHours;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOLocationLocationWorkingHourItemDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOLocationLocationWorkingHourItemDto
     */
    'list'?: number | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordDiagnosisSaveDto
 */
export interface APIXdentDTOMedicalRecordDiagnosisSaveDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'position'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'idDiagnosis'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'idDiagnosisGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'idParentGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'idInvoiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'codeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'localization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'material'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'area'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'priceLaboratory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'pricePerMinute'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'lot'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordDiagnosisSaveDto
     */
    'exp'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordInsuranceClientHistoryDto
 */
export interface APIXdentDTOMedicalRecordInsuranceClientHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'codeDiagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'lokalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'paymentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'patientPrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'insurancePrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'laboratoryPrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'doctor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'laboratoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'diagnoseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'itemsName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryDto
     */
    'date'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
 */
export interface APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'idMedicalReport'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'codeDiagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'lokalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'paymentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'amount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'patientPrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'insurancePrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'laboratoryPrice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'doctor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'laboratoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'diagnoseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'itemsName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto
     */
    'date'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordInsuranceItemSaveDto
 */
export interface APIXdentDTOMedicalRecordInsuranceItemSaveDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'idInsuranceReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'localization'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'amount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'patiencePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'insurancePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'insurancePoints'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'codeDiagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'codeDiagnoseItem'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'laboratoryPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'idPaymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'customCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordInsuranceItemSaveDto
     */
    'idLaboratoryCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
 */
export interface APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
     */
    'externGrid'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult> | null;
    /**
     * 
     * @type {Array<APIXdentDTOClientDetailGeneralPrescriptionDto>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
     */
    'prescriptionGrid'?: Array<APIXdentDTOClientDetailGeneralPrescriptionDto> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
     */
    'medicalExaminationGrid'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
     */
    'doctorPasses'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
     */
    'transportOrders'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto
     */
    'laboratoryLabels'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordMedicalReportInsuranceDto
 */
export interface APIXdentDTOMedicalRecordMedicalReportInsuranceDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idMedicalReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idParent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'codeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'localization'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'amount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'patiencePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'insurancePrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'laboratoryPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'codeDiagnose'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'codeDiagnoseItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'created'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idStaffInsurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idPaymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'paymentTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'idLaboratoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'customCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'maxCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportInsuranceDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordMedicalReportSaveDto
 */
export interface APIXdentDTOMedicalRecordMedicalReportSaveDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'idReport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'idStaff'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'datetime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffLastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'idStaffInsurance'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffInsuranceEducation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffInsuranceEducationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffInsuranceFirstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'staffInsuranceLastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'insuranceReportedCodes'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'toothLocalizations'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'isLockedReport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'isGallery'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'isDentalChart'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'medicalPrescriptionsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'insuranceRegistrationsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'medicalReportExternsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'laboratoryLabelsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'locationStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'locationCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'locationPost'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'locationRegNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveDto
     */
    'idSupervisor'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto
 */
export interface APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto {
    /**
     * 
     * @type {APIXdentDTOMedicalRecordMedicalReportSaveDto}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto
     */
    'report'?: APIXdentDTOMedicalRecordMedicalReportSaveDto;
    /**
     * 
     * @type {Array<APIXdentDTOMedicalRecordDiagnosisSaveDto>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto
     */
    'diagnosis'?: Array<APIXdentDTOMedicalRecordDiagnosisSaveDto> | null;
    /**
     * 
     * @type {Array<APIXdentDTOMedicalRecordInsuranceItemSaveDto>}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto
     */
    'insurance'?: Array<APIXdentDTOMedicalRecordInsuranceItemSaveDto> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto
     */
    'reportType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTONCZIReportSendReportApiDto
 */
export interface APIXdentDTONCZIReportSendReportApiDto {
    /**
     * base64bytes
     * @type {string}
     * @memberof APIXdentDTONCZIReportSendReportApiDto
     */
    'statement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTONCZIReportSendReportApiDto
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTONCZIReportSendReportApiDto
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTONCZIReportSendReportApiDto
     */
    'idNcziReport'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTONCZIReportSendReportApiResponseDto
 */
export interface APIXdentDTONCZIReportSendReportApiResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTONCZIReportSendReportApiResponseDto
     */
    'idNcziReportSendRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTONCZIReportSendReportApiResponseDto
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOOnlineBookingAppointmentDto
 */
export interface APIXdentDTOOnlineBookingAppointmentDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'idClient'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'idLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'idAppointmentCancel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'isConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'isOnlineBooked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'locationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'idStaffProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentDto
     */
    'idStaff'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOOnlineBookingAppointmentRequestBookingDto
 */
export interface APIXdentDTOOnlineBookingAppointmentRequestBookingDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idAppointmentRequest'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idStaff'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idBudgetPlanService'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'staffFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'serviceName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOOnlineBookingAppointmentRequestBookingDto
     */
    'isFinished'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOOnlineBookingBookingPartnershipRequestDto
 */
export interface APIXdentDTOOnlineBookingBookingPartnershipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingBookingPartnershipRequestDto
     */
    'idForm'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOOnlineBookingClientExtraInfoDto
 */
export interface APIXdentDTOOnlineBookingClientExtraInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingClientExtraInfoDto
     */
    'educationAfter'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
 */
export interface APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto {
    /**
     * 
     * @type {Array<APIXdentDTOOnlineBookingAppointmentDto>}
     * @memberof APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
     */
    'appointments'?: Array<APIXdentDTOOnlineBookingAppointmentDto> | null;
    /**
     * 
     * @type {Array<APISharedFormTemplateDto>}
     * @memberof APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
     */
    'forms'?: Array<APISharedFormTemplateDto> | null;
    /**
     * 
     * @type {Array<APIXdentDTOOnlineBookingAppointmentRequestBookingDto>}
     * @memberof APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
     */
    'appointmentRequests'?: Array<APIXdentDTOOnlineBookingAppointmentRequestBookingDto> | null;
    /**
     * 
     * @type {APISharedUserDto}
     * @memberof APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
     */
    'user'?: APISharedUserDto;
    /**
     * 
     * @type {APIXdentDTOOnlineBookingUserExtraInfoDto}
     * @memberof APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
     */
    'userExtraInfo'?: APIXdentDTOOnlineBookingUserExtraInfoDto;
    /**
     * 
     * @type {APIXdentDTOOnlineBookingClientExtraInfoDto}
     * @memberof APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto
     */
    'clientExtraInfo'?: APIXdentDTOOnlineBookingClientExtraInfoDto;
}
/**
 * 
 * @export
 * @interface APIXdentDTOOnlineBookingUserExtraInfoDto
 */
export interface APIXdentDTOOnlineBookingUserExtraInfoDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingUserExtraInfoDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingUserExtraInfoDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingUserExtraInfoDto
     */
    'idPartnership'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOOnlineBookingUserExtraInfoDto
     */
    'idLocation'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPaymentGatewayEchoApiDto
 */
export interface APIXdentDTOPaymentGatewayEchoApiDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayEchoApiDto
     */
    'idCompany': string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayEchoApiDto
     */
    'idLocation': string;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPaymentGatewayEchoApiResponseDto
 */
export interface APIXdentDTOPaymentGatewayEchoApiResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayEchoApiResponseDto
     */
    'dttm'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPaymentGatewayEchoApiResponseDto
     */
    'resultCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayEchoApiResponseDto
     */
    'resultMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayEchoApiResponseDto
     */
    'signature'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPaymentGatewayEchoApiResponseDto
     */
    'isVerified'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPaymentGatewayPaymentInitApiDto
 */
export interface APIXdentDTOPaymentGatewayPaymentInitApiDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentInitApiDto
     */
    'idAppointment': string;
    /**
     * Enum: [cs,en,de,fr,hu,it,ja,pl,pt,ro,ru,sk,es,tr,vi,hr,sl,sv], if null or not in enum --> en is selected.
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentInitApiDto
     */
    'lang'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPaymentGatewayPaymentInitApiResponseDto
 */
export interface APIXdentDTOPaymentGatewayPaymentInitApiResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentInitApiResponseDto
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentInitApiResponseDto
     */
    'payId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPaymentGatewayPaymentInitApiResponseDto
     */
    'isVerified'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
 */
export interface APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
     */
    'idAppointment'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
     */
    'payId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
     */
    'paymentStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
     */
    'paymentStatusCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
     */
    'statusIsFinalForClient'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto
     */
    'dateCreated'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto
 */
export interface APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto
     */
    'age'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto
     */
    'gender'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto
     */
    'validFormat'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPremiumPremiumServicesSettings
 */
export interface APIXdentDTOPremiumPremiumServicesSettings {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPremiumPremiumServicesSettings
     */
    'analytics'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPremiumPremiumServicesSettings
     */
    'isBiometricSignActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPremiumPremiumServicesSettings
     */
    'isFinancingActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPremiumPremiumServicesSettings
     */
    'isLegalProtectionActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOPremiumPremiumServicesSettings
     */
    'isLaboratoryLink'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPriceListPriceListDto
 */
export interface APIXdentDTOPriceListPriceListDto {
    /**
     * 
     * @type {Array<APIXdentDTOPriceListPriceListInvoiceDto>}
     * @memberof APIXdentDTOPriceListPriceListDto
     */
    'invoices'?: Array<APIXdentDTOPriceListPriceListInvoiceDto> | null;
    /**
     * 
     * @type {APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult}
     * @memberof APIXdentDTOPriceListPriceListDto
     */
    'stats'?: APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYSTATSFORMResult;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult>}
     * @memberof APIXdentDTOPriceListPriceListDto
     */
    'premium'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedHELPCOMPANYPREMIUMSERVICESGRIDResult> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPriceListPriceListInvoiceDto
 */
export interface APIXdentDTOPriceListPriceListInvoiceDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'idCompanyInvoice'?: string;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'priceSubscription'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'quantitySubscription'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'priceSMS'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'quantitySMS'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'quantityStorage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'priceStorage'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'priceLocation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'quantityLocation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'dateIssue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'variableSymbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'fixSale'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'credit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'eetPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'quantityEetLocation'?: number;
    /**
     * 
     * @type {Array<APIXdentDTOPriceListPriceListInvoiceItemDto>}
     * @memberof APIXdentDTOPriceListPriceListInvoiceDto
     */
    'invoiceItems'?: Array<APIXdentDTOPriceListPriceListInvoiceItemDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPriceListPriceListInvoiceItemDto
 */
export interface APIXdentDTOPriceListPriceListInvoiceItemDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'idCompanyInvoiceItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'itemCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'itemName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'itemDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'itemUnitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'itemQuantity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOPriceListPriceListInvoiceItemDto
     */
    'itemVATRateCode'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOPrintformsPrintFormOverviewDto
 */
export interface APIXdentDTOPrintformsPrintFormOverviewDto {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult>}
     * @memberof APIXdentDTOPrintformsPrintFormOverviewDto
     */
    'labelList'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult> | null;
    /**
     * 
     * @type {Array<APIXdentDTOClientDetailGeneralPrescriptionDto>}
     * @memberof APIXdentDTOPrintformsPrintFormOverviewDto
     */
    'prescriptionList'?: Array<APIXdentDTOClientDetailGeneralPrescriptionDto> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult>}
     * @memberof APIXdentDTOPrintformsPrintFormOverviewDto
     */
    'medicalReportExternGrid'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult>}
     * @memberof APIXdentDTOPrintformsPrintFormOverviewDto
     */
    'medicalExaminationGrid'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult>}
     * @memberof APIXdentDTOPrintformsPrintFormOverviewDto
     */
    'transportOrders'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult> | null;
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult>}
     * @memberof APIXdentDTOPrintformsPrintFormOverviewDto
     */
    'doctorPasses'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTORvgCoordinate
 */
export interface APIXdentDTORvgCoordinate {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTORvgCoordinate
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTORvgCoordinate
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTORvgSegmentationResponseDto
 */
export interface APIXdentDTORvgSegmentationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'toothLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTORvgCoordinate>}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'bbox'?: Array<APIXdentDTORvgCoordinate> | null;
    /**
     * 
     * @type {Array<APIXdentDTORvgCoordinate>}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'polygonData'?: Array<APIXdentDTORvgCoordinate> | null;
    /**
     * 
     * @type {APIXdentDTORvgCoordinate}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'polygonCenter'?: APIXdentDTORvgCoordinate;
    /**
     * 
     * @type {APIXdentDTORvgCoordinate}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'lineData'?: APIXdentDTORvgCoordinate;
    /**
     * 
     * @type {APIXdentDTORvgCoordinate}
     * @memberof APIXdentDTORvgSegmentationResponseDto
     */
    'pointData'?: APIXdentDTORvgCoordinate;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesPdfFileData
 */
export interface APIXdentDTOSafeSignaturesPdfFileData {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesPdfFileData
     */
    'idFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesPdfFileData
     */
    'fileContentBase64'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesSafeSignatureSignBound
 */
export interface APIXdentDTOSafeSignaturesSafeSignatureSignBound {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSafeSignatureSignBound
     */
    'signSizeY'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSafeSignatureSignBound
     */
    'signSizeX'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSafeSignatureSignBound
     */
    'signPosX'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSafeSignatureSignBound
     */
    'signPosY'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSafeSignatureSignBound
     */
    'signPage'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesSafeSignaturesPrivateSignCertDto
 */
export interface APIXdentDTOSafeSignaturesSafeSignaturesPrivateSignCertDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSafeSignaturesPrivateSignCertDto
     */
    'safeSignaturesPrivateSignCertBase64'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesSignItemMetadataDto
 */
export interface APIXdentDTOSafeSignaturesSignItemMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignItemMetadataDto
     */
    'idFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignItemMetadataDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignItemMetadataDto
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignItemMetadataDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSignItemMetadataDto
     */
    'order'?: number;
    /**
     * 
     * @type {APIXdentDTOSafeSignaturesSafeSignatureSignBound}
     * @memberof APIXdentDTOSafeSignaturesSignItemMetadataDto
     */
    'safeSignatureSignBound'?: APIXdentDTOSafeSignaturesSafeSignatureSignBound;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesSignMetadataDto
 */
export interface APIXdentDTOSafeSignaturesSignMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignMetadataDto
     */
    'idBatch'?: string;
    /**
     * 
     * @type {Array<APIXdentDTOSafeSignaturesSignerMetadataDto>}
     * @memberof APIXdentDTOSafeSignaturesSignMetadataDto
     */
    'signers'?: Array<APIXdentDTOSafeSignaturesSignerMetadataDto> | null;
    /**
     * 
     * @type {Array<APIXdentDTOSafeSignaturesPdfFileData>}
     * @memberof APIXdentDTOSafeSignaturesSignMetadataDto
     */
    'pdfFiles'?: Array<APIXdentDTOSafeSignaturesPdfFileData> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesSignerIdentityConfirm
 */
export interface APIXdentDTOSafeSignaturesSignerIdentityConfirm {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerIdentityConfirm
     */
    'idIdentityConfirm'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerIdentityConfirm
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerIdentityConfirm
     */
    'result'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerIdentityConfirm
     */
    'validToDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOSafeSignaturesSignerIdentityConfirm
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSafeSignaturesSignerMetadataDto
 */
export interface APIXdentDTOSafeSignaturesSignerMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'idPrivateSignCertRequest'?: string;
    /**
     * 
     * @type {Array<APIXdentDTOSafeSignaturesSignItemMetadataDto>}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'itemsToSign'?: Array<APIXdentDTOSafeSignaturesSignItemMetadataDto> | null;
    /**
     * 
     * @type {APIXdentDTOSafeSignaturesSafeSignaturesPrivateSignCertDto}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'privateSignCert'?: APIXdentDTOSafeSignaturesSafeSignaturesPrivateSignCertDto;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'order'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'personalNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {APIXdentDTOSafeSignaturesSignerIdentityConfirm}
     * @memberof APIXdentDTOSafeSignaturesSignerMetadataDto
     */
    'identityConfirm'?: APIXdentDTOSafeSignaturesSignerIdentityConfirm;
}
/**
 * 
 * @export
 * @interface APIXdentDTOServiceFormSendDeltaSettingJSON
 */
export interface APIXdentDTOServiceFormSendDeltaSettingJSON {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOServiceFormSendDeltaSettingJSON
     */
    'days'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOServiceServiceLocationFormSettingJSON
 */
export interface APIXdentDTOServiceServiceLocationFormSettingJSON {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOServiceServiceLocationFormSettingJSON
     */
    'idForm'?: string;
    /**
     * 
     * @type {Array<APIXdentDTOServiceFormSendDeltaSettingJSON>}
     * @memberof APIXdentDTOServiceServiceLocationFormSettingJSON
     */
    'daysDelta'?: Array<APIXdentDTOServiceFormSendDeltaSettingJSON> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOServiceServiceLocationSettingDTO
 */
export interface APIXdentDTOServiceServiceLocationSettingDTO {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOServiceServiceLocationSettingDTO
     */
    'idServiceLocationSetting'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOServiceServiceLocationSettingDTO
     */
    'idService'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOServiceServiceLocationSettingDTO
     */
    'idLocation'?: string;
    /**
     * 
     * @type {APIXdentDTOServiceServiceLocationSettingJSON}
     * @memberof APIXdentDTOServiceServiceLocationSettingDTO
     */
    'settings'?: APIXdentDTOServiceServiceLocationSettingJSON;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOServiceServiceLocationSettingDTO
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOServiceServiceLocationSettingDTO
     */
    'updatedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOServiceServiceLocationSettingJSON
 */
export interface APIXdentDTOServiceServiceLocationSettingJSON {
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOServiceServiceLocationSettingJSON
     */
    'shouldSendExpiringBefore'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOServiceServiceLocationSettingJSON
     */
    'shouldSendExpiringAfter'?: boolean;
    /**
     * 
     * @type {Array<APIXdentDTOServiceFormSendDeltaSettingJSON>}
     * @memberof APIXdentDTOServiceServiceLocationSettingJSON
     */
    'beforeEventFormsDaysDelta'?: Array<APIXdentDTOServiceFormSendDeltaSettingJSON> | null;
    /**
     * 
     * @type {Array<APIXdentDTOServiceFormSendDeltaSettingJSON>}
     * @memberof APIXdentDTOServiceServiceLocationSettingJSON
     */
    'afterEventFormsDaysDelta'?: Array<APIXdentDTOServiceFormSendDeltaSettingJSON> | null;
    /**
     * 
     * @type {Array<APIXdentDTOServiceServiceLocationFormSettingJSON>}
     * @memberof APIXdentDTOServiceServiceLocationSettingJSON
     */
    'beforeEventForms'?: Array<APIXdentDTOServiceServiceLocationFormSettingJSON> | null;
    /**
     * 
     * @type {Array<APIXdentDTOServiceServiceLocationFormSettingJSON>}
     * @memberof APIXdentDTOServiceServiceLocationSettingJSON
     */
    'afterEventForms'?: Array<APIXdentDTOServiceServiceLocationFormSettingJSON> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSignSafeSigSealSignedDocumentDto
 */
export interface APIXdentDTOSignSafeSigSealSignedDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSignSafeSigSealSignedDocumentDto
     */
    'idBatch'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSignSafeSigSealSignedDocumentDto
     */
    'signedDocumentBase64'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSmartformAddressDto
 */
export interface APIXdentDTOSmartformAddressDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'NUMBER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'STREET_AND_NUMBER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'STREET'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'CITY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'CITY_EXTENDED'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'CITY_AND_DISTRICT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'ZIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressDto
     */
    'WHOLE_ADDRESS'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSmartformAddressReponseDto
 */
export interface APIXdentDTOSmartformAddressReponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'district'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'NUMBER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'STREET_AND_NUMBER'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'STREET'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'CITY'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'CITY_EXTENDED'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'CITY_AND_DISTRICT'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'ZIP'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressReponseDto
     */
    'WHOLE_ADDRESS'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSmartformAddressSuggestionDto
 */
export interface APIXdentDTOSmartformAddressSuggestionDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSmartformAddressSuggestionDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSmartformAddressSuggestionDto
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressSuggestionDto
     */
    'fieldType': string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressSuggestionDto
     */
    'country': string;
    /**
     * 
     * @type {APIXdentDTOSmartformAddressSuggestionDtoValues}
     * @memberof APIXdentDTOSmartformAddressSuggestionDto
     */
    'values'?: APIXdentDTOSmartformAddressSuggestionDtoValues | null;
}
/**
 * @type APIXdentDTOSmartformAddressSuggestionDtoValues
 * @export
 */
export type APIXdentDTOSmartformAddressSuggestionDtoValues = APIXdentDTOSmartformAddressDto | APIXdentDTOSmartformAddressReponseDto;

/**
 * 
 * @export
 * @interface APIXdentDTOSmartformAddressSuggestionResponseDto
 */
export interface APIXdentDTOSmartformAddressSuggestionResponseDto {
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOSmartformAddressSuggestionResponseDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressSuggestionResponseDto
     */
    'resultCode': string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformAddressSuggestionResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {Array<APIXdentDTOSmartformSuggestionDto>}
     * @memberof APIXdentDTOSmartformAddressSuggestionResponseDto
     */
    'suggestions'?: Array<APIXdentDTOSmartformSuggestionDto> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSmartformFlagDto
 */
export interface APIXdentDTOSmartformFlagDto {
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOSmartformFlagDto
     */
    'wholE_ADDRESS'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOSmartformFlagDto
     */
    'uniquE_CITY'?: boolean | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOSmartformSuggestionDto
 */
export interface APIXdentDTOSmartformSuggestionDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOSmartformSuggestionDto
     */
    'fieldType': string;
    /**
     * 
     * @type {APIXdentDTOSmartformAddressReponseDto}
     * @memberof APIXdentDTOSmartformSuggestionDto
     */
    'values'?: APIXdentDTOSmartformAddressReponseDto;
    /**
     * 
     * @type {APIXdentDTOSmartformFlagDto}
     * @memberof APIXdentDTOSmartformSuggestionDto
     */
    'flags'?: APIXdentDTOSmartformFlagDto;
}
/**
 * 
 * @export
 * @interface APIXdentDTOTaskChangeStatusDto
 */
export interface APIXdentDTOTaskChangeStatusDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskChangeStatusDto
     */
    'idTask'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskChangeStatusDto
     */
    'idBatch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskChangeStatusDto
     */
    'taskStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOTaskCommentDto
 */
export interface APIXdentDTOTaskCommentDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCommentDto
     */
    'idBatch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCommentDto
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCommentDto
     */
    'body'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOTaskCreateTaskDto
 */
export interface APIXdentDTOTaskCreateTaskDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCreateTaskDto
     */
    'finishDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCreateTaskDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOTaskCreateTaskDto
     */
    'slaves'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCreateTaskDto
     */
    'taskMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCreateTaskDto
     */
    'taskPriority'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskCreateTaskDto
     */
    'taskType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOTaskEditTaskDto
 */
export interface APIXdentDTOTaskEditTaskDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskEditTaskDto
     */
    'idBatch'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskEditTaskDto
     */
    'finishDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskEditTaskDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskEditTaskDto
     */
    'taskMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskEditTaskDto
     */
    'taskPriority'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOTaskEditTaskDto
     */
    'taskType'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOUserGlobalXdentConfigurationDto
 */
export interface APIXdentDTOUserGlobalXdentConfigurationDto {
    /**
     * 
     * @type {{ [key: string]: Array<APISharedCodelistDto>; }}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationDto
     */
    'codeLists'?: { [key: string]: Array<APISharedCodelistDto>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationDto
     */
    'roles'?: Array<string> | null;
    /**
     * 
     * @type {Array<APISharedDentalCategoryColorsDto>}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationDto
     */
    'dentalCategoryColors'?: Array<APISharedDentalCategoryColorsDto> | null;
    /**
     * 
     * @type {APISharedGlobalXdentConfigCmpanyDto}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationDto
     */
    'company'?: APISharedGlobalXdentConfigCmpanyDto;
    /**
     * 
     * @type {APISharedGlobalXdentConfigLocationDto}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationDto
     */
    'location'?: APISharedGlobalXdentConfigLocationDto;
}
/**
 * 
 * @export
 * @interface APIXdentDTOUserGlobalXdentConfigurationWrapperDto
 */
export interface APIXdentDTOUserGlobalXdentConfigurationWrapperDto {
    /**
     * 
     * @type {APIXdentDTOUserGlobalXdentConfigurationDto}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationWrapperDto
     */
    'configuration'?: APIXdentDTOUserGlobalXdentConfigurationDto;
    /**
     * 
     * @type {APIXdentDTOUserUserXdentDto}
     * @memberof APIXdentDTOUserGlobalXdentConfigurationWrapperDto
     */
    'user'?: APIXdentDTOUserUserXdentDto;
}
/**
 * 
 * @export
 * @interface APIXdentDTOUserLogTestLogPostDto
 */
export interface APIXdentDTOUserLogTestLogPostDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserLogTestLogPostDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOUserLogTestLogPostDto
     */
    'age'?: number;
}
/**
 * 
 * @export
 * @interface APIXdentDTOUserLogUserLogDto
 */
export interface APIXdentDTOUserLogUserLogDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserLogUserLogDto
     */
    'logJson'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOUserUserXdentDto
 */
export interface APIXdentDTOUserUserXdentDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'dob'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'education'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'phonePrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'icp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'idExpertise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'insuranceAccess'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'educationAfter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'nameOnline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOUserUserXdentDto
     */
    'idProfilePhoto'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOVzpAsyncResponseDto
 */
export interface APIXdentDTOVzpAsyncResponseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'idState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'idXdentCorrelation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'dateRecieved'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'xPathToFile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpAsyncResponseDto
     */
    'idVzpAsyncResponse'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOVzpAsyncResponsesGridDto
 */
export interface APIXdentDTOVzpAsyncResponsesGridDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof APIXdentDTOVzpAsyncResponsesGridDto
     */
    'xdentCorrelations'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOVzpInsuranceItemDto
 */
export interface APIXdentDTOVzpInsuranceItemDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'idShort'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'naz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'kod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'insurancePrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'typeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'laboratoryPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'idPaymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'paymentTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'isLocalizationRequire'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'ageStart'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'ageFinish'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'clientPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'customCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'nameLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'laboratoryColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'idLaboratoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'odb'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'maxCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'upo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceItemDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOVzpInsuranceSearchDiagnoseDto
 */
export interface APIXdentDTOVzpInsuranceSearchDiagnoseDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceSearchDiagnoseDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOVzpInsuranceSearchDiagnoseDto
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentDTOXdentShareShareLabTicketFileDto
 */
export interface APIXdentDTOXdentShareShareLabTicketFileDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentDTOXdentShareShareLabTicketFileDto
     */
    'idFile'?: string;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto
 */
export interface APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto
     */
    'alerts'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto
     */
    'medicaments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto
     */
    'formMedicaments'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto
     */
    'formAlerts'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto
     */
    'anamnesisDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryDto
 */
export interface APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryDto {
    /**
     * 
     * @type {Array<APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto>}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryDto
     */
    'items'?: Array<APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryDto
     */
    'anamnesisDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto
 */
export interface APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto
     */
    'idForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto
     */
    'formName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto
     */
    'formMedicaments'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryItemDto
     */
    'formAlerts'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedClientServiceXdentPointGenderDto
 */
export interface APIXdentServicesXdentScopedClientServiceXdentPointGenderDto {
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGenderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGenderDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGenderDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGenderDto
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
 */
export interface APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment {
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'idAppointment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'staffName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'staffDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'serviceCaption'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'color'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'onlineBooked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'directions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {APIXdentServicesXdentScopedClientServiceXdentPointGridClient}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'client'?: APIXdentServicesXdentScopedClientServiceXdentPointGridClient;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'idStaffProfilePhoto'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'directionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'directionDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto
 */
export interface APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto {
    /**
     * 
     * @type {Array<APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment>}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto
     */
    'appointments'?: Array<APIXdentServicesXdentScopedClientServiceXdentPointGridAppointment> | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto
     */
    'searchStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto
     */
    'searchEnd'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto
     */
    'filteredByLocation'?: boolean;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedClientServiceXdentPointGridClient
 */
export interface APIXdentServicesXdentScopedClientServiceXdentPointGridClient {
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'idClient'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'firstname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'lastname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'personalId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {APIXdentServicesXdentScopedClientServiceXdentPointGenderDto}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'gender'?: APIXdentServicesXdentScopedClientServiceXdentPointGenderDto;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedClientServiceXdentPointGridClient
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedInterfacesGetCompanyIdByUserIdDTO
 */
export interface APIXdentServicesXdentScopedInterfacesGetCompanyIdByUserIdDTO {
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedInterfacesGetCompanyIdByUserIdDTO
     */
    'idUser'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedInterfacesGetCompanyIdByUserIdDTO
     */
    'idCompany'?: string | null;
}
/**
 * 
 * @export
 * @interface APIXdentServicesXdentScopedLanguagesServiceLanguageDTO
 */
export interface APIXdentServicesXdentScopedLanguagesServiceLanguageDTO {
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerLanguage}
     * @memberof APIXdentServicesXdentScopedLanguagesServiceLanguageDTO
     */
    'language'?: APIContextGeneratedXdentCoreEntityLayerLanguage;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedLanguagesServiceLanguageDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedLanguagesServiceLanguageDTO
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIXdentServicesXdentScopedLanguagesServiceLanguageDTO
     */
    'anotation'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedXdentCoreEntityLayerMutation}
     * @memberof APIXdentServicesXdentScopedLanguagesServiceLanguageDTO
     */
    'mutation'?: APIContextGeneratedXdentCoreEntityLayerMutation;
}
/**
 * @type ApiAppointmentAddappointmentPostRequest
 * @export
 */
export type ApiAppointmentAddappointmentPostRequest = APISharedCreateAppointmentDto | APISharedEditAppointmentDto;

/**
 * @type ApiClientAddclientPostRequest
 * @export
 */
export type ApiClientAddclientPostRequest = APISharedAddClientDto | APISharedAddClientInternal | APISharedEditClientDto | APISharedEditClientInternal;

/**
 * @type ApiClientEditclientPutRequest
 * @export
 */
export type ApiClientEditclientPutRequest = APISharedEditClientDto | APISharedEditClientInternal;

/**
 * @type ApiDentalcategoryUpdateclientdentalcategoriesPutRequest
 * @export
 */
export type ApiDentalcategoryUpdateclientdentalcategoriesPutRequest = APISharedUpdateClientCategoriesDto | APISharedUpdateClientCategoriesInternal;

/**
 * @type ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest
 * @export
 */
export type ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest = LabinoDtoShareTicketXdentRequest | LabinoDtoShareTicketXdentRequestFromXdent | LabinoDtoShareTicketXdentRequestToLabino;

/**
 * @type ApiOnlinebookingAuthonlinebookingiklientPostRequest
 * @export
 */
export type ApiOnlinebookingAuthonlinebookingiklientPostRequest = APISharedLoginBookingDto | APISharedLoginDto;

/**
 * @type ApiSafesignaturesAuthPostRequest
 * @export
 */
export type ApiSafesignaturesAuthPostRequest = APISharedChiroLoginDto | APISharedXdentLoginDto;

/**
 * @type ApiTagAddcustomtagPostRequest
 * @export
 */
export type ApiTagAddcustomtagPostRequest = APISharedAddClientRelationCustomTagDto | APISharedAddRelationCustomTagDto;

/**
 * 
 * @export
 * @interface LabinoDtoChatMessageFileDto
 */
export interface LabinoDtoChatMessageFileDto {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoChatMessageFileDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoChatMessageFileDto
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoChatMessageFileDto
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoChatMessageFileDto
     */
    'imageDataBase64'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoInvalidateLaboratoryRequest
 */
export interface LabinoDtoInvalidateLaboratoryRequest {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvalidateLaboratoryRequest
     */
    'idOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvalidateLaboratoryRequest
     */
    'idXLaboratory'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvalidateLaboratoryRequest
     */
    'idXLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvalidateLaboratoryRequest
     */
    'idLabLocation'?: string;
}
/**
 * 
 * @export
 * @interface LabinoDtoInvoicePartnerInvitationDTO
 */
export interface LabinoDtoInvoicePartnerInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvoicePartnerInvitationDTO
     */
    'idInvoicePartner'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvoicePartnerInvitationDTO
     */
    'regNr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvoicePartnerInvitationDTO
     */
    'idLabCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvoicePartnerInvitationDTO
     */
    'idLabLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoInvoicePartnerInvitationDTO
     */
    'labLaboratoryName'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoLabMacro
 */
export interface LabinoDtoLabMacro {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'idMacro'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'idTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'idCategory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'categoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'categoryTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacro
     */
    'sumQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacro
     */
    'sumPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacro
     */
    'position'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'localization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacro
     */
    'text'?: string | null;
    /**
     * 
     * @type {Array<LabinoDtoLabMacroItem>}
     * @memberof LabinoDtoLabMacro
     */
    'macroItems'?: Array<LabinoDtoLabMacroItem> | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoLabMacroItem
 */
export interface LabinoDtoLabMacroItem {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idMacroItem'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idInvoiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idParentMacro'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LabinoDtoLabMacroItem
     */
    'isDone'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'localization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idLocalization'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'laborant'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabMacroItem
     */
    'idLaborant'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'laborantBonusFix'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'position'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'insertedUnitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLabMacroItem
     */
    'materialLoss'?: number | null;
}
/**
 * This is used by labino frontend (legacy stuff, but dont refactor it)
 * @export
 * @interface LabinoDtoLabPairHelp
 */
export interface LabinoDtoLabPairHelp {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'idLabLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'idLabCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'idCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'idLaboratory'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'idLocation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LabinoDtoLabPairHelp
     */
    'pairSucceded'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabPairHelp
     */
    'idCreatedLab'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoLabTicketHistoryDto
 */
export interface LabinoDtoLabTicketHistoryDto {
    /**
     * 
     * @type {APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'labTicketDetail'?: APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABTICKETDETAILResult;
    /**
     * 
     * @type {APIContextGeneratedLabinoCoreEntityLayerLabTicket}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'labTicket'?: APIContextGeneratedLabinoCoreEntityLayerLabTicket;
    /**
     * 
     * @type {Array<LabinoDtoLabMacro>}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'labMacros'?: Array<LabinoDtoLabMacro> | null;
    /**
     * 
     * @type {Array<APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections>}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'inspections'?: Array<APIContextGeneratedLabinoCoreEntityLayerLabTicketInspections> | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'idLabinoUser'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'historyBatchGeneratedName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'isTicketExternalStatusUpdate'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LabinoDtoLabTicketHistoryDto
     */
    'isSilentUpdate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoLaboratoryGridMarketplaceDto
 */
export interface LabinoDtoLaboratoryGridMarketplaceDto {
    /**
     * 
     * @type {APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult}
     * @memberof LabinoDtoLaboratoryGridMarketplaceDto
     */
    'laboratory'?: APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult;
    /**
     * 
     * @type {LabinoDtoLocationGeocache}
     * @memberof LabinoDtoLaboratoryGridMarketplaceDto
     */
    'geocache'?: LabinoDtoLocationGeocache;
}
/**
 * 
 * @export
 * @interface LabinoDtoLocationGeocache
 */
export interface LabinoDtoLocationGeocache {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLocationGeocache
     */
    'idEntity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLocationGeocache
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LabinoDtoLocationGeocache
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoLocationGeocache
     */
    'map'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoMasterListMap
 */
export interface LabinoDtoMasterListMap {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoMasterListMap
     */
    'idMaster'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoMasterListMap
     */
    'idFile'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoMasterListMap
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoMasterListMap
     */
    'idPartnerFile'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoShareLabLaboratoryRequest
 */
export interface LabinoDtoShareLabLaboratoryRequest {
    /**
     * 
     * @type {APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest}
     * @memberof LabinoDtoShareLabLaboratoryRequest
     */
    'laboratoryRequest'?: APIContextGeneratedLabinoCoreEntityLayerLabLaboratoryRequest;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareLabLaboratoryRequest
     */
    'labinoUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareLabLaboratoryRequest
     */
    'labLaboratoryName'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoSharePricelistValidityInternalDto
 */
export interface LabinoDtoSharePricelistValidityInternalDto {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoSharePricelistValidityInternalDto
     */
    'idPricelistValidity'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoSharePricelistValidityInternalDto
     */
    'idLabCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoSharePricelistValidityInternalDto
     */
    'dateFrom'?: string;
}
/**
 * 
 * @export
 * @interface LabinoDtoShareTicketChatMessage
 */
export interface LabinoDtoShareTicketChatMessage {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketChatMessage
     */
    'idLabLabel'?: string;
    /**
     * 
     * @type {Array<LabinoDtoChatMessageFileDto>}
     * @memberof LabinoDtoShareTicketChatMessage
     */
    'files'?: Array<LabinoDtoChatMessageFileDto> | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketChatMessage
     */
    'messageText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketChatMessage
     */
    'existingIdFile'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoShareTicketXdentRequest
 */
export interface LabinoDtoShareTicketXdentRequest {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'labLabelNumber'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'externalMessage'?: APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal;
    /**
     * 
     * @type {LabinoDtoShareTicketXdentRequestSharedFilesWrapper}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'sharedFilesWrapper'?: LabinoDtoShareTicketXdentRequestSharedFilesWrapper | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'idXdentLaboratory'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'idXdentCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequest
     */
    'labelNumberLab'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoShareTicketXdentRequestFromXdent
 */
export interface LabinoDtoShareTicketXdentRequestFromXdent {
    /**
     * 
     * @type {LabinoDtoSharedFilesWrapperFromXdent}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'sharedFilesWrapper'?: LabinoDtoSharedFilesWrapperFromXdent;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'labLabelNumber'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'externalMessage'?: APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'idXdentLaboratory'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'idXdentCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestFromXdent
     */
    'labelNumberLab'?: string | null;
}
/**
 * @type LabinoDtoShareTicketXdentRequestSharedFilesWrapper
 * @export
 */
export type LabinoDtoShareTicketXdentRequestSharedFilesWrapper = LabinoDtoSharedFilesWrapper | LabinoDtoSharedFilesWrapperFromXdent | LabinoDtoSharedFilesWrapperToLabino;

/**
 * 
 * @export
 * @interface LabinoDtoShareTicketXdentRequestToLabino
 */
export interface LabinoDtoShareTicketXdentRequestToLabino {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'idLabTicket'?: string | null;
    /**
     * 
     * @type {APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'externalMessage'?: APIContextGeneratedLabinoCoreEntityLayerChatMessageExternal;
    /**
     * 
     * @type {LabinoDtoSharedFilesWrapperToLabino}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'sharedFilesWrapper'?: LabinoDtoSharedFilesWrapperToLabino;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'idXdentCompany'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'labLabelNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'idXdentLaboratory'?: string;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoShareTicketXdentRequestToLabino
     */
    'labelNumberLab'?: string | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoSharedFilesWrapper
 */
export interface LabinoDtoSharedFilesWrapper {
    /**
     * 
     * @type {Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result>}
     * @memberof LabinoDtoSharedFilesWrapper
     */
    'galleryFiles'?: Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedGALLERYFILESGRID2Result> | null;
    /**
     * 
     * @type {Array<LabinoDtoMasterListMap>}
     * @memberof LabinoDtoSharedFilesWrapper
     */
    'sharedFiles'?: Array<LabinoDtoMasterListMap> | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoSharedFilesWrapperFromXdent
 */
export interface LabinoDtoSharedFilesWrapperFromXdent {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result>}
     * @memberof LabinoDtoSharedFilesWrapperFromXdent
     */
    'galleryFiles'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result> | null;
    /**
     * 
     * @type {Array<LabinoDtoMasterListMap>}
     * @memberof LabinoDtoSharedFilesWrapperFromXdent
     */
    'sharedFiles'?: Array<LabinoDtoMasterListMap> | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoSharedFilesWrapperToLabino
 */
export interface LabinoDtoSharedFilesWrapperToLabino {
    /**
     * 
     * @type {Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result>}
     * @memberof LabinoDtoSharedFilesWrapperToLabino
     */
    'galleryFiles'?: Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result> | null;
    /**
     * 
     * @type {Array<LabinoDtoMasterListMap>}
     * @memberof LabinoDtoSharedFilesWrapperToLabino
     */
    'sharedFiles'?: Array<LabinoDtoMasterListMap> | null;
}
/**
 * 
 * @export
 * @interface LabinoDtoXdentLocationBillingInfoDto
 */
export interface LabinoDtoXdentLocationBillingInfoDto {
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'post'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'regNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LabinoDtoXdentLocationBillingInfoDto
     */
    'web'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'typDavkovania': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'denneDavkovanie1'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'denneDavkovanie1Specified'?: boolean;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDenneDavkovanie}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'denneDavkovanie2'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDenneDavkovanie;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'jednotkaDavkovania'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'sposobPodania'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'periodicita'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'dobaLiecbyValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'dobaLiecbyValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'dobaLiecby'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'poznamka'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie
     */
    'prepisDavkovania': string;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDenneDavkovanie
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDenneDavkovanie {
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDenneDavkovanie
     */
    'pocetJednotiek': number;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDenneDavkovanie
     */
    'opakovanieVHodinach': number;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj
     */
    'ico': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj
     */
    'kodNZ': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj
     */
    'nastavenia'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'kodOdporucajucaNZ': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'idOdporucajucaNZValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'idOdporucajucaNZValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'idOdporucajucaNZ'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'kodOdporucajuciLekar': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'idOdporucajuciLekarValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'idOdporucajuciLekarValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'idOdporucajuciLekar'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'menoOdporucajucehoLekara'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar
     */
    'datumOdporucania': string;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'icoHlavnaZlozka': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idHlavnaZlozkaValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idHlavnaZlozkaValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idHlavnaZlozka'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'kodNizsiaZlozkaPredpisujucehoLekara': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idNizsiaZlozkaPredpisujucehoLekaraValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idNizsiaZlozkaPredpisujucehoLekaraValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idNizsiaZlozkaPredpisujucehoLekara'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'kodPredpisujuciLekar': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idPredpisujuciLekarValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idPredpisujuciLekarValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'idPredpisujuciLekar'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar
     */
    'menoPredpisujucehoLekara'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam {
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idPreskripcnehoZaznamuValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idPreskripcnehoZaznamuValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idPreskripcnehoZaznamu'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'externyIdentifikatorRiadku': number;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'predpisujuciLekar': XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPredpisujuciLekar;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumPrepusteniaZHospitalizacieValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumPrepusteniaZHospitalizacieValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumPrepusteniaZHospitalizacie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idObdobieDavkyValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idObdobieDavkyValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idObdobieDavky'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'rodneCisloVykazane': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'rodneCisloAkceptovane'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idPoistenecValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idPoistenecValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'idPoistenec'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datum2Vykazany': string;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'udajeProdukt': XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'davkovanie': XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDavkovanie;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'kodDiagnozaVykazana': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'kodDiagnozaAkcept'?: string | null;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'odporucajuciLekar'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIOdporucajuciLekar;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zastupovanyLekar'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'statPoistenca': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'identifikacneCisloPoistenca'?: string | null;
    /**
     * 0 - M, 1 - Z, 2 - X, 3 - N
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'pohlaviePoistencaValue'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaValueEnum;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'pohlaviePoistencaValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'pohlaviePoistenca'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'neodkladnaZS': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'neplatic': boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'evident'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumCasEvidencieValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumCasEvidencieValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumCasEvidencie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'modifikator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumPoslednejModifikacieValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumPoslednejModifikacieValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'datumPoslednejModifikacie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'ciarovyKod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'poznamkaZP'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zakazVydaniaNahrady': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zakazaneLieky'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zakazaneLiekySpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'periodaOpakovanehoReceptuValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'periodaOpakovanehoReceptuValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'periodaOpakovanehoReceptu'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'pocetOpakovaniOpakovanehoReceptuValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'pocetOpakovaniOpakovanehoReceptuValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'pocetOpakovaniOpakovanehoReceptu'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'platnostReceptuValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'platnostReceptuValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'platnostReceptu'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'vynutenyZapis': boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'vynutenyZapisDovod'?: string | null;
    /**
     * 0 - NEZML, 1 - JASAM
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'dovodUhradyPacientaValue'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaValueEnum;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'dovodUhradyPacientaValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'dovodUhradyPacienta'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaEnum;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'produktNaVynimku': boolean;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'udajeVynimky'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'udajeIPL'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zdovodnenie'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'opisPomockyNaMieru'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'novorodenecNaRCRodica': boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'poznamka'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'trvalePostihnutie': boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'kodIndikacneObmedzenie'?: string | null;
    /**
     * 0 - L, 1 - P
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'aplikacnaZonaParovehoOrganuValue'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuValueEnum;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'aplikacnaZonaParovehoOrganuValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'aplikacnaZonaParovehoOrganu'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuEnum;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'mnozstvovyLimitPreDP'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'nadLimitneMnozstvoZP': boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'cisloSchvalenejVynimky'?: string | null;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'dychaciPristroj'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIDychaciPristroj;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zaloznyAtribut1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zaloznyAtribut2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'zaloznyAtribut3'?: string | null;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'signature'?: XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType;
    /**
     * 0 - JePodpisanyPlatny, 1 - JePodpisanyNeplatny, 2 - JePodpisanyNeovereny, 3 - NieJePodpisany
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'signatureValidationStatusValue'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusValueEnum;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'signatureValidationStatusValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznam
     */
    'signatureValidationStatus'?: XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusEnum;
}

export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaValueEnum = {
    M: 'M',
    Z: 'Z',
    X: 'X',
    N: 'N'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaValueEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaValueEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaValueEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaEnum = {
    M: 'M',
    Z: 'Z',
    X: 'X',
    N: 'N'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamPohlaviePoistencaEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaValueEnum = {
    Nezml: 'NEZML',
    Jasam: 'JASAM'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaValueEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaValueEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaValueEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaEnum = {
    Nezml: 'NEZML',
    Jasam: 'JASAM'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamDovodUhradyPacientaEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuValueEnum = {
    L: 'L',
    P: 'P'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuValueEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuValueEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuValueEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuEnum = {
    L: 'L',
    P: 'P'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamAplikacnaZonaParovehoOrganuEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusValueEnum = {
    JePodpisanyPlatny: 'JePodpisanyPlatny',
    JePodpisanyNeplatny: 'JePodpisanyNeplatny',
    JePodpisanyNeovereny: 'JePodpisanyNeovereny',
    NieJePodpisany: 'NieJePodpisany'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusValueEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusValueEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusValueEnum];
export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusEnum = {
    JePodpisanyPlatny: 'JePodpisanyPlatny',
    JePodpisanyNeplatny: 'JePodpisanyNeplatny',
    JePodpisanyNeovereny: 'JePodpisanyNeovereny',
    NieJePodpisany: 'NieJePodpisany'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIPreskripcnyZaznamSignatureValidationStatusEnum];

/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava
     */
    'zavaznostText'?: string | null;
    /**
     * 0 - INFO, 1 - VAROVANIE, 2 - CHYBA, 3 - ODMIETNUTIE
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava
     */
    'zavaznost': XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISpravaZavaznostEnum;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava
     */
    'kod': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava
     */
    'text': string;
}

export const XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISpravaZavaznostEnum = {
    Info: 'INFO',
    Varovanie: 'VAROVANIE',
    Chyba: 'CHYBA',
    Odmietnutie: 'ODMIETNUTIE'
} as const;

export type XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISpravaZavaznostEnum = typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISpravaZavaznostEnum[keyof typeof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISpravaZavaznostEnum];

/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL
     */
    'zlozenieIPL': string;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL
     */
    'zlozkyIPL'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL
     */
    'zlozkyIPLSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL
     */
    'pokynyPriVyrobeIPL': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeIPL
     */
    'pokynKUzivaniuIPL': string;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'kodProduktPZSVykaz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'kodProduktPZSAkcept'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'podskupinaPomockyVykaz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'nazovProduktVykaz'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'mernaJednotka'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'kodATCVykazany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'atcDoplnok'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'kodATCAkceptovany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIUdajeProdukt
     */
    'mnozstvoVykazane': number;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka {
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'idVynimky': number;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'cisloVynimky': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'platnostOd': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'platnostDoValue'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'platnostDoValueSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'platnostDo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'schvaleneMnozstvo': number;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'nevycerpaneMnozstvo': number;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIVynimka
     */
    'dohodnutaCena': number;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'kodZastupovanaNZ': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'idZastupovanaNZValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'idZastupovanaNZValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'idZastupovanaNZ'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'kodZastupovanyLekar': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'idZastupovanyLekarValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'idZastupovanyLekarValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'idZastupovanyLekar'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZastupovanyLekar
     */
    'menoZastupovanehoLekara'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'kod': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'nazov': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'mnozstvo': number;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'mernaJednotka': string;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'jednotkovaCenaBezDPHValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'jednotkovaCenaBezDPHValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'jednotkovaCenaBezDPH'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'jednotkovaCenaSDPHValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'jednotkovaCenaSDPHValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'jednotkovaCenaSDPH'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'uhradaZPValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'uhradaZPValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'uhradaZP'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'uhradaPacientaValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'uhradaPacientaValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPIZlozkaIPL
     */
    'uhradaPacienta'?: number | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup {
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'idPreskripcnehoZaznamuValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'idPreskripcnehoZaznamuValueSpecified'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'idPreskripcnehoZaznamu'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'ciarovyKod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'poznamkaZP'?: string | null;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'spravy'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIEreceptTypesAPISprava> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIEreceptUlozPreskripcnyZaznamVystup
     */
    'spravySpecified'?: boolean;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType {
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType
     */
    'algorithm': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'p'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'q'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'g'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'y': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'j'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'seed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType
     */
    'pgenCounter'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType {
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType
     */
    'algorithm': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType {
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'keyName'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'keyNameSpecified'?: boolean;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'keyValue'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'keyValueSpecified'?: boolean;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'retrievalMethod'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'retrievalMethodSpecified'?: boolean;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'x509Data'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'x509DataSpecified'?: boolean;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'pgpData'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'pgpDataSpecified'?: boolean;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'spkiData'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'spkiDataSpecified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'mgmtData'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'mgmtDataSpecified'?: boolean;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType {
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType
     */
    'dsaKeyValue'?: XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDSAKeyValueType;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRSAKeyValueType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType
     */
    'rsaKeyValue'?: XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRSAKeyValueType;
    /**
     * 
     * @type {Array<any>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType
     */
    'any'?: Array<any> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyValueType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType {
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
     */
    'mimeType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
     */
    'encoding'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType
     */
    'pgpKeyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType
     */
    'pgpKeyPacket'?: string | null;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigPGPDataType
     */
    'pgpKeyPacket1'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRSAKeyValueType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRSAKeyValueType {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRSAKeyValueType
     */
    'modulus': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRSAKeyValueType
     */
    'exponent': string;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType {
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'transforms'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'transformsSpecified'?: boolean;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'digestMethod': XDTEZdravieModelEReceptV6SchemaNCZIXmldsigDigestMethodType;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'digestValue': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType {
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType
     */
    'transforms'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType
     */
    'transformsSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType
     */
    'uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigRetrievalMethodType
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType {
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType
     */
    'spkiSexp': Array<string>;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSPKIDataType
     */
    'anySpecified'?: boolean;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType
     */
    'hmacOutputLength'?: string | null;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType
     */
    'algorithm': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType {
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
     */
    'signedInfo': XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureValueType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
     */
    'signatureValue': XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureValueType;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
     */
    'keyInfo'?: XDTEZdravieModelEReceptV6SchemaNCZIXmldsigKeyInfoType;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
     */
    'object'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigObjectType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
     */
    'objectSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureType
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureValueType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureValueType {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureValueType
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureValueType
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType {
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType
     */
    'canonicalizationMethod': XDTEZdravieModelEReceptV6SchemaNCZIXmldsigCanonicalizationMethodType;
    /**
     * 
     * @type {XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType
     */
    'signatureMethod': XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignatureMethodType;
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType
     */
    'reference': Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigReferenceType>;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigSignedInfoType
     */
    'id'?: string | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType {
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
     */
    'anySpecified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
     */
    'xPath'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
     */
    'xPathSpecified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
     */
    'algorithm': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigTransformType
     */
    'text'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType {
    /**
     * 
     * @type {Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509IssuerSerialType>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509IssuerSerial'?: Array<XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509IssuerSerialType> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509IssuerSerialSpecified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509SKI'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509SKISpecified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509SubjectName'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509SubjectNameSpecified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509Certificate'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509CertificateSpecified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509CRL'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'x509CRLSpecified'?: boolean;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'any'?: Array<Array<any>> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509DataType
     */
    'anySpecified'?: boolean;
}
/**
 * 
 * @export
 * @interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509IssuerSerialType
 */
export interface XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509IssuerSerialType {
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509IssuerSerialType
     */
    'x509IssuerName': string;
    /**
     * 
     * @type {string}
     * @memberof XDTEZdravieModelEReceptV6SchemaNCZIXmldsigX509IssuerSerialType
     */
    'x509SerialNumber': string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLocalizationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/localization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountLocalizationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountLocalizationGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AccountApi.apiAccountLocalizationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLocalizationGet(options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSETCOMPANYFORMResult> {
            return localVarFp.apiAccountLocalizationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLocalizationGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountLocalizationGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetageclientsGet: async (freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytic/getageclients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (freshDataDays !== undefined) {
                localVarQueryParameter['freshDataDays'] = freshDataDays;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetappointmentsGet: async (freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytic/getappointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (freshDataDays !== undefined) {
                localVarQueryParameter['freshDataDays'] = freshDataDays;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetdurationsGet: async (freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytic/getdurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (freshDataDays !== undefined) {
                localVarQueryParameter['freshDataDays'] = freshDataDays;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetnewclientsGet: async (freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytic/getnewclients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (freshDataDays !== undefined) {
                localVarQueryParameter['freshDataDays'] = freshDataDays;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetpaymentsGet: async (freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytic/getpayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (freshDataDays !== undefined) {
                localVarQueryParameter['freshDataDays'] = freshDataDays;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticGetageclientsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSAGECLIENTS>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticGetageclientsGet(freshDataDays, dateFrom, dateTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsApi.apiAnalyticGetageclientsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticGetappointmentsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticGetappointmentsGet(freshDataDays, dateFrom, dateTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsApi.apiAnalyticGetappointmentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticGetdurationsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSDURATIONS>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticGetdurationsGet(freshDataDays, dateFrom, dateTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsApi.apiAnalyticGetdurationsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticGetnewclientsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSNEWCLIENTS>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticGetnewclientsGet(freshDataDays, dateFrom, dateTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsApi.apiAnalyticGetnewclientsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticGetpaymentsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSPAYMENTS>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticGetpaymentsGet(freshDataDays, dateFrom, dateTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnalyticsApi.apiAnalyticGetpaymentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetageclientsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSAGECLIENTS>> {
            return localVarFp.apiAnalyticGetageclientsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetappointmentsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSAPPOINTMENTS>> {
            return localVarFp.apiAnalyticGetappointmentsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetdurationsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSDURATIONS>> {
            return localVarFp.apiAnalyticGetdurationsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetnewclientsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSNEWCLIENTS>> {
            return localVarFp.apiAnalyticGetnewclientsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} [freshDataDays] 
         * @param {string | null} [dateFrom] 
         * @param {string | null} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticGetpaymentsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOAnalyticsANALYTICSPAYMENTS>> {
            return localVarFp.apiAnalyticGetpaymentsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {number} [freshDataDays] 
     * @param {string | null} [dateFrom] 
     * @param {string | null} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticGetageclientsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticGetageclientsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} [freshDataDays] 
     * @param {string | null} [dateFrom] 
     * @param {string | null} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticGetappointmentsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticGetappointmentsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} [freshDataDays] 
     * @param {string | null} [dateFrom] 
     * @param {string | null} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticGetdurationsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticGetdurationsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} [freshDataDays] 
     * @param {string | null} [dateFrom] 
     * @param {string | null} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticGetnewclientsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticGetnewclientsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} [freshDataDays] 
     * @param {string | null} [dateFrom] 
     * @param {string | null} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticGetpaymentsGet(freshDataDays?: number, dateFrom?: string | null, dateTo?: string | null, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticGetpaymentsGet(freshDataDays, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnamnesisApi - axios parameter creator
 * @export
 */
export const AnamnesisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnamnesisGetanamnesisGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/anamnesis/getanamnesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnamnesisGetformanamnesisGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/anamnesis/getformanamnesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnamnesisGetformanamnesishistoryGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/anamnesis/getformanamnesishistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnamnesisApi - functional programming interface
 * @export
 */
export const AnamnesisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnamnesisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnamnesisGetanamnesisGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnamnesisGetanamnesisGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnamnesisApi.apiAnamnesisGetanamnesisGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnamnesisGetformanamnesisGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnamnesisGetformanamnesisGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnamnesisApi.apiAnamnesisGetformanamnesisGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnamnesisGetformanamnesishistoryGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnamnesisGetformanamnesishistoryGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AnamnesisApi.apiAnamnesisGetformanamnesishistoryGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AnamnesisApi - factory interface
 * @export
 */
export const AnamnesisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnamnesisApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnamnesisGetanamnesisGet(idClient?: string, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult> {
            return localVarFp.apiAnamnesisGetanamnesisGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnamnesisGetformanamnesisGet(idClient?: string, options?: any): AxiosPromise<APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisDto> {
            return localVarFp.apiAnamnesisGetformanamnesisGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnamnesisGetformanamnesishistoryGet(idClient?: string, options?: any): AxiosPromise<APIXdentServicesXdentScopedAnamnesisServiceClientFormAnamnesisHistoryDto> {
            return localVarFp.apiAnamnesisGetformanamnesishistoryGet(idClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnamnesisApi - object-oriented interface
 * @export
 * @class AnamnesisApi
 * @extends {BaseAPI}
 */
export class AnamnesisApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnamnesisApi
     */
    public apiAnamnesisGetanamnesisGet(idClient?: string, options?: AxiosRequestConfig) {
        return AnamnesisApiFp(this.configuration).apiAnamnesisGetanamnesisGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnamnesisApi
     */
    public apiAnamnesisGetformanamnesisGet(idClient?: string, options?: AxiosRequestConfig) {
        return AnamnesisApiFp(this.configuration).apiAnamnesisGetformanamnesisGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnamnesisApi
     */
    public apiAnamnesisGetformanamnesishistoryGet(idClient?: string, options?: AxiosRequestConfig) {
        return AnamnesisApiFp(this.configuration).apiAnamnesisGetformanamnesishistoryGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AppointmentApi - axios parameter creator
 * @export
 */
export const AppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds appointment by model for owner user.
         * @param {ApiAppointmentAddappointmentPostRequest} [apiAppointmentAddappointmentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentAddappointmentPost: async (apiAppointmentAddappointmentPostRequest?: ApiAppointmentAddappointmentPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/addappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiAppointmentAddappointmentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add appointment
         * @param {APISharedCalendarAppointmentX2Dto} [aPISharedCalendarAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentAddappointmentx2Post: async (aPISharedCalendarAppointmentX2Dto?: APISharedCalendarAppointmentX2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/addappointmentx2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedCalendarAppointmentX2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedAddAppointmenX2Dto} [aPISharedAddAppointmenX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentAddappointmentx3Post: async (aPISharedAddAppointmenX2Dto?: APISharedAddAppointmenX2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/addappointmentx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedAddAppointmenX2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set appointment confirmation status from onlineBooking
         * @param {string | null} [idAppointment] 
         * @param {boolean | null} [isConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentConfirmappointmentGet: async (idAppointment?: string | null, isConfirm?: boolean | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/confirmappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idAppointment !== undefined) {
                localVarQueryParameter['idAppointment'] = idAppointment;
            }

            if (isConfirm !== undefined) {
                localVarQueryParameter['isConfirm'] = isConfirm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {APISharedDeleteAppointment} [aPISharedDeleteAppointment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentDeleteappointmentbyidDelete: async (aPISharedDeleteAppointment?: APISharedDeleteAppointment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/deleteappointmentbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedDeleteAppointment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete appointment
         * @param {APISharedDeleteAppointmentX2Dto} [aPISharedDeleteAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentDeleteappointmentx2Post: async (aPISharedDeleteAppointmentX2Dto?: APISharedDeleteAppointmentX2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/deleteappointmentx2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedDeleteAppointmentX2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedDeleteAppointment2Dto} [aPISharedDeleteAppointment2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentDeleteappointmentx3Post: async (aPISharedDeleteAppointment2Dto?: APISharedDeleteAppointment2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/deleteappointmentx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedDeleteAppointment2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits appointment by id for owner user.
         * @param {APISharedEditAppointmentDto} [aPISharedEditAppointmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditappointmentPut: async (aPISharedEditAppointmentDto?: APISharedEditAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/editappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedEditAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits appointment by id for owner user
         * @param {APISharedEditAppointmentX2Dto} [aPISharedEditAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditappointmentx2Post: async (aPISharedEditAppointmentX2Dto?: APISharedEditAppointmentX2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/editappointmentx2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedEditAppointmentX2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedEditAppointmentValuesX2Dto} [aPISharedEditAppointmentValuesX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditappointmentx3Post: async (aPISharedEditAppointmentValuesX2Dto?: APISharedEditAppointmentValuesX2Dto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/editappointmentx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedEditAppointmentValuesX2Dto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits appointment status by status id for owner user.
         * @param {string | null} [idAppointment] 
         * @param {string | null} [idStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditstatusPut: async (idAppointment?: string | null, idStatus?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/editstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idAppointment !== undefined) {
                localVarQueryParameter['idAppointment'] = idAppointment;
            }

            if (idStatus !== undefined) {
                localVarQueryParameter['idStatus'] = idStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {string | null} [appointmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentbyidGet: async (appointmentId?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getappointmentbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {number | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsGet: async (startDate?: number | null, endDate?: number | null, idStaff?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getappointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idOwner] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsandopenhoursdurationGet: async (idOwner?: string | null, startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getappointmentsandopenhoursduration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idOwner !== undefined) {
                localVarQueryParameter['idOwner'] = idOwner;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {string | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsx2Get: async (startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getappointmentsx2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsx3Get: async (startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getappointmentsx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (controller !== undefined) {
                localVarQueryParameter['controller'] = controller;
            }

            if (cacheContext !== undefined) {
                localVarQueryParameter['cacheContext'] = cacheContext;
            }

            if (invoker !== undefined) {
                localVarQueryParameter['invoker'] = invoker;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (idGlobal !== undefined) {
                localVarQueryParameter['idGlobal'] = idGlobal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetclientsbyuserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getclientsbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetclientsbyuserx3Get: async (controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getclientsbyuserx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controller !== undefined) {
                localVarQueryParameter['controller'] = controller;
            }

            if (cacheContext !== undefined) {
                localVarQueryParameter['cacheContext'] = cacheContext;
            }

            if (invoker !== undefined) {
                localVarQueryParameter['invoker'] = invoker;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (idGlobal !== undefined) {
                localVarQueryParameter['idGlobal'] = idGlobal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets open hours for provided staff
         * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {number | null} [endDate] Else start date + 52 days will be used
         * @param {string | null} [idStaff] Desired staff opening hours.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetopenhoursGet: async (startDate?: number | null, endDate?: number | null, idStaff?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getopenhours`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {string | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetopenhourx2Get: async (startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getopenhourx2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetopenhourx3Get: async (startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getopenhourx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (controller !== undefined) {
                localVarQueryParameter['controller'] = controller;
            }

            if (cacheContext !== undefined) {
                localVarQueryParameter['cacheContext'] = cacheContext;
            }

            if (invoker !== undefined) {
                localVarQueryParameter['invoker'] = invoker;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (idGlobal !== undefined) {
                localVarQueryParameter['idGlobal'] = idGlobal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetservicesbyuserx3Get: async (controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getservicesbyuserx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controller !== undefined) {
                localVarQueryParameter['controller'] = controller;
            }

            if (cacheContext !== undefined) {
                localVarQueryParameter['cacheContext'] = cacheContext;
            }

            if (invoker !== undefined) {
                localVarQueryParameter['invoker'] = invoker;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (idGlobal !== undefined) {
                localVarQueryParameter['idGlobal'] = idGlobal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetserviceswithrelationshipbyuserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getserviceswithrelationshipbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all staffs for user (company)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetstaffbyuserx3Get: async (controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/appointment/getstaffbyuserx3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controller !== undefined) {
                localVarQueryParameter['controller'] = controller;
            }

            if (cacheContext !== undefined) {
                localVarQueryParameter['cacheContext'] = cacheContext;
            }

            if (invoker !== undefined) {
                localVarQueryParameter['invoker'] = invoker;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (idGlobal !== undefined) {
                localVarQueryParameter['idGlobal'] = idGlobal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppointmentApi - functional programming interface
 * @export
 */
export const AppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds appointment by model for owner user.
         * @param {ApiAppointmentAddappointmentPostRequest} [apiAppointmentAddappointmentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentAddappointmentPost(apiAppointmentAddappointmentPostRequest?: ApiAppointmentAddappointmentPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentAddappointmentPost(apiAppointmentAddappointmentPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentAddappointmentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Add appointment
         * @param {APISharedCalendarAppointmentX2Dto} [aPISharedCalendarAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentAddappointmentx2Post(aPISharedCalendarAppointmentX2Dto?: APISharedCalendarAppointmentX2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentAddappointmentx2Post(aPISharedCalendarAppointmentX2Dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentAddappointmentx2Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedAddAppointmenX2Dto} [aPISharedAddAppointmenX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentAddappointmentx3Post(aPISharedAddAppointmenX2Dto?: APISharedAddAppointmenX2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedAddAppointmenX2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentAddappointmentx3Post(aPISharedAddAppointmenX2Dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentAddappointmentx3Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Set appointment confirmation status from onlineBooking
         * @param {string | null} [idAppointment] 
         * @param {boolean | null} [isConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentConfirmappointmentGet(idAppointment?: string | null, isConfirm?: boolean | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentConfirmappointmentGet(idAppointment, isConfirm, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentConfirmappointmentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {APISharedDeleteAppointment} [aPISharedDeleteAppointment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentDeleteappointmentbyidDelete(aPISharedDeleteAppointment?: APISharedDeleteAppointment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentDeleteappointmentbyidDelete(aPISharedDeleteAppointment, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentDeleteappointmentbyidDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete appointment
         * @param {APISharedDeleteAppointmentX2Dto} [aPISharedDeleteAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentDeleteappointmentx2Post(aPISharedDeleteAppointmentX2Dto?: APISharedDeleteAppointmentX2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentDeleteappointmentx2Post(aPISharedDeleteAppointmentX2Dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentDeleteappointmentx2Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedDeleteAppointment2Dto} [aPISharedDeleteAppointment2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentDeleteappointmentx3Post(aPISharedDeleteAppointment2Dto?: APISharedDeleteAppointment2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDeleteAppointment2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentDeleteappointmentx3Post(aPISharedDeleteAppointment2Dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentDeleteappointmentx3Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits appointment by id for owner user.
         * @param {APISharedEditAppointmentDto} [aPISharedEditAppointmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentEditappointmentPut(aPISharedEditAppointmentDto?: APISharedEditAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentEditappointmentPut(aPISharedEditAppointmentDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentEditappointmentPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits appointment by id for owner user
         * @param {APISharedEditAppointmentX2Dto} [aPISharedEditAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentEditappointmentx2Post(aPISharedEditAppointmentX2Dto?: APISharedEditAppointmentX2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentEditappointmentx2Post(aPISharedEditAppointmentX2Dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentEditappointmentx2Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedEditAppointmentValuesX2Dto} [aPISharedEditAppointmentValuesX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentEditappointmentx3Post(aPISharedEditAppointmentValuesX2Dto?: APISharedEditAppointmentValuesX2Dto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedEditAppointmentValuesX2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentEditappointmentx3Post(aPISharedEditAppointmentValuesX2Dto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentEditappointmentx3Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits appointment status by status id for owner user.
         * @param {string | null} [idAppointment] 
         * @param {string | null} [idStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentEditstatusPut(idAppointment?: string | null, idStatus?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentEditstatusPut(idAppointment, idStatus, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentEditstatusPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {string | null} [appointmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetappointmentbyidGet(appointmentId?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetappointmentbyidGet(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetappointmentbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {number | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetappointmentsGet(startDate?: number | null, endDate?: number | null, idStaff?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedAppointmentWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetappointmentsGet(startDate, endDate, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetappointmentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idOwner] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetappointmentsandopenhoursdurationGet(idOwner?: string | null, startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedAppointmentsAndOpenHoursDurationWrapperX2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetappointmentsandopenhoursdurationGet(idOwner, startDate, endDate, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetappointmentsandopenhoursdurationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {string | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetappointmentsx2Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedAppointmentWrapperX2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetappointmentsx2Get(startDate, endDate, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetappointmentsx2Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetappointmentsx3Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedCalendarAppointmentX3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetappointmentsx3Get(startDate, endDate, idStaff, controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetappointmentsx3Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetclientsbyuserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetclientsbyuserGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetclientsbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetclientsbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedCalendarClientsX3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetclientsbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetclientsbyuserx3Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets open hours for provided staff
         * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {number | null} [endDate] Else start date + 52 days will be used
         * @param {string | null} [idStaff] Desired staff opening hours.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetopenhoursGet(startDate?: number | null, endDate?: number | null, idStaff?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedAppointmentWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetopenhoursGet(startDate, endDate, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetopenhoursGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {string | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetopenhourx2Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedOpenHourWrapperX2Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetopenhourx2Get(startDate, endDate, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetopenhourx2Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetopenhourx3Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedCalendarOpenHoursX3Dto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetopenhourx3Get(startDate, endDate, idStaff, controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetopenhourx3Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetservicesbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedCalendarServicesX3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetservicesbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetservicesbyuserx3Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetserviceswithrelationshipbyuserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetserviceswithrelationshipbyuserGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetserviceswithrelationshipbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all staffs for user (company)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAppointmentGetstaffbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedCalendarStaffsX3Dto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAppointmentGetstaffbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AppointmentApi.apiAppointmentGetstaffbyuserx3Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AppointmentApi - factory interface
 * @export
 */
export const AppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds appointment by model for owner user.
         * @param {ApiAppointmentAddappointmentPostRequest} [apiAppointmentAddappointmentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentAddappointmentPost(apiAppointmentAddappointmentPostRequest?: ApiAppointmentAddappointmentPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentAddappointmentPost(apiAppointmentAddappointmentPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add appointment
         * @param {APISharedCalendarAppointmentX2Dto} [aPISharedCalendarAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentAddappointmentx2Post(aPISharedCalendarAppointmentX2Dto?: APISharedCalendarAppointmentX2Dto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentAddappointmentx2Post(aPISharedCalendarAppointmentX2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedAddAppointmenX2Dto} [aPISharedAddAppointmenX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentAddappointmentx3Post(aPISharedAddAppointmenX2Dto?: APISharedAddAppointmenX2Dto, options?: any): AxiosPromise<APISharedAddAppointmenX2Dto> {
            return localVarFp.apiAppointmentAddappointmentx3Post(aPISharedAddAppointmenX2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set appointment confirmation status from onlineBooking
         * @param {string | null} [idAppointment] 
         * @param {boolean | null} [isConfirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentConfirmappointmentGet(idAppointment?: string | null, isConfirm?: boolean | null, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiAppointmentConfirmappointmentGet(idAppointment, isConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {APISharedDeleteAppointment} [aPISharedDeleteAppointment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentDeleteappointmentbyidDelete(aPISharedDeleteAppointment?: APISharedDeleteAppointment, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentDeleteappointmentbyidDelete(aPISharedDeleteAppointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete appointment
         * @param {APISharedDeleteAppointmentX2Dto} [aPISharedDeleteAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentDeleteappointmentx2Post(aPISharedDeleteAppointmentX2Dto?: APISharedDeleteAppointmentX2Dto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentDeleteappointmentx2Post(aPISharedDeleteAppointmentX2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedDeleteAppointment2Dto} [aPISharedDeleteAppointment2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentDeleteappointmentx3Post(aPISharedDeleteAppointment2Dto?: APISharedDeleteAppointment2Dto, options?: any): AxiosPromise<APISharedDeleteAppointment2Dto> {
            return localVarFp.apiAppointmentDeleteappointmentx3Post(aPISharedDeleteAppointment2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits appointment by id for owner user.
         * @param {APISharedEditAppointmentDto} [aPISharedEditAppointmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditappointmentPut(aPISharedEditAppointmentDto?: APISharedEditAppointmentDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentEditappointmentPut(aPISharedEditAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits appointment by id for owner user
         * @param {APISharedEditAppointmentX2Dto} [aPISharedEditAppointmentX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditappointmentx2Post(aPISharedEditAppointmentX2Dto?: APISharedEditAppointmentX2Dto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentEditappointmentx2Post(aPISharedEditAppointmentX2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedEditAppointmentValuesX2Dto} [aPISharedEditAppointmentValuesX2Dto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditappointmentx3Post(aPISharedEditAppointmentValuesX2Dto?: APISharedEditAppointmentValuesX2Dto, options?: any): AxiosPromise<APISharedEditAppointmentValuesX2Dto> {
            return localVarFp.apiAppointmentEditappointmentx3Post(aPISharedEditAppointmentValuesX2Dto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits appointment status by status id for owner user.
         * @param {string | null} [idAppointment] 
         * @param {string | null} [idStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentEditstatusPut(idAppointment?: string | null, idStatus?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentEditstatusPut(idAppointment, idStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {string | null} [appointmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentbyidGet(appointmentId?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentGetappointmentbyidGet(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {number | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsGet(startDate?: number | null, endDate?: number | null, idStaff?: Array<string>, options?: any): AxiosPromise<APISharedAppointmentWrapperDto> {
            return localVarFp.apiAppointmentGetappointmentsGet(startDate, endDate, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idOwner] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsandopenhoursdurationGet(idOwner?: string | null, startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: any): AxiosPromise<APISharedAppointmentsAndOpenHoursDurationWrapperX2Dto> {
            return localVarFp.apiAppointmentGetappointmentsandopenhoursdurationGet(idOwner, startDate, endDate, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {string | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsx2Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: any): AxiosPromise<APISharedAppointmentWrapperX2Dto> {
            return localVarFp.apiAppointmentGetappointmentsx2Get(startDate, endDate, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetappointmentsx3Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: any): AxiosPromise<APISharedCalendarAppointmentX3Dto> {
            return localVarFp.apiAppointmentGetappointmentsx3Get(startDate, endDate, idStaff, controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetclientsbyuserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentGetclientsbyuserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetclientsbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: any): AxiosPromise<APISharedCalendarClientsX3Dto> {
            return localVarFp.apiAppointmentGetclientsbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets open hours for provided staff
         * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {number | null} [endDate] Else start date + 52 days will be used
         * @param {string | null} [idStaff] Desired staff opening hours.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetopenhoursGet(startDate?: number | null, endDate?: number | null, idStaff?: string | null, options?: any): AxiosPromise<APISharedAppointmentWrapperDto> {
            return localVarFp.apiAppointmentGetopenhoursGet(startDate, endDate, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
         * @param {string | null} [endDate] Else start date + 52 days will be used
         * @param {Array<string>} [idStaff] Filter by staff
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetopenhourx2Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: any): AxiosPromise<APISharedOpenHourWrapperX2Dto> {
            return localVarFp.apiAppointmentGetopenhourx2Get(startDate, endDate, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all appointments for user per all valid clients
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {Array<string>} [idStaff] 
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetopenhourx3Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: any): AxiosPromise<Array<APISharedCalendarOpenHoursX3Dto>> {
            return localVarFp.apiAppointmentGetopenhourx3Get(startDate, endDate, idStaff, controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetservicesbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: any): AxiosPromise<APISharedCalendarServicesX3Dto> {
            return localVarFp.apiAppointmentGetservicesbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetserviceswithrelationshipbyuserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAppointmentGetserviceswithrelationshipbyuserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all staffs for user (company)
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAppointmentGetstaffbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: any): AxiosPromise<APISharedCalendarStaffsX3Dto> {
            return localVarFp.apiAppointmentGetstaffbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppointmentApi - object-oriented interface
 * @export
 * @class AppointmentApi
 * @extends {BaseAPI}
 */
export class AppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Adds appointment by model for owner user.
     * @param {ApiAppointmentAddappointmentPostRequest} [apiAppointmentAddappointmentPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentAddappointmentPost(apiAppointmentAddappointmentPostRequest?: ApiAppointmentAddappointmentPostRequest, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentAddappointmentPost(apiAppointmentAddappointmentPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add appointment
     * @param {APISharedCalendarAppointmentX2Dto} [aPISharedCalendarAppointmentX2Dto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentAddappointmentx2Post(aPISharedCalendarAppointmentX2Dto?: APISharedCalendarAppointmentX2Dto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentAddappointmentx2Post(aPISharedCalendarAppointmentX2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APISharedAddAppointmenX2Dto} [aPISharedAddAppointmenX2Dto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentAddappointmentx3Post(aPISharedAddAppointmenX2Dto?: APISharedAddAppointmenX2Dto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentAddappointmentx3Post(aPISharedAddAppointmenX2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set appointment confirmation status from onlineBooking
     * @param {string | null} [idAppointment] 
     * @param {boolean | null} [isConfirm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentConfirmappointmentGet(idAppointment?: string | null, isConfirm?: boolean | null, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentConfirmappointmentGet(idAppointment, isConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets appointment by id for owner user.
     * @param {APISharedDeleteAppointment} [aPISharedDeleteAppointment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentDeleteappointmentbyidDelete(aPISharedDeleteAppointment?: APISharedDeleteAppointment, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentDeleteappointmentbyidDelete(aPISharedDeleteAppointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete appointment
     * @param {APISharedDeleteAppointmentX2Dto} [aPISharedDeleteAppointmentX2Dto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentDeleteappointmentx2Post(aPISharedDeleteAppointmentX2Dto?: APISharedDeleteAppointmentX2Dto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentDeleteappointmentx2Post(aPISharedDeleteAppointmentX2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APISharedDeleteAppointment2Dto} [aPISharedDeleteAppointment2Dto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentDeleteappointmentx3Post(aPISharedDeleteAppointment2Dto?: APISharedDeleteAppointment2Dto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentDeleteappointmentx3Post(aPISharedDeleteAppointment2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits appointment by id for owner user.
     * @param {APISharedEditAppointmentDto} [aPISharedEditAppointmentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentEditappointmentPut(aPISharedEditAppointmentDto?: APISharedEditAppointmentDto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentEditappointmentPut(aPISharedEditAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits appointment by id for owner user
     * @param {APISharedEditAppointmentX2Dto} [aPISharedEditAppointmentX2Dto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentEditappointmentx2Post(aPISharedEditAppointmentX2Dto?: APISharedEditAppointmentX2Dto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentEditappointmentx2Post(aPISharedEditAppointmentX2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APISharedEditAppointmentValuesX2Dto} [aPISharedEditAppointmentValuesX2Dto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentEditappointmentx3Post(aPISharedEditAppointmentValuesX2Dto?: APISharedEditAppointmentValuesX2Dto, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentEditappointmentx3Post(aPISharedEditAppointmentValuesX2Dto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits appointment status by status id for owner user.
     * @param {string | null} [idAppointment] 
     * @param {string | null} [idStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentEditstatusPut(idAppointment?: string | null, idStatus?: string | null, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentEditstatusPut(idAppointment, idStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets appointment by id for owner user.
     * @param {string | null} [appointmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetappointmentbyidGet(appointmentId?: string | null, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetappointmentbyidGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all appointments for user per all valid clients
     * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
     * @param {number | null} [endDate] Else start date + 52 days will be used
     * @param {Array<string>} [idStaff] Filter by staff
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetappointmentsGet(startDate?: number | null, endDate?: number | null, idStaff?: Array<string>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetappointmentsGet(startDate, endDate, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idOwner] 
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {Array<string>} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetappointmentsandopenhoursdurationGet(idOwner?: string | null, startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetappointmentsandopenhoursdurationGet(idOwner, startDate, endDate, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all appointments for user per all valid clients
     * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
     * @param {string | null} [endDate] Else start date + 52 days will be used
     * @param {Array<string>} [idStaff] Filter by staff
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetappointmentsx2Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetappointmentsx2Get(startDate, endDate, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all appointments for user per all valid clients
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {Array<string>} [idStaff] 
     * @param {string} [controller] 
     * @param {string} [cacheContext] 
     * @param {string} [invoker] 
     * @param {string} [key] 
     * @param {string} [transactionId] 
     * @param {number} [expiration] 
     * @param {string} [idGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetappointmentsx3Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetappointmentsx3Get(startDate, endDate, idStaff, controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetclientsbyuserGet(options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetclientsbyuserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [controller] 
     * @param {string} [cacheContext] 
     * @param {string} [invoker] 
     * @param {string} [key] 
     * @param {string} [transactionId] 
     * @param {number} [expiration] 
     * @param {string} [idGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetclientsbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetclientsbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets open hours for provided staff
     * @param {number | null} [startDate] Difference between dates should not be bigger than 52 days
     * @param {number | null} [endDate] Else start date + 52 days will be used
     * @param {string | null} [idStaff] Desired staff opening hours.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetopenhoursGet(startDate?: number | null, endDate?: number | null, idStaff?: string | null, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetopenhoursGet(startDate, endDate, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all appointments for user per all valid clients
     * @param {string | null} [startDate] Difference between dates should not be bigger than 52 days
     * @param {string | null} [endDate] Else start date + 52 days will be used
     * @param {Array<string>} [idStaff] Filter by staff
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetopenhourx2Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetopenhourx2Get(startDate, endDate, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all appointments for user per all valid clients
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {Array<string>} [idStaff] 
     * @param {string} [controller] 
     * @param {string} [cacheContext] 
     * @param {string} [invoker] 
     * @param {string} [key] 
     * @param {string} [transactionId] 
     * @param {number} [expiration] 
     * @param {string} [idGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetopenhourx3Get(startDate?: string | null, endDate?: string | null, idStaff?: Array<string>, controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetopenhourx3Get(startDate, endDate, idStaff, controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [controller] 
     * @param {string} [cacheContext] 
     * @param {string} [invoker] 
     * @param {string} [key] 
     * @param {string} [transactionId] 
     * @param {number} [expiration] 
     * @param {string} [idGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetservicesbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetservicesbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetserviceswithrelationshipbyuserGet(options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetserviceswithrelationshipbyuserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all staffs for user (company)
     * @param {string} [controller] 
     * @param {string} [cacheContext] 
     * @param {string} [invoker] 
     * @param {string} [key] 
     * @param {string} [transactionId] 
     * @param {number} [expiration] 
     * @param {string} [idGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppointmentApi
     */
    public apiAppointmentGetstaffbyuserx3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig) {
        return AppointmentApiFp(this.configuration).apiAppointmentGetstaffbyuserx3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AresApi - axios parameter creator
 * @export
 */
export const AresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [ico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAresGetsubjectGet: async (ico?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ares/getsubject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ico !== undefined) {
                localVarQueryParameter['ico'] = ico;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [ico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAresSearchGet: async (ico?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ares/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ico !== undefined) {
                localVarQueryParameter['ico'] = ico;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AresApi - functional programming interface
 * @export
 */
export const AresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [ico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAresGetsubjectGet(ico?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedLibsEkonomickySubjekt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAresGetsubjectGet(ico, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AresApi.apiAresGetsubjectGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [ico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAresSearchGet(ico?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOAresAresSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAresSearchGet(ico, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AresApi.apiAresSearchGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AresApi - factory interface
 * @export
 */
export const AresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AresApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [ico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAresGetsubjectGet(ico?: string, options?: any): AxiosPromise<APISharedLibsEkonomickySubjekt> {
            return localVarFp.apiAresGetsubjectGet(ico, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [ico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAresSearchGet(ico?: string, options?: any): AxiosPromise<APIXdentDTOAresAresSearchResult> {
            return localVarFp.apiAresSearchGet(ico, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AresApi - object-oriented interface
 * @export
 * @class AresApi
 * @extends {BaseAPI}
 */
export class AresApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [ico] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AresApi
     */
    public apiAresGetsubjectGet(ico?: string, options?: AxiosRequestConfig) {
        return AresApiFp(this.configuration).apiAresGetsubjectGet(ico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [ico] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AresApi
     */
    public apiAresSearchGet(ico?: string, options?: AxiosRequestConfig) {
        return AresApiFp(this.configuration).apiAresSearchGet(ico, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuditApi - axios parameter creator
 * @export
 */
export const AuditApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Debug method for getting last N audits. Will be removed, just for the sake of testing (for FE devs)
         * @param {number} [takeLast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditGet: async (takeLast?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (takeLast !== undefined) {
                localVarQueryParameter['takeLast'] = takeLast;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditPost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditApi - functional programming interface
 * @export
 */
export const AuditApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Debug method for getting last N audits. Will be removed, just for the sake of testing (for FE devs)
         * @param {number} [takeLast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditGet(takeLast?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditGet(takeLast, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuditApi.apiAuditGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuditPost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuditPost(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuditApi.apiAuditPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuditApi - factory interface
 * @export
 */
export const AuditApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditApiFp(configuration)
    return {
        /**
         * 
         * @summary Debug method for getting last N audits. Will be removed, just for the sake of testing (for FE devs)
         * @param {number} [takeLast] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditGet(takeLast?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditGet(takeLast, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuditPost(body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuditPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditApi - object-oriented interface
 * @export
 * @class AuditApi
 * @extends {BaseAPI}
 */
export class AuditApi extends BaseAPI {
    /**
     * 
     * @summary Debug method for getting last N audits. Will be removed, just for the sake of testing (for FE devs)
     * @param {number} [takeLast] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public apiAuditGet(takeLast?: number, options?: AxiosRequestConfig) {
        return AuditApiFp(this.configuration).apiAuditGet(takeLast, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditApi
     */
    public apiAuditPost(body?: any, options?: AxiosRequestConfig) {
        return AuditApiFp(this.configuration).apiAuditPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if provided credentials are valid. Whatever scheme is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthIsauthvalidGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/isauthvalid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {string} deviceToken 
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesAuthExtendPost: async (deviceToken: string, aPISharedExtendBearerDto?: APISharedExtendBearerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesAuthExtendPost', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/auth/extend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedExtendBearerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {string} deviceToken 
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesAuthPost: async (deviceToken: string, apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesAuthPost', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiSafesignaturesAuthPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Debug method, unpairs device from platform.
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesAuthUnpairPost: async (deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesAuthUnpairPost', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/auth/unpair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if provided credentials are valid. Whatever scheme is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthIsauthvalidGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthIsauthvalidGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.apiAuthIsauthvalidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {string} deviceToken 
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesAuthExtendPost(deviceToken: string, aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesAuthExtendPost(deviceToken, aPISharedExtendBearerDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.apiSafesignaturesAuthExtendPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {string} deviceToken 
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesAuthPost(deviceToken: string, apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesAuthPost(deviceToken, apiSafesignaturesAuthPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.apiSafesignaturesAuthPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Debug method, unpairs device from platform.
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesAuthUnpairPost(deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesAuthUnpairPost(deviceToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.apiSafesignaturesAuthUnpairPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if provided credentials are valid. Whatever scheme is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthIsauthvalidGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiAuthIsauthvalidGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {string} deviceToken 
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesAuthExtendPost(deviceToken: string, aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiSafesignaturesAuthExtendPost(deviceToken, aPISharedExtendBearerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {string} deviceToken 
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesAuthPost(deviceToken: string, apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiSafesignaturesAuthPost(deviceToken, apiSafesignaturesAuthPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Debug method, unpairs device from platform.
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesAuthUnpairPost(deviceToken: string, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiSafesignaturesAuthUnpairPost(deviceToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Check if provided credentials are valid. Whatever scheme is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthIsauthvalidGet(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthIsauthvalidGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extends bearer token with provided refresh and old bearer
     * @param {string} deviceToken 
     * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiSafesignaturesAuthExtendPost(deviceToken: string, aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiSafesignaturesAuthExtendPost(deviceToken, aPISharedExtendBearerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates user by login credentials via post model
     * @param {string} deviceToken 
     * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiSafesignaturesAuthPost(deviceToken: string, apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiSafesignaturesAuthPost(deviceToken, apiSafesignaturesAuthPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Debug method, unpairs device from platform.
     * @param {string} deviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiSafesignaturesAuthUnpairPost(deviceToken: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiSafesignaturesAuthUnpairPost(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BudgetPlanApi - axios parameter creator
 * @export
 */
export const BudgetPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetbaseplangridGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/budgetplan/getbaseplangrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetdispensarizationGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/budgetplan/getdispensarization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetplanGet: async (idPlan?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/budgetplan/getplan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idPlan !== undefined) {
                localVarQueryParameter['idPlan'] = idPlan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetrandomguidsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/budgetplan/getrandomguids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BudgetPlanApi - functional programming interface
 * @export
 */
export const BudgetPlanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BudgetPlanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetplanGetbaseplangridGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetplanGetbaseplangridGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BudgetPlanApi.apiBudgetplanGetbaseplangridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetplanGetdispensarizationGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOBudgetPlanBudgetPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetplanGetdispensarizationGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BudgetPlanApi.apiBudgetplanGetdispensarizationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetplanGetplanGet(idPlan?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOBudgetPlanBudgetPlanDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetplanGetplanGet(idPlan, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BudgetPlanApi.apiBudgetplanGetplanGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBudgetplanGetrandomguidsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBudgetplanGetrandomguidsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['BudgetPlanApi.apiBudgetplanGetrandomguidsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * BudgetPlanApi - factory interface
 * @export
 */
export const BudgetPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BudgetPlanApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetbaseplangridGet(idClient?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedBUDGETPLANGRIDResult>> {
            return localVarFp.apiBudgetplanGetbaseplangridGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetdispensarizationGet(idClient?: string | null, options?: any): AxiosPromise<APIXdentDTOBudgetPlanBudgetPlanDto> {
            return localVarFp.apiBudgetplanGetdispensarizationGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetplanGet(idPlan?: string, options?: any): AxiosPromise<APIXdentDTOBudgetPlanBudgetPlanDto> {
            return localVarFp.apiBudgetplanGetplanGet(idPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBudgetplanGetrandomguidsGet(options?: any): AxiosPromise<Set<string>> {
            return localVarFp.apiBudgetplanGetrandomguidsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BudgetPlanApi - object-oriented interface
 * @export
 * @class BudgetPlanApi
 * @extends {BaseAPI}
 */
export class BudgetPlanApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPlanApi
     */
    public apiBudgetplanGetbaseplangridGet(idClient?: string, options?: AxiosRequestConfig) {
        return BudgetPlanApiFp(this.configuration).apiBudgetplanGetbaseplangridGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPlanApi
     */
    public apiBudgetplanGetdispensarizationGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return BudgetPlanApiFp(this.configuration).apiBudgetplanGetdispensarizationGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPlanApi
     */
    public apiBudgetplanGetplanGet(idPlan?: string, options?: AxiosRequestConfig) {
        return BudgetPlanApiFp(this.configuration).apiBudgetplanGetplanGet(idPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BudgetPlanApi
     */
    public apiBudgetplanGetrandomguidsGet(options?: AxiosRequestConfig) {
        return BudgetPlanApiFp(this.configuration).apiBudgetplanGetrandomguidsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CallApi - axios parameter creator
 * @export
 */
export const CallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sets call status
         * @param {APIXdentDTOCallSetCallStatusDto} [aPIXdentDTOCallSetCallStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallSetstatusPost: async (aPIXdentDTOCallSetCallStatusDto?: APIXdentDTOCallSetCallStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/call/setstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOCallSetCallStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CallApi - functional programming interface
 * @export
 */
export const CallApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CallApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sets call status
         * @param {APIXdentDTOCallSetCallStatusDto} [aPIXdentDTOCallSetCallStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCallSetstatusPost(aPIXdentDTOCallSetCallStatusDto?: APIXdentDTOCallSetCallStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCallSetstatusPost(aPIXdentDTOCallSetCallStatusDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CallApi.apiCallSetstatusPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CallApi - factory interface
 * @export
 */
export const CallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CallApiFp(configuration)
    return {
        /**
         * 
         * @summary Sets call status
         * @param {APIXdentDTOCallSetCallStatusDto} [aPIXdentDTOCallSetCallStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCallSetstatusPost(aPIXdentDTOCallSetCallStatusDto?: APIXdentDTOCallSetCallStatusDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiCallSetstatusPost(aPIXdentDTOCallSetCallStatusDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CallApi - object-oriented interface
 * @export
 * @class CallApi
 * @extends {BaseAPI}
 */
export class CallApi extends BaseAPI {
    /**
     * 
     * @summary Sets call status
     * @param {APIXdentDTOCallSetCallStatusDto} [aPIXdentDTOCallSetCallStatusDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CallApi
     */
    public apiCallSetstatusPost(aPIXdentDTOCallSetCallStatusDto?: APIXdentDTOCallSetCallStatusDto, options?: AxiosRequestConfig) {
        return CallApiFp(this.configuration).apiCallSetstatusPost(aPIXdentDTOCallSetCallStatusDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CertsApi - axios parameter creator
 * @export
 */
export const CertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets sign certs info. Returns empty 200 if fingerprints match. As there is no need to fetch the same data.
         * @param {string} deviceToken 
         * @param {string} [pubSafeSigThumb] 
         * @param {string} [thirdPartySealThumb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesCertsCurrentGet: async (deviceToken: string, pubSafeSigThumb?: string, thirdPartySealThumb?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesCertsCurrentGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/certs/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pubSafeSigThumb !== undefined) {
                localVarQueryParameter['pubSafeSigThumb'] = pubSafeSigThumb;
            }

            if (thirdPartySealThumb !== undefined) {
                localVarQueryParameter['thirdPartySealThumb'] = thirdPartySealThumb;
            }

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CertsApi - functional programming interface
 * @export
 */
export const CertsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CertsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets sign certs info. Returns empty 200 if fingerprints match. As there is no need to fetch the same data.
         * @param {string} deviceToken 
         * @param {string} [pubSafeSigThumb] 
         * @param {string} [thirdPartySealThumb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesCertsCurrentGet(deviceToken: string, pubSafeSigThumb?: string, thirdPartySealThumb?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentControllersSafeSignaturesSafeSignaturesCurrentCertsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesCertsCurrentGet(deviceToken, pubSafeSigThumb, thirdPartySealThumb, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CertsApi.apiSafesignaturesCertsCurrentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CertsApi - factory interface
 * @export
 */
export const CertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CertsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets sign certs info. Returns empty 200 if fingerprints match. As there is no need to fetch the same data.
         * @param {string} deviceToken 
         * @param {string} [pubSafeSigThumb] 
         * @param {string} [thirdPartySealThumb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesCertsCurrentGet(deviceToken: string, pubSafeSigThumb?: string, thirdPartySealThumb?: string, options?: any): AxiosPromise<APIXdentControllersSafeSignaturesSafeSignaturesCurrentCertsDto> {
            return localVarFp.apiSafesignaturesCertsCurrentGet(deviceToken, pubSafeSigThumb, thirdPartySealThumb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CertsApi - object-oriented interface
 * @export
 * @class CertsApi
 * @extends {BaseAPI}
 */
export class CertsApi extends BaseAPI {
    /**
     * 
     * @summary Gets sign certs info. Returns empty 200 if fingerprints match. As there is no need to fetch the same data.
     * @param {string} deviceToken 
     * @param {string} [pubSafeSigThumb] 
     * @param {string} [thirdPartySealThumb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CertsApi
     */
    public apiSafesignaturesCertsCurrentGet(deviceToken: string, pubSafeSigThumb?: string, thirdPartySealThumb?: string, options?: AxiosRequestConfig) {
        return CertsApiFp(this.configuration).apiSafesignaturesCertsCurrentGet(deviceToken, pubSafeSigThumb, thirdPartySealThumb, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds client to location by logged user.
         * @param {ApiClientAddclientPostRequest} [apiClientAddclientPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientAddclientPost: async (apiClientAddclientPostRequest?: ApiClientAddclientPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/addclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiClientAddclientPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits client in location by logged user.
         * @param {ApiClientEditclientPutRequest} [apiClientEditclientPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientEditclientPut: async (apiClientEditclientPutRequest?: ApiClientEditclientPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/editclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiClientEditclientPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets filtered alerts
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientFilteralertsGet: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/filteralerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets filtered drugs
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientFilterdrugGet: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/filterdrug`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get upcoming clients for user (By appointments). Default is 3
         * @param {string | null} [idClient] 
         * @param {string} [period] 
         * @param {number} [limit] 
         * @param {boolean} [onlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientappointmentsGet: async (idClient?: string | null, period?: string, limit?: number, onlyValid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getclientappointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (onlyValid !== undefined) {
                localVarQueryParameter['onlyValid'] = onlyValid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client detail by clientId, restricted by user ownership
         * @param {string | null} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientdetailGet: async (clientId?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getclientdetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client general data by provided model. (restricted by user ownership)
         * @param {APISharedClientGeneralDataDto} [aPISharedClientGeneralDataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientgeneraldataPost: async (aPISharedClientGeneralDataDto?: APISharedClientGeneralDataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getclientgeneraldata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedClientGeneralDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clients for signed user (Paginated by default)
         * @param {boolean} [pagination] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientsbyuserGet: async (pagination?: boolean, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getclientsbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clients detail by clientId, restricted by user ownership
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientsdetailPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getclientsdetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets magistralis
         * @param {string | null} [idMagistralis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetmagistralisGet: async (idMagistralis?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getmagistralis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMagistralis !== undefined) {
                localVarQueryParameter['idMagistralis'] = idMagistralis;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get upcoming clients for logged user
         * @param {number} [numberOfClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetupcomingclientsforuserGet: async (numberOfClients?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/getupcomingclientsforuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (numberOfClients !== undefined) {
                localVarQueryParameter['numberOfClients'] = numberOfClients;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get clients for signed user (Paginated by default)
         * @param {string} [filter] 
         * @param {number} [startIndex] 
         * @param {number} [length] 
         * @param {string | null} [idStaff] 
         * @param {string | null} [idLocation] 
         * @param {boolean} [onlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientSearchclientsGet: async (filter?: string, startIndex?: number, length?: number, idStaff?: string | null, idLocation?: string | null, onlyValid?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/searchclients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (length !== undefined) {
                localVarQueryParameter['length'] = length;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (onlyValid !== undefined) {
                localVarQueryParameter['onlyValid'] = onlyValid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds client to location by logged user.
         * @param {ApiClientAddclientPostRequest} [apiClientAddclientPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientAddclientPost(apiClientAddclientPostRequest?: ApiClientAddclientPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientAddclientPost(apiClientAddclientPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientAddclientPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits client in location by logged user.
         * @param {ApiClientEditclientPutRequest} [apiClientEditclientPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientEditclientPut(apiClientEditclientPutRequest?: ApiClientEditclientPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientEditclientPut(apiClientEditclientPutRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientEditclientPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets filtered alerts
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientFilteralertsGet(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientFilteralertsGet(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientFilteralertsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets filtered drugs
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientFilterdrugGet(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOClientFilteredDrugsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientFilterdrugGet(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientFilterdrugGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get upcoming clients for user (By appointments). Default is 3
         * @param {string | null} [idClient] 
         * @param {string} [period] 
         * @param {number} [limit] 
         * @param {boolean} [onlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetclientappointmentsGet(idClient?: string | null, period?: string, limit?: number, onlyValid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetclientappointmentsGet(idClient, period, limit, onlyValid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetclientappointmentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client detail by clientId, restricted by user ownership
         * @param {string | null} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetclientdetailGet(clientId?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetclientdetailGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetclientdetailGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client general data by provided model. (restricted by user ownership)
         * @param {APISharedClientGeneralDataDto} [aPISharedClientGeneralDataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetclientgeneraldataPost(aPISharedClientGeneralDataDto?: APISharedClientGeneralDataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetclientgeneraldataPost(aPISharedClientGeneralDataDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetclientgeneraldataPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get clients for signed user (Paginated by default)
         * @param {boolean} [pagination] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetclientsbyuserGet(pagination?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetclientsbyuserGet(pagination, page, perPage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetclientsbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get clients detail by clientId, restricted by user ownership
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetclientsdetailPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetclientsdetailPost(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetclientsdetailPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets magistralis
         * @param {string | null} [idMagistralis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetmagistralisGet(idMagistralis?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOClientClientMagistralisDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetmagistralisGet(idMagistralis, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetmagistralisGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get upcoming clients for logged user
         * @param {number} [numberOfClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientGetupcomingclientsforuserGet(numberOfClients?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientGetupcomingclientsforuserGet(numberOfClients, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientGetupcomingclientsforuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get clients for signed user (Paginated by default)
         * @param {string} [filter] 
         * @param {number} [startIndex] 
         * @param {number} [length] 
         * @param {string | null} [idStaff] 
         * @param {string | null} [idLocation] 
         * @param {boolean} [onlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientSearchclientsGet(filter?: string, startIndex?: number, length?: number, idStaff?: string | null, idLocation?: string | null, onlyValid?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientSearchclientsGet(filter, startIndex, length, idStaff, idLocation, onlyValid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientApi.apiClientSearchclientsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds client to location by logged user.
         * @param {ApiClientAddclientPostRequest} [apiClientAddclientPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientAddclientPost(apiClientAddclientPostRequest?: ApiClientAddclientPostRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiClientAddclientPost(apiClientAddclientPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits client in location by logged user.
         * @param {ApiClientEditclientPutRequest} [apiClientEditclientPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientEditclientPut(apiClientEditclientPutRequest?: ApiClientEditclientPutRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiClientEditclientPut(apiClientEditclientPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets filtered alerts
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientFilteralertsGet(data?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedALERTITEMSEARCHGRIDResult>> {
            return localVarFp.apiClientFilteralertsGet(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets filtered drugs
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientFilterdrugGet(data?: string, options?: any): AxiosPromise<Array<APIXdentDTOClientFilteredDrugsDto>> {
            return localVarFp.apiClientFilterdrugGet(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get upcoming clients for user (By appointments). Default is 3
         * @param {string | null} [idClient] 
         * @param {string} [period] 
         * @param {number} [limit] 
         * @param {boolean} [onlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientappointmentsGet(idClient?: string | null, period?: string, limit?: number, onlyValid?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientGetclientappointmentsGet(idClient, period, limit, onlyValid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client detail by clientId, restricted by user ownership
         * @param {string | null} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientdetailGet(clientId?: string | null, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTFORMResult> {
            return localVarFp.apiClientGetclientdetailGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client general data by provided model. (restricted by user ownership)
         * @param {APISharedClientGeneralDataDto} [aPISharedClientGeneralDataDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientgeneraldataPost(aPISharedClientGeneralDataDto?: APISharedClientGeneralDataDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientGetclientgeneraldataPost(aPISharedClientGeneralDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clients for signed user (Paginated by default)
         * @param {boolean} [pagination] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientsbyuserGet(pagination?: boolean, page?: number, perPage?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientGetclientsbyuserGet(pagination, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clients detail by clientId, restricted by user ownership
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetclientsdetailPost(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientGetclientsdetailPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets magistralis
         * @param {string | null} [idMagistralis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetmagistralisGet(idMagistralis?: string | null, options?: any): AxiosPromise<APIXdentDTOClientClientMagistralisDto> {
            return localVarFp.apiClientGetmagistralisGet(idMagistralis, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get upcoming clients for logged user
         * @param {number} [numberOfClients] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientGetupcomingclientsforuserGet(numberOfClients?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientGetupcomingclientsforuserGet(numberOfClients, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get clients for signed user (Paginated by default)
         * @param {string} [filter] 
         * @param {number} [startIndex] 
         * @param {number} [length] 
         * @param {string | null} [idStaff] 
         * @param {string | null} [idLocation] 
         * @param {boolean} [onlyValid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientSearchclientsGet(filter?: string, startIndex?: number, length?: number, idStaff?: string | null, idLocation?: string | null, onlyValid?: boolean, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERCLIENTGRIDPERFORMEDResult>> {
            return localVarFp.apiClientSearchclientsGet(filter, startIndex, length, idStaff, idLocation, onlyValid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @summary Adds client to location by logged user.
     * @param {ApiClientAddclientPostRequest} [apiClientAddclientPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientAddclientPost(apiClientAddclientPostRequest?: ApiClientAddclientPostRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientAddclientPost(apiClientAddclientPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits client in location by logged user.
     * @param {ApiClientEditclientPutRequest} [apiClientEditclientPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientEditclientPut(apiClientEditclientPutRequest?: ApiClientEditclientPutRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientEditclientPut(apiClientEditclientPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets filtered alerts
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientFilteralertsGet(data?: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientFilteralertsGet(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets filtered drugs
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientFilterdrugGet(data?: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientFilterdrugGet(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get upcoming clients for user (By appointments). Default is 3
     * @param {string | null} [idClient] 
     * @param {string} [period] 
     * @param {number} [limit] 
     * @param {boolean} [onlyValid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetclientappointmentsGet(idClient?: string | null, period?: string, limit?: number, onlyValid?: boolean, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetclientappointmentsGet(idClient, period, limit, onlyValid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client detail by clientId, restricted by user ownership
     * @param {string | null} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetclientdetailGet(clientId?: string | null, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetclientdetailGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client general data by provided model. (restricted by user ownership)
     * @param {APISharedClientGeneralDataDto} [aPISharedClientGeneralDataDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetclientgeneraldataPost(aPISharedClientGeneralDataDto?: APISharedClientGeneralDataDto, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetclientgeneraldataPost(aPISharedClientGeneralDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clients for signed user (Paginated by default)
     * @param {boolean} [pagination] 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetclientsbyuserGet(pagination?: boolean, page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetclientsbyuserGet(pagination, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clients detail by clientId, restricted by user ownership
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetclientsdetailPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetclientsdetailPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets magistralis
     * @param {string | null} [idMagistralis] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetmagistralisGet(idMagistralis?: string | null, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetmagistralisGet(idMagistralis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get upcoming clients for logged user
     * @param {number} [numberOfClients] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientGetupcomingclientsforuserGet(numberOfClients?: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientGetupcomingclientsforuserGet(numberOfClients, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get clients for signed user (Paginated by default)
     * @param {string} [filter] 
     * @param {number} [startIndex] 
     * @param {number} [length] 
     * @param {string | null} [idStaff] 
     * @param {string | null} [idLocation] 
     * @param {boolean} [onlyValid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public apiClientSearchclientsGet(filter?: string, startIndex?: number, length?: number, idStaff?: string | null, idLocation?: string | null, onlyValid?: boolean, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).apiClientSearchclientsGet(filter, startIndex, length, idStaff, idLocation, onlyValid, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientDetailAnamnesisApi - axios parameter creator
 * @export
 */
export const ClientDetailAnamnesisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailanamnesisGetanamnesisGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailanamnesis/getanamnesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean | null} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailanamnesisGetformrequestsGet: async (idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean | null, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailanamnesis/getformrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (statusCode !== undefined) {
                localVarQueryParameter['statusCode'] = statusCode;
            }

            if (includeDocumentFile !== undefined) {
                localVarQueryParameter['includeDocumentFile'] = includeDocumentFile;
            }

            if (stDate !== undefined) {
                localVarQueryParameter['stDate'] = stDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (stExpDate !== undefined) {
                localVarQueryParameter['stExpDate'] = stExpDate;
            }

            if (endExpDate !== undefined) {
                localVarQueryParameter['endExpDate'] = endExpDate;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientDetailAnamnesisApi - functional programming interface
 * @export
 */
export const ClientDetailAnamnesisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientDetailAnamnesisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailanamnesisGetanamnesisGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailanamnesisGetanamnesisGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailAnamnesisApi.apiClientdetailanamnesisGetanamnesisGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean | null} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailanamnesisGetformrequestsGet(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean | null, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailanamnesisGetformrequestsGet(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailAnamnesisApi.apiClientdetailanamnesisGetformrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ClientDetailAnamnesisApi - factory interface
 * @export
 */
export const ClientDetailAnamnesisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientDetailAnamnesisApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailanamnesisGetanamnesisGet(idClient?: string, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedANAMNESISFORMResult> {
            return localVarFp.apiClientdetailanamnesisGetanamnesisGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean | null} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailanamnesisGetformrequestsGet(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean | null, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult>> {
            return localVarFp.apiClientdetailanamnesisGetformrequestsGet(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientDetailAnamnesisApi - object-oriented interface
 * @export
 * @class ClientDetailAnamnesisApi
 * @extends {BaseAPI}
 */
export class ClientDetailAnamnesisApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailAnamnesisApi
     */
    public apiClientdetailanamnesisGetanamnesisGet(idClient?: string, options?: AxiosRequestConfig) {
        return ClientDetailAnamnesisApiFp(this.configuration).apiClientdetailanamnesisGetanamnesisGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idRequest] 
     * @param {string | null} [idStaff] 
     * @param {string} [statusCode] 
     * @param {boolean | null} [includeDocumentFile] 
     * @param {string} [stDate] 
     * @param {string} [endDate] 
     * @param {string} [stExpDate] 
     * @param {string} [endExpDate] 
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailAnamnesisApi
     */
    public apiClientdetailanamnesisGetformrequestsGet(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean | null, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: AxiosRequestConfig) {
        return ClientDetailAnamnesisApiFp(this.configuration).apiClientdetailanamnesisGetformrequestsGet(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientDetailGeneralApi - axios parameter creator
 * @export
 */
export const ClientDetailGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {string} [period] 
         * @param {number} [limit] 
         * @param {boolean} [validOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientappointmentsGet: async (idClient?: string | null, period?: string, limit?: number, validOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getclientappointments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }

            if (period !== undefined) {
                localVarQueryParameter['period'] = period;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (validOnly !== undefined) {
                localVarQueryParameter['validOnly'] = validOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientemailarchiveGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getclientemailarchive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientinvoicelistGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getclientinvoicelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientsmsarchiveGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getclientsmsarchive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientsmsincomingGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getclientsmsincoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetdoctorpassesGet: async (idMedicalReport?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getdoctorpasses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetlaboratorylabellistGet: async (idMedicalReport?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getlaboratorylabellist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetmedicalexaminationgridGet: async (idMedicalReport?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getmedicalexaminationgrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetmedicalreportexterngridGet: async (idMedicalReport?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getmedicalreportexterngrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {boolean | null} [includeExternGrid] 
         * @param {boolean | null} [includePrescriptionGrid] 
         * @param {boolean | null} [includeMedicalExaminationGrid] 
         * @param {boolean | null} [includeDoctorPasses] 
         * @param {boolean | null} [includeTransportOrders] 
         * @param {boolean | null} [includeLaboratoryLabels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetmedicalreportgeneraldetailGet: async (idMedicalReport?: string | null, idClient?: string | null, includeExternGrid?: boolean | null, includePrescriptionGrid?: boolean | null, includeMedicalExaminationGrid?: boolean | null, includeDoctorPasses?: boolean | null, includeTransportOrders?: boolean | null, includeLaboratoryLabels?: boolean | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getmedicalreportgeneraldetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }

            if (includeExternGrid !== undefined) {
                localVarQueryParameter['includeExternGrid'] = includeExternGrid;
            }

            if (includePrescriptionGrid !== undefined) {
                localVarQueryParameter['includePrescriptionGrid'] = includePrescriptionGrid;
            }

            if (includeMedicalExaminationGrid !== undefined) {
                localVarQueryParameter['includeMedicalExaminationGrid'] = includeMedicalExaminationGrid;
            }

            if (includeDoctorPasses !== undefined) {
                localVarQueryParameter['includeDoctorPasses'] = includeDoctorPasses;
            }

            if (includeTransportOrders !== undefined) {
                localVarQueryParameter['includeTransportOrders'] = includeTransportOrders;
            }

            if (includeLaboratoryLabels !== undefined) {
                localVarQueryParameter['includeLaboratoryLabels'] = includeLaboratoryLabels;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetprescriptionlistGet: async (idMedicalReport?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/getprescriptionlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGettransportordersGet: async (idMedicalReport?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientdetailgeneral/gettransportorders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientDetailGeneralApi - functional programming interface
 * @export
 */
export const ClientDetailGeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientDetailGeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {string} [period] 
         * @param {number} [limit] 
         * @param {boolean} [validOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetclientappointmentsGet(idClient?: string | null, period?: string, limit?: number, validOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetclientappointmentsGet(idClient, period, limit, validOnly, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetclientappointmentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetclientemailarchiveGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOClientDetailGeneralReminderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetclientemailarchiveGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetclientemailarchiveGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetclientinvoicelistGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOClientDetailGeneralInvoiceClientInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetclientinvoicelistGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetclientinvoicelistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetclientsmsarchiveGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOClientDetailGeneralReminderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetclientsmsarchiveGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetclientsmsarchiveGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetclientsmsincomingGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOClientDetailGeneralReminderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetclientsmsincomingGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetclientsmsincomingGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetdoctorpassesGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetdoctorpassesGet(idMedicalReport, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetdoctorpassesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetlaboratorylabellistGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetlaboratorylabellistGet(idMedicalReport, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetlaboratorylabellistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetmedicalexaminationgridGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetmedicalexaminationgridGet(idMedicalReport, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetmedicalexaminationgridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetmedicalreportexterngridGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetmedicalreportexterngridGet(idMedicalReport, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetmedicalreportexterngridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {boolean | null} [includeExternGrid] 
         * @param {boolean | null} [includePrescriptionGrid] 
         * @param {boolean | null} [includeMedicalExaminationGrid] 
         * @param {boolean | null} [includeDoctorPasses] 
         * @param {boolean | null} [includeTransportOrders] 
         * @param {boolean | null} [includeLaboratoryLabels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetmedicalreportgeneraldetailGet(idMedicalReport?: string | null, idClient?: string | null, includeExternGrid?: boolean | null, includePrescriptionGrid?: boolean | null, includeMedicalExaminationGrid?: boolean | null, includeDoctorPasses?: boolean | null, includeTransportOrders?: boolean | null, includeLaboratoryLabels?: boolean | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetmedicalreportgeneraldetailGet(idMedicalReport, idClient, includeExternGrid, includePrescriptionGrid, includeMedicalExaminationGrid, includeDoctorPasses, includeTransportOrders, includeLaboratoryLabels, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetmedicalreportgeneraldetailGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGetprescriptionlistGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOClientDetailGeneralPrescriptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGetprescriptionlistGet(idMedicalReport, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGetprescriptionlistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientdetailgeneralGettransportordersGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientdetailgeneralGettransportordersGet(idMedicalReport, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ClientDetailGeneralApi.apiClientdetailgeneralGettransportordersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ClientDetailGeneralApi - factory interface
 * @export
 */
export const ClientDetailGeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientDetailGeneralApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {string} [period] 
         * @param {number} [limit] 
         * @param {boolean} [validOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientappointmentsGet(idClient?: string | null, period?: string, limit?: number, validOnly?: boolean, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedAPPOINTMENTGRIDResult>> {
            return localVarFp.apiClientdetailgeneralGetclientappointmentsGet(idClient, period, limit, validOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientemailarchiveGet(idClient?: string, options?: any): AxiosPromise<Array<APIXdentDTOClientDetailGeneralReminderDto>> {
            return localVarFp.apiClientdetailgeneralGetclientemailarchiveGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientinvoicelistGet(idClient?: string | null, options?: any): AxiosPromise<APIXdentDTOClientDetailGeneralInvoiceClientInfoDto> {
            return localVarFp.apiClientdetailgeneralGetclientinvoicelistGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientsmsarchiveGet(idClient?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOClientDetailGeneralReminderDto>> {
            return localVarFp.apiClientdetailgeneralGetclientsmsarchiveGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetclientsmsincomingGet(idClient?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOClientDetailGeneralReminderDto>> {
            return localVarFp.apiClientdetailgeneralGetclientsmsincomingGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetdoctorpassesGet(idMedicalReport?: string | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCTORPASSCLIENTGRIDResult>> {
            return localVarFp.apiClientdetailgeneralGetdoctorpassesGet(idMedicalReport, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetlaboratorylabellistGet(idMedicalReport?: string | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELCLIENTGRIDNEWResult>> {
            return localVarFp.apiClientdetailgeneralGetlaboratorylabellistGet(idMedicalReport, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetmedicalexaminationgridGet(idMedicalReport?: string | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALEXAMINATIONGRIDResult>> {
            return localVarFp.apiClientdetailgeneralGetmedicalexaminationgridGet(idMedicalReport, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetmedicalreportexterngridGet(idMedicalReport?: string | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTEXTERNGRIDNEWResult>> {
            return localVarFp.apiClientdetailgeneralGetmedicalreportexterngridGet(idMedicalReport, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {boolean | null} [includeExternGrid] 
         * @param {boolean | null} [includePrescriptionGrid] 
         * @param {boolean | null} [includeMedicalExaminationGrid] 
         * @param {boolean | null} [includeDoctorPasses] 
         * @param {boolean | null} [includeTransportOrders] 
         * @param {boolean | null} [includeLaboratoryLabels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetmedicalreportgeneraldetailGet(idMedicalReport?: string | null, idClient?: string | null, includeExternGrid?: boolean | null, includePrescriptionGrid?: boolean | null, includeMedicalExaminationGrid?: boolean | null, includeDoctorPasses?: boolean | null, includeTransportOrders?: boolean | null, includeLaboratoryLabels?: boolean | null, options?: any): AxiosPromise<APIXdentDTOMedicalRecordMedicalReportGeneralDetailDto> {
            return localVarFp.apiClientdetailgeneralGetmedicalreportgeneraldetailGet(idMedicalReport, idClient, includeExternGrid, includePrescriptionGrid, includeMedicalExaminationGrid, includeDoctorPasses, includeTransportOrders, includeLaboratoryLabels, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGetprescriptionlistGet(idMedicalReport?: string | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIXdentDTOClientDetailGeneralPrescriptionDto>> {
            return localVarFp.apiClientdetailgeneralGetprescriptionlistGet(idMedicalReport, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idMedicalReport] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientdetailgeneralGettransportordersGet(idMedicalReport?: string | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTRANSPORTORDERCLIENTGRIDResult>> {
            return localVarFp.apiClientdetailgeneralGettransportordersGet(idMedicalReport, idClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientDetailGeneralApi - object-oriented interface
 * @export
 * @class ClientDetailGeneralApi
 * @extends {BaseAPI}
 */
export class ClientDetailGeneralApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idClient] 
     * @param {string} [period] 
     * @param {number} [limit] 
     * @param {boolean} [validOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetclientappointmentsGet(idClient?: string | null, period?: string, limit?: number, validOnly?: boolean, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetclientappointmentsGet(idClient, period, limit, validOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetclientemailarchiveGet(idClient?: string, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetclientemailarchiveGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetclientinvoicelistGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetclientinvoicelistGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetclientsmsarchiveGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetclientsmsarchiveGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetclientsmsincomingGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetclientsmsincomingGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetdoctorpassesGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetdoctorpassesGet(idMedicalReport, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetlaboratorylabellistGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetlaboratorylabellistGet(idMedicalReport, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetmedicalexaminationgridGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetmedicalexaminationgridGet(idMedicalReport, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetmedicalreportexterngridGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetmedicalreportexterngridGet(idMedicalReport, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {boolean | null} [includeExternGrid] 
     * @param {boolean | null} [includePrescriptionGrid] 
     * @param {boolean | null} [includeMedicalExaminationGrid] 
     * @param {boolean | null} [includeDoctorPasses] 
     * @param {boolean | null} [includeTransportOrders] 
     * @param {boolean | null} [includeLaboratoryLabels] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetmedicalreportgeneraldetailGet(idMedicalReport?: string | null, idClient?: string | null, includeExternGrid?: boolean | null, includePrescriptionGrid?: boolean | null, includeMedicalExaminationGrid?: boolean | null, includeDoctorPasses?: boolean | null, includeTransportOrders?: boolean | null, includeLaboratoryLabels?: boolean | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetmedicalreportgeneraldetailGet(idMedicalReport, idClient, includeExternGrid, includePrescriptionGrid, includeMedicalExaminationGrid, includeDoctorPasses, includeTransportOrders, includeLaboratoryLabels, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGetprescriptionlistGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGetprescriptionlistGet(idMedicalReport, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idMedicalReport] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientDetailGeneralApi
     */
    public apiClientdetailgeneralGettransportordersGet(idMedicalReport?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return ClientDetailGeneralApiFp(this.configuration).apiClientdetailgeneralGettransportordersGet(idMedicalReport, idClient, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommunityApi - axios parameter creator
 * @export
 */
export const CommunityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the metadata.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunityGetmetadataGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/community/getmetadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityApi - functional programming interface
 * @export
 */
export const CommunityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the metadata.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommunityGetmetadataGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedCommunityCommunityPlatformMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommunityGetmetadataGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CommunityApi.apiCommunityGetmetadataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CommunityApi - factory interface
 * @export
 */
export const CommunityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the metadata.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommunityGetmetadataGet(options?: any): AxiosPromise<APISharedCommunityCommunityPlatformMetadataDto> {
            return localVarFp.apiCommunityGetmetadataGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommunityApi - object-oriented interface
 * @export
 * @class CommunityApi
 * @extends {BaseAPI}
 */
export class CommunityApi extends BaseAPI {
    /**
     * 
     * @summary Gets the metadata.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityApi
     */
    public apiCommunityGetmetadataGet(options?: AxiosRequestConfig) {
        return CommunityApiFp(this.configuration).apiCommunityGetmetadataGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationServiceavailabilityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/configuration/serviceavailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConfigurationServiceavailabilityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedOptionsServiceAvailabilityOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConfigurationServiceavailabilityGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ConfigurationApi.apiConfigurationServiceavailabilityGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConfigurationServiceavailabilityGet(options?: any): AxiosPromise<APISharedOptionsServiceAvailabilityOptions> {
            return localVarFp.apiConfigurationServiceavailabilityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public apiConfigurationServiceavailabilityGet(options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).apiConfigurationServiceavailabilityGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CronApi - axios parameter creator
 * @export
 */
export const CronApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Runs cron to check NCZI requests statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronChecknczirequestsstatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/checknczirequestsstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes Appointment Requests  For now it runs generation and ProcessFormSendRequests right after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronCreateformsendrequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/createformsendrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches local certificate from Windows Cert Store for SK XDENT Service app and saves it in DB.
         * @param {string} [subject] 
         * @param {ApiCronFetchlocalcertificateGetLocationEnum} [location] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronFetchlocalcertificateGet: async (subject?: string, location?: ApiCronFetchlocalcertificateGetLocationEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/fetchlocalcertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates Admin Global Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronGenerateadminglobalstatsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/generateadminglobalstats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronGenerateanalyticsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/generateanalytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets data from Woocommerce API and saves it to our database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronGetxdentshopdataGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/getxdentshopdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Insert reminderAfter to ReminderEmail table and send today remindersAfter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronInsertandsendreminderafterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/insertandsendreminderafter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invalidates all refresh tokens for all expired companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronInvalidaterefreshtokenforexpiredcompaniesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/invalidaterefreshtokenforexpiredcompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log Timeouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronLogtimeoutsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/logtimeouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Notification cron table population,.. auth for security reasons here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronNotificationpopulateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/notificationpopulate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends all notifications..
         * @param {boolean} [returnAfterDbQuery] 
         * @param {boolean} [returnAfterQueryDeviceEval] 
         * @param {boolean} [returnAfterSent] 
         * @param {boolean} [returnBeforeConfirmed] 
         * @param {boolean} [returnAfterConfirmed] 
         * @param {boolean} [sendToFirebase] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPendingnotificationssendGet: async (returnAfterDbQuery?: boolean, returnAfterQueryDeviceEval?: boolean, returnAfterSent?: boolean, returnBeforeConfirmed?: boolean, returnAfterConfirmed?: boolean, sendToFirebase?: boolean, idCompany?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/pendingnotificationssend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnAfterDbQuery !== undefined) {
                localVarQueryParameter['returnAfterDbQuery'] = returnAfterDbQuery;
            }

            if (returnAfterQueryDeviceEval !== undefined) {
                localVarQueryParameter['returnAfterQueryDeviceEval'] = returnAfterQueryDeviceEval;
            }

            if (returnAfterSent !== undefined) {
                localVarQueryParameter['returnAfterSent'] = returnAfterSent;
            }

            if (returnBeforeConfirmed !== undefined) {
                localVarQueryParameter['returnBeforeConfirmed'] = returnBeforeConfirmed;
            }

            if (returnAfterConfirmed !== undefined) {
                localVarQueryParameter['returnAfterConfirmed'] = returnAfterConfirmed;
            }

            if (sendToFirebase !== undefined) {
                localVarQueryParameter['sendToFirebase'] = sendToFirebase;
            }

            if (idCompany !== undefined) {
                localVarQueryParameter['idCompany'] = idCompany;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes Appointment Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessappointmentrequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processappointmentrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by budget
         * @param {ApiCronProcessemailstatesbybudgetGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {number} [budget] Number of emails to process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatesbybudgetGet: async (emailType?: ApiCronProcessemailstatesbybudgetGetEmailTypeEnum, budget?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processemailstatesbybudget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailType !== undefined) {
                localVarQueryParameter['emailType'] = emailType;
            }

            if (budget !== undefined) {
                localVarQueryParameter['budget'] = budget;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by date range (includes time)
         * @param {ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {string} [dateFrom] Datetime from which emails should be processed
         * @param {string} [dateTo] Datetime to which emails should be processed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatesbydaterangeGet: async (emailType?: ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum, dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processemailstatesbydaterange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailType !== undefined) {
                localVarQueryParameter['emailType'] = emailType;
            }

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by days (and or) hours retroactively
         * @param {ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {number} [days] Number of days
         * @param {number} [hours] Number of hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatesbydaysretroactivelyGet: async (emailType?: ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum, days?: number, hours?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processemailstatesbydaysretroactively`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailType !== undefined) {
                localVarQueryParameter['emailType'] = emailType;
            }

            if (days !== undefined) {
                localVarQueryParameter['days'] = days;
            }

            if (hours !== undefined) {
                localVarQueryParameter['hours'] = hours;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when today (00:00:00 - 23:59:59)
         * @param {ApiCronProcessemailstatestodayGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatestodayGet: async (emailType?: ApiCronProcessemailstatestodayGetEmailTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processemailstatestoday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (emailType !== undefined) {
                localVarQueryParameter['emailType'] = emailType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Runs payment gateway cron on awaiting (almost finished) payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessfinishedgatewaypaymentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processfinishedgatewaypayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Processes and sends generated FormSendRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessformsendrequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processformsendrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Runs payment gateway cron on processing payment requests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessgatewaypaymentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/processgatewaypayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Propagates only WP XDT Banners to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPropagateshopbannersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/propagateshopbanners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Propagates only WC XDT Orders to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPropagateshopordersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/propagateshoporders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Propagates only WP XDT Posts to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPropagateshoppostsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/propagateshopposts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks the health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPuppeteerClosefinishedtabsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/puppeteer/closefinishedtabs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks the health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPuppeteerHealthcheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/puppeteer/healthcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the refresh token bearer identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSetrefreshtokenbeareridPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/setrefreshtokenbearerid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Syncs labino laboratories to xdent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSynclabinolaboratoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/synclabinolaboratories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Syncs labino lab requests to xdent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSynclabinolaboratoryrequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/synclabinolaboratoryrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetches table data from Labino and saves them to xdt DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSynclabinotablesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/synclabinotables`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlocks Labino for XDENT companies that are invoicePartners in Labino
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronUnlocklabinocronGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cron/unlocklabinocron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CronApi - functional programming interface
 * @export
 */
export const CronApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CronApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Runs cron to check NCZI requests statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronChecknczirequestsstatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronChecknczirequestsstatusGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronChecknczirequestsstatusGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Processes Appointment Requests  For now it runs generation and ProcessFormSendRequests right after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronCreateformsendrequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronCreateformsendrequestsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronCreateformsendrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches local certificate from Windows Cert Store for SK XDENT Service app and saves it in DB.
         * @param {string} [subject] 
         * @param {ApiCronFetchlocalcertificateGetLocationEnum} [location] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronFetchlocalcertificateGet(subject?: string, location?: ApiCronFetchlocalcertificateGetLocationEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronFetchlocalcertificateGet(subject, location, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronFetchlocalcertificateGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generates Admin Global Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronGenerateadminglobalstatsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronGenerateadminglobalstatsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronGenerateadminglobalstatsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generates analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronGenerateanalyticsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronGenerateanalyticsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronGenerateanalyticsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets data from Woocommerce API and saves it to our database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronGetxdentshopdataGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronGetxdentshopdataGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronGetxdentshopdataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Insert reminderAfter to ReminderEmail table and send today remindersAfter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronInsertandsendreminderafterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronInsertandsendreminderafterGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronInsertandsendreminderafterGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Invalidates all refresh tokens for all expired companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronInvalidaterefreshtokenforexpiredcompaniesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronInvalidaterefreshtokenforexpiredcompaniesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronInvalidaterefreshtokenforexpiredcompaniesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Log Timeouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronLogtimeoutsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronLogtimeoutsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronLogtimeoutsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Notification cron table population,.. auth for security reasons here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronNotificationpopulateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronNotificationpopulateGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronNotificationpopulateGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sends all notifications..
         * @param {boolean} [returnAfterDbQuery] 
         * @param {boolean} [returnAfterQueryDeviceEval] 
         * @param {boolean} [returnAfterSent] 
         * @param {boolean} [returnBeforeConfirmed] 
         * @param {boolean} [returnAfterConfirmed] 
         * @param {boolean} [sendToFirebase] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronPendingnotificationssendGet(returnAfterDbQuery?: boolean, returnAfterQueryDeviceEval?: boolean, returnAfterSent?: boolean, returnBeforeConfirmed?: boolean, returnAfterConfirmed?: boolean, sendToFirebase?: boolean, idCompany?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronPendingnotificationssendGet(returnAfterDbQuery, returnAfterQueryDeviceEval, returnAfterSent, returnBeforeConfirmed, returnAfterConfirmed, sendToFirebase, idCompany, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronPendingnotificationssendGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Processes Appointment Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessappointmentrequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessappointmentrequestsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessappointmentrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by budget
         * @param {ApiCronProcessemailstatesbybudgetGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {number} [budget] Number of emails to process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessemailstatesbybudgetGet(emailType?: ApiCronProcessemailstatesbybudgetGetEmailTypeEnum, budget?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessemailstatesbybudgetGet(emailType, budget, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessemailstatesbybudgetGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by date range (includes time)
         * @param {ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {string} [dateFrom] Datetime from which emails should be processed
         * @param {string} [dateTo] Datetime to which emails should be processed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessemailstatesbydaterangeGet(emailType?: ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessemailstatesbydaterangeGet(emailType, dateFrom, dateTo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessemailstatesbydaterangeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by days (and or) hours retroactively
         * @param {ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {number} [days] Number of days
         * @param {number} [hours] Number of hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessemailstatesbydaysretroactivelyGet(emailType?: ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum, days?: number, hours?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessemailstatesbydaysretroactivelyGet(emailType, days, hours, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessemailstatesbydaysretroactivelyGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when today (00:00:00 - 23:59:59)
         * @param {ApiCronProcessemailstatestodayGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessemailstatestodayGet(emailType?: ApiCronProcessemailstatestodayGetEmailTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessemailstatestodayGet(emailType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessemailstatestodayGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Runs payment gateway cron on awaiting (almost finished) payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessfinishedgatewaypaymentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessfinishedgatewaypaymentsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessfinishedgatewaypaymentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Processes and sends generated FormSendRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessformsendrequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessformsendrequestsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessformsendrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Runs payment gateway cron on processing payment requests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronProcessgatewaypaymentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronProcessgatewaypaymentsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronProcessgatewaypaymentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Propagates only WP XDT Banners to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronPropagateshopbannersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronPropagateshopbannersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronPropagateshopbannersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Propagates only WC XDT Orders to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronPropagateshopordersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronPropagateshopordersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronPropagateshopordersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Propagates only WP XDT Posts to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronPropagateshoppostsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronPropagateshoppostsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronPropagateshoppostsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Checks the health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronPuppeteerClosefinishedtabsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronPuppeteerClosefinishedtabsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronPuppeteerClosefinishedtabsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Checks the health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronPuppeteerHealthcheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronPuppeteerHealthcheckGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronPuppeteerHealthcheckGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets the refresh token bearer identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronSetrefreshtokenbeareridPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronSetrefreshtokenbeareridPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronSetrefreshtokenbeareridPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Syncs labino laboratories to xdent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronSynclabinolaboratoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronSynclabinolaboratoriesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronSynclabinolaboratoriesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Syncs labino lab requests to xdent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronSynclabinolaboratoryrequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronSynclabinolaboratoryrequestsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronSynclabinolaboratoryrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Fetches table data from Labino and saves them to xdt DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronSynclabinotablesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronSynclabinotablesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronSynclabinotablesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Unlocks Labino for XDENT companies that are invoicePartners in Labino
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCronUnlocklabinocronGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCronUnlocklabinocronGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CronApi.apiCronUnlocklabinocronGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CronApi - factory interface
 * @export
 */
export const CronApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CronApiFp(configuration)
    return {
        /**
         * 
         * @summary Runs cron to check NCZI requests statuses.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronChecknczirequestsstatusGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronChecknczirequestsstatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes Appointment Requests  For now it runs generation and ProcessFormSendRequests right after.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronCreateformsendrequestsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronCreateformsendrequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches local certificate from Windows Cert Store for SK XDENT Service app and saves it in DB.
         * @param {string} [subject] 
         * @param {ApiCronFetchlocalcertificateGetLocationEnum} [location] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronFetchlocalcertificateGet(subject?: string, location?: ApiCronFetchlocalcertificateGetLocationEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiCronFetchlocalcertificateGet(subject, location, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates Admin Global Stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronGenerateadminglobalstatsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronGenerateadminglobalstatsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates analytics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronGenerateanalyticsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronGenerateanalyticsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets data from Woocommerce API and saves it to our database
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronGetxdentshopdataGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronGetxdentshopdataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Insert reminderAfter to ReminderEmail table and send today remindersAfter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronInsertandsendreminderafterGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronInsertandsendreminderafterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invalidates all refresh tokens for all expired companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronInvalidaterefreshtokenforexpiredcompaniesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronInvalidaterefreshtokenforexpiredcompaniesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log Timeouts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronLogtimeoutsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronLogtimeoutsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Notification cron table population,.. auth for security reasons here.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronNotificationpopulateGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronNotificationpopulateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends all notifications..
         * @param {boolean} [returnAfterDbQuery] 
         * @param {boolean} [returnAfterQueryDeviceEval] 
         * @param {boolean} [returnAfterSent] 
         * @param {boolean} [returnBeforeConfirmed] 
         * @param {boolean} [returnAfterConfirmed] 
         * @param {boolean} [sendToFirebase] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPendingnotificationssendGet(returnAfterDbQuery?: boolean, returnAfterQueryDeviceEval?: boolean, returnAfterSent?: boolean, returnBeforeConfirmed?: boolean, returnAfterConfirmed?: boolean, sendToFirebase?: boolean, idCompany?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiCronPendingnotificationssendGet(returnAfterDbQuery, returnAfterQueryDeviceEval, returnAfterSent, returnBeforeConfirmed, returnAfterConfirmed, sendToFirebase, idCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes Appointment Requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessappointmentrequestsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessappointmentrequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by budget
         * @param {ApiCronProcessemailstatesbybudgetGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {number} [budget] Number of emails to process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatesbybudgetGet(emailType?: ApiCronProcessemailstatesbybudgetGetEmailTypeEnum, budget?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessemailstatesbybudgetGet(emailType, budget, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by date range (includes time)
         * @param {ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {string} [dateFrom] Datetime from which emails should be processed
         * @param {string} [dateTo] Datetime to which emails should be processed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatesbydaterangeGet(emailType?: ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum, dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessemailstatesbydaterangeGet(emailType, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when by days (and or) hours retroactively
         * @param {ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {number} [days] Number of days
         * @param {number} [hours] Number of hours
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatesbydaysretroactivelyGet(emailType?: ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum, days?: number, hours?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessemailstatesbydaysretroactivelyGet(emailType, days, hours, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets if emails have been delivered and when today (00:00:00 - 23:59:59)
         * @param {ApiCronProcessemailstatestodayGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessemailstatestodayGet(emailType?: ApiCronProcessemailstatestodayGetEmailTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessemailstatestodayGet(emailType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Runs payment gateway cron on awaiting (almost finished) payments.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessfinishedgatewaypaymentsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessfinishedgatewaypaymentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Processes and sends generated FormSendRequests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessformsendrequestsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessformsendrequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Runs payment gateway cron on processing payment requests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronProcessgatewaypaymentsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronProcessgatewaypaymentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Propagates only WP XDT Banners to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPropagateshopbannersGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronPropagateshopbannersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Propagates only WC XDT Orders to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPropagateshopordersGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronPropagateshopordersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Propagates only WP XDT Posts to XDT DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPropagateshoppostsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronPropagateshoppostsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks the health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPuppeteerClosefinishedtabsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronPuppeteerClosefinishedtabsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks the health.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronPuppeteerHealthcheckGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronPuppeteerHealthcheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the refresh token bearer identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSetrefreshtokenbeareridPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronSetrefreshtokenbeareridPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Syncs labino laboratories to xdent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSynclabinolaboratoriesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronSynclabinolaboratoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Syncs labino lab requests to xdent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSynclabinolaboratoryrequestsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronSynclabinolaboratoryrequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetches table data from Labino and saves them to xdt DB
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronSynclabinotablesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronSynclabinotablesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlocks Labino for XDENT companies that are invoicePartners in Labino
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCronUnlocklabinocronGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCronUnlocklabinocronGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CronApi - object-oriented interface
 * @export
 * @class CronApi
 * @extends {BaseAPI}
 */
export class CronApi extends BaseAPI {
    /**
     * 
     * @summary Runs cron to check NCZI requests statuses.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronChecknczirequestsstatusGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronChecknczirequestsstatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes Appointment Requests  For now it runs generation and ProcessFormSendRequests right after.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronCreateformsendrequestsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronCreateformsendrequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches local certificate from Windows Cert Store for SK XDENT Service app and saves it in DB.
     * @param {string} [subject] 
     * @param {ApiCronFetchlocalcertificateGetLocationEnum} [location] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronFetchlocalcertificateGet(subject?: string, location?: ApiCronFetchlocalcertificateGetLocationEnum, options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronFetchlocalcertificateGet(subject, location, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates Admin Global Stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronGenerateadminglobalstatsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronGenerateadminglobalstatsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates analytics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronGenerateanalyticsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronGenerateanalyticsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets data from Woocommerce API and saves it to our database
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronGetxdentshopdataGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronGetxdentshopdataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Insert reminderAfter to ReminderEmail table and send today remindersAfter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronInsertandsendreminderafterGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronInsertandsendreminderafterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invalidates all refresh tokens for all expired companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronInvalidaterefreshtokenforexpiredcompaniesGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronInvalidaterefreshtokenforexpiredcompaniesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log Timeouts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronLogtimeoutsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronLogtimeoutsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Notification cron table population,.. auth for security reasons here.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronNotificationpopulateGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronNotificationpopulateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends all notifications..
     * @param {boolean} [returnAfterDbQuery] 
     * @param {boolean} [returnAfterQueryDeviceEval] 
     * @param {boolean} [returnAfterSent] 
     * @param {boolean} [returnBeforeConfirmed] 
     * @param {boolean} [returnAfterConfirmed] 
     * @param {boolean} [sendToFirebase] 
     * @param {string | null} [idCompany] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronPendingnotificationssendGet(returnAfterDbQuery?: boolean, returnAfterQueryDeviceEval?: boolean, returnAfterSent?: boolean, returnBeforeConfirmed?: boolean, returnAfterConfirmed?: boolean, sendToFirebase?: boolean, idCompany?: string | null, options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronPendingnotificationssendGet(returnAfterDbQuery, returnAfterQueryDeviceEval, returnAfterSent, returnBeforeConfirmed, returnAfterConfirmed, sendToFirebase, idCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes Appointment Requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessappointmentrequestsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessappointmentrequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets if emails have been delivered and when by budget
     * @param {ApiCronProcessemailstatesbybudgetGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
     * @param {number} [budget] Number of emails to process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessemailstatesbybudgetGet(emailType?: ApiCronProcessemailstatesbybudgetGetEmailTypeEnum, budget?: number, options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessemailstatesbybudgetGet(emailType, budget, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets if emails have been delivered and when by date range (includes time)
     * @param {ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
     * @param {string} [dateFrom] Datetime from which emails should be processed
     * @param {string} [dateTo] Datetime to which emails should be processed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessemailstatesbydaterangeGet(emailType?: ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum, dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessemailstatesbydaterangeGet(emailType, dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets if emails have been delivered and when by days (and or) hours retroactively
     * @param {ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
     * @param {number} [days] Number of days
     * @param {number} [hours] Number of hours
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessemailstatesbydaysretroactivelyGet(emailType?: ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum, days?: number, hours?: number, options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessemailstatesbydaysretroactivelyGet(emailType, days, hours, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets if emails have been delivered and when today (00:00:00 - 23:59:59)
     * @param {ApiCronProcessemailstatestodayGetEmailTypeEnum} [emailType] Type of email to process (or ALL)              0 - INVOICE,              1 - RECOMMENDATION,              2 - RECOMMENDATION_CONFIRM,              3 - RECOMMENDATION_NEW_CLIENT,              4 - FILE,              5 - APPOINTMENT_REMINDER,              6 - EQUIPMENT_REMINDER,              7 - OWNER_REGISTER,              8 - BUG_REPORT,              9 - ACTIVATION_REQUEST,              10 - LEASING,              11 - LEGAL,              12 - ACCOUNT_ACTIVATE,              13 - TEST,              14 - UNKNOWN,              15 - ALL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessemailstatestodayGet(emailType?: ApiCronProcessemailstatestodayGetEmailTypeEnum, options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessemailstatestodayGet(emailType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Runs payment gateway cron on awaiting (almost finished) payments.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessfinishedgatewaypaymentsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessfinishedgatewaypaymentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Processes and sends generated FormSendRequests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessformsendrequestsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessformsendrequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Runs payment gateway cron on processing payment requests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronProcessgatewaypaymentsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronProcessgatewaypaymentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Propagates only WP XDT Banners to XDT DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronPropagateshopbannersGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronPropagateshopbannersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Propagates only WC XDT Orders to XDT DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronPropagateshopordersGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronPropagateshopordersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Propagates only WP XDT Posts to XDT DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronPropagateshoppostsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronPropagateshoppostsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks the health.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronPuppeteerClosefinishedtabsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronPuppeteerClosefinishedtabsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks the health.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronPuppeteerHealthcheckGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronPuppeteerHealthcheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the refresh token bearer identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronSetrefreshtokenbeareridPost(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronSetrefreshtokenbeareridPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Syncs labino laboratories to xdent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronSynclabinolaboratoriesGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronSynclabinolaboratoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Syncs labino lab requests to xdent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronSynclabinolaboratoryrequestsGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronSynclabinolaboratoryrequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetches table data from Labino and saves them to xdt DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronSynclabinotablesGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronSynclabinotablesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlocks Labino for XDENT companies that are invoicePartners in Labino
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronApi
     */
    public apiCronUnlocklabinocronGet(options?: AxiosRequestConfig) {
        return CronApiFp(this.configuration).apiCronUnlocklabinocronGet(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiCronFetchlocalcertificateGetLocationEnum = {
    CurrentUser: 'CurrentUser',
    LocalMachine: 'LocalMachine'
} as const;
export type ApiCronFetchlocalcertificateGetLocationEnum = typeof ApiCronFetchlocalcertificateGetLocationEnum[keyof typeof ApiCronFetchlocalcertificateGetLocationEnum];
/**
 * @export
 */
export const ApiCronProcessemailstatesbybudgetGetEmailTypeEnum = {
    Invoice: 'INVOICE',
    Recommendation: 'RECOMMENDATION',
    RecommendationConfirm: 'RECOMMENDATION_CONFIRM',
    RecommendationNewClient: 'RECOMMENDATION_NEW_CLIENT',
    File: 'FILE',
    AppointmentReminder: 'APPOINTMENT_REMINDER',
    EquipmentReminder: 'EQUIPMENT_REMINDER',
    OwnerRegister: 'OWNER_REGISTER',
    BugReport: 'BUG_REPORT',
    ActivationRequest: 'ACTIVATION_REQUEST',
    Leasing: 'LEASING',
    Legal: 'LEGAL',
    AccountActivate: 'ACCOUNT_ACTIVATE',
    Test: 'TEST',
    Unknown: 'UNKNOWN',
    All: 'ALL',
    BookingConfirmPayment: 'BOOKING_CONFIRM_PAYMENT',
    FormFillRequest: 'FORM_FILL_REQUEST'
} as const;
export type ApiCronProcessemailstatesbybudgetGetEmailTypeEnum = typeof ApiCronProcessemailstatesbybudgetGetEmailTypeEnum[keyof typeof ApiCronProcessemailstatesbybudgetGetEmailTypeEnum];
/**
 * @export
 */
export const ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum = {
    Invoice: 'INVOICE',
    Recommendation: 'RECOMMENDATION',
    RecommendationConfirm: 'RECOMMENDATION_CONFIRM',
    RecommendationNewClient: 'RECOMMENDATION_NEW_CLIENT',
    File: 'FILE',
    AppointmentReminder: 'APPOINTMENT_REMINDER',
    EquipmentReminder: 'EQUIPMENT_REMINDER',
    OwnerRegister: 'OWNER_REGISTER',
    BugReport: 'BUG_REPORT',
    ActivationRequest: 'ACTIVATION_REQUEST',
    Leasing: 'LEASING',
    Legal: 'LEGAL',
    AccountActivate: 'ACCOUNT_ACTIVATE',
    Test: 'TEST',
    Unknown: 'UNKNOWN',
    All: 'ALL',
    BookingConfirmPayment: 'BOOKING_CONFIRM_PAYMENT',
    FormFillRequest: 'FORM_FILL_REQUEST'
} as const;
export type ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum = typeof ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum[keyof typeof ApiCronProcessemailstatesbydaterangeGetEmailTypeEnum];
/**
 * @export
 */
export const ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum = {
    Invoice: 'INVOICE',
    Recommendation: 'RECOMMENDATION',
    RecommendationConfirm: 'RECOMMENDATION_CONFIRM',
    RecommendationNewClient: 'RECOMMENDATION_NEW_CLIENT',
    File: 'FILE',
    AppointmentReminder: 'APPOINTMENT_REMINDER',
    EquipmentReminder: 'EQUIPMENT_REMINDER',
    OwnerRegister: 'OWNER_REGISTER',
    BugReport: 'BUG_REPORT',
    ActivationRequest: 'ACTIVATION_REQUEST',
    Leasing: 'LEASING',
    Legal: 'LEGAL',
    AccountActivate: 'ACCOUNT_ACTIVATE',
    Test: 'TEST',
    Unknown: 'UNKNOWN',
    All: 'ALL',
    BookingConfirmPayment: 'BOOKING_CONFIRM_PAYMENT',
    FormFillRequest: 'FORM_FILL_REQUEST'
} as const;
export type ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum = typeof ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum[keyof typeof ApiCronProcessemailstatesbydaysretroactivelyGetEmailTypeEnum];
/**
 * @export
 */
export const ApiCronProcessemailstatestodayGetEmailTypeEnum = {
    Invoice: 'INVOICE',
    Recommendation: 'RECOMMENDATION',
    RecommendationConfirm: 'RECOMMENDATION_CONFIRM',
    RecommendationNewClient: 'RECOMMENDATION_NEW_CLIENT',
    File: 'FILE',
    AppointmentReminder: 'APPOINTMENT_REMINDER',
    EquipmentReminder: 'EQUIPMENT_REMINDER',
    OwnerRegister: 'OWNER_REGISTER',
    BugReport: 'BUG_REPORT',
    ActivationRequest: 'ACTIVATION_REQUEST',
    Leasing: 'LEASING',
    Legal: 'LEGAL',
    AccountActivate: 'ACCOUNT_ACTIVATE',
    Test: 'TEST',
    Unknown: 'UNKNOWN',
    All: 'ALL',
    BookingConfirmPayment: 'BOOKING_CONFIRM_PAYMENT',
    FormFillRequest: 'FORM_FILL_REQUEST'
} as const;
export type ApiCronProcessemailstatestodayGetEmailTypeEnum = typeof ApiCronProcessemailstatestodayGetEmailTypeEnum[keyof typeof ApiCronProcessemailstatestodayGetEmailTypeEnum];


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Uploads a certificate to database for later use.
         * @param {string} [code] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatevaultUploadcertificatePost: async (code?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/certificatevault/uploadcertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the answer confirmation.
         * @param {string} [answerId] The answer identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardChangeanswerconfirmationPost: async (answerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/changeanswerconfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (answerId !== undefined) {
                localVarQueryParameter['answerId'] = answerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms all sms answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardConfirmallanswersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/confirmallanswers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms form request, in case of regFrom, creates new client, gallery etc..
         * @param {string | null} [idFormRequest] 
         * @param {boolean} [confirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardConfirmorrejectformrequestGet: async (idFormRequest?: string | null, confirm?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/confirmorrejectformrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFormRequest !== undefined) {
                localVarQueryParameter['idFormRequest'] = idFormRequest;
            }

            if (confirm !== undefined) {
                localVarQueryParameter['confirm'] = confirm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return company summary stats. (Primarily pending notifications etc...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGetcompanystatsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/getcompanystats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns xdent dashboard summary data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGetdashboardGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/getdashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets notification center data, (list of generalised notifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGetnotificationcenterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/getnotificationcenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates mock data for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardRefreshmockdataPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/refreshmockdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows to delete dashboard note by id
         * @param {string | null} [idNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardnotesdeleteDelete: async (idNote?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/dashboardnotesdelete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idNote !== undefined) {
                localVarQueryParameter['idNote'] = idNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allow to create or update dashboard note.
         * @param {APISharedDashboardNotesInsertUpdateDTO} [aPISharedDashboardNotesInsertUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardnotesinsertupdatePost: async (aPISharedDashboardNotesInsertUpdateDTO?: APISharedDashboardNotesInsertUpdateDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/dashboardnotesinsertupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedDashboardNotesInsertUpdateDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for dashboard notes widget
         * @param {number} [offset] 
         * @param {number | null} [limit] 
         * @param {boolean} [isComplete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardnoteslistGet: async (offset?: number, limit?: number | null, isComplete?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/dashboardnoteslist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (isComplete !== undefined) {
                localVarQueryParameter['isComplete'] = isComplete;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary NOT TO BE USED ON MOBILE NOW - Returns data for widget layout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardwidgetslayoutgetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/dashboardwidgetslayoutget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary NOT TO BE USED ON MOBILE NOW - Saves widget layout
         * @param {Array<APISharedWidgetDTO>} [aPISharedWidgetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardwidgetslayoutsavePost: async (aPISharedWidgetDTO?: Array<APISharedWidgetDTO>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/dashboardwidgetslayoutsave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedWidgetDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for Calls widget
         * @param {string} [callType] 
         * @param {string} [callDateStart] 
         * @param {string} [callDateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetcalloverviewGet: async (callType?: string, callDateStart?: string, callDateFinish?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getcalloverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (callType !== undefined) {
                localVarQueryParameter['callType'] = callType;
            }

            if (callDateStart !== undefined) {
                localVarQueryParameter['callDateStart'] = callDateStart;
            }

            if (callDateFinish !== undefined) {
                localVarQueryParameter['callDateFinish'] = callDateFinish;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Web version of XDENT calls it with statusCode as \"PENDING\" and includeDocumentFile as false - every other parameter is passed as NULL.
         * @summary Returns data for Forms widget
         * @param {string | null} [idRequest] 
         * @param {string} [statusCode] 
         * @param {string} [formTypeCode] 
         * @param {boolean | null} [includeDocumentFile] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetformrequestsGet: async (idRequest?: string | null, statusCode?: string, formTypeCode?: string, includeDocumentFile?: boolean | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getformrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }

            if (statusCode !== undefined) {
                localVarQueryParameter['statusCode'] = statusCode;
            }

            if (formTypeCode !== undefined) {
                localVarQueryParameter['formTypeCode'] = formTypeCode;
            }

            if (includeDocumentFile !== undefined) {
                localVarQueryParameter['includeDocumentFile'] = includeDocumentFile;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for income per day widget
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetincomeperdayGet: async (date?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getincomeperday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for laboratory label widget
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetlaboratorylabellistGet: async (date?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getlaboratorylabellist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets notification center data, (list of individually strongly typed notifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetnotificationcenterGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getnotificationcenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns PDF file by request ID
         * @param {string | null} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetpdfbyrequestidGet: async (idRequest?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getpdfbyrequestid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for reservations widget
         * @param {string | null} [date] 
         * @param {boolean | null} [online] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetreservationsperdayGet: async (date?: string | null, online?: boolean | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getreservationsperday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (online !== undefined) {
                localVarQueryParameter['online'] = online;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for SMS widget
         * @param {number} [offset] 
         * @param {number | null} [limit] 
         * @param {string | null} [dateDashboard] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetsmslistdashboardGet: async (offset?: number, limit?: number | null, dateDashboard?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getsmslistdashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (dateDashboard !== undefined) {
                localVarQueryParameter['dateDashboard'] = (dateDashboard as any instanceof Date) ?
                    (dateDashboard as any).toISOString() :
                    dateDashboard;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for store transactions widget
         * @param {string | null} [date] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetstoretransactionsperdayGet: async (date?: string | null, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/getstoretransactionsperday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns data for Tasks widget
         * @param {boolean} [active] 
         * @param {number | null} [offset] 
         * @param {number} [rowsToFetch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GettasksGet: async (active?: boolean, offset?: number | null, rowsToFetch?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard/v2/gettasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (rowsToFetch !== undefined) {
                localVarQueryParameter['rowsToFetch'] = rowsToFetch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Uploads a certificate to database for later use.
         * @param {string} [code] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCertificatevaultUploadcertificatePost(code?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCertificatevaultUploadcertificatePost(code, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiCertificatevaultUploadcertificatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Changes the answer confirmation.
         * @param {string} [answerId] The answer identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardChangeanswerconfirmationPost(answerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardChangeanswerconfirmationPost(answerId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardChangeanswerconfirmationPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Confirms all sms answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardConfirmallanswersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardConfirmallanswersGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardConfirmallanswersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Confirms form request, in case of regFrom, creates new client, gallery etc..
         * @param {string | null} [idFormRequest] 
         * @param {boolean} [confirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardConfirmorrejectformrequestGet(idFormRequest?: string | null, confirm?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardConfirmorrejectformrequestGet(idFormRequest, confirm, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardConfirmorrejectformrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return company summary stats. (Primarily pending notifications etc...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardGetcompanystatsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardGetcompanystatsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardGetcompanystatsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns xdent dashboard summary data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardGetdashboardGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDashobardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardGetdashboardGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardGetdashboardGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets notification center data, (list of generalised notifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardGetnotificationcenterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedUnifiedNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardGetnotificationcenterGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardGetnotificationcenterGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates mock data for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardRefreshmockdataPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardRefreshmockdataPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardRefreshmockdataPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Allows to delete dashboard note by id
         * @param {string | null} [idNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2DashboardnotesdeleteDelete(idNote?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2DashboardnotesdeleteDelete(idNote, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2DashboardnotesdeleteDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Allow to create or update dashboard note.
         * @param {APISharedDashboardNotesInsertUpdateDTO} [aPISharedDashboardNotesInsertUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2DashboardnotesinsertupdatePost(aPISharedDashboardNotesInsertUpdateDTO?: APISharedDashboardNotesInsertUpdateDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2DashboardnotesinsertupdatePost(aPISharedDashboardNotesInsertUpdateDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2DashboardnotesinsertupdatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for dashboard notes widget
         * @param {number} [offset] 
         * @param {number | null} [limit] 
         * @param {boolean} [isComplete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2DashboardnoteslistGet(offset?: number, limit?: number | null, isComplete?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedDashboardNotesListDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2DashboardnoteslistGet(offset, limit, isComplete, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2DashboardnoteslistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary NOT TO BE USED ON MOBILE NOW - Returns data for widget layout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2DashboardwidgetslayoutgetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedWidgetLayoutMobileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2DashboardwidgetslayoutgetGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2DashboardwidgetslayoutgetGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary NOT TO BE USED ON MOBILE NOW - Saves widget layout
         * @param {Array<APISharedWidgetDTO>} [aPISharedWidgetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2DashboardwidgetslayoutsavePost(aPISharedWidgetDTO?: Array<APISharedWidgetDTO>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2DashboardwidgetslayoutsavePost(aPISharedWidgetDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2DashboardwidgetslayoutsavePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for Calls widget
         * @param {string} [callType] 
         * @param {string} [callDateStart] 
         * @param {string} [callDateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetcalloverviewGet(callType?: string, callDateStart?: string, callDateFinish?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedCallEventDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetcalloverviewGet(callType, callDateStart, callDateFinish, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetcalloverviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Web version of XDENT calls it with statusCode as \"PENDING\" and includeDocumentFile as false - every other parameter is passed as NULL.
         * @summary Returns data for Forms widget
         * @param {string | null} [idRequest] 
         * @param {string} [statusCode] 
         * @param {string} [formTypeCode] 
         * @param {boolean | null} [includeDocumentFile] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetformrequestsGet(idRequest?: string | null, statusCode?: string, formTypeCode?: string, includeDocumentFile?: boolean | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetformrequestsGet(idRequest, statusCode, formTypeCode, includeDocumentFile, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetformrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for income per day widget
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetincomeperdayGet(date?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetincomeperdayGet(date, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetincomeperdayGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for laboratory label widget
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetlaboratorylabellistGet(date?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedGetLaboratoryLabelListDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetlaboratorylabellistGet(date, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetlaboratorylabellistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets notification center data, (list of individually strongly typed notifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetnotificationcenterGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTODashboardDashboardNotificationWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetnotificationcenterGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetnotificationcenterGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns PDF file by request ID
         * @param {string | null} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetpdfbyrequestidGet(idRequest?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetpdfbyrequestidGet(idRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetpdfbyrequestidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for reservations widget
         * @param {string | null} [date] 
         * @param {boolean | null} [online] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetreservationsperdayGet(date?: string | null, online?: boolean | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedGetReservationsPerDayDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetreservationsperdayGet(date, online, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetreservationsperdayGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for SMS widget
         * @param {number} [offset] 
         * @param {number | null} [limit] 
         * @param {string | null} [dateDashboard] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetsmslistdashboardGet(offset?: number, limit?: number | null, dateDashboard?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedSMSAnswersDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetsmslistdashboardGet(offset, limit, dateDashboard, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetsmslistdashboardGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for store transactions widget
         * @param {string | null} [date] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GetstoretransactionsperdayGet(date?: string | null, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedGetStoreTransactionsPerDayDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GetstoretransactionsperdayGet(date, lang, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GetstoretransactionsperdayGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns data for Tasks widget
         * @param {boolean} [active] 
         * @param {number | null} [offset] 
         * @param {number} [rowsToFetch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardV2GettasksGet(active?: boolean, offset?: number | null, rowsToFetch?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTODashboardV2TaskDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardV2GettasksGet(active, offset, rowsToFetch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.apiDashboardV2GettasksGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @summary Uploads a certificate to database for later use.
         * @param {string} [code] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCertificatevaultUploadcertificatePost(code?: string, file?: File, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiCertificatevaultUploadcertificatePost(code, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the answer confirmation.
         * @param {string} [answerId] The answer identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardChangeanswerconfirmationPost(answerId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDashboardChangeanswerconfirmationPost(answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms all sms answers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardConfirmallanswersGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDashboardConfirmallanswersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms form request, in case of regFrom, creates new client, gallery etc..
         * @param {string | null} [idFormRequest] 
         * @param {boolean} [confirm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardConfirmorrejectformrequestGet(idFormRequest?: string | null, confirm?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDashboardConfirmorrejectformrequestGet(idFormRequest, confirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return company summary stats. (Primarily pending notifications etc...)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGetcompanystatsGet(options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedCOMPANYSTATSResult> {
            return localVarFp.apiDashboardGetcompanystatsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns xdent dashboard summary data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGetdashboardGet(options?: any): AxiosPromise<APISharedDashobardDto> {
            return localVarFp.apiDashboardGetdashboardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets notification center data, (list of generalised notifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardGetnotificationcenterGet(options?: any): AxiosPromise<Array<APISharedUnifiedNotification>> {
            return localVarFp.apiDashboardGetnotificationcenterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates mock data for dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardRefreshmockdataPost(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDashboardRefreshmockdataPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows to delete dashboard note by id
         * @param {string | null} [idNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardnotesdeleteDelete(idNote?: string | null, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDashboardV2DashboardnotesdeleteDelete(idNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allow to create or update dashboard note.
         * @param {APISharedDashboardNotesInsertUpdateDTO} [aPISharedDashboardNotesInsertUpdateDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardnotesinsertupdatePost(aPISharedDashboardNotesInsertUpdateDTO?: APISharedDashboardNotesInsertUpdateDTO, options?: any): AxiosPromise<string> {
            return localVarFp.apiDashboardV2DashboardnotesinsertupdatePost(aPISharedDashboardNotesInsertUpdateDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for dashboard notes widget
         * @param {number} [offset] 
         * @param {number | null} [limit] 
         * @param {boolean} [isComplete] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardnoteslistGet(offset?: number, limit?: number | null, isComplete?: boolean, options?: any): AxiosPromise<Array<APISharedDashboardNotesListDTO>> {
            return localVarFp.apiDashboardV2DashboardnoteslistGet(offset, limit, isComplete, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary NOT TO BE USED ON MOBILE NOW - Returns data for widget layout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardwidgetslayoutgetGet(options?: any): AxiosPromise<Array<APISharedWidgetLayoutMobileDto>> {
            return localVarFp.apiDashboardV2DashboardwidgetslayoutgetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary NOT TO BE USED ON MOBILE NOW - Saves widget layout
         * @param {Array<APISharedWidgetDTO>} [aPISharedWidgetDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2DashboardwidgetslayoutsavePost(aPISharedWidgetDTO?: Array<APISharedWidgetDTO>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDashboardV2DashboardwidgetslayoutsavePost(aPISharedWidgetDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for Calls widget
         * @param {string} [callType] 
         * @param {string} [callDateStart] 
         * @param {string} [callDateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetcalloverviewGet(callType?: string, callDateStart?: string, callDateFinish?: string, options?: any): AxiosPromise<Array<APISharedCallEventDTO>> {
            return localVarFp.apiDashboardV2GetcalloverviewGet(callType, callDateStart, callDateFinish, options).then((request) => request(axios, basePath));
        },
        /**
         * Web version of XDENT calls it with statusCode as \"PENDING\" and includeDocumentFile as false - every other parameter is passed as NULL.
         * @summary Returns data for Forms widget
         * @param {string | null} [idRequest] 
         * @param {string} [statusCode] 
         * @param {string} [formTypeCode] 
         * @param {boolean | null} [includeDocumentFile] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetformrequestsGet(idRequest?: string | null, statusCode?: string, formTypeCode?: string, includeDocumentFile?: boolean | null, idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult>> {
            return localVarFp.apiDashboardV2GetformrequestsGet(idRequest, statusCode, formTypeCode, includeDocumentFile, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for income per day widget
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetincomeperdayGet(date?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDASHBOARDINCOMEPERDAYGRIDResult>> {
            return localVarFp.apiDashboardV2GetincomeperdayGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for laboratory label widget
         * @param {string | null} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetlaboratorylabellistGet(date?: string | null, options?: any): AxiosPromise<Array<APISharedGetLaboratoryLabelListDTO>> {
            return localVarFp.apiDashboardV2GetlaboratorylabellistGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets notification center data, (list of individually strongly typed notifications)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetnotificationcenterGet(options?: any): AxiosPromise<APIXdentDTODashboardDashboardNotificationWrapper> {
            return localVarFp.apiDashboardV2GetnotificationcenterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns PDF file by request ID
         * @param {string | null} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetpdfbyrequestidGet(idRequest?: string | null, options?: any): AxiosPromise<string> {
            return localVarFp.apiDashboardV2GetpdfbyrequestidGet(idRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for reservations widget
         * @param {string | null} [date] 
         * @param {boolean | null} [online] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetreservationsperdayGet(date?: string | null, online?: boolean | null, options?: any): AxiosPromise<Array<APISharedGetReservationsPerDayDTO>> {
            return localVarFp.apiDashboardV2GetreservationsperdayGet(date, online, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for SMS widget
         * @param {number} [offset] 
         * @param {number | null} [limit] 
         * @param {string | null} [dateDashboard] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetsmslistdashboardGet(offset?: number, limit?: number | null, dateDashboard?: string | null, options?: any): AxiosPromise<Array<APISharedSMSAnswersDTO>> {
            return localVarFp.apiDashboardV2GetsmslistdashboardGet(offset, limit, dateDashboard, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for store transactions widget
         * @param {string | null} [date] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GetstoretransactionsperdayGet(date?: string | null, lang?: string, options?: any): AxiosPromise<Array<APISharedGetStoreTransactionsPerDayDTO>> {
            return localVarFp.apiDashboardV2GetstoretransactionsperdayGet(date, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns data for Tasks widget
         * @param {boolean} [active] 
         * @param {number | null} [offset] 
         * @param {number} [rowsToFetch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardV2GettasksGet(active?: boolean, offset?: number | null, rowsToFetch?: number, options?: any): AxiosPromise<Array<APIXdentDTODashboardV2TaskDto>> {
            return localVarFp.apiDashboardV2GettasksGet(active, offset, rowsToFetch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Uploads a certificate to database for later use.
     * @param {string} [code] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiCertificatevaultUploadcertificatePost(code?: string, file?: File, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiCertificatevaultUploadcertificatePost(code, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the answer confirmation.
     * @param {string} [answerId] The answer identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardChangeanswerconfirmationPost(answerId?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardChangeanswerconfirmationPost(answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms all sms answers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardConfirmallanswersGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardConfirmallanswersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirms form request, in case of regFrom, creates new client, gallery etc..
     * @param {string | null} [idFormRequest] 
     * @param {boolean} [confirm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardConfirmorrejectformrequestGet(idFormRequest?: string | null, confirm?: boolean, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardConfirmorrejectformrequestGet(idFormRequest, confirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return company summary stats. (Primarily pending notifications etc...)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardGetcompanystatsGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardGetcompanystatsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns xdent dashboard summary data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardGetdashboardGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardGetdashboardGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets notification center data, (list of generalised notifications)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardGetnotificationcenterGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardGetnotificationcenterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates mock data for dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardRefreshmockdataPost(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardRefreshmockdataPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows to delete dashboard note by id
     * @param {string | null} [idNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2DashboardnotesdeleteDelete(idNote?: string | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2DashboardnotesdeleteDelete(idNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allow to create or update dashboard note.
     * @param {APISharedDashboardNotesInsertUpdateDTO} [aPISharedDashboardNotesInsertUpdateDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2DashboardnotesinsertupdatePost(aPISharedDashboardNotesInsertUpdateDTO?: APISharedDashboardNotesInsertUpdateDTO, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2DashboardnotesinsertupdatePost(aPISharedDashboardNotesInsertUpdateDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for dashboard notes widget
     * @param {number} [offset] 
     * @param {number | null} [limit] 
     * @param {boolean} [isComplete] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2DashboardnoteslistGet(offset?: number, limit?: number | null, isComplete?: boolean, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2DashboardnoteslistGet(offset, limit, isComplete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary NOT TO BE USED ON MOBILE NOW - Returns data for widget layout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2DashboardwidgetslayoutgetGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2DashboardwidgetslayoutgetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary NOT TO BE USED ON MOBILE NOW - Saves widget layout
     * @param {Array<APISharedWidgetDTO>} [aPISharedWidgetDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2DashboardwidgetslayoutsavePost(aPISharedWidgetDTO?: Array<APISharedWidgetDTO>, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2DashboardwidgetslayoutsavePost(aPISharedWidgetDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for Calls widget
     * @param {string} [callType] 
     * @param {string} [callDateStart] 
     * @param {string} [callDateFinish] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetcalloverviewGet(callType?: string, callDateStart?: string, callDateFinish?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetcalloverviewGet(callType, callDateStart, callDateFinish, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Web version of XDENT calls it with statusCode as \"PENDING\" and includeDocumentFile as false - every other parameter is passed as NULL.
     * @summary Returns data for Forms widget
     * @param {string | null} [idRequest] 
     * @param {string} [statusCode] 
     * @param {string} [formTypeCode] 
     * @param {boolean | null} [includeDocumentFile] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetformrequestsGet(idRequest?: string | null, statusCode?: string, formTypeCode?: string, includeDocumentFile?: boolean | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetformrequestsGet(idRequest, statusCode, formTypeCode, includeDocumentFile, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for income per day widget
     * @param {string | null} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetincomeperdayGet(date?: string | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetincomeperdayGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for laboratory label widget
     * @param {string | null} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetlaboratorylabellistGet(date?: string | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetlaboratorylabellistGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets notification center data, (list of individually strongly typed notifications)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetnotificationcenterGet(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetnotificationcenterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns PDF file by request ID
     * @param {string | null} [idRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetpdfbyrequestidGet(idRequest?: string | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetpdfbyrequestidGet(idRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for reservations widget
     * @param {string | null} [date] 
     * @param {boolean | null} [online] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetreservationsperdayGet(date?: string | null, online?: boolean | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetreservationsperdayGet(date, online, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for SMS widget
     * @param {number} [offset] 
     * @param {number | null} [limit] 
     * @param {string | null} [dateDashboard] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetsmslistdashboardGet(offset?: number, limit?: number | null, dateDashboard?: string | null, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetsmslistdashboardGet(offset, limit, dateDashboard, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for store transactions widget
     * @param {string | null} [date] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GetstoretransactionsperdayGet(date?: string | null, lang?: string, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GetstoretransactionsperdayGet(date, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns data for Tasks widget
     * @param {boolean} [active] 
     * @param {number | null} [offset] 
     * @param {number} [rowsToFetch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public apiDashboardV2GettasksGet(active?: boolean, offset?: number | null, rowsToFetch?: number, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).apiDashboardV2GettasksGet(active, offset, rowsToFetch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiValuesIdDelete', 'id', id)
            const localVarPath = `/api/values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiValuesIdGet', 'id', id)
            const localVarPath = `/api/values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesIdPut: async (id: number, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiValuesIdPut', 'id', id)
            const localVarPath = `/api/values/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesPost: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValuesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValuesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiValuesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValuesIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValuesIdDelete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiValuesIdDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValuesIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValuesIdGet(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiValuesIdGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValuesIdPut(id: number, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValuesIdPut(id, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiValuesIdPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiValuesPost(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiValuesPost(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.apiValuesPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiValuesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiValuesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesIdGet(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiValuesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {number} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesIdPut(id: number, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiValuesIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiValuesPost(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiValuesPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiValuesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiValuesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiValuesIdDelete(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiValuesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiValuesIdGet(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiValuesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {number} id 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiValuesIdPut(id: number, body?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiValuesIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiValuesPost(body?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiValuesPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DentalApi - axios parameter creator
 * @export
 */
export const DentalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalReport] 
         * @param {ApiDentalDentalchartimageGetTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalDentalchartimageGet: async (idMedicalReport?: string, type?: ApiDentalDentalchartimageGetTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/dentalchartimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalDhchartindexespdfGet: async (idMedicalReport?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/dhchartindexespdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets client dental chart as PDF and saves it to MR gallery
         * @param {string} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalExportdhchartGet: async (idMedicalReport?: string, printNote?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/exportdhchart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (printNote !== undefined) {
                localVarQueryParameter['printNote'] = printNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets client recommendation based on dh data from medical report
         * @param {string | null} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {ApiDentalGeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGeneratedentalchartpdfGet: async (idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalGeneratedentalchartpdfGetExportTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/generatedentalchartpdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (printNote !== undefined) {
                localVarQueryParameter['printNote'] = printNote;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dental categories by idClient for owner user.
         * @param {string | null} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetdentalchartGet: async (idMedicalReport?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/getdentalchart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dental chart by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetdentalchartclientdataGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/getdentalchartclientdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets perio dental chart history by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetdhhistoryGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/getdhhistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the peridontal chart indices value.
         * @param {APIXdentDTODentalGetPeridontalChartIndicesValueDataDto} [aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto] The model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetperidontalchartindicesvaluePost: async (aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto?: APIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/getperidontalchartindicesvalue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets perio dental chart data by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetperiodontalchartclientdataGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/getperiodontalchartclientdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Historie dh krize. (po najeti v xdentu na history node se zobrazi pdf v iframe)
         * @param {Array<string>} [idReports] You can compare multiple med reports with each other - multiple dh charts
         * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalIndexcomparisonGet: async (idReports?: Array<string>, indexes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/indexcomparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idReports) {
                localVarQueryParameter['idReports'] = idReports;
            }

            if (indexes) {
                localVarQueryParameter['indexes'] = indexes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview jednotliveho indexu dh krize ve forme image (png)
         * @param {string} [idMedicalReport] 
         * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalIndexpreviewGet: async (idMedicalReport?: string, indexes?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/indexpreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (indexes) {
                localVarQueryParameter['indexes'] = indexes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Vrati pdf dh krize se vsema indexama
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalV2DhchartindexespdfGet: async (idMedicalReport?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/v2/dhchartindexespdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets client recommendation based on dh data from medical report (doporuceni pro pacienta)
         * @param {string | null} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalV2GeneratedentalchartpdfGet: async (idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/v2/generatedentalchartpdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (printNote !== undefined) {
                localVarQueryParameter['printNote'] = printNote;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dental chart by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalV2GetdentalchartclientdataGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dental/v2/getdentalchartclientdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets dental categories by idClient for owner user.
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalcategoryGetdentalcategoriesbyclientGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dentalcategory/getdentalcategoriesbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates dental categories by idClient for owner user.
         * @param {ApiDentalcategoryUpdateclientdentalcategoriesPutRequest} [apiDentalcategoryUpdateclientdentalcategoriesPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalcategoryUpdateclientdentalcategoriesPut: async (apiDentalcategoryUpdateclientdentalcategoriesPutRequest?: ApiDentalcategoryUpdateclientdentalcategoriesPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dentalcategory/updateclientdentalcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiDentalcategoryUpdateclientdentalcategoriesPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DentalApi - functional programming interface
 * @export
 */
export const DentalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DentalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalReport] 
         * @param {ApiDentalDentalchartimageGetTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalDentalchartimageGet(idMedicalReport?: string, type?: ApiDentalDentalchartimageGetTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalDentalchartimageGet(idMedicalReport, type, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalDentalchartimageGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalDhchartindexespdfGet(idMedicalReport?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalDhchartindexespdfGet(idMedicalReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalDhchartindexespdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets client dental chart as PDF and saves it to MR gallery
         * @param {string} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalExportdhchartGet(idMedicalReport?: string, printNote?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalExportdhchartGet(idMedicalReport, printNote, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalExportdhchartGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets client recommendation based on dh data from medical report
         * @param {string | null} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {ApiDentalGeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalGeneratedentalchartpdfGet(idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalGeneratedentalchartpdfGetExportTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalGeneratedentalchartpdfGet(idMedicalReport, printNote, exportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalGeneratedentalchartpdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets dental categories by idClient for owner user.
         * @param {string | null} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalGetdentalchartGet(idMedicalReport?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerClientDentalCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalGetdentalchartGet(idMedicalReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalGetdentalchartGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets dental chart by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalGetdentalchartclientdataGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTODentalDentalChartItemData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalGetdentalchartclientdataGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalGetdentalchartclientdataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets perio dental chart history by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalGetdhhistoryGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTODentalDhHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalGetdhhistoryGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalGetdhhistoryGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the peridontal chart indices value.
         * @param {APIXdentDTODentalGetPeridontalChartIndicesValueDataDto} [aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto] The model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalGetperidontalchartindicesvaluePost(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto?: APIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTODentalGetPeridontalChartIndicesValueResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalGetperidontalchartindicesvaluePost(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalGetperidontalchartindicesvaluePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets perio dental chart data by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalGetperiodontalchartclientdataGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTODentalPeriodontalChartItemData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalGetperiodontalchartclientdataGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalGetperiodontalchartclientdataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Historie dh krize. (po najeti v xdentu na history node se zobrazi pdf v iframe)
         * @param {Array<string>} [idReports] You can compare multiple med reports with each other - multiple dh charts
         * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalIndexcomparisonGet(idReports?: Array<string>, indexes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalIndexcomparisonGet(idReports, indexes, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalIndexcomparisonGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Preview jednotliveho indexu dh krize ve forme image (png)
         * @param {string} [idMedicalReport] 
         * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalIndexpreviewGet(idMedicalReport?: string, indexes?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalIndexpreviewGet(idMedicalReport, indexes, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalIndexpreviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Vrati pdf dh krize se vsema indexama
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalV2DhchartindexespdfGet(idMedicalReport?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalV2DhchartindexespdfGet(idMedicalReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalV2DhchartindexespdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets client recommendation based on dh data from medical report (doporuceni pro pacienta)
         * @param {string | null} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalV2GeneratedentalchartpdfGet(idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalV2GeneratedentalchartpdfGet(idMedicalReport, printNote, exportType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalV2GeneratedentalchartpdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets dental chart by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalV2GetdentalchartclientdataGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTODentalDentalChartItemData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalV2GetdentalchartclientdataGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalV2GetdentalchartclientdataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets dental categories by idClient for owner user.
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalcategoryGetdentalcategoriesbyclientGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerClientDentalCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalcategoryGetdentalcategoriesbyclientGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalcategoryGetdentalcategoriesbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates dental categories by idClient for owner user.
         * @param {ApiDentalcategoryUpdateclientdentalcategoriesPutRequest} [apiDentalcategoryUpdateclientdentalcategoriesPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDentalcategoryUpdateclientdentalcategoriesPut(apiDentalcategoryUpdateclientdentalcategoriesPutRequest?: ApiDentalcategoryUpdateclientdentalcategoriesPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDentalcategoryUpdateclientdentalcategoriesPut(apiDentalcategoryUpdateclientdentalcategoriesPutRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DentalApi.apiDentalcategoryUpdateclientdentalcategoriesPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DentalApi - factory interface
 * @export
 */
export const DentalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DentalApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalReport] 
         * @param {ApiDentalDentalchartimageGetTypeEnum} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalDentalchartimageGet(idMedicalReport?: string, type?: ApiDentalDentalchartimageGetTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiDentalDentalchartimageGet(idMedicalReport, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalDhchartindexespdfGet(idMedicalReport?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDentalDhchartindexespdfGet(idMedicalReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets client dental chart as PDF and saves it to MR gallery
         * @param {string} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalExportdhchartGet(idMedicalReport?: string, printNote?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiDentalExportdhchartGet(idMedicalReport, printNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets client recommendation based on dh data from medical report
         * @param {string | null} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {ApiDentalGeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGeneratedentalchartpdfGet(idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalGeneratedentalchartpdfGetExportTypeEnum, options?: any): AxiosPromise<object> {
            return localVarFp.apiDentalGeneratedentalchartpdfGet(idMedicalReport, printNote, exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dental categories by idClient for owner user.
         * @param {string | null} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetdentalchartGet(idMedicalReport?: string | null, options?: any): AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerClientDentalCategory>> {
            return localVarFp.apiDentalGetdentalchartGet(idMedicalReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dental chart by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetdentalchartclientdataGet(idClient?: string, options?: any): AxiosPromise<Array<APIXdentDTODentalDentalChartItemData>> {
            return localVarFp.apiDentalGetdentalchartclientdataGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets perio dental chart history by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetdhhistoryGet(idClient?: string, options?: any): AxiosPromise<APIXdentDTODentalDhHistory> {
            return localVarFp.apiDentalGetdhhistoryGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the peridontal chart indices value.
         * @param {APIXdentDTODentalGetPeridontalChartIndicesValueDataDto} [aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto] The model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetperidontalchartindicesvaluePost(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto?: APIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options?: any): AxiosPromise<APIXdentDTODentalGetPeridontalChartIndicesValueResponseDto> {
            return localVarFp.apiDentalGetperidontalchartindicesvaluePost(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets perio dental chart data by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalGetperiodontalchartclientdataGet(idClient?: string, options?: any): AxiosPromise<Array<APIXdentDTODentalPeriodontalChartItemData>> {
            return localVarFp.apiDentalGetperiodontalchartclientdataGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Historie dh krize. (po najeti v xdentu na history node se zobrazi pdf v iframe)
         * @param {Array<string>} [idReports] You can compare multiple med reports with each other - multiple dh charts
         * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalIndexcomparisonGet(idReports?: Array<string>, indexes?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiDentalIndexcomparisonGet(idReports, indexes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview jednotliveho indexu dh krize ve forme image (png)
         * @param {string} [idMedicalReport] 
         * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalIndexpreviewGet(idMedicalReport?: string, indexes?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiDentalIndexpreviewGet(idMedicalReport, indexes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Vrati pdf dh krize se vsema indexama
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalV2DhchartindexespdfGet(idMedicalReport?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDentalV2DhchartindexespdfGet(idMedicalReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets client recommendation based on dh data from medical report (doporuceni pro pacienta)
         * @param {string | null} [idMedicalReport] 
         * @param {boolean} [printNote] 
         * @param {ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalV2GeneratedentalchartpdfGet(idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum, options?: any): AxiosPromise<object> {
            return localVarFp.apiDentalV2GeneratedentalchartpdfGet(idMedicalReport, printNote, exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dental chart by idClient for owner user.
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalV2GetdentalchartclientdataGet(idClient?: string, options?: any): AxiosPromise<Array<APIXdentDTODentalDentalChartItemData>> {
            return localVarFp.apiDentalV2GetdentalchartclientdataGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets dental categories by idClient for owner user.
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalcategoryGetdentalcategoriesbyclientGet(idClient?: string | null, options?: any): AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerClientDentalCategory>> {
            return localVarFp.apiDentalcategoryGetdentalcategoriesbyclientGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates dental categories by idClient for owner user.
         * @param {ApiDentalcategoryUpdateclientdentalcategoriesPutRequest} [apiDentalcategoryUpdateclientdentalcategoriesPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDentalcategoryUpdateclientdentalcategoriesPut(apiDentalcategoryUpdateclientdentalcategoriesPutRequest?: ApiDentalcategoryUpdateclientdentalcategoriesPutRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiDentalcategoryUpdateclientdentalcategoriesPut(apiDentalcategoryUpdateclientdentalcategoriesPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DentalApi - object-oriented interface
 * @export
 * @class DentalApi
 * @extends {BaseAPI}
 */
export class DentalApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idMedicalReport] 
     * @param {ApiDentalDentalchartimageGetTypeEnum} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalDentalchartimageGet(idMedicalReport?: string, type?: ApiDentalDentalchartimageGetTypeEnum, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalDentalchartimageGet(idMedicalReport, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idMedicalReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalDhchartindexespdfGet(idMedicalReport?: string, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalDhchartindexespdfGet(idMedicalReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets client dental chart as PDF and saves it to MR gallery
     * @param {string} [idMedicalReport] 
     * @param {boolean} [printNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalExportdhchartGet(idMedicalReport?: string, printNote?: boolean, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalExportdhchartGet(idMedicalReport, printNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets client recommendation based on dh data from medical report
     * @param {string | null} [idMedicalReport] 
     * @param {boolean} [printNote] 
     * @param {ApiDentalGeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalGeneratedentalchartpdfGet(idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalGeneratedentalchartpdfGetExportTypeEnum, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalGeneratedentalchartpdfGet(idMedicalReport, printNote, exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dental categories by idClient for owner user.
     * @param {string | null} [idMedicalReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalGetdentalchartGet(idMedicalReport?: string | null, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalGetdentalchartGet(idMedicalReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dental chart by idClient for owner user.
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalGetdentalchartclientdataGet(idClient?: string, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalGetdentalchartclientdataGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets perio dental chart history by idClient for owner user.
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalGetdhhistoryGet(idClient?: string, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalGetdhhistoryGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the peridontal chart indices value.
     * @param {APIXdentDTODentalGetPeridontalChartIndicesValueDataDto} [aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto] The model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalGetperidontalchartindicesvaluePost(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto?: APIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalGetperidontalchartindicesvaluePost(aPIXdentDTODentalGetPeridontalChartIndicesValueDataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets perio dental chart data by idClient for owner user.
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalGetperiodontalchartclientdataGet(idClient?: string, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalGetperiodontalchartclientdataGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Historie dh krize. (po najeti v xdentu na history node se zobrazi pdf v iframe)
     * @param {Array<string>} [idReports] You can compare multiple med reports with each other - multiple dh charts
     * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalIndexcomparisonGet(idReports?: Array<string>, indexes?: Array<string>, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalIndexcomparisonGet(idReports, indexes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview jednotliveho indexu dh krize ve forme image (png)
     * @param {string} [idMedicalReport] 
     * @param {Array<string>} [indexes] PI,BOP,API,BI,PBI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalIndexpreviewGet(idMedicalReport?: string, indexes?: Array<string>, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalIndexpreviewGet(idMedicalReport, indexes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Vrati pdf dh krize se vsema indexama
     * @param {string} [idMedicalReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalV2DhchartindexespdfGet(idMedicalReport?: string, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalV2DhchartindexespdfGet(idMedicalReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets client recommendation based on dh data from medical report (doporuceni pro pacienta)
     * @param {string | null} [idMedicalReport] 
     * @param {boolean} [printNote] 
     * @param {ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum} [exportType] 0 &#x3D; HTML, 1 &#x3D; PDF BYTES, 2 &#x3D; PDF FILE, 3 &#x3D; EXPORT TO MR GALLERY
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalV2GeneratedentalchartpdfGet(idMedicalReport?: string | null, printNote?: boolean, exportType?: ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalV2GeneratedentalchartpdfGet(idMedicalReport, printNote, exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dental chart by idClient for owner user.
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalV2GetdentalchartclientdataGet(idClient?: string, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalV2GetdentalchartclientdataGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets dental categories by idClient for owner user.
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalcategoryGetdentalcategoriesbyclientGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalcategoryGetdentalcategoriesbyclientGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates dental categories by idClient for owner user.
     * @param {ApiDentalcategoryUpdateclientdentalcategoriesPutRequest} [apiDentalcategoryUpdateclientdentalcategoriesPutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DentalApi
     */
    public apiDentalcategoryUpdateclientdentalcategoriesPut(apiDentalcategoryUpdateclientdentalcategoriesPutRequest?: ApiDentalcategoryUpdateclientdentalcategoriesPutRequest, options?: AxiosRequestConfig) {
        return DentalApiFp(this.configuration).apiDentalcategoryUpdateclientdentalcategoriesPut(apiDentalcategoryUpdateclientdentalcategoriesPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiDentalDentalchartimageGetTypeEnum = {
    Standard: 'STANDARD',
    Periodontal: 'PERIODONTAL'
} as const;
export type ApiDentalDentalchartimageGetTypeEnum = typeof ApiDentalDentalchartimageGetTypeEnum[keyof typeof ApiDentalDentalchartimageGetTypeEnum];
/**
 * @export
 */
export const ApiDentalGeneratedentalchartpdfGetExportTypeEnum = {
    Html: 'HTML',
    PdfBytes: 'PDF_BYTES',
    PdfFile: 'PDF_FILE',
    MrGallery: 'MR_GALLERY'
} as const;
export type ApiDentalGeneratedentalchartpdfGetExportTypeEnum = typeof ApiDentalGeneratedentalchartpdfGetExportTypeEnum[keyof typeof ApiDentalGeneratedentalchartpdfGetExportTypeEnum];
/**
 * @export
 */
export const ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum = {
    Html: 'HTML',
    PdfBytes: 'PDF_BYTES',
    PdfFile: 'PDF_FILE',
    MrGallery: 'MR_GALLERY'
} as const;
export type ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum = typeof ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum[keyof typeof ApiDentalV2GeneratedentalchartpdfGetExportTypeEnum];


/**
 * EReceptApi - axios parameter creator
 * @export
 */
export const EReceptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptFilterdiagnoseGet: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erecept/filterdiagnose`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalPrescription] 
         * @param {boolean} [duplicate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptGetprescriptionforeditGet: async (idMedicalPrescription?: string, duplicate?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erecept/getprescriptionforedit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalPrescription !== undefined) {
                localVarQueryParameter['idMedicalPrescription'] = idMedicalPrescription;
            }

            if (duplicate !== undefined) {
                localVarQueryParameter['duplicate'] = duplicate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptMagistralisSaveDto} [aPIXdentDTOEreceptMagistralisSaveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSavemagistralisPost: async (aPIXdentDTOEreceptMagistralisSaveDto?: APIXdentDTOEreceptMagistralisSaveDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erecept/savemagistralis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEreceptMagistralisSaveDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptSavePrescriptionDto} [aPIXdentDTOEreceptSavePrescriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSaveprescriptionPost: async (aPIXdentDTOEreceptSavePrescriptionDto?: APIXdentDTOEreceptSavePrescriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erecept/saveprescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEreceptSavePrescriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptSendSignedElectronicPrescriptionDto} [aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSendsignedelectronicprescriptionPost: async (aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto?: APIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erecept/sendsignedelectronicprescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSuklerrorsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erecept/suklerrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EReceptApi - functional programming interface
 * @export
 */
export const EReceptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EReceptApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEreceptFilterdiagnoseGet(query?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSESEARCHGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEreceptFilterdiagnoseGet(query, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EReceptApi.apiEreceptFilterdiagnoseGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalPrescription] 
         * @param {boolean} [duplicate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEreceptGetprescriptionforeditGet(idMedicalPrescription?: string, duplicate?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEreceptCopyPrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEreceptGetprescriptionforeditGet(idMedicalPrescription, duplicate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EReceptApi.apiEreceptGetprescriptionforeditGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptMagistralisSaveDto} [aPIXdentDTOEreceptMagistralisSaveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEreceptSavemagistralisPost(aPIXdentDTOEreceptMagistralisSaveDto?: APIXdentDTOEreceptMagistralisSaveDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEreceptMagistralisSaveResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEreceptSavemagistralisPost(aPIXdentDTOEreceptMagistralisSaveDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EReceptApi.apiEreceptSavemagistralisPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptSavePrescriptionDto} [aPIXdentDTOEreceptSavePrescriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEreceptSaveprescriptionPost(aPIXdentDTOEreceptSavePrescriptionDto?: APIXdentDTOEreceptSavePrescriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEreceptSavePrescriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEreceptSaveprescriptionPost(aPIXdentDTOEreceptSavePrescriptionDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EReceptApi.apiEreceptSaveprescriptionPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptSendSignedElectronicPrescriptionDto} [aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEreceptSendsignedelectronicprescriptionPost(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto?: APIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEreceptSendsignedelectronicprescriptionPost(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EReceptApi.apiEreceptSendsignedelectronicprescriptionPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEreceptSuklerrorsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEreceptSuklerrorsPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EReceptApi.apiEreceptSuklerrorsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EReceptApi - factory interface
 * @export
 */
export const EReceptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EReceptApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptFilterdiagnoseGet(query?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCEDIAGNOSESEARCHGRIDResult>> {
            return localVarFp.apiEreceptFilterdiagnoseGet(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idMedicalPrescription] 
         * @param {boolean} [duplicate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptGetprescriptionforeditGet(idMedicalPrescription?: string, duplicate?: boolean, options?: any): AxiosPromise<APIXdentDTOEreceptCopyPrescriptionDto> {
            return localVarFp.apiEreceptGetprescriptionforeditGet(idMedicalPrescription, duplicate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptMagistralisSaveDto} [aPIXdentDTOEreceptMagistralisSaveDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSavemagistralisPost(aPIXdentDTOEreceptMagistralisSaveDto?: APIXdentDTOEreceptMagistralisSaveDto, options?: any): AxiosPromise<APIXdentDTOEreceptMagistralisSaveResponseDto> {
            return localVarFp.apiEreceptSavemagistralisPost(aPIXdentDTOEreceptMagistralisSaveDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptSavePrescriptionDto} [aPIXdentDTOEreceptSavePrescriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSaveprescriptionPost(aPIXdentDTOEreceptSavePrescriptionDto?: APIXdentDTOEreceptSavePrescriptionDto, options?: any): AxiosPromise<APIXdentDTOEreceptSavePrescriptionResponseDto> {
            return localVarFp.apiEreceptSaveprescriptionPost(aPIXdentDTOEreceptSavePrescriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOEreceptSendSignedElectronicPrescriptionDto} [aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSendsignedelectronicprescriptionPost(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto?: APIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options?: any): AxiosPromise<APIXdentDTOEreceptSignedElectronicPrescriptionResponseDto> {
            return localVarFp.apiEreceptSendsignedelectronicprescriptionPost(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEreceptSuklerrorsPost(options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSUKLERRORSGRIDResult>> {
            return localVarFp.apiEreceptSuklerrorsPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EReceptApi - object-oriented interface
 * @export
 * @class EReceptApi
 * @extends {BaseAPI}
 */
export class EReceptApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EReceptApi
     */
    public apiEreceptFilterdiagnoseGet(query?: string, options?: AxiosRequestConfig) {
        return EReceptApiFp(this.configuration).apiEreceptFilterdiagnoseGet(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idMedicalPrescription] 
     * @param {boolean} [duplicate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EReceptApi
     */
    public apiEreceptGetprescriptionforeditGet(idMedicalPrescription?: string, duplicate?: boolean, options?: AxiosRequestConfig) {
        return EReceptApiFp(this.configuration).apiEreceptGetprescriptionforeditGet(idMedicalPrescription, duplicate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOEreceptMagistralisSaveDto} [aPIXdentDTOEreceptMagistralisSaveDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EReceptApi
     */
    public apiEreceptSavemagistralisPost(aPIXdentDTOEreceptMagistralisSaveDto?: APIXdentDTOEreceptMagistralisSaveDto, options?: AxiosRequestConfig) {
        return EReceptApiFp(this.configuration).apiEreceptSavemagistralisPost(aPIXdentDTOEreceptMagistralisSaveDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOEreceptSavePrescriptionDto} [aPIXdentDTOEreceptSavePrescriptionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EReceptApi
     */
    public apiEreceptSaveprescriptionPost(aPIXdentDTOEreceptSavePrescriptionDto?: APIXdentDTOEreceptSavePrescriptionDto, options?: AxiosRequestConfig) {
        return EReceptApiFp(this.configuration).apiEreceptSaveprescriptionPost(aPIXdentDTOEreceptSavePrescriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOEreceptSendSignedElectronicPrescriptionDto} [aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EReceptApi
     */
    public apiEreceptSendsignedelectronicprescriptionPost(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto?: APIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options?: AxiosRequestConfig) {
        return EReceptApiFp(this.configuration).apiEreceptSendsignedelectronicprescriptionPost(aPIXdentDTOEreceptSendSignedElectronicPrescriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EReceptApi
     */
    public apiEreceptSuklerrorsPost(options?: AxiosRequestConfig) {
        return EReceptApiFp(this.configuration).apiEreceptSuklerrorsPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EZdravieApi - axios parameter creator
 * @export
 */
export const EZdravieApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generates a unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGenerateexternyidentifikatorriadkuGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/generateexternyidentifikatorriadku`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGenerateidPost: async (aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/generateid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieGenerateEZdravieIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets SK client by id
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetclientskbyidGet: async (clientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getclientskbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns filtered diagnoses based on name
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetdiagnosesGet: async (search?: string, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getdiagnoses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets insurance settings for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetinsurancesettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getinsurancesettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns filtered medical aid tools based on name
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetmedicalaidtoolsGet: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getmedicalaidtools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns filtered medicaments based on name and ATC
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetmedicamentsGet: async (search?: string, take?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getmedicaments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the oupzs by ICO.
         * @param {string} [ico] The ICO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetoupzsbyicoGet: async (ico?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getoupzsbyico`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ico !== undefined) {
                localVarQueryParameter['ico'] = ico;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the provider  by ICO.
         * @param {string} [ico] The ICO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetproviderzsbyicoGet: async (ico?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getproviderzsbyico`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ico !== undefined) {
                localVarQueryParameter['ico'] = ico;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get unprocessed requests that are in the queue alongside some already processed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetrequestqueueinfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getrequestqueueinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idOUPZS] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetsomebyidoupzsGet: async (idOUPZS?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getsomebyidoupzs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idOUPZS !== undefined) {
                localVarQueryParameter['idOUPZS'] = idOUPZS;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idZPr] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetsomebyidzprGet: async (idZPr?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/getsomebyidzpr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idZPr !== undefined) {
                localVarQueryParameter['idZPr'] = idZPr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EZdravieApi - functional programming interface
 * @export
 */
export const EZdravieApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EZdravieApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generates a unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGenerateexternyidentifikatorriadkuGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGenerateexternyidentifikatorriadkuGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGenerateexternyidentifikatorriadkuGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGenerateidPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets SK client by id
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetclientskbyidGet(clientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieClientSkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetclientskbyidGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetclientskbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns filtered diagnoses based on name
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetdiagnosesGet(search?: string, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOEZdravieDiagnosisDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetdiagnosesGet(search, take, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetdiagnosesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets insurance settings for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetinsurancesettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieInsuranceSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetinsurancesettingsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetinsurancesettingsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns filtered medical aid tools based on name
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetmedicalaidtoolsGet(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOEZdravieMedicalAidToolDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetmedicalaidtoolsGet(search, take, skip, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetmedicalaidtoolsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns filtered medicaments based on name and ATC
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetmedicamentsGet(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOEZdravieMedicamentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetmedicamentsGet(search, take, skip, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetmedicamentsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the oupzs by ICO.
         * @param {string} [ico] The ICO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetoupzsbyicoGet(ico?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetoupzsbyicoGet(ico, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetoupzsbyicoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the provider  by ICO.
         * @param {string} [ico] The ICO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetproviderzsbyicoGet(ico?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetproviderzsbyicoGet(ico, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetproviderzsbyicoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get unprocessed requests that are in the queue alongside some already processed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetrequestqueueinfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetrequestqueueinfoGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetrequestqueueinfoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idOUPZS] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetsomebyidoupzsGet(idOUPZS?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOEZdravieGetDataByIdOUPZSDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetsomebyidoupzsGet(idOUPZS, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetsomebyidoupzsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idZPr] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieGetsomebyidzprGet(idZPr?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieGetDataByIdZPrDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieGetsomebyidzprGet(idZPr, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieApi.apiEzdravieGetsomebyidzprGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EZdravieApi - factory interface
 * @export
 */
export const EZdravieApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EZdravieApiFp(configuration)
    return {
        /**
         * 
         * @summary Generates a unique id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGenerateexternyidentifikatorriadkuGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiEzdravieGenerateexternyidentifikatorriadkuGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets SK client by id
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetclientskbyidGet(clientId?: string, options?: any): AxiosPromise<APIXdentDTOEZdravieClientSkDto> {
            return localVarFp.apiEzdravieGetclientskbyidGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns filtered diagnoses based on name
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetdiagnosesGet(search?: string, take?: number, options?: any): AxiosPromise<Array<APIXdentDTOEZdravieDiagnosisDto>> {
            return localVarFp.apiEzdravieGetdiagnosesGet(search, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets insurance settings for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetinsurancesettingsGet(options?: any): AxiosPromise<APIXdentDTOEZdravieInsuranceSettingsDto> {
            return localVarFp.apiEzdravieGetinsurancesettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns filtered medical aid tools based on name
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetmedicalaidtoolsGet(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<APIXdentDTOEZdravieMedicalAidToolDto>> {
            return localVarFp.apiEzdravieGetmedicalaidtoolsGet(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns filtered medicaments based on name and ATC
         * @param {string} [search] 
         * @param {number} [take] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetmedicamentsGet(search?: string, take?: number, skip?: number, options?: any): AxiosPromise<Array<APIXdentDTOEZdravieMedicamentDto>> {
            return localVarFp.apiEzdravieGetmedicamentsGet(search, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the oupzs by ICO.
         * @param {string} [ico] The ICO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetoupzsbyicoGet(ico?: string, options?: any): AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerEZdravieOUPZS>> {
            return localVarFp.apiEzdravieGetoupzsbyicoGet(ico, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the provider  by ICO.
         * @param {string} [ico] The ICO.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetproviderzsbyicoGet(ico?: string, options?: any): AxiosPromise<APIContextGeneratedXdentCoreEntityLayerEZdravieProviderZS> {
            return localVarFp.apiEzdravieGetproviderzsbyicoGet(ico, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get unprocessed requests that are in the queue alongside some already processed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetrequestqueueinfoGet(options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEZDRAVIEREQUESTQUEUEINFOGRIDResult>> {
            return localVarFp.apiEzdravieGetrequestqueueinfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idOUPZS] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetsomebyidoupzsGet(idOUPZS?: string, options?: any): AxiosPromise<Array<APIXdentDTOEZdravieGetDataByIdOUPZSDto>> {
            return localVarFp.apiEzdravieGetsomebyidoupzsGet(idOUPZS, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idZPr] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieGetsomebyidzprGet(idZPr?: string, options?: any): AxiosPromise<APIXdentDTOEZdravieGetDataByIdZPrDto> {
            return localVarFp.apiEzdravieGetsomebyidzprGet(idZPr, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EZdravieApi - object-oriented interface
 * @export
 * @class EZdravieApi
 * @extends {BaseAPI}
 */
export class EZdravieApi extends BaseAPI {
    /**
     * 
     * @summary Generates a unique id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGenerateexternyidentifikatorriadkuGet(options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGenerateexternyidentifikatorriadkuGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates id for ezdravie medical report
     * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets SK client by id
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetclientskbyidGet(clientId?: string, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetclientskbyidGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns filtered diagnoses based on name
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetdiagnosesGet(search?: string, take?: number, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetdiagnosesGet(search, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets insurance settings for logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetinsurancesettingsGet(options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetinsurancesettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns filtered medical aid tools based on name
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetmedicalaidtoolsGet(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetmedicalaidtoolsGet(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns filtered medicaments based on name and ATC
     * @param {string} [search] 
     * @param {number} [take] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetmedicamentsGet(search?: string, take?: number, skip?: number, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetmedicamentsGet(search, take, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the oupzs by ICO.
     * @param {string} [ico] The ICO.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetoupzsbyicoGet(ico?: string, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetoupzsbyicoGet(ico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the provider  by ICO.
     * @param {string} [ico] The ICO.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetproviderzsbyicoGet(ico?: string, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetproviderzsbyicoGet(ico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get unprocessed requests that are in the queue alongside some already processed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetrequestqueueinfoGet(options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetrequestqueueinfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idOUPZS] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetsomebyidoupzsGet(idOUPZS?: string, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetsomebyidoupzsGet(idOUPZS, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idZPr] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieApi
     */
    public apiEzdravieGetsomebyidzprGet(idZPr?: string, options?: AxiosRequestConfig) {
        return EZdravieApiFp(this.configuration).apiEzdravieGetsomebyidzprGet(idZPr, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EZdravieBasicApi - axios parameter creator
 * @export
 */
export const EZdravieBasicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Enqueques request
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthEnqueueezdravierequestPost: async (idXdentUser: string, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiEzdraviebasicauthEnqueueezdravierequestPost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/ezdraviebasicauth/enqueueezdravierequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthGenerateidPost: async (idXdentUser: string, aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiEzdraviebasicauthGenerateidPost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/ezdraviebasicauth/generateid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieGenerateEZdravieIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs request
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthLogezdravierequestPost: async (idXdentUser: string, aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiEzdraviebasicauthLogezdravierequestPost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/ezdraviebasicauth/logezdravierequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieExtLogRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves PreskripcnyZaznam to xdent
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthUlozpreskripcnyzaznamPost: async (idXdentUser: string, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiEzdraviebasicauthUlozpreskripcnyzaznamPost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/ezdraviebasicauth/ulozpreskripcnyzaznam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} idXdentUser 
         * @param {File} [file] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthUploadcertificatePost: async (idXdentUser: string, file?: File, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiEzdraviebasicauthUploadcertificatePost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/ezdraviebasicauth/uploadcertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.append('code', code as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EZdravieBasicApi - functional programming interface
 * @export
 */
export const EZdravieBasicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EZdravieBasicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Enqueques request
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdraviebasicauthEnqueueezdravierequestPost(idXdentUser: string, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdraviebasicauthEnqueueezdravierequestPost(idXdentUser, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieBasicApi.apiEzdraviebasicauthEnqueueezdravierequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdraviebasicauthGenerateidPost(idXdentUser: string, aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdraviebasicauthGenerateidPost(idXdentUser, aPIXdentDTOEZdravieGenerateEZdravieIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieBasicApi.apiEzdraviebasicauthGenerateidPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Logs request
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdraviebasicauthLogezdravierequestPost(idXdentUser: string, aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdraviebasicauthLogezdravierequestPost(idXdentUser, aPIXdentDTOEZdravieExtLogRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieBasicApi.apiEzdraviebasicauthLogezdravierequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Saves PreskripcnyZaznam to xdent
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdraviebasicauthUlozpreskripcnyzaznamPost(idXdentUser: string, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdraviebasicauthUlozpreskripcnyzaznamPost(idXdentUser, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieBasicApi.apiEzdraviebasicauthUlozpreskripcnyzaznamPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} idXdentUser 
         * @param {File} [file] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdraviebasicauthUploadcertificatePost(idXdentUser: string, file?: File, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdraviebasicauthUploadcertificatePost(idXdentUser, file, code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieBasicApi.apiEzdraviebasicauthUploadcertificatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EZdravieBasicApi - factory interface
 * @export
 */
export const EZdravieBasicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EZdravieBasicApiFp(configuration)
    return {
        /**
         * 
         * @summary Enqueques request
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthEnqueueezdravierequestPost(idXdentUser: string, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdraviebasicauthEnqueueezdravierequestPost(idXdentUser, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthGenerateidPost(idXdentUser: string, aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdraviebasicauthGenerateidPost(idXdentUser, aPIXdentDTOEZdravieGenerateEZdravieIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs request
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthLogezdravierequestPost(idXdentUser: string, aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdraviebasicauthLogezdravierequestPost(idXdentUser, aPIXdentDTOEZdravieExtLogRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves PreskripcnyZaznam to xdent
         * @param {string} idXdentUser 
         * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthUlozpreskripcnyzaznamPost(idXdentUser: string, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdraviebasicauthUlozpreskripcnyzaznamPost(idXdentUser, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} idXdentUser 
         * @param {File} [file] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdraviebasicauthUploadcertificatePost(idXdentUser: string, file?: File, code?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEzdraviebasicauthUploadcertificatePost(idXdentUser, file, code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EZdravieBasicApi - object-oriented interface
 * @export
 * @class EZdravieBasicApi
 * @extends {BaseAPI}
 */
export class EZdravieBasicApi extends BaseAPI {
    /**
     * 
     * @summary Enqueques request
     * @param {string} idXdentUser 
     * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieBasicApi
     */
    public apiEzdraviebasicauthEnqueueezdravierequestPost(idXdentUser: string, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options?: AxiosRequestConfig) {
        return EZdravieBasicApiFp(this.configuration).apiEzdraviebasicauthEnqueueezdravierequestPost(idXdentUser, aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates id for ezdravie medical report
     * @param {string} idXdentUser 
     * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieBasicApi
     */
    public apiEzdraviebasicauthGenerateidPost(idXdentUser: string, aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: AxiosRequestConfig) {
        return EZdravieBasicApiFp(this.configuration).apiEzdraviebasicauthGenerateidPost(idXdentUser, aPIXdentDTOEZdravieGenerateEZdravieIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs request
     * @param {string} idXdentUser 
     * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieBasicApi
     */
    public apiEzdraviebasicauthLogezdravierequestPost(idXdentUser: string, aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options?: AxiosRequestConfig) {
        return EZdravieBasicApiFp(this.configuration).apiEzdraviebasicauthLogezdravierequestPost(idXdentUser, aPIXdentDTOEZdravieExtLogRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves PreskripcnyZaznam to xdent
     * @param {string} idXdentUser 
     * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieBasicApi
     */
    public apiEzdraviebasicauthUlozpreskripcnyzaznamPost(idXdentUser: string, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options?: AxiosRequestConfig) {
        return EZdravieBasicApiFp(this.configuration).apiEzdraviebasicauthUlozpreskripcnyzaznamPost(idXdentUser, aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} idXdentUser 
     * @param {File} [file] 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieBasicApi
     */
    public apiEzdraviebasicauthUploadcertificatePost(idXdentUser: string, file?: File, code?: string, options?: AxiosRequestConfig) {
        return EZdravieBasicApiFp(this.configuration).apiEzdraviebasicauthUploadcertificatePost(idXdentUser, file, code, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EZdravieEXTApi - axios parameter creator
 * @export
 */
export const EZdravieEXTApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIXdentDTOEZdravieExtLoginDto} [aPIXdentDTOEZdravieExtLoginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtAuthPost: async (aPIXdentDTOEZdravieExtLoginDto?: APIXdentDTOEZdravieExtLoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieExtLoginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enqueques request
         * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtEnqueueezdravierequestPost: async (aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/enqueueezdravierequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGenerateidPost: async (aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/generateid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieGenerateEZdravieIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get local https certificate file
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetcertificateGet: async (code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/getcertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets SK client by id
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetclientskbyidGet: async (clientId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/getclientskbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets insurance settings for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetinsurancesettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/getinsurancesettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get next request from queue for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetnextrequestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/getnextrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get request from the queue by idRequest.
         * @param {string} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetrequestGet: async (idRequest?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/getrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get if next request exists in queue for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtHasnextrequestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/hasnextrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs request
         * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtLogezdravierequestPost: async (aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/logezdravierequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieExtLogRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Just a test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves PreskripcnyZaznam to xdent
         * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtUlozpreskripcnyzaznamPost: async (aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/ulozpreskripcnyzaznam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates request in DB, adds log.
         * @param {APIXdentDTOEZdravieExtRequestResultDto} [aPIXdentDTOEZdravieExtRequestResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtUpdaterequestPost: async (aPIXdentDTOEZdravieExtRequestResultDto?: APIXdentDTOEZdravieExtRequestResultDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ezdravie/ext/updaterequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOEZdravieExtRequestResultDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EZdravieEXTApi - functional programming interface
 * @export
 */
export const EZdravieEXTApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EZdravieEXTApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIXdentDTOEZdravieExtLoginDto} [aPIXdentDTOEZdravieExtLoginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtAuthPost(aPIXdentDTOEZdravieExtLoginDto?: APIXdentDTOEZdravieExtLoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtAuthPost(aPIXdentDTOEZdravieExtLoginDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtAuthPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Enqueques request
         * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtEnqueueezdravierequestPost(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtEnqueueezdravierequestPost(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtEnqueueezdravierequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtGenerateidPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get local https certificate file
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtGetcertificateGet(code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtGetcertificateGet(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtGetcertificateGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets SK client by id
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtGetclientskbyidGet(clientId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieClientSkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtGetclientskbyidGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtGetclientskbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets insurance settings for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtGetinsurancesettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieInsuranceSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtGetinsurancesettingsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtGetinsurancesettingsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get next request from queue for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtGetnextrequestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieExtRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtGetnextrequestGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtGetnextrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get request from the queue by idRequest.
         * @param {string} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtGetrequestGet(idRequest?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOEZdravieExtRequestDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtGetrequestGet(idRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtGetrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get if next request exists in queue for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtHasnextrequestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtHasnextrequestGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtHasnextrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Logs request
         * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtLogezdravierequestPost(aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtLogezdravierequestPost(aPIXdentDTOEZdravieExtLogRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtLogezdravierequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Just a test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtTestGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtTestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Saves PreskripcnyZaznam to xdent
         * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtUlozpreskripcnyzaznamPost(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtUlozpreskripcnyzaznamPost(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtUlozpreskripcnyzaznamPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates request in DB, adds log.
         * @param {APIXdentDTOEZdravieExtRequestResultDto} [aPIXdentDTOEZdravieExtRequestResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEzdravieExtUpdaterequestPost(aPIXdentDTOEZdravieExtRequestResultDto?: APIXdentDTOEZdravieExtRequestResultDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEzdravieExtUpdaterequestPost(aPIXdentDTOEZdravieExtRequestResultDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EZdravieEXTApi.apiEzdravieExtUpdaterequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EZdravieEXTApi - factory interface
 * @export
 */
export const EZdravieEXTApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EZdravieEXTApiFp(configuration)
    return {
        /**
         * 
         * @param {APIXdentDTOEZdravieExtLoginDto} [aPIXdentDTOEZdravieExtLoginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtAuthPost(aPIXdentDTOEZdravieExtLoginDto?: APIXdentDTOEZdravieExtLoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiEzdravieExtAuthPost(aPIXdentDTOEZdravieExtLoginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enqueques request
         * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtEnqueueezdravierequestPost(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieExtEnqueueezdravierequestPost(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates id for ezdravie medical report
         * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieExtGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get local https certificate file
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetcertificateGet(code?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieExtGetcertificateGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets SK client by id
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetclientskbyidGet(clientId?: string, options?: any): AxiosPromise<APIXdentDTOEZdravieClientSkDto> {
            return localVarFp.apiEzdravieExtGetclientskbyidGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets insurance settings for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetinsurancesettingsGet(options?: any): AxiosPromise<APIXdentDTOEZdravieInsuranceSettingsDto> {
            return localVarFp.apiEzdravieExtGetinsurancesettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get next request from queue for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetnextrequestGet(options?: any): AxiosPromise<APIXdentDTOEZdravieExtRequestDto> {
            return localVarFp.apiEzdravieExtGetnextrequestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get request from the queue by idRequest.
         * @param {string} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtGetrequestGet(idRequest?: string, options?: any): AxiosPromise<APIXdentDTOEZdravieExtRequestDto> {
            return localVarFp.apiEzdravieExtGetrequestGet(idRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get if next request exists in queue for current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtHasnextrequestGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiEzdravieExtHasnextrequestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs request
         * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtLogezdravierequestPost(aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieExtLogezdravierequestPost(aPIXdentDTOEZdravieExtLogRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Just a test
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtTestGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieExtTestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves PreskripcnyZaznam to xdent
         * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtUlozpreskripcnyzaznamPost(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiEzdravieExtUlozpreskripcnyzaznamPost(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates request in DB, adds log.
         * @param {APIXdentDTOEZdravieExtRequestResultDto} [aPIXdentDTOEZdravieExtRequestResultDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEzdravieExtUpdaterequestPost(aPIXdentDTOEZdravieExtRequestResultDto?: APIXdentDTOEZdravieExtRequestResultDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiEzdravieExtUpdaterequestPost(aPIXdentDTOEZdravieExtRequestResultDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EZdravieEXTApi - object-oriented interface
 * @export
 * @class EZdravieEXTApi
 * @extends {BaseAPI}
 */
export class EZdravieEXTApi extends BaseAPI {
    /**
     * 
     * @param {APIXdentDTOEZdravieExtLoginDto} [aPIXdentDTOEZdravieExtLoginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtAuthPost(aPIXdentDTOEZdravieExtLoginDto?: APIXdentDTOEZdravieExtLoginDto, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtAuthPost(aPIXdentDTOEZdravieExtLoginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enqueques request
     * @param {APIXdentDTOEZdravieExtEnquequeRequestQueueDto} [aPIXdentDTOEZdravieExtEnquequeRequestQueueDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtEnqueueezdravierequestPost(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto?: APIXdentDTOEZdravieExtEnquequeRequestQueueDto, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtEnqueueezdravierequestPost(aPIXdentDTOEZdravieExtEnquequeRequestQueueDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates id for ezdravie medical report
     * @param {APIXdentDTOEZdravieGenerateEZdravieIdDto} [aPIXdentDTOEZdravieGenerateEZdravieIdDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto?: APIXdentDTOEZdravieGenerateEZdravieIdDto, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtGenerateidPost(aPIXdentDTOEZdravieGenerateEZdravieIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get local https certificate file
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtGetcertificateGet(code?: string, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtGetcertificateGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets SK client by id
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtGetclientskbyidGet(clientId?: string, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtGetclientskbyidGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets insurance settings for logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtGetinsurancesettingsGet(options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtGetinsurancesettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get next request from queue for current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtGetnextrequestGet(options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtGetnextrequestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get request from the queue by idRequest.
     * @param {string} [idRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtGetrequestGet(idRequest?: string, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtGetrequestGet(idRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get if next request exists in queue for current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtHasnextrequestGet(options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtHasnextrequestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs request
     * @param {APIXdentDTOEZdravieExtLogRequestDto} [aPIXdentDTOEZdravieExtLogRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtLogezdravierequestPost(aPIXdentDTOEZdravieExtLogRequestDto?: APIXdentDTOEZdravieExtLogRequestDto, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtLogezdravierequestPost(aPIXdentDTOEZdravieExtLogRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Just a test
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtTestGet(options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtTestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves PreskripcnyZaznam to xdent
     * @param {APIXdentDTOEZdravieUlozPreskripcnyZaznamDto} [aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtUlozpreskripcnyzaznamPost(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto?: APIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtUlozpreskripcnyzaznamPost(aPIXdentDTOEZdravieUlozPreskripcnyZaznamDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates request in DB, adds log.
     * @param {APIXdentDTOEZdravieExtRequestResultDto} [aPIXdentDTOEZdravieExtRequestResultDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EZdravieEXTApi
     */
    public apiEzdravieExtUpdaterequestPost(aPIXdentDTOEZdravieExtRequestResultDto?: APIXdentDTOEZdravieExtRequestResultDto, options?: AxiosRequestConfig) {
        return EZdravieEXTApiFp(this.configuration).apiEzdravieExtUpdaterequestPost(aPIXdentDTOEZdravieExtRequestResultDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all employees for user (company).  Staffs have calendars, employees don\'t have to.  Every staff is also employee but not every employee is staff.
         * @param {Array<string>} [roles] Optional roles filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmployeeGetemployeesbyuserGet: async (roles?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/employee/getemployeesbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all employees for user (company).  Staffs have calendars, employees don\'t have to.  Every staff is also employee but not every employee is staff.
         * @param {Array<string>} [roles] Optional roles filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmployeeGetemployeesbyuserGet(roles?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmployeeGetemployeesbyuserGet(roles, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['EmployeeApi.apiEmployeeGetemployeesbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all employees for user (company).  Staffs have calendars, employees don\'t have to.  Every staff is also employee but not every employee is staff.
         * @param {Array<string>} [roles] Optional roles filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmployeeGetemployeesbyuserGet(roles?: Array<string>, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedEMPLOYEEGRIDSIMPLEResult>> {
            return localVarFp.apiEmployeeGetemployeesbyuserGet(roles, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * 
     * @summary Gets all employees for user (company).  Staffs have calendars, employees don\'t have to.  Every staff is also employee but not every employee is staff.
     * @param {Array<string>} [roles] Optional roles filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public apiEmployeeGetemployeesbyuserGet(roles?: Array<string>, options?: AxiosRequestConfig) {
        return EmployeeApiFp(this.configuration).apiEmployeeGetemployeesbyuserGet(roles, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormApi - axios parameter creator
 * @export
 */
export const FormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {boolean} [isFavouriteOnly] 
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGetdefaultformsbyclientGet: async (idClient?: string | null, isFavouriteOnly?: boolean, published?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/form/getdefaultformsbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }

            if (isFavouriteOnly !== undefined) {
                localVarQueryParameter['isFavouriteOnly'] = isFavouriteOnly;
            }

            if (published !== undefined) {
                localVarQueryParameter['published'] = published;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGetpopulatedformsbyclientGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/form/getpopulatedformsbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets signed forms for client.
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGetsignedformsGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/form/getsignedforms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGettreatmentplansbyclientGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/form/gettreatmentplansbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormApi - functional programming interface
 * @export
 */
export const FormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {boolean} [isFavouriteOnly] 
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormGetdefaultformsbyclientGet(idClient?: string | null, isFavouriteOnly?: boolean, published?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedXdentFormDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormGetdefaultformsbyclientGet(idClient, isFavouriteOnly, published, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormApi.apiFormGetdefaultformsbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormGetpopulatedformsbyclientGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedXdentFormDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormGetpopulatedformsbyclientGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormApi.apiFormGetpopulatedformsbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets signed forms for client.
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormGetsignedformsGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormGetsignedformsGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormApi.apiFormGetsignedformsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormGettreatmentplansbyclientGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedTreatmentPlanDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormGettreatmentplansbyclientGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormApi.apiFormGettreatmentplansbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FormApi - factory interface
 * @export
 */
export const FormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {boolean} [isFavouriteOnly] 
         * @param {boolean} [published] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGetdefaultformsbyclientGet(idClient?: string | null, isFavouriteOnly?: boolean, published?: boolean, options?: any): AxiosPromise<Array<APISharedXdentFormDto>> {
            return localVarFp.apiFormGetdefaultformsbyclientGet(idClient, isFavouriteOnly, published, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGetpopulatedformsbyclientGet(idClient?: string | null, options?: any): AxiosPromise<Array<APISharedXdentFormDto>> {
            return localVarFp.apiFormGetpopulatedformsbyclientGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets signed forms for client.
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGetsignedformsGet(idClient?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDResult>> {
            return localVarFp.apiFormGetsignedformsGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormGettreatmentplansbyclientGet(idClient?: string | null, options?: any): AxiosPromise<Array<APISharedTreatmentPlanDto>> {
            return localVarFp.apiFormGettreatmentplansbyclientGet(idClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormApi - object-oriented interface
 * @export
 * @class FormApi
 * @extends {BaseAPI}
 */
export class FormApi extends BaseAPI {
    /**
     * 
     * @summary Gets all available forms by location based od provided idClient
     * @param {string | null} [idClient] 
     * @param {boolean} [isFavouriteOnly] 
     * @param {boolean} [published] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public apiFormGetdefaultformsbyclientGet(idClient?: string | null, isFavouriteOnly?: boolean, published?: boolean, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).apiFormGetdefaultformsbyclientGet(idClient, isFavouriteOnly, published, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all available forms by location based od provided idClient
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public apiFormGetpopulatedformsbyclientGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).apiFormGetpopulatedformsbyclientGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets signed forms for client.
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public apiFormGetsignedformsGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).apiFormGetsignedformsGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all available forms by location based od provided idClient
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormApi
     */
    public apiFormGettreatmentplansbyclientGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return FormApiFp(this.configuration).apiFormGettreatmentplansbyclientGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FormRequestApi - axios parameter creator
 * @export
 */
export const FormRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Accepts form request
         * @param {APIXdentControllersXdentFormRequestActionDto} [aPIXdentControllersXdentFormRequestActionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestAcceptPost: async (aPIXdentControllersXdentFormRequestActionDto?: APIXdentControllersXdentFormRequestActionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/formrequest/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentControllersXdentFormRequestActionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestDocumentrequestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/formrequest/documentrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get form requests
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestFormrequestsGet: async (idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/formrequest/formrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (statusCode !== undefined) {
                localVarQueryParameter['statusCode'] = statusCode;
            }

            if (includeDocumentFile !== undefined) {
                localVarQueryParameter['includeDocumentFile'] = includeDocumentFile;
            }

            if (stDate !== undefined) {
                localVarQueryParameter['stDate'] = stDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (stExpDate !== undefined) {
                localVarQueryParameter['stExpDate'] = stExpDate;
            }

            if (endExpDate !== undefined) {
                localVarQueryParameter['endExpDate'] = endExpDate;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get form requests (bad webmethod, but it\'s what the client expects maybe)
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestGetformrequestsgridPost: async (idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/formrequest/getformrequestsgrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (statusCode !== undefined) {
                localVarQueryParameter['statusCode'] = statusCode;
            }

            if (includeDocumentFile !== undefined) {
                localVarQueryParameter['includeDocumentFile'] = includeDocumentFile;
            }

            if (stDate !== undefined) {
                localVarQueryParameter['stDate'] = stDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (stExpDate !== undefined) {
                localVarQueryParameter['stExpDate'] = stExpDate;
            }

            if (endExpDate !== undefined) {
                localVarQueryParameter['endExpDate'] = endExpDate;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rejects form request
         * @param {string | null} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestRejectDelete: async (idRequest?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/formrequest/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idRequest !== undefined) {
                localVarQueryParameter['idRequest'] = idRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FormRequestApi - functional programming interface
 * @export
 */
export const FormRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Accepts form request
         * @param {APIXdentControllersXdentFormRequestActionDto} [aPIXdentControllersXdentFormRequestActionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormrequestAcceptPost(aPIXdentControllersXdentFormRequestActionDto?: APIXdentControllersXdentFormRequestActionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormrequestAcceptPost(aPIXdentControllersXdentFormRequestActionDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormRequestApi.apiFormrequestAcceptPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get document requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormrequestDocumentrequestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormrequestDocumentrequestsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormRequestApi.apiFormrequestDocumentrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get form requests
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormrequestFormrequestsGet(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormrequestFormrequestsGet(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormRequestApi.apiFormrequestFormrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get form requests (bad webmethod, but it\'s what the client expects maybe)
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormrequestGetformrequestsgridPost(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormrequestGetformrequestsgridPost(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormRequestApi.apiFormrequestGetformrequestsgridPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Rejects form request
         * @param {string | null} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormrequestRejectDelete(idRequest?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormrequestRejectDelete(idRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FormRequestApi.apiFormrequestRejectDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FormRequestApi - factory interface
 * @export
 */
export const FormRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Accepts form request
         * @param {APIXdentControllersXdentFormRequestActionDto} [aPIXdentControllersXdentFormRequestActionDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestAcceptPost(aPIXdentControllersXdentFormRequestActionDto?: APIXdentControllersXdentFormRequestActionDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiFormrequestAcceptPost(aPIXdentControllersXdentFormRequestActionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestDocumentrequestsGet(options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedDOCUMENTREQUESTGRIDResult>> {
            return localVarFp.apiFormrequestDocumentrequestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get form requests
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestFormrequestsGet(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult>> {
            return localVarFp.apiFormrequestFormrequestsGet(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get form requests (bad webmethod, but it\'s what the client expects maybe)
         * @param {string | null} [idRequest] 
         * @param {string | null} [idStaff] 
         * @param {string} [statusCode] 
         * @param {boolean} [includeDocumentFile] 
         * @param {string} [stDate] 
         * @param {string} [endDate] 
         * @param {string} [stExpDate] 
         * @param {string} [endExpDate] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestGetformrequestsgridPost(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedFORMREQUESTGRIDSTATISTICSResult>> {
            return localVarFp.apiFormrequestGetformrequestsgridPost(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rejects form request
         * @param {string | null} [idRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormrequestRejectDelete(idRequest?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiFormrequestRejectDelete(idRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormRequestApi - object-oriented interface
 * @export
 * @class FormRequestApi
 * @extends {BaseAPI}
 */
export class FormRequestApi extends BaseAPI {
    /**
     * 
     * @summary Accepts form request
     * @param {APIXdentControllersXdentFormRequestActionDto} [aPIXdentControllersXdentFormRequestActionDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormRequestApi
     */
    public apiFormrequestAcceptPost(aPIXdentControllersXdentFormRequestActionDto?: APIXdentControllersXdentFormRequestActionDto, options?: AxiosRequestConfig) {
        return FormRequestApiFp(this.configuration).apiFormrequestAcceptPost(aPIXdentControllersXdentFormRequestActionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormRequestApi
     */
    public apiFormrequestDocumentrequestsGet(options?: AxiosRequestConfig) {
        return FormRequestApiFp(this.configuration).apiFormrequestDocumentrequestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get form requests
     * @param {string | null} [idRequest] 
     * @param {string | null} [idStaff] 
     * @param {string} [statusCode] 
     * @param {boolean} [includeDocumentFile] 
     * @param {string} [stDate] 
     * @param {string} [endDate] 
     * @param {string} [stExpDate] 
     * @param {string} [endExpDate] 
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormRequestApi
     */
    public apiFormrequestFormrequestsGet(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: AxiosRequestConfig) {
        return FormRequestApiFp(this.configuration).apiFormrequestFormrequestsGet(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get form requests (bad webmethod, but it\'s what the client expects maybe)
     * @param {string | null} [idRequest] 
     * @param {string | null} [idStaff] 
     * @param {string} [statusCode] 
     * @param {boolean} [includeDocumentFile] 
     * @param {string} [stDate] 
     * @param {string} [endDate] 
     * @param {string} [stExpDate] 
     * @param {string} [endExpDate] 
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormRequestApi
     */
    public apiFormrequestGetformrequestsgridPost(idRequest?: string | null, idStaff?: string | null, statusCode?: string, includeDocumentFile?: boolean, stDate?: string, endDate?: string, stExpDate?: string, endExpDate?: string, idLocation?: string, options?: AxiosRequestConfig) {
        return FormRequestApiFp(this.configuration).apiFormrequestGetformrequestsgridPost(idRequest, idStaff, statusCode, includeDocumentFile, stDate, endDate, stExpDate, endExpDate, idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rejects form request
     * @param {string | null} [idRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormRequestApi
     */
    public apiFormrequestRejectDelete(idRequest?: string | null, options?: AxiosRequestConfig) {
        return FormRequestApiFp(this.configuration).apiFormrequestRejectDelete(idRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GalleryApi - axios parameter creator
 * @export
 */
export const GalleryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes file by id for owner user.
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryDeletefileDelete: async (idFile?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/deletefile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits file in client gallery, limit per request is 50mb. (Will have to be shared again to iklient)
         * @param {string | null} [idExistingFile] 
         * @param {File} [file] 
         * @param {ApiGalleryEditfilePutGalleryTypeEnum} [galleryType] 0 - General, 1 - MedicalReport, 2 - SignedDocumentation, 3 - HealthPlan, -1 - MedicalReportDetail
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryEditfilePut: async (idExistingFile?: string | null, file?: File, galleryType?: ApiGalleryEditfilePutGalleryTypeEnum, idMaster?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/editfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (idExistingFile !== undefined) { 
                localVarFormParams.append('idExistingFile', idExistingFile as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (galleryType !== undefined) { 
                localVarFormParams.append('galleryType', galleryType as any);
            }
    
            if (idMaster !== undefined) { 
                localVarFormParams.append('idMaster', idMaster as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits file metadata
         * @param {APIXdentDTOGalleryEditGalleryFileMetadataDto} [aPIXdentDTOGalleryEditGalleryFileMetadataDto] IdExistingFile is for file replacement; IdMaster is IdGallery, only used for new file upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryEditfilemetadataPut: async (aPIXdentDTOGalleryEditGalleryFileMetadataDto?: APIXdentDTOGalleryEditGalleryFileMetadataDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/editfilemetadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOGalleryEditGalleryFileMetadataDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all possible company tags for file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetallfiletagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getallfiletags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client gallery based on galleryType
         * @param {string | null} [idMaster] Client that owns the gallery
         * @param {string | null} [idLastFile] Acts as start index in gallery
         * @param {number | null} [pageSize] Files count to fetch
         * @param {ApiGalleryGetclientgalleryGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
         * @param {string} [thumbSize] Size of the thumbnail (if thumbnail type is NOT None)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetclientgalleryGet: async (idMaster?: string | null, idLastFile?: string | null, pageSize?: number | null, galleryType?: ApiGalleryGetclientgalleryGetGalleryTypeEnum, thumbnailAsType?: ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum, thumbSize?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getclientgallery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMaster !== undefined) {
                localVarQueryParameter['idMaster'] = idMaster;
            }

            if (idLastFile !== undefined) {
                localVarQueryParameter['idLastFile'] = idLastFile;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (galleryType !== undefined) {
                localVarQueryParameter['galleryType'] = galleryType;
            }

            if (thumbnailAsType !== undefined) {
                localVarQueryParameter['thumbnailAsType'] = thumbnailAsType;
            }

            if (thumbSize !== undefined) {
                localVarQueryParameter['thumbSize'] = thumbSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client gallery based on galleryType
         * @param {string | null} [idMaster] Client that owns the gallery
         * @param {Array<string>} [idGalleries] Id of wanted galleries, for General it stays empty
         * @param {ApiGalleryGetclientgallerytestGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetclientgallerytestGet: async (idMaster?: string | null, idGalleries?: Array<string>, galleryType?: ApiGalleryGetclientgallerytestGetGalleryTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getclientgallerytest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMaster !== undefined) {
                localVarQueryParameter['idMaster'] = idMaster;
            }

            if (idGalleries) {
                localVarQueryParameter['idGalleries'] = idGalleries;
            }

            if (galleryType !== undefined) {
                localVarQueryParameter['galleryType'] = galleryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {ApiGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfilebyidGet: async (idFile?: string | null, imageAsType?: ApiGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getfilebyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (imageAsType !== undefined) {
                localVarQueryParameter['imageAsType'] = imageAsType;
            }

            if (extendedBaseModel !== undefined) {
                localVarQueryParameter['extendedBaseModel'] = extendedBaseModel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets file info by id
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfileinfobyidGet: async (idFile?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getfileinfobyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets files grid
         * @param {APIXdentDTOGalleryGetFilesGridDto} [aPIXdentDTOGalleryGetFilesGridDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfilesgridPost: async (aPIXdentDTOGalleryGetFilesGridDto?: APIXdentDTOGalleryGetFilesGridDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getfilesgrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOGalleryGetFilesGridDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets already set tags for specific file.
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfiletagsGet: async (idFile?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getfiletags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns GalleryIds respective for each galleryType, General not included
         * @param {string} [idClient] 
         * @param {ApiGalleryGetgalleryidsGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetgalleryidsGet: async (idClient?: string, galleryType?: ApiGalleryGetgalleryidsGetGalleryTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getgalleryids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }

            if (galleryType !== undefined) {
                localVarQueryParameter['galleryType'] = galleryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetthumbnailbyidGet: async (idFile?: string | null, size?: string, thumbnailAsType?: ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/getthumbnailbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (thumbnailAsType !== undefined) {
                localVarQueryParameter['thumbnailAsType'] = thumbnailAsType;
            }

            if (extendedBaseModel !== undefined) {
                localVarQueryParameter['extendedBaseModel'] = extendedBaseModel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shares file to iklient, on behalf of user company.
         * @param {string | null} [idFile] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryIklientshareGet: async (idFile?: string | null, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/iklientshare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves the profile photo.
         * @param {APIXdentDTOGallerySaveProfilePhotoRequestDto} [aPIXdentDTOGallerySaveProfilePhotoRequestDto] The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGallerySaveprofilephotoPost: async (aPIXdentDTOGallerySaveProfilePhotoRequestDto?: APIXdentDTOGallerySaveProfilePhotoRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/saveprofilephoto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOGallerySaveProfilePhotoRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the color of the client badge.
         * @param {APIXdentDTOGalleryUpdateClientBadgeColorRequestDto} [aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto] The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryUpdateclientbadgecolorPost: async (aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto?: APIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/updateclientbadgecolor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads file into client gallery, limit per request is 50mb.
         * @param {File} [file] 
         * @param {ApiGalleryUploadfilePostGalleryTypeEnum} [galleryType] 
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryUploadfilePost: async (file?: File, galleryType?: ApiGalleryUploadfilePostGalleryTypeEnum, idMaster?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/uploadfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (galleryType !== undefined) { 
                localVarFormParams.append('galleryType', galleryType as any);
            }
    
            if (idMaster !== undefined) { 
                localVarFormParams.append('idMaster', idMaster as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads file into client gallery, limit per request is 50mb.  Does not call upload service, only returns passed iFile, use this to setup your api call.  Only validates passed model, if returns Ok, gallery will accept this file.
         * @param {File} [file] 
         * @param {ApiGalleryUploadfiletestPostGalleryTypeEnum} [galleryType] 
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryUploadfiletestPost: async (file?: File, galleryType?: ApiGalleryUploadfiletestPostGalleryTypeEnum, idMaster?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gallery/uploadfiletest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (galleryType !== undefined) { 
                localVarFormParams.append('galleryType', galleryType as any);
            }
    
            if (idMaster !== undefined) { 
                localVarFormParams.append('idMaster', idMaster as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string} deviceToken 
         * @param {string | null} [idFile] 
         * @param {ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesGalleryGetfilebyidGet: async (deviceToken: string, idFile?: string | null, imageAsType?: ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesGalleryGetfilebyidGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/gallery/getfilebyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (imageAsType !== undefined) {
                localVarQueryParameter['imageAsType'] = imageAsType;
            }

            if (extendedBaseModel !== undefined) {
                localVarQueryParameter['extendedBaseModel'] = extendedBaseModel;
            }

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string} deviceToken 
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesGalleryGetthumbnailbyidGet: async (deviceToken: string, idFile?: string | null, size?: string, thumbnailAsType?: ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesGalleryGetthumbnailbyidGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/gallery/getthumbnailbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (thumbnailAsType !== undefined) {
                localVarQueryParameter['thumbnailAsType'] = thumbnailAsType;
            }

            if (extendedBaseModel !== undefined) {
                localVarQueryParameter['extendedBaseModel'] = extendedBaseModel;
            }

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return pdf from gallery by id iklient form as stream. Signed by our safe signatures public cert.
         * @param {string} deviceToken 
         * @param {string | null} [idForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesGallerySafesignaturespdfGet: async (deviceToken: string, idForm?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesGallerySafesignaturespdfGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/gallery/safesignaturespdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idForm !== undefined) {
                localVarQueryParameter['idForm'] = idForm;
            }

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GalleryApi - functional programming interface
 * @export
 */
export const GalleryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GalleryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes file by id for owner user.
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryDeletefileDelete(idFile?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryDeletefileDelete(idFile, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryDeletefileDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits file in client gallery, limit per request is 50mb. (Will have to be shared again to iklient)
         * @param {string | null} [idExistingFile] 
         * @param {File} [file] 
         * @param {ApiGalleryEditfilePutGalleryTypeEnum} [galleryType] 0 - General, 1 - MedicalReport, 2 - SignedDocumentation, 3 - HealthPlan, -1 - MedicalReportDetail
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryEditfilePut(idExistingFile?: string | null, file?: File, galleryType?: ApiGalleryEditfilePutGalleryTypeEnum, idMaster?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOGalleryGalleryFilesGrid2WithContentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryEditfilePut(idExistingFile, file, galleryType, idMaster, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryEditfilePut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits file metadata
         * @param {APIXdentDTOGalleryEditGalleryFileMetadataDto} [aPIXdentDTOGalleryEditGalleryFileMetadataDto] IdExistingFile is for file replacement; IdMaster is IdGallery, only used for new file upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryEditfilemetadataPut(aPIXdentDTOGalleryEditGalleryFileMetadataDto?: APIXdentDTOGalleryEditGalleryFileMetadataDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryEditfilemetadataPut(aPIXdentDTOGalleryEditGalleryFileMetadataDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryEditfilemetadataPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all possible company tags for file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetallfiletagsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetallfiletagsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetallfiletagsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client gallery based on galleryType
         * @param {string | null} [idMaster] Client that owns the gallery
         * @param {string | null} [idLastFile] Acts as start index in gallery
         * @param {number | null} [pageSize] Files count to fetch
         * @param {ApiGalleryGetclientgalleryGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
         * @param {string} [thumbSize] Size of the thumbnail (if thumbnail type is NOT None)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetclientgalleryGet(idMaster?: string | null, idLastFile?: string | null, pageSize?: number | null, galleryType?: ApiGalleryGetclientgalleryGetGalleryTypeEnum, thumbnailAsType?: ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum, thumbSize?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOGalleryGroupedGalleryWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetclientgalleryGet(idMaster, idLastFile, pageSize, galleryType, thumbnailAsType, thumbSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetclientgalleryGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get client gallery based on galleryType
         * @param {string | null} [idMaster] Client that owns the gallery
         * @param {Array<string>} [idGalleries] Id of wanted galleries, for General it stays empty
         * @param {ApiGalleryGetclientgallerytestGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetclientgallerytestGet(idMaster?: string | null, idGalleries?: Array<string>, galleryType?: ApiGalleryGetclientgallerytestGetGalleryTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOGalleryGroupedGalleryWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetclientgallerytestGet(idMaster, idGalleries, galleryType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetclientgallerytestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {ApiGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetfilebyidGet(idFile?: string | null, imageAsType?: ApiGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedExtendedBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetfilebyidGet(idFile, imageAsType, extendedBaseModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetfilebyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets file info by id
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetfileinfobyidGet(idFile?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetfileinfobyidGet(idFile, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetfileinfobyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets files grid
         * @param {APIXdentDTOGalleryGetFilesGridDto} [aPIXdentDTOGalleryGetFilesGridDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetfilesgridPost(aPIXdentDTOGalleryGetFilesGridDto?: APIXdentDTOGalleryGetFilesGridDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetfilesgridPost(aPIXdentDTOGalleryGetFilesGridDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetfilesgridPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets already set tags for specific file.
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetfiletagsGet(idFile?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetfiletagsGet(idFile, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetfiletagsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns GalleryIds respective for each galleryType, General not included
         * @param {string} [idClient] 
         * @param {ApiGalleryGetgalleryidsGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetgalleryidsGet(idClient?: string, galleryType?: ApiGalleryGetgalleryidsGetGalleryTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetgalleryidsGet(idClient, galleryType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetgalleryidsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryGetthumbnailbyidGet(idFile?: string | null, size?: string, thumbnailAsType?: ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedExtendedBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryGetthumbnailbyidGet(idFile, size, thumbnailAsType, extendedBaseModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryGetthumbnailbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Shares file to iklient, on behalf of user company.
         * @param {string | null} [idFile] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryIklientshareGet(idFile?: string | null, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryIklientshareGet(idFile, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryIklientshareGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Saves the profile photo.
         * @param {APIXdentDTOGallerySaveProfilePhotoRequestDto} [aPIXdentDTOGallerySaveProfilePhotoRequestDto] The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGallerySaveprofilephotoPost(aPIXdentDTOGallerySaveProfilePhotoRequestDto?: APIXdentDTOGallerySaveProfilePhotoRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGallerySaveprofilephotoPost(aPIXdentDTOGallerySaveProfilePhotoRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGallerySaveprofilephotoPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the color of the client badge.
         * @param {APIXdentDTOGalleryUpdateClientBadgeColorRequestDto} [aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto] The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryUpdateclientbadgecolorPost(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto?: APIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryUpdateclientbadgecolorPost(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryUpdateclientbadgecolorPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Uploads file into client gallery, limit per request is 50mb.
         * @param {File} [file] 
         * @param {ApiGalleryUploadfilePostGalleryTypeEnum} [galleryType] 
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryUploadfilePost(file?: File, galleryType?: ApiGalleryUploadfilePostGalleryTypeEnum, idMaster?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOGalleryGalleryFilesGrid2WithContentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryUploadfilePost(file, galleryType, idMaster, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryUploadfilePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Uploads file into client gallery, limit per request is 50mb.  Does not call upload service, only returns passed iFile, use this to setup your api call.  Only validates passed model, if returns Ok, gallery will accept this file.
         * @param {File} [file] 
         * @param {ApiGalleryUploadfiletestPostGalleryTypeEnum} [galleryType] 
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGalleryUploadfiletestPost(file?: File, galleryType?: ApiGalleryUploadfiletestPostGalleryTypeEnum, idMaster?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedUploadGalleryFileInternal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGalleryUploadfiletestPost(file, galleryType, idMaster, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiGalleryUploadfiletestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string} deviceToken 
         * @param {string | null} [idFile] 
         * @param {ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesGalleryGetfilebyidGet(deviceToken: string, idFile?: string | null, imageAsType?: ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedExtendedBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesGalleryGetfilebyidGet(deviceToken, idFile, imageAsType, extendedBaseModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiSafesignaturesGalleryGetfilebyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string} deviceToken 
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesGalleryGetthumbnailbyidGet(deviceToken: string, idFile?: string | null, size?: string, thumbnailAsType?: ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedExtendedBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesGalleryGetthumbnailbyidGet(deviceToken, idFile, size, thumbnailAsType, extendedBaseModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiSafesignaturesGalleryGetthumbnailbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return pdf from gallery by id iklient form as stream. Signed by our safe signatures public cert.
         * @param {string} deviceToken 
         * @param {string | null} [idForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesGallerySafesignaturespdfGet(deviceToken: string, idForm?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesGallerySafesignaturespdfGet(deviceToken, idForm, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GalleryApi.apiSafesignaturesGallerySafesignaturespdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GalleryApi - factory interface
 * @export
 */
export const GalleryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GalleryApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes file by id for owner user.
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryDeletefileDelete(idFile?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiGalleryDeletefileDelete(idFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits file in client gallery, limit per request is 50mb. (Will have to be shared again to iklient)
         * @param {string | null} [idExistingFile] 
         * @param {File} [file] 
         * @param {ApiGalleryEditfilePutGalleryTypeEnum} [galleryType] 0 - General, 1 - MedicalReport, 2 - SignedDocumentation, 3 - HealthPlan, -1 - MedicalReportDetail
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryEditfilePut(idExistingFile?: string | null, file?: File, galleryType?: ApiGalleryEditfilePutGalleryTypeEnum, idMaster?: string | null, options?: any): AxiosPromise<APIXdentDTOGalleryGalleryFilesGrid2WithContentDto> {
            return localVarFp.apiGalleryEditfilePut(idExistingFile, file, galleryType, idMaster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits file metadata
         * @param {APIXdentDTOGalleryEditGalleryFileMetadataDto} [aPIXdentDTOGalleryEditGalleryFileMetadataDto] IdExistingFile is for file replacement; IdMaster is IdGallery, only used for new file upload.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryEditfilemetadataPut(aPIXdentDTOGalleryEditGalleryFileMetadataDto?: APIXdentDTOGalleryEditGalleryFileMetadataDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiGalleryEditfilemetadataPut(aPIXdentDTOGalleryEditGalleryFileMetadataDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all possible company tags for file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetallfiletagsGet(options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGGRIDResult>> {
            return localVarFp.apiGalleryGetallfiletagsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client gallery based on galleryType
         * @param {string | null} [idMaster] Client that owns the gallery
         * @param {string | null} [idLastFile] Acts as start index in gallery
         * @param {number | null} [pageSize] Files count to fetch
         * @param {ApiGalleryGetclientgalleryGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
         * @param {string} [thumbSize] Size of the thumbnail (if thumbnail type is NOT None)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetclientgalleryGet(idMaster?: string | null, idLastFile?: string | null, pageSize?: number | null, galleryType?: ApiGalleryGetclientgalleryGetGalleryTypeEnum, thumbnailAsType?: ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum, thumbSize?: string, options?: any): AxiosPromise<APIXdentDTOGalleryGroupedGalleryWrapperDto> {
            return localVarFp.apiGalleryGetclientgalleryGet(idMaster, idLastFile, pageSize, galleryType, thumbnailAsType, thumbSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client gallery based on galleryType
         * @param {string | null} [idMaster] Client that owns the gallery
         * @param {Array<string>} [idGalleries] Id of wanted galleries, for General it stays empty
         * @param {ApiGalleryGetclientgallerytestGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetclientgallerytestGet(idMaster?: string | null, idGalleries?: Array<string>, galleryType?: ApiGalleryGetclientgallerytestGetGalleryTypeEnum, options?: any): AxiosPromise<APIXdentDTOGalleryGroupedGalleryWrapperDto> {
            return localVarFp.apiGalleryGetclientgallerytestGet(idMaster, idGalleries, galleryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {ApiGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfilebyidGet(idFile?: string | null, imageAsType?: ApiGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: any): AxiosPromise<APISharedExtendedBaseModel> {
            return localVarFp.apiGalleryGetfilebyidGet(idFile, imageAsType, extendedBaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets file info by id
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfileinfobyidGet(idFile?: string | null, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result> {
            return localVarFp.apiGalleryGetfileinfobyidGet(idFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets files grid
         * @param {APIXdentDTOGalleryGetFilesGridDto} [aPIXdentDTOGalleryGetFilesGridDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfilesgridPost(aPIXdentDTOGalleryGetFilesGridDto?: APIXdentDTOGalleryGetFilesGridDto, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedGALLERYFILESGRID2Result>> {
            return localVarFp.apiGalleryGetfilesgridPost(aPIXdentDTOGalleryGetFilesGridDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets already set tags for specific file.
         * @param {string | null} [idFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetfiletagsGet(idFile?: string | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTAGENTITYGRIDResult>> {
            return localVarFp.apiGalleryGetfiletagsGet(idFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns GalleryIds respective for each galleryType, General not included
         * @param {string} [idClient] 
         * @param {ApiGalleryGetgalleryidsGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetgalleryidsGet(idClient?: string, galleryType?: ApiGalleryGetgalleryidsGetGalleryTypeEnum, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiGalleryGetgalleryidsGet(idClient, galleryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryGetthumbnailbyidGet(idFile?: string | null, size?: string, thumbnailAsType?: ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: any): AxiosPromise<APISharedExtendedBaseModel> {
            return localVarFp.apiGalleryGetthumbnailbyidGet(idFile, size, thumbnailAsType, extendedBaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shares file to iklient, on behalf of user company.
         * @param {string | null} [idFile] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryIklientshareGet(idFile?: string | null, idClient?: string | null, options?: any): AxiosPromise<string> {
            return localVarFp.apiGalleryIklientshareGet(idFile, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves the profile photo.
         * @param {APIXdentDTOGallerySaveProfilePhotoRequestDto} [aPIXdentDTOGallerySaveProfilePhotoRequestDto] The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGallerySaveprofilephotoPost(aPIXdentDTOGallerySaveProfilePhotoRequestDto?: APIXdentDTOGallerySaveProfilePhotoRequestDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiGallerySaveprofilephotoPost(aPIXdentDTOGallerySaveProfilePhotoRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the color of the client badge.
         * @param {APIXdentDTOGalleryUpdateClientBadgeColorRequestDto} [aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto] The request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryUpdateclientbadgecolorPost(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto?: APIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiGalleryUpdateclientbadgecolorPost(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads file into client gallery, limit per request is 50mb.
         * @param {File} [file] 
         * @param {ApiGalleryUploadfilePostGalleryTypeEnum} [galleryType] 
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryUploadfilePost(file?: File, galleryType?: ApiGalleryUploadfilePostGalleryTypeEnum, idMaster?: string | null, options?: any): AxiosPromise<APIXdentDTOGalleryGalleryFilesGrid2WithContentDto> {
            return localVarFp.apiGalleryUploadfilePost(file, galleryType, idMaster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads file into client gallery, limit per request is 50mb.  Does not call upload service, only returns passed iFile, use this to setup your api call.  Only validates passed model, if returns Ok, gallery will accept this file.
         * @param {File} [file] 
         * @param {ApiGalleryUploadfiletestPostGalleryTypeEnum} [galleryType] 
         * @param {string | null} [idMaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGalleryUploadfiletestPost(file?: File, galleryType?: ApiGalleryUploadfiletestPostGalleryTypeEnum, idMaster?: string | null, options?: any): AxiosPromise<APISharedUploadGalleryFileInternal> {
            return localVarFp.apiGalleryUploadfiletestPost(file, galleryType, idMaster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string} deviceToken 
         * @param {string | null} [idFile] 
         * @param {ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesGalleryGetfilebyidGet(deviceToken: string, idFile?: string | null, imageAsType?: ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: any): AxiosPromise<APISharedExtendedBaseModel> {
            return localVarFp.apiSafesignaturesGalleryGetfilebyidGet(deviceToken, idFile, imageAsType, extendedBaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string} deviceToken 
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesGalleryGetthumbnailbyidGet(deviceToken: string, idFile?: string | null, size?: string, thumbnailAsType?: ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: any): AxiosPromise<APISharedExtendedBaseModel> {
            return localVarFp.apiSafesignaturesGalleryGetthumbnailbyidGet(deviceToken, idFile, size, thumbnailAsType, extendedBaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return pdf from gallery by id iklient form as stream. Signed by our safe signatures public cert.
         * @param {string} deviceToken 
         * @param {string | null} [idForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesGallerySafesignaturespdfGet(deviceToken: string, idForm?: string | null, options?: any): AxiosPromise<string> {
            return localVarFp.apiSafesignaturesGallerySafesignaturespdfGet(deviceToken, idForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GalleryApi - object-oriented interface
 * @export
 * @class GalleryApi
 * @extends {BaseAPI}
 */
export class GalleryApi extends BaseAPI {
    /**
     * 
     * @summary Deletes file by id for owner user.
     * @param {string | null} [idFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryDeletefileDelete(idFile?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryDeletefileDelete(idFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits file in client gallery, limit per request is 50mb. (Will have to be shared again to iklient)
     * @param {string | null} [idExistingFile] 
     * @param {File} [file] 
     * @param {ApiGalleryEditfilePutGalleryTypeEnum} [galleryType] 0 - General, 1 - MedicalReport, 2 - SignedDocumentation, 3 - HealthPlan, -1 - MedicalReportDetail
     * @param {string | null} [idMaster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryEditfilePut(idExistingFile?: string | null, file?: File, galleryType?: ApiGalleryEditfilePutGalleryTypeEnum, idMaster?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryEditfilePut(idExistingFile, file, galleryType, idMaster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits file metadata
     * @param {APIXdentDTOGalleryEditGalleryFileMetadataDto} [aPIXdentDTOGalleryEditGalleryFileMetadataDto] IdExistingFile is for file replacement; IdMaster is IdGallery, only used for new file upload.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryEditfilemetadataPut(aPIXdentDTOGalleryEditGalleryFileMetadataDto?: APIXdentDTOGalleryEditGalleryFileMetadataDto, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryEditfilemetadataPut(aPIXdentDTOGalleryEditGalleryFileMetadataDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all possible company tags for file.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetallfiletagsGet(options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetallfiletagsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client gallery based on galleryType
     * @param {string | null} [idMaster] Client that owns the gallery
     * @param {string | null} [idLastFile] Acts as start index in gallery
     * @param {number | null} [pageSize] Files count to fetch
     * @param {ApiGalleryGetclientgalleryGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
     * @param {ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
     * @param {string} [thumbSize] Size of the thumbnail (if thumbnail type is NOT None)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetclientgalleryGet(idMaster?: string | null, idLastFile?: string | null, pageSize?: number | null, galleryType?: ApiGalleryGetclientgalleryGetGalleryTypeEnum, thumbnailAsType?: ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum, thumbSize?: string, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetclientgalleryGet(idMaster, idLastFile, pageSize, galleryType, thumbnailAsType, thumbSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client gallery based on galleryType
     * @param {string | null} [idMaster] Client that owns the gallery
     * @param {Array<string>} [idGalleries] Id of wanted galleries, for General it stays empty
     * @param {ApiGalleryGetclientgallerytestGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetclientgallerytestGet(idMaster?: string | null, idGalleries?: Array<string>, galleryType?: ApiGalleryGetclientgallerytestGetGalleryTypeEnum, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetclientgallerytestGet(idMaster, idGalleries, galleryType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return image from gallery by id as provided type
     * @param {string | null} [idFile] 
     * @param {ApiGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {boolean} [extendedBaseModel] Return extended base64 model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetfilebyidGet(idFile?: string | null, imageAsType?: ApiGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetfilebyidGet(idFile, imageAsType, extendedBaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets file info by id
     * @param {string | null} [idFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetfileinfobyidGet(idFile?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetfileinfobyidGet(idFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets files grid
     * @param {APIXdentDTOGalleryGetFilesGridDto} [aPIXdentDTOGalleryGetFilesGridDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetfilesgridPost(aPIXdentDTOGalleryGetFilesGridDto?: APIXdentDTOGalleryGetFilesGridDto, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetfilesgridPost(aPIXdentDTOGalleryGetFilesGridDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets already set tags for specific file.
     * @param {string | null} [idFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetfiletagsGet(idFile?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetfiletagsGet(idFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns GalleryIds respective for each galleryType, General not included
     * @param {string} [idClient] 
     * @param {ApiGalleryGetgalleryidsGetGalleryTypeEnum} [galleryType] 0 &#x3D; General,1 &#x3D; MedicalReport, 2 &#x3D; SignedDocs, 3 &#x3D; HealthPlan, (-1) Medical Report Detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetgalleryidsGet(idClient?: string, galleryType?: ApiGalleryGetgalleryidsGetGalleryTypeEnum, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetgalleryidsGet(idClient, galleryType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return thumbnail from gallery by id as provided type
     * @param {string | null} [idFile] 
     * @param {string} [size] 
     * @param {ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {boolean} [extendedBaseModel] Returns extended base model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryGetthumbnailbyidGet(idFile?: string | null, size?: string, thumbnailAsType?: ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryGetthumbnailbyidGet(idFile, size, thumbnailAsType, extendedBaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shares file to iklient, on behalf of user company.
     * @param {string | null} [idFile] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryIklientshareGet(idFile?: string | null, idClient?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryIklientshareGet(idFile, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves the profile photo.
     * @param {APIXdentDTOGallerySaveProfilePhotoRequestDto} [aPIXdentDTOGallerySaveProfilePhotoRequestDto] The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGallerySaveprofilephotoPost(aPIXdentDTOGallerySaveProfilePhotoRequestDto?: APIXdentDTOGallerySaveProfilePhotoRequestDto, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGallerySaveprofilephotoPost(aPIXdentDTOGallerySaveProfilePhotoRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the color of the client badge.
     * @param {APIXdentDTOGalleryUpdateClientBadgeColorRequestDto} [aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto] The request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryUpdateclientbadgecolorPost(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto?: APIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryUpdateclientbadgecolorPost(aPIXdentDTOGalleryUpdateClientBadgeColorRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads file into client gallery, limit per request is 50mb.
     * @param {File} [file] 
     * @param {ApiGalleryUploadfilePostGalleryTypeEnum} [galleryType] 
     * @param {string | null} [idMaster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryUploadfilePost(file?: File, galleryType?: ApiGalleryUploadfilePostGalleryTypeEnum, idMaster?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryUploadfilePost(file, galleryType, idMaster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads file into client gallery, limit per request is 50mb.  Does not call upload service, only returns passed iFile, use this to setup your api call.  Only validates passed model, if returns Ok, gallery will accept this file.
     * @param {File} [file] 
     * @param {ApiGalleryUploadfiletestPostGalleryTypeEnum} [galleryType] 
     * @param {string | null} [idMaster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiGalleryUploadfiletestPost(file?: File, galleryType?: ApiGalleryUploadfiletestPostGalleryTypeEnum, idMaster?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiGalleryUploadfiletestPost(file, galleryType, idMaster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return image from gallery by id as provided type
     * @param {string} deviceToken 
     * @param {string | null} [idFile] 
     * @param {ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {boolean} [extendedBaseModel] Return extended base64 model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiSafesignaturesGalleryGetfilebyidGet(deviceToken: string, idFile?: string | null, imageAsType?: ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiSafesignaturesGalleryGetfilebyidGet(deviceToken, idFile, imageAsType, extendedBaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return thumbnail from gallery by id as provided type
     * @param {string} deviceToken 
     * @param {string | null} [idFile] 
     * @param {string} [size] 
     * @param {ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {boolean} [extendedBaseModel] Returns extended base model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiSafesignaturesGalleryGetthumbnailbyidGet(deviceToken: string, idFile?: string | null, size?: string, thumbnailAsType?: ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiSafesignaturesGalleryGetthumbnailbyidGet(deviceToken, idFile, size, thumbnailAsType, extendedBaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return pdf from gallery by id iklient form as stream. Signed by our safe signatures public cert.
     * @param {string} deviceToken 
     * @param {string | null} [idForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GalleryApi
     */
    public apiSafesignaturesGallerySafesignaturespdfGet(deviceToken: string, idForm?: string | null, options?: AxiosRequestConfig) {
        return GalleryApiFp(this.configuration).apiSafesignaturesGallerySafesignaturespdfGet(deviceToken, idForm, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiGalleryEditfilePutGalleryTypeEnum = {
    General: 'General',
    MedicalReport: 'MedicalReport',
    SignedDocumentation: 'SignedDocumentation',
    HealthPlan: 'HealthPlan',
    MedicalReportDetail: 'MedicalReportDetail'
} as const;
export type ApiGalleryEditfilePutGalleryTypeEnum = typeof ApiGalleryEditfilePutGalleryTypeEnum[keyof typeof ApiGalleryEditfilePutGalleryTypeEnum];
/**
 * @export
 */
export const ApiGalleryGetclientgalleryGetGalleryTypeEnum = {
    General: 'General',
    MedicalReport: 'MedicalReport',
    SignedDocumentation: 'SignedDocumentation',
    HealthPlan: 'HealthPlan',
    MedicalReportDetail: 'MedicalReportDetail'
} as const;
export type ApiGalleryGetclientgalleryGetGalleryTypeEnum = typeof ApiGalleryGetclientgalleryGetGalleryTypeEnum[keyof typeof ApiGalleryGetclientgalleryGetGalleryTypeEnum];
/**
 * @export
 */
export const ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum = typeof ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum[keyof typeof ApiGalleryGetclientgalleryGetThumbnailAsTypeEnum];
/**
 * @export
 */
export const ApiGalleryGetclientgallerytestGetGalleryTypeEnum = {
    General: 'General',
    MedicalReport: 'MedicalReport',
    SignedDocumentation: 'SignedDocumentation',
    HealthPlan: 'HealthPlan',
    MedicalReportDetail: 'MedicalReportDetail'
} as const;
export type ApiGalleryGetclientgallerytestGetGalleryTypeEnum = typeof ApiGalleryGetclientgallerytestGetGalleryTypeEnum[keyof typeof ApiGalleryGetclientgallerytestGetGalleryTypeEnum];
/**
 * @export
 */
export const ApiGalleryGetfilebyidGetImageAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiGalleryGetfilebyidGetImageAsTypeEnum = typeof ApiGalleryGetfilebyidGetImageAsTypeEnum[keyof typeof ApiGalleryGetfilebyidGetImageAsTypeEnum];
/**
 * @export
 */
export const ApiGalleryGetgalleryidsGetGalleryTypeEnum = {
    General: 'General',
    MedicalReport: 'MedicalReport',
    SignedDocumentation: 'SignedDocumentation',
    HealthPlan: 'HealthPlan',
    MedicalReportDetail: 'MedicalReportDetail'
} as const;
export type ApiGalleryGetgalleryidsGetGalleryTypeEnum = typeof ApiGalleryGetgalleryidsGetGalleryTypeEnum[keyof typeof ApiGalleryGetgalleryidsGetGalleryTypeEnum];
/**
 * @export
 */
export const ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum = typeof ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum[keyof typeof ApiGalleryGetthumbnailbyidGetThumbnailAsTypeEnum];
/**
 * @export
 */
export const ApiGalleryUploadfilePostGalleryTypeEnum = {
    General: 'General',
    MedicalReport: 'MedicalReport',
    SignedDocumentation: 'SignedDocumentation',
    HealthPlan: 'HealthPlan',
    MedicalReportDetail: 'MedicalReportDetail'
} as const;
export type ApiGalleryUploadfilePostGalleryTypeEnum = typeof ApiGalleryUploadfilePostGalleryTypeEnum[keyof typeof ApiGalleryUploadfilePostGalleryTypeEnum];
/**
 * @export
 */
export const ApiGalleryUploadfiletestPostGalleryTypeEnum = {
    General: 'General',
    MedicalReport: 'MedicalReport',
    SignedDocumentation: 'SignedDocumentation',
    HealthPlan: 'HealthPlan',
    MedicalReportDetail: 'MedicalReportDetail'
} as const;
export type ApiGalleryUploadfiletestPostGalleryTypeEnum = typeof ApiGalleryUploadfiletestPostGalleryTypeEnum[keyof typeof ApiGalleryUploadfiletestPostGalleryTypeEnum];
/**
 * @export
 */
export const ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum = typeof ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum[keyof typeof ApiSafesignaturesGalleryGetfilebyidGetImageAsTypeEnum];
/**
 * @export
 */
export const ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum = typeof ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum[keyof typeof ApiSafesignaturesGalleryGetthumbnailbyidGetThumbnailAsTypeEnum];


/**
 * InsuranceApi - axios parameter creator
 * @export
 */
export const InsuranceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check insurance code add
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceRulesInsuranceCheckAddCode} [aPISharedInsuranceRulesInsuranceCheckAddCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceCheckaddcodePost: async (idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAddCode?: APISharedInsuranceRulesInsuranceCheckAddCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance/checkaddcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedInsuranceRulesInsuranceCheckAddCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check insurance code advisor
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceRulesInsuranceCheckAdviseCode} [aPISharedInsuranceRulesInsuranceCheckAdviseCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceCheckadvisecodePost: async (idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAdviseCode?: APISharedInsuranceRulesInsuranceCheckAdviseCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance/checkadvisecode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedInsuranceRulesInsuranceCheckAdviseCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns medical reports for unlocking by given client
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceInvoiceGetLockedReportsModel} [aPISharedInsuranceInvoiceGetLockedReportsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceGetclientmedicalreportlockedPost: async (idXdentUser?: string, aPISharedInsuranceInvoiceGetLockedReportsModel?: APISharedInsuranceInvoiceGetLockedReportsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance/getclientmedicalreportlocked`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedInsuranceInvoiceGetLockedReportsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate insurance invoice davky
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel} [aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceInsuranceinvoicegeneratePost: async (idXdentUser?: string, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel?: APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance/insuranceinvoicegenerate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlocks medical report
         * @param {string} [idMedicalReport] 
         * @param {string | null} [idStaff] 
         * @param {string} [idXdentUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceUnlockmedicalreportPost: async (idMedicalReport?: string, idStaff?: string | null, idXdentUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance/unlockmedicalreport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlocks medical report - batch action
         * @param {string | null} [idStaff] 
         * @param {string} [idXdentUser] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceUnlockmedicalreportsbatchPost: async (idStaff?: string | null, idXdentUser?: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insurance/unlockmedicalreportsbatch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceApi - functional programming interface
 * @export
 */
export const InsuranceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check insurance code add
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceRulesInsuranceCheckAddCode} [aPISharedInsuranceRulesInsuranceCheckAddCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceCheckaddcodePost(idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAddCode?: APISharedInsuranceRulesInsuranceCheckAddCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceCheckaddcodePost(idXdentUser, aPISharedInsuranceRulesInsuranceCheckAddCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceApi.apiInsuranceCheckaddcodePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Check insurance code advisor
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceRulesInsuranceCheckAdviseCode} [aPISharedInsuranceRulesInsuranceCheckAdviseCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceCheckadvisecodePost(idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAdviseCode?: APISharedInsuranceRulesInsuranceCheckAdviseCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceCheckadvisecodePost(idXdentUser, aPISharedInsuranceRulesInsuranceCheckAdviseCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceApi.apiInsuranceCheckadvisecodePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns medical reports for unlocking by given client
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceInvoiceGetLockedReportsModel} [aPISharedInsuranceInvoiceGetLockedReportsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceGetclientmedicalreportlockedPost(idXdentUser?: string, aPISharedInsuranceInvoiceGetLockedReportsModel?: APISharedInsuranceInvoiceGetLockedReportsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceGetclientmedicalreportlockedPost(idXdentUser, aPISharedInsuranceInvoiceGetLockedReportsModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceApi.apiInsuranceGetclientmedicalreportlockedPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate insurance invoice davky
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel} [aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceInsuranceinvoicegeneratePost(idXdentUser?: string, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel?: APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceInsuranceinvoicegeneratePost(idXdentUser, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceApi.apiInsuranceInsuranceinvoicegeneratePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Unlocks medical report
         * @param {string} [idMedicalReport] 
         * @param {string | null} [idStaff] 
         * @param {string} [idXdentUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceUnlockmedicalreportPost(idMedicalReport?: string, idStaff?: string | null, idXdentUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceUnlockmedicalreportPost(idMedicalReport, idStaff, idXdentUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceApi.apiInsuranceUnlockmedicalreportPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Unlocks medical report - batch action
         * @param {string | null} [idStaff] 
         * @param {string} [idXdentUser] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceUnlockmedicalreportsbatchPost(idStaff?: string | null, idXdentUser?: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceUnlockmedicalreportsbatchPost(idStaff, idXdentUser, requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceApi.apiInsuranceUnlockmedicalreportsbatchPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InsuranceApi - factory interface
 * @export
 */
export const InsuranceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceApiFp(configuration)
    return {
        /**
         * 
         * @summary Check insurance code add
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceRulesInsuranceCheckAddCode} [aPISharedInsuranceRulesInsuranceCheckAddCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceCheckaddcodePost(idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAddCode?: APISharedInsuranceRulesInsuranceCheckAddCode, options?: any): AxiosPromise<void> {
            return localVarFp.apiInsuranceCheckaddcodePost(idXdentUser, aPISharedInsuranceRulesInsuranceCheckAddCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check insurance code advisor
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceRulesInsuranceCheckAdviseCode} [aPISharedInsuranceRulesInsuranceCheckAdviseCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceCheckadvisecodePost(idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAdviseCode?: APISharedInsuranceRulesInsuranceCheckAdviseCode, options?: any): AxiosPromise<void> {
            return localVarFp.apiInsuranceCheckadvisecodePost(idXdentUser, aPISharedInsuranceRulesInsuranceCheckAdviseCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns medical reports for unlocking by given client
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceInvoiceGetLockedReportsModel} [aPISharedInsuranceInvoiceGetLockedReportsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceGetclientmedicalreportlockedPost(idXdentUser?: string, aPISharedInsuranceInvoiceGetLockedReportsModel?: APISharedInsuranceInvoiceGetLockedReportsModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiInsuranceGetclientmedicalreportlockedPost(idXdentUser, aPISharedInsuranceInvoiceGetLockedReportsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate insurance invoice davky
         * @param {string} [idXdentUser] 
         * @param {APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel} [aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceInsuranceinvoicegeneratePost(idXdentUser?: string, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel?: APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiInsuranceInsuranceinvoicegeneratePost(idXdentUser, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlocks medical report
         * @param {string} [idMedicalReport] 
         * @param {string | null} [idStaff] 
         * @param {string} [idXdentUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceUnlockmedicalreportPost(idMedicalReport?: string, idStaff?: string | null, idXdentUser?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiInsuranceUnlockmedicalreportPost(idMedicalReport, idStaff, idXdentUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlocks medical report - batch action
         * @param {string | null} [idStaff] 
         * @param {string} [idXdentUser] 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceUnlockmedicalreportsbatchPost(idStaff?: string | null, idXdentUser?: string, requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiInsuranceUnlockmedicalreportsbatchPost(idStaff, idXdentUser, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceApi - object-oriented interface
 * @export
 * @class InsuranceApi
 * @extends {BaseAPI}
 */
export class InsuranceApi extends BaseAPI {
    /**
     * 
     * @summary Check insurance code add
     * @param {string} [idXdentUser] 
     * @param {APISharedInsuranceRulesInsuranceCheckAddCode} [aPISharedInsuranceRulesInsuranceCheckAddCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceApi
     */
    public apiInsuranceCheckaddcodePost(idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAddCode?: APISharedInsuranceRulesInsuranceCheckAddCode, options?: AxiosRequestConfig) {
        return InsuranceApiFp(this.configuration).apiInsuranceCheckaddcodePost(idXdentUser, aPISharedInsuranceRulesInsuranceCheckAddCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check insurance code advisor
     * @param {string} [idXdentUser] 
     * @param {APISharedInsuranceRulesInsuranceCheckAdviseCode} [aPISharedInsuranceRulesInsuranceCheckAdviseCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceApi
     */
    public apiInsuranceCheckadvisecodePost(idXdentUser?: string, aPISharedInsuranceRulesInsuranceCheckAdviseCode?: APISharedInsuranceRulesInsuranceCheckAdviseCode, options?: AxiosRequestConfig) {
        return InsuranceApiFp(this.configuration).apiInsuranceCheckadvisecodePost(idXdentUser, aPISharedInsuranceRulesInsuranceCheckAdviseCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns medical reports for unlocking by given client
     * @param {string} [idXdentUser] 
     * @param {APISharedInsuranceInvoiceGetLockedReportsModel} [aPISharedInsuranceInvoiceGetLockedReportsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceApi
     */
    public apiInsuranceGetclientmedicalreportlockedPost(idXdentUser?: string, aPISharedInsuranceInvoiceGetLockedReportsModel?: APISharedInsuranceInvoiceGetLockedReportsModel, options?: AxiosRequestConfig) {
        return InsuranceApiFp(this.configuration).apiInsuranceGetclientmedicalreportlockedPost(idXdentUser, aPISharedInsuranceInvoiceGetLockedReportsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate insurance invoice davky
     * @param {string} [idXdentUser] 
     * @param {APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel} [aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceApi
     */
    public apiInsuranceInsuranceinvoicegeneratePost(idXdentUser?: string, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel?: APISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options?: AxiosRequestConfig) {
        return InsuranceApiFp(this.configuration).apiInsuranceInsuranceinvoicegeneratePost(idXdentUser, aPISharedInsuranceInvoiceInsuranceInvoiceGenerateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlocks medical report
     * @param {string} [idMedicalReport] 
     * @param {string | null} [idStaff] 
     * @param {string} [idXdentUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceApi
     */
    public apiInsuranceUnlockmedicalreportPost(idMedicalReport?: string, idStaff?: string | null, idXdentUser?: string, options?: AxiosRequestConfig) {
        return InsuranceApiFp(this.configuration).apiInsuranceUnlockmedicalreportPost(idMedicalReport, idStaff, idXdentUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlocks medical report - batch action
     * @param {string | null} [idStaff] 
     * @param {string} [idXdentUser] 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceApi
     */
    public apiInsuranceUnlockmedicalreportsbatchPost(idStaff?: string | null, idXdentUser?: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return InsuranceApiFp(this.configuration).apiInsuranceUnlockmedicalreportsbatchPost(idStaff, idXdentUser, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InsuranceItemApi - axios parameter creator
 * @export
 */
export const InsuranceItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {number} [pageLimit] 
         * @param {string | null} [idStaff] 
         * @param {number | null} [clientAge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceitemGetinsuranceservicecodesGet: async (query?: string, pageLimit?: number, idStaff?: string | null, clientAge?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/insuranceitem/getinsuranceservicecodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['pageLimit'] = pageLimit;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (clientAge !== undefined) {
                localVarQueryParameter['clientAge'] = clientAge;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InsuranceItemApi - functional programming interface
 * @export
 */
export const InsuranceItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InsuranceItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {number} [pageLimit] 
         * @param {string | null} [idStaff] 
         * @param {number | null} [clientAge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInsuranceitemGetinsuranceservicecodesGet(query?: string, pageLimit?: number, idStaff?: string | null, clientAge?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInsuranceitemGetinsuranceservicecodesGet(query, pageLimit, idStaff, clientAge, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InsuranceItemApi.apiInsuranceitemGetinsuranceservicecodesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InsuranceItemApi - factory interface
 * @export
 */
export const InsuranceItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InsuranceItemApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {number} [pageLimit] 
         * @param {string | null} [idStaff] 
         * @param {number | null} [clientAge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInsuranceitemGetinsuranceservicecodesGet(query?: string, pageLimit?: number, idStaff?: string | null, clientAge?: number | null, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINSURANCELABORATORYITEMSSEARCHGRIDNEWResult>> {
            return localVarFp.apiInsuranceitemGetinsuranceservicecodesGet(query, pageLimit, idStaff, clientAge, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InsuranceItemApi - object-oriented interface
 * @export
 * @class InsuranceItemApi
 * @extends {BaseAPI}
 */
export class InsuranceItemApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [query] 
     * @param {number} [pageLimit] 
     * @param {string | null} [idStaff] 
     * @param {number | null} [clientAge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InsuranceItemApi
     */
    public apiInsuranceitemGetinsuranceservicecodesGet(query?: string, pageLimit?: number, idStaff?: string | null, clientAge?: number | null, options?: AxiosRequestConfig) {
        return InsuranceItemApiFp(this.configuration).apiInsuranceitemGetinsuranceservicecodesGet(query, pageLimit, idStaff, clientAge, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes the invoice.
         * @param {string} [id] 
         * @param {string} [type] 
         * @param {boolean | null} [delTransaction] 
         * @param {boolean | null} [storeUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceDeleteinvoiceDelete: async (id?: string, type?: string, delTransaction?: boolean | null, storeUpdate?: boolean | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/deleteinvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (delTransaction !== undefined) {
                localVarQueryParameter['delTransaction'] = delTransaction;
            }

            if (storeUpdate !== undefined) {
                localVarQueryParameter['storeUpdate'] = storeUpdate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceInvoiceExportConfigDto} [aPIXdentDTOInvoiceInvoiceExportConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceExportinvoicePost: async (aPIXdentDTOInvoiceInvoiceExportConfigDto?: APIXdentDTOInvoiceInvoiceExportConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/exportinvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOInvoiceInvoiceExportConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceInvoiceExportRangeConfigDto} [aPIXdentDTOInvoiceInvoiceExportRangeConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceExportinvoicerangePost: async (aPIXdentDTOInvoiceInvoiceExportRangeConfigDto?: APIXdentDTOInvoiceInvoiceExportRangeConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/exportinvoicerange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceFilterclientspartnersGet: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/filterclientspartners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {number} [itemLimit] 
         * @param {boolean} [showMacro] 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceFilterinvoiceitemsGet: async (query?: string, itemLimit?: number, showMacro?: boolean, activeOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/filterinvoiceitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (itemLimit !== undefined) {
                localVarQueryParameter['itemLimit'] = itemLimit;
            }

            if (showMacro !== undefined) {
                localVarQueryParameter['showMacro'] = showMacro;
            }

            if (activeOnly !== undefined) {
                localVarQueryParameter['activeOnly'] = activeOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the currency rates.
         * @param {string | null} [idLocation] Can be null. Used for fixed currency location settings. Overrides the actual rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetcurrencyratesGet: async (idLocation?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getcurrencyrates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the invoice data.
         * @param {string} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicedataGet: async (id: string, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiInvoiceGetinvoicedataGet', 'id', id)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiInvoiceGetinvoicedataGet', 'language', language)
            const localVarPath = `/api/invoice/getinvoicedata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idIssuer] 
         * @param {string} [idStaff] 
         * @param {string} [idInvoiceType] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicelistGet: async (idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getinvoicelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (idIssuer !== undefined) {
                localVarQueryParameter['idIssuer'] = idIssuer;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (idInvoiceType !== undefined) {
                localVarQueryParameter['idInvoiceType'] = idInvoiceType;
            }

            if (idBillingType !== undefined) {
                localVarQueryParameter['idBillingType'] = idBillingType;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateFinish !== undefined) {
                localVarQueryParameter['dateFinish'] = dateFinish;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idIssuer] 
         * @param {string} [idStaff] 
         * @param {string} [idInvoiceType] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicelistafterdueGet: async (idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getinvoicelistafterdue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (idIssuer !== undefined) {
                localVarQueryParameter['idIssuer'] = idIssuer;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (idInvoiceType !== undefined) {
                localVarQueryParameter['idInvoiceType'] = idInvoiceType;
            }

            if (idBillingType !== undefined) {
                localVarQueryParameter['idBillingType'] = idBillingType;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateFinish !== undefined) {
                localVarQueryParameter['dateFinish'] = dateFinish;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets company invoice settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicesettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getinvoicesettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idStaff] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicetransactionlistGet: async (idLocation?: string, idStaff?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getinvoicetransactionlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (idBillingType !== undefined) {
                localVarQueryParameter['idBillingType'] = idBillingType;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateFinish !== undefined) {
                localVarQueryParameter['dateFinish'] = dateFinish;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicetrashlistGet: async (idLocation?: string, dateStart?: string, dateFinish?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getinvoicetrashlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateFinish !== undefined) {
                localVarQueryParameter['dateFinish'] = dateFinish;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetusercreditdebtGet: async (idUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/getusercreditdebt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idUser !== undefined) {
                localVarQueryParameter['idUser'] = idUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [id] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLoadmacroitemsGet: async (id?: string, lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/loadmacroitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceSaveinvoicePost: async (aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/saveinvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOInvoiceSaveInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceSaveinvoicelegacyPost: async (aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/saveinvoicelegacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOInvoiceSaveInvoiceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [location] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceSummaryinvoicegridGet: async (location?: string, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoice/summaryinvoicegrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes the invoice.
         * @param {string} [id] 
         * @param {string} [type] 
         * @param {boolean | null} [delTransaction] 
         * @param {boolean | null} [storeUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceDeleteinvoiceDelete(id?: string, type?: string, delTransaction?: boolean | null, storeUpdate?: boolean | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOInvoiceInvoiceDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceDeleteinvoiceDelete(id, type, delTransaction, storeUpdate, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceDeleteinvoiceDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceInvoiceExportConfigDto} [aPIXdentDTOInvoiceInvoiceExportConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceExportinvoicePost(aPIXdentDTOInvoiceInvoiceExportConfigDto?: APIXdentDTOInvoiceInvoiceExportConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceExportinvoicePost(aPIXdentDTOInvoiceInvoiceExportConfigDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceExportinvoicePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceInvoiceExportRangeConfigDto} [aPIXdentDTOInvoiceInvoiceExportRangeConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceExportinvoicerangePost(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto?: APIXdentDTOInvoiceInvoiceExportRangeConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceExportinvoicerangePost(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceExportinvoicerangePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceFilterclientspartnersGet(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceClientPartnerFilterHelpDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceFilterclientspartnersGet(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceFilterclientspartnersGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {number} [itemLimit] 
         * @param {boolean} [showMacro] 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceFilterinvoiceitemsGet(query?: string, itemLimit?: number, showMacro?: boolean, activeOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceInvoiceCodesItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceFilterinvoiceitemsGet(query, itemLimit, showMacro, activeOnly, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceFilterinvoiceitemsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the currency rates.
         * @param {string | null} [idLocation] Can be null. Used for fixed currency location settings. Overrides the actual rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetcurrencyratesGet(idLocation?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedServicesCurrencyRate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetcurrencyratesGet(idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetcurrencyratesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the invoice data.
         * @param {string} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetinvoicedataGet(id: string, language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOInvoiceInvoiceDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetinvoicedataGet(id, language, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetinvoicedataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idIssuer] 
         * @param {string} [idStaff] 
         * @param {string} [idInvoiceType] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetinvoicelistGet(idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceInvoiceGridInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetinvoicelistGet(idLocation, idIssuer, idStaff, idInvoiceType, idBillingType, dateStart, dateFinish, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetinvoicelistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idIssuer] 
         * @param {string} [idStaff] 
         * @param {string} [idInvoiceType] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetinvoicelistafterdueGet(idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceInvoiceGridInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetinvoicelistafterdueGet(idLocation, idIssuer, idStaff, idInvoiceType, idBillingType, dateStart, dateFinish, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetinvoicelistafterdueGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets company invoice settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetinvoicesettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOInvoiceInvoiceSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetinvoicesettingsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetinvoicesettingsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idStaff] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetinvoicetransactionlistGet(idLocation?: string, idStaff?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceInvoiceTransactionInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetinvoicetransactionlistGet(idLocation, idStaff, idBillingType, dateStart, dateFinish, lang, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetinvoicetransactionlistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetinvoicetrashlistGet(idLocation?: string, dateStart?: string, dateFinish?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceInvoiceGridInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetinvoicetrashlistGet(idLocation, dateStart, dateFinish, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetinvoicetrashlistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceGetusercreditdebtGet(idUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceGetusercreditdebtGet(idUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceGetusercreditdebtGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [id] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceLoadmacroitemsGet(id?: string, lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceInvoiceCodesItemHelpDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceLoadmacroitemsGet(id, lang, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceLoadmacroitemsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceSaveinvoicePost(aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOInvoiceInvoiceReturnInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceSaveinvoicePost(aPIXdentDTOInvoiceSaveInvoiceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceSaveinvoicePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceSaveinvoicelegacyPost(aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOInvoiceInvoiceReturnInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceSaveinvoicelegacyPost(aPIXdentDTOInvoiceSaveInvoiceDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceSaveinvoicelegacyPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [location] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoiceSummaryinvoicegridGet(location?: string, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceSummaryInvoiceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoiceSummaryinvoicegridGet(location, from, to, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceApi.apiInvoiceSummaryinvoicegridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes the invoice.
         * @param {string} [id] 
         * @param {string} [type] 
         * @param {boolean | null} [delTransaction] 
         * @param {boolean | null} [storeUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceDeleteinvoiceDelete(id?: string, type?: string, delTransaction?: boolean | null, storeUpdate?: boolean | null, options?: any): AxiosPromise<APIXdentDTOInvoiceInvoiceDataDto> {
            return localVarFp.apiInvoiceDeleteinvoiceDelete(id, type, delTransaction, storeUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceInvoiceExportConfigDto} [aPIXdentDTOInvoiceInvoiceExportConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceExportinvoicePost(aPIXdentDTOInvoiceInvoiceExportConfigDto?: APIXdentDTOInvoiceInvoiceExportConfigDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiInvoiceExportinvoicePost(aPIXdentDTOInvoiceInvoiceExportConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceInvoiceExportRangeConfigDto} [aPIXdentDTOInvoiceInvoiceExportRangeConfigDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceExportinvoicerangePost(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto?: APIXdentDTOInvoiceInvoiceExportRangeConfigDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiInvoiceExportinvoicerangePost(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceFilterclientspartnersGet(data?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceClientPartnerFilterHelpDto>> {
            return localVarFp.apiInvoiceFilterclientspartnersGet(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [query] 
         * @param {number} [itemLimit] 
         * @param {boolean} [showMacro] 
         * @param {boolean} [activeOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceFilterinvoiceitemsGet(query?: string, itemLimit?: number, showMacro?: boolean, activeOnly?: boolean, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceInvoiceCodesItemDto>> {
            return localVarFp.apiInvoiceFilterinvoiceitemsGet(query, itemLimit, showMacro, activeOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the currency rates.
         * @param {string | null} [idLocation] Can be null. Used for fixed currency location settings. Overrides the actual rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetcurrencyratesGet(idLocation?: string | null, options?: any): AxiosPromise<Array<APISharedServicesCurrencyRate>> {
            return localVarFp.apiInvoiceGetcurrencyratesGet(idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the invoice data.
         * @param {string} id 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicedataGet(id: string, language: string, options?: any): AxiosPromise<APIXdentDTOInvoiceInvoiceDataDto> {
            return localVarFp.apiInvoiceGetinvoicedataGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idIssuer] 
         * @param {string} [idStaff] 
         * @param {string} [idInvoiceType] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicelistGet(idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceInvoiceGridInfoDto>> {
            return localVarFp.apiInvoiceGetinvoicelistGet(idLocation, idIssuer, idStaff, idInvoiceType, idBillingType, dateStart, dateFinish, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idIssuer] 
         * @param {string} [idStaff] 
         * @param {string} [idInvoiceType] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicelistafterdueGet(idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceInvoiceGridInfoDto>> {
            return localVarFp.apiInvoiceGetinvoicelistafterdueGet(idLocation, idIssuer, idStaff, idInvoiceType, idBillingType, dateStart, dateFinish, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets company invoice settings.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicesettingsGet(options?: any): AxiosPromise<APIXdentDTOInvoiceInvoiceSettingsDto> {
            return localVarFp.apiInvoiceGetinvoicesettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [idStaff] 
         * @param {string} [idBillingType] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicetransactionlistGet(idLocation?: string, idStaff?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, lang?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceInvoiceTransactionInfoDto>> {
            return localVarFp.apiInvoiceGetinvoicetransactionlistGet(idLocation, idStaff, idBillingType, dateStart, dateFinish, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idLocation] 
         * @param {string} [dateStart] 
         * @param {string} [dateFinish] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetinvoicetrashlistGet(idLocation?: string, dateStart?: string, dateFinish?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceInvoiceGridInfoDto>> {
            return localVarFp.apiInvoiceGetinvoicetrashlistGet(idLocation, dateStart, dateFinish, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceGetusercreditdebtGet(idUser?: string, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTATSUSERINVOICEResult> {
            return localVarFp.apiInvoiceGetusercreditdebtGet(idUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [id] 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceLoadmacroitemsGet(id?: string, lang?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceInvoiceCodesItemHelpDto>> {
            return localVarFp.apiInvoiceLoadmacroitemsGet(id, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceSaveinvoicePost(aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options?: any): AxiosPromise<APIXdentDTOInvoiceInvoiceReturnInfoDto> {
            return localVarFp.apiInvoiceSaveinvoicePost(aPIXdentDTOInvoiceSaveInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceSaveinvoicelegacyPost(aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options?: any): AxiosPromise<APIXdentDTOInvoiceInvoiceReturnInfoDto> {
            return localVarFp.apiInvoiceSaveinvoicelegacyPost(aPIXdentDTOInvoiceSaveInvoiceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [location] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoiceSummaryinvoicegridGet(location?: string, from?: string, to?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceSummaryInvoiceDto>> {
            return localVarFp.apiInvoiceSummaryinvoicegridGet(location, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Deletes the invoice.
     * @param {string} [id] 
     * @param {string} [type] 
     * @param {boolean | null} [delTransaction] 
     * @param {boolean | null} [storeUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceDeleteinvoiceDelete(id?: string, type?: string, delTransaction?: boolean | null, storeUpdate?: boolean | null, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceDeleteinvoiceDelete(id, type, delTransaction, storeUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOInvoiceInvoiceExportConfigDto} [aPIXdentDTOInvoiceInvoiceExportConfigDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceExportinvoicePost(aPIXdentDTOInvoiceInvoiceExportConfigDto?: APIXdentDTOInvoiceInvoiceExportConfigDto, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceExportinvoicePost(aPIXdentDTOInvoiceInvoiceExportConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOInvoiceInvoiceExportRangeConfigDto} [aPIXdentDTOInvoiceInvoiceExportRangeConfigDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceExportinvoicerangePost(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto?: APIXdentDTOInvoiceInvoiceExportRangeConfigDto, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceExportinvoicerangePost(aPIXdentDTOInvoiceInvoiceExportRangeConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceFilterclientspartnersGet(data?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceFilterclientspartnersGet(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [query] 
     * @param {number} [itemLimit] 
     * @param {boolean} [showMacro] 
     * @param {boolean} [activeOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceFilterinvoiceitemsGet(query?: string, itemLimit?: number, showMacro?: boolean, activeOnly?: boolean, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceFilterinvoiceitemsGet(query, itemLimit, showMacro, activeOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the currency rates.
     * @param {string | null} [idLocation] Can be null. Used for fixed currency location settings. Overrides the actual rate.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetcurrencyratesGet(idLocation?: string | null, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetcurrencyratesGet(idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the invoice data.
     * @param {string} id 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetinvoicedataGet(id: string, language: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetinvoicedataGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idLocation] 
     * @param {string} [idIssuer] 
     * @param {string} [idStaff] 
     * @param {string} [idInvoiceType] 
     * @param {string} [idBillingType] 
     * @param {string} [dateStart] 
     * @param {string} [dateFinish] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetinvoicelistGet(idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetinvoicelistGet(idLocation, idIssuer, idStaff, idInvoiceType, idBillingType, dateStart, dateFinish, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idLocation] 
     * @param {string} [idIssuer] 
     * @param {string} [idStaff] 
     * @param {string} [idInvoiceType] 
     * @param {string} [idBillingType] 
     * @param {string} [dateStart] 
     * @param {string} [dateFinish] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetinvoicelistafterdueGet(idLocation?: string, idIssuer?: string, idStaff?: string, idInvoiceType?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetinvoicelistafterdueGet(idLocation, idIssuer, idStaff, idInvoiceType, idBillingType, dateStart, dateFinish, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets company invoice settings.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetinvoicesettingsGet(options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetinvoicesettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idLocation] 
     * @param {string} [idStaff] 
     * @param {string} [idBillingType] 
     * @param {string} [dateStart] 
     * @param {string} [dateFinish] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetinvoicetransactionlistGet(idLocation?: string, idStaff?: string, idBillingType?: string, dateStart?: string, dateFinish?: string, lang?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetinvoicetransactionlistGet(idLocation, idStaff, idBillingType, dateStart, dateFinish, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idLocation] 
     * @param {string} [dateStart] 
     * @param {string} [dateFinish] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetinvoicetrashlistGet(idLocation?: string, dateStart?: string, dateFinish?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetinvoicetrashlistGet(idLocation, dateStart, dateFinish, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceGetusercreditdebtGet(idUser?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceGetusercreditdebtGet(idUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [id] 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceLoadmacroitemsGet(id?: string, lang?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceLoadmacroitemsGet(id, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceSaveinvoicePost(aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceSaveinvoicePost(aPIXdentDTOInvoiceSaveInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOInvoiceSaveInvoiceDto} [aPIXdentDTOInvoiceSaveInvoiceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceSaveinvoicelegacyPost(aPIXdentDTOInvoiceSaveInvoiceDto?: APIXdentDTOInvoiceSaveInvoiceDto, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceSaveinvoicelegacyPost(aPIXdentDTOInvoiceSaveInvoiceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [location] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiInvoiceSummaryinvoicegridGet(location?: string, from?: string, to?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiInvoiceSummaryinvoicegridGet(location, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvoiceNoteApi - axios parameter creator
 * @export
 */
export const InvoiceNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteDeletedatanotePost: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoicenote/deletedatanote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteLoaddatanoteGet: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoicenote/loaddatanote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteLoaddatanotelistGet: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoicenote/loaddatanotelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceNoteInvoiceNoteDto} [aPIXdentDTOInvoiceNoteInvoiceNoteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteSavedatanotePost: async (aPIXdentDTOInvoiceNoteInvoiceNoteDto?: APIXdentDTOInvoiceNoteInvoiceNoteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/invoicenote/savedatanote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOInvoiceNoteInvoiceNoteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceNoteApi - functional programming interface
 * @export
 */
export const InvoiceNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoicenoteDeletedatanotePost(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoicenoteDeletedatanotePost(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceNoteApi.apiInvoicenoteDeletedatanotePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoicenoteLoaddatanoteGet(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOInvoiceNoteInvoiceNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoicenoteLoaddatanoteGet(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceNoteApi.apiInvoicenoteLoaddatanoteGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoicenoteLoaddatanotelistGet(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOInvoiceNoteInvoiceNoteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoicenoteLoaddatanotelistGet(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceNoteApi.apiInvoicenoteLoaddatanotelistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceNoteInvoiceNoteDto} [aPIXdentDTOInvoiceNoteInvoiceNoteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInvoicenoteSavedatanotePost(aPIXdentDTOInvoiceNoteInvoiceNoteDto?: APIXdentDTOInvoiceNoteInvoiceNoteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInvoicenoteSavedatanotePost(aPIXdentDTOInvoiceNoteInvoiceNoteDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InvoiceNoteApi.apiInvoicenoteSavedatanotePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InvoiceNoteApi - factory interface
 * @export
 */
export const InvoiceNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceNoteApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteDeletedatanotePost(data?: string, options?: any): AxiosPromise<number> {
            return localVarFp.apiInvoicenoteDeletedatanotePost(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteLoaddatanoteGet(data?: string, options?: any): AxiosPromise<APIXdentDTOInvoiceNoteInvoiceNoteDto> {
            return localVarFp.apiInvoicenoteLoaddatanoteGet(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteLoaddatanotelistGet(data?: string, options?: any): AxiosPromise<Array<APIXdentDTOInvoiceNoteInvoiceNoteDto>> {
            return localVarFp.apiInvoicenoteLoaddatanotelistGet(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOInvoiceNoteInvoiceNoteDto} [aPIXdentDTOInvoiceNoteInvoiceNoteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInvoicenoteSavedatanotePost(aPIXdentDTOInvoiceNoteInvoiceNoteDto?: APIXdentDTOInvoiceNoteInvoiceNoteDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiInvoicenoteSavedatanotePost(aPIXdentDTOInvoiceNoteInvoiceNoteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceNoteApi - object-oriented interface
 * @export
 * @class InvoiceNoteApi
 * @extends {BaseAPI}
 */
export class InvoiceNoteApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceNoteApi
     */
    public apiInvoicenoteDeletedatanotePost(data?: string, options?: AxiosRequestConfig) {
        return InvoiceNoteApiFp(this.configuration).apiInvoicenoteDeletedatanotePost(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceNoteApi
     */
    public apiInvoicenoteLoaddatanoteGet(data?: string, options?: AxiosRequestConfig) {
        return InvoiceNoteApiFp(this.configuration).apiInvoicenoteLoaddatanoteGet(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceNoteApi
     */
    public apiInvoicenoteLoaddatanotelistGet(data?: string, options?: AxiosRequestConfig) {
        return InvoiceNoteApiFp(this.configuration).apiInvoicenoteLoaddatanotelistGet(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOInvoiceNoteInvoiceNoteDto} [aPIXdentDTOInvoiceNoteInvoiceNoteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceNoteApi
     */
    public apiInvoicenoteSavedatanotePost(aPIXdentDTOInvoiceNoteInvoiceNoteDto?: APIXdentDTOInvoiceNoteInvoiceNoteDto, options?: AxiosRequestConfig) {
        return InvoiceNoteApiFp(this.configuration).apiInvoicenoteSavedatanotePost(aPIXdentDTOInvoiceNoteInvoiceNoteDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JsAuthApi - axios parameter creator
 * @export
 */
export const JsAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticates user by provided id from xdent BE.
         * @param {string} idXdentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJsauthAuthbyidPost: async (idXdentUser: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiJsauthAuthbyidPost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/jsauth/authbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates user by provided id from xdent BE. (Audience for community)
         * @param {string} idXdentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJsauthAuthbyidcommunityPost: async (idXdentUser: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentUser' is not null or undefined
            assertParamExists('apiJsauthAuthbyidcommunityPost', 'idXdentUser', idXdentUser)
            const localVarPath = `/api/jsauth/authbyidcommunity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JsAuthApi - functional programming interface
 * @export
 */
export const JsAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JsAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authenticates user by provided id from xdent BE.
         * @param {string} idXdentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJsauthAuthbyidPost(idXdentUser: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJsauthAuthbyidPost(idXdentUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['JsAuthApi.apiJsauthAuthbyidPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Authenticates user by provided id from xdent BE. (Audience for community)
         * @param {string} idXdentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiJsauthAuthbyidcommunityPost(idXdentUser: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiJsauthAuthbyidcommunityPost(idXdentUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['JsAuthApi.apiJsauthAuthbyidcommunityPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * JsAuthApi - factory interface
 * @export
 */
export const JsAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JsAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Authenticates user by provided id from xdent BE.
         * @param {string} idXdentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJsauthAuthbyidPost(idXdentUser: string, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiJsauthAuthbyidPost(idXdentUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates user by provided id from xdent BE. (Audience for community)
         * @param {string} idXdentUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiJsauthAuthbyidcommunityPost(idXdentUser: string, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiJsauthAuthbyidcommunityPost(idXdentUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JsAuthApi - object-oriented interface
 * @export
 * @class JsAuthApi
 * @extends {BaseAPI}
 */
export class JsAuthApi extends BaseAPI {
    /**
     * 
     * @summary Authenticates user by provided id from xdent BE.
     * @param {string} idXdentUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsAuthApi
     */
    public apiJsauthAuthbyidPost(idXdentUser: string, options?: AxiosRequestConfig) {
        return JsAuthApiFp(this.configuration).apiJsauthAuthbyidPost(idXdentUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates user by provided id from xdent BE. (Audience for community)
     * @param {string} idXdentUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsAuthApi
     */
    public apiJsauthAuthbyidcommunityPost(idXdentUser: string, options?: AxiosRequestConfig) {
        return JsAuthApiFp(this.configuration).apiJsauthAuthbyidcommunityPost(idXdentUser, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LabinoApi - axios parameter creator
 * @export
 */
export const LabinoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all the laboratory pair requests for xdent company.
         * @param {string} [idXdentCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoChecklaboratoryrequestsGet: async (idXdentCompany?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/checklaboratoryrequests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentCompany !== undefined) {
                localVarQueryParameter['idXdentCompany'] = idXdentCompany;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoCreatelaboratoryPost: async (labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/createlaboratory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoLabPairHelp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all available laboratories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetalllaboratoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/getalllaboratories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all available laboratories with geocache.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetalllaboratorieswithgeocacheGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/getalllaboratorieswithgeocache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets labTicketHistory
         * @param {string} [idLaboratory] 
         * @param {string} [ticketNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetlablabelhistorygridGet: async (idLaboratory?: string, ticketNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/getlablabelhistorygrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLaboratory !== undefined) {
                localVarQueryParameter['idLaboratory'] = idLaboratory;
            }

            if (ticketNumber !== undefined) {
                localVarQueryParameter['ticketNumber'] = ticketNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all the laboratory pair requests for xdent company.
         * @param {boolean} [unreadOnly] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetlabrequestsnotificationsGet: async (unreadOnly?: boolean, start?: number, end?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/getlabrequestsnotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (unreadOnly !== undefined) {
                localVarQueryParameter['unreadOnly'] = unreadOnly;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoInvalidateLaboratoryRequest} [labinoDtoInvalidateLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoInvalidatelaboratoryrequestPost: async (labinoDtoInvalidateLaboratoryRequest?: LabinoDtoInvalidateLaboratoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/invalidatelaboratoryrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoInvalidateLaboratoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoInvalidatexdentlaboratoryPost: async (labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/invalidatexdentlaboratory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoLabPairHelp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoReadlabinomessagesGet: async (idChatMessage?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/readlabinomessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idChatMessage !== undefined) {
                localVarQueryParameter['idChatMessage'] = idChatMessage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes status of LabLaboratoryRequest to read. (1/true)
         * @param {APIXdentDTOLabinoReadLabRequestsNotificationDto} [aPIXdentDTOLabinoReadLabRequestsNotificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoReadlabrequestsnotificationPost: async (aPIXdentDTOLabinoReadLabRequestsNotificationDto?: APIXdentDTOLabinoReadLabRequestsNotificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/readlabrequestsnotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOLabinoReadLabRequestsNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} idOwner 
         * @param {string | null} idLaboratory 
         * @param {string | null} idLabLocation 
         * @param {string | null} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoSendlaboratoryrequestGet: async (idOwner: string | null, idLaboratory: string | null, idLabLocation: string | null, idLocation?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOwner' is not null or undefined
            assertParamExists('apiLabinoSendlaboratoryrequestGet', 'idOwner', idOwner)
            // verify required parameter 'idLaboratory' is not null or undefined
            assertParamExists('apiLabinoSendlaboratoryrequestGet', 'idLaboratory', idLaboratory)
            // verify required parameter 'idLabLocation' is not null or undefined
            assertParamExists('apiLabinoSendlaboratoryrequestGet', 'idLabLocation', idLabLocation)
            const localVarPath = `/api/labino/sendlaboratoryrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idOwner !== undefined) {
                localVarQueryParameter['idOwner'] = idOwner;
            }

            if (idLaboratory !== undefined) {
                localVarQueryParameter['idLaboratory'] = idLaboratory;
            }

            if (idLabLocation !== undefined) {
                localVarQueryParameter['idLabLocation'] = idLabLocation;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shares message to labino. If message already exists and idFile is provided, file is shared to that message.
         * @param {LabinoDtoShareTicketChatMessage} [labinoDtoShareTicketChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentshareSendexternalticketchatmessagePost: async (labinoDtoShareTicketChatMessage?: LabinoDtoShareTicketChatMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentshare/sendexternalticketchatmessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoShareTicketChatMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOXdentShareShareLabTicketFileDto} [aPIXdentDTOXdentShareShareLabTicketFileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentshareSharelabticketfiletolabinoaschatmessagePost: async (aPIXdentDTOXdentShareShareLabTicketFileDto?: APIXdentDTOXdentShareShareLabTicketFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentshare/sharelabticketfiletolabinoaschatmessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOXdentShareShareLabTicketFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabinoApi - functional programming interface
 * @export
 */
export const LabinoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LabinoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all the laboratory pair requests for xdent company.
         * @param {string} [idXdentCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoChecklaboratoryrequestsGet(idXdentCompany?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoChecklaboratoryrequestsGet(idXdentCompany, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoChecklaboratoryrequestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoCreatelaboratoryPost(labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoCreatelaboratoryPost(labinoDtoLabPairHelp, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoCreatelaboratoryPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all available laboratories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoGetalllaboratoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoGetalllaboratoriesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoGetalllaboratoriesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all available laboratories with geocache.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoGetalllaboratorieswithgeocacheGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LabinoDtoLaboratoryGridMarketplaceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoGetalllaboratorieswithgeocacheGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoGetalllaboratorieswithgeocacheGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets labTicketHistory
         * @param {string} [idLaboratory] 
         * @param {string} [ticketNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoGetlablabelhistorygridGet(idLaboratory?: string, ticketNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoGetlablabelhistorygridGet(idLaboratory, ticketNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoGetlablabelhistorygridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all the laboratory pair requests for xdent company.
         * @param {boolean} [unreadOnly] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoGetlabrequestsnotificationsGet(unreadOnly?: boolean, start?: number, end?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoGetlabrequestsnotificationsGet(unreadOnly, start, end, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoGetlabrequestsnotificationsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoInvalidateLaboratoryRequest} [labinoDtoInvalidateLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoInvalidatelaboratoryrequestPost(labinoDtoInvalidateLaboratoryRequest?: LabinoDtoInvalidateLaboratoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoInvalidatelaboratoryrequestPost(labinoDtoInvalidateLaboratoryRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoInvalidatelaboratoryrequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoInvalidatexdentlaboratoryPost(labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoInvalidatexdentlaboratoryPost(labinoDtoLabPairHelp, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoInvalidatexdentlaboratoryPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoReadlabinomessagesGet(idChatMessage?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoReadlabinomessagesGet(idChatMessage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoReadlabinomessagesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Changes status of LabLaboratoryRequest to read. (1/true)
         * @param {APIXdentDTOLabinoReadLabRequestsNotificationDto} [aPIXdentDTOLabinoReadLabRequestsNotificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoReadlabrequestsnotificationPost(aPIXdentDTOLabinoReadLabRequestsNotificationDto?: APIXdentDTOLabinoReadLabRequestsNotificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoReadlabrequestsnotificationPost(aPIXdentDTOLabinoReadLabRequestsNotificationDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoReadlabrequestsnotificationPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} idOwner 
         * @param {string | null} idLaboratory 
         * @param {string | null} idLabLocation 
         * @param {string | null} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoSendlaboratoryrequestGet(idOwner: string | null, idLaboratory: string | null, idLabLocation: string | null, idLocation?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoSendlaboratoryrequestGet(idOwner, idLaboratory, idLabLocation, idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiLabinoSendlaboratoryrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Shares message to labino. If message already exists and idFile is provided, file is shared to that message.
         * @param {LabinoDtoShareTicketChatMessage} [labinoDtoShareTicketChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentshareSendexternalticketchatmessagePost(labinoDtoShareTicketChatMessage?: LabinoDtoShareTicketChatMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentshareSendexternalticketchatmessagePost(labinoDtoShareTicketChatMessage, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiXdentshareSendexternalticketchatmessagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOXdentShareShareLabTicketFileDto} [aPIXdentDTOXdentShareShareLabTicketFileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentshareSharelabticketfiletolabinoaschatmessagePost(aPIXdentDTOXdentShareShareLabTicketFileDto?: APIXdentDTOXdentShareShareLabTicketFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentshareSharelabticketfiletolabinoaschatmessagePost(aPIXdentDTOXdentShareShareLabTicketFileDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LabinoApi.apiXdentshareSharelabticketfiletolabinoaschatmessagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LabinoApi - factory interface
 * @export
 */
export const LabinoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabinoApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all the laboratory pair requests for xdent company.
         * @param {string} [idXdentCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoChecklaboratoryrequestsGet(idXdentCompany?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPICHECKREQUESTSResult>> {
            return localVarFp.apiLabinoChecklaboratoryrequestsGet(idXdentCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoCreatelaboratoryPost(labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options?: any): AxiosPromise<string> {
            return localVarFp.apiLabinoCreatelaboratoryPost(labinoDtoLabPairHelp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all available laboratories.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetalllaboratoriesGet(options?: any): AxiosPromise<Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORIESGRIDResult>> {
            return localVarFp.apiLabinoGetalllaboratoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all available laboratories with geocache.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetalllaboratorieswithgeocacheGet(options?: any): AxiosPromise<Array<LabinoDtoLaboratoryGridMarketplaceDto>> {
            return localVarFp.apiLabinoGetalllaboratorieswithgeocacheGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets labTicketHistory
         * @param {string} [idLaboratory] 
         * @param {string} [ticketNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetlablabelhistorygridGet(idLaboratory?: string, ticketNumber?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsLabinoLabinoDbProceduresContextGeneratedLABAPILABORATORYLABELHISTORYGRIDResult>> {
            return localVarFp.apiLabinoGetlablabelhistorygridGet(idLaboratory, ticketNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all the laboratory pair requests for xdent company.
         * @param {boolean} [unreadOnly] 
         * @param {number} [start] 
         * @param {number} [end] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoGetlabrequestsnotificationsGet(unreadOnly?: boolean, start?: number, end?: number, options?: any): AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerLabLaboratoryRequest>> {
            return localVarFp.apiLabinoGetlabrequestsnotificationsGet(unreadOnly, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoInvalidateLaboratoryRequest} [labinoDtoInvalidateLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoInvalidatelaboratoryrequestPost(labinoDtoInvalidateLaboratoryRequest?: LabinoDtoInvalidateLaboratoryRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoInvalidatelaboratoryrequestPost(labinoDtoInvalidateLaboratoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoInvalidatexdentlaboratoryPost(labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoInvalidatexdentlaboratoryPost(labinoDtoLabPairHelp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoReadlabinomessagesGet(idChatMessage?: string | null, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoReadlabinomessagesGet(idChatMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes status of LabLaboratoryRequest to read. (1/true)
         * @param {APIXdentDTOLabinoReadLabRequestsNotificationDto} [aPIXdentDTOLabinoReadLabRequestsNotificationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoReadlabrequestsnotificationPost(aPIXdentDTOLabinoReadLabRequestsNotificationDto?: APIXdentDTOLabinoReadLabRequestsNotificationDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoReadlabrequestsnotificationPost(aPIXdentDTOLabinoReadLabRequestsNotificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} idOwner 
         * @param {string | null} idLaboratory 
         * @param {string | null} idLabLocation 
         * @param {string | null} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoSendlaboratoryrequestGet(idOwner: string | null, idLaboratory: string | null, idLabLocation: string | null, idLocation?: string | null, options?: any): AxiosPromise<string> {
            return localVarFp.apiLabinoSendlaboratoryrequestGet(idOwner, idLaboratory, idLabLocation, idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shares message to labino. If message already exists and idFile is provided, file is shared to that message.
         * @param {LabinoDtoShareTicketChatMessage} [labinoDtoShareTicketChatMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentshareSendexternalticketchatmessagePost(labinoDtoShareTicketChatMessage?: LabinoDtoShareTicketChatMessage, options?: any): AxiosPromise<string> {
            return localVarFp.apiXdentshareSendexternalticketchatmessagePost(labinoDtoShareTicketChatMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOXdentShareShareLabTicketFileDto} [aPIXdentDTOXdentShareShareLabTicketFileDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentshareSharelabticketfiletolabinoaschatmessagePost(aPIXdentDTOXdentShareShareLabTicketFileDto?: APIXdentDTOXdentShareShareLabTicketFileDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiXdentshareSharelabticketfiletolabinoaschatmessagePost(aPIXdentDTOXdentShareShareLabTicketFileDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabinoApi - object-oriented interface
 * @export
 * @class LabinoApi
 * @extends {BaseAPI}
 */
export class LabinoApi extends BaseAPI {
    /**
     * 
     * @summary Gets all the laboratory pair requests for xdent company.
     * @param {string} [idXdentCompany] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoChecklaboratoryrequestsGet(idXdentCompany?: string, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoChecklaboratoryrequestsGet(idXdentCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoCreatelaboratoryPost(labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoCreatelaboratoryPost(labinoDtoLabPairHelp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all available laboratories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoGetalllaboratoriesGet(options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoGetalllaboratoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all available laboratories with geocache.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoGetalllaboratorieswithgeocacheGet(options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoGetalllaboratorieswithgeocacheGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets labTicketHistory
     * @param {string} [idLaboratory] 
     * @param {string} [ticketNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoGetlablabelhistorygridGet(idLaboratory?: string, ticketNumber?: string, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoGetlablabelhistorygridGet(idLaboratory, ticketNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all the laboratory pair requests for xdent company.
     * @param {boolean} [unreadOnly] 
     * @param {number} [start] 
     * @param {number} [end] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoGetlabrequestsnotificationsGet(unreadOnly?: boolean, start?: number, end?: number, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoGetlabrequestsnotificationsGet(unreadOnly, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {LabinoDtoInvalidateLaboratoryRequest} [labinoDtoInvalidateLaboratoryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoInvalidatelaboratoryrequestPost(labinoDtoInvalidateLaboratoryRequest?: LabinoDtoInvalidateLaboratoryRequest, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoInvalidatelaboratoryrequestPost(labinoDtoInvalidateLaboratoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {LabinoDtoLabPairHelp} [labinoDtoLabPairHelp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoInvalidatexdentlaboratoryPost(labinoDtoLabPairHelp?: LabinoDtoLabPairHelp, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoInvalidatexdentlaboratoryPost(labinoDtoLabPairHelp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idChatMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoReadlabinomessagesGet(idChatMessage?: string | null, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoReadlabinomessagesGet(idChatMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes status of LabLaboratoryRequest to read. (1/true)
     * @param {APIXdentDTOLabinoReadLabRequestsNotificationDto} [aPIXdentDTOLabinoReadLabRequestsNotificationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoReadlabrequestsnotificationPost(aPIXdentDTOLabinoReadLabRequestsNotificationDto?: APIXdentDTOLabinoReadLabRequestsNotificationDto, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoReadlabrequestsnotificationPost(aPIXdentDTOLabinoReadLabRequestsNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} idOwner 
     * @param {string | null} idLaboratory 
     * @param {string | null} idLabLocation 
     * @param {string | null} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiLabinoSendlaboratoryrequestGet(idOwner: string | null, idLaboratory: string | null, idLabLocation: string | null, idLocation?: string | null, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiLabinoSendlaboratoryrequestGet(idOwner, idLaboratory, idLabLocation, idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shares message to labino. If message already exists and idFile is provided, file is shared to that message.
     * @param {LabinoDtoShareTicketChatMessage} [labinoDtoShareTicketChatMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiXdentshareSendexternalticketchatmessagePost(labinoDtoShareTicketChatMessage?: LabinoDtoShareTicketChatMessage, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiXdentshareSendexternalticketchatmessagePost(labinoDtoShareTicketChatMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOXdentShareShareLabTicketFileDto} [aPIXdentDTOXdentShareShareLabTicketFileDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabinoApi
     */
    public apiXdentshareSharelabticketfiletolabinoaschatmessagePost(aPIXdentDTOXdentShareShareLabTicketFileDto?: APIXdentDTOXdentShareShareLabTicketFileDto, options?: AxiosRequestConfig) {
        return LabinoApiFp(this.configuration).apiXdentshareSharelabticketfiletolabinoaschatmessagePost(aPIXdentDTOXdentShareShareLabTicketFileDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LanguagesApi - axios parameter creator
 * @export
 */
export const LanguagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all available languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGetlanguagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/languages/getlanguages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns localizations for mobile app
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesMobilelocalizationGet: async (languageCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/languages/mobilelocalization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguagesApi - functional programming interface
 * @export
 */
export const LanguagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns all available languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesGetlanguagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentServicesXdentScopedLanguagesServiceLanguageDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesGetlanguagesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LanguagesApi.apiLanguagesGetlanguagesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns localizations for mobile app
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLanguagesMobilelocalizationGet(languageCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLanguagesMobilelocalizationGet(languageCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LanguagesApi.apiLanguagesMobilelocalizationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LanguagesApi - factory interface
 * @export
 */
export const LanguagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns all available languages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGetlanguagesGet(options?: any): AxiosPromise<Array<APIXdentServicesXdentScopedLanguagesServiceLanguageDTO>> {
            return localVarFp.apiLanguagesGetlanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns localizations for mobile app
         * @param {string} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesMobilelocalizationGet(languageCode?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiLanguagesMobilelocalizationGet(languageCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguagesApi - object-oriented interface
 * @export
 * @class LanguagesApi
 * @extends {BaseAPI}
 */
export class LanguagesApi extends BaseAPI {
    /**
     * 
     * @summary Returns all available languages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesGetlanguagesGet(options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesGetlanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns localizations for mobile app
     * @param {string} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesMobilelocalizationGet(languageCode?: string, options?: AxiosRequestConfig) {
        return LanguagesApiFp(this.configuration).apiLanguagesMobilelocalizationGet(languageCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LegacyApi - axios parameter creator
 * @export
 */
export const LegacyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates occurence from appointment pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyCreateoccurenceGet: async (idResource?: string | null, data?: string, intervalOriginal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/createoccurence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }

            if (intervalOriginal !== undefined) {
                localVarQueryParameter['intervalOriginal'] = intervalOriginal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyDeleteappointmentGet: async (idResource?: string | null, data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/deleteappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes changed occurence (pattern element).
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyDeletechangedoccurenceGet: async (idResource?: string | null, data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/deletechangedoccurence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes occurence from appointment pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyDeleteoccurenceGet: async (idResource?: string | null, data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/deleteoccurence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Inserts appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyInsertappointmentGet: async (idResource?: string | null, data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/insertappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyPatternGet: async (idResource?: string | null, data?: string, intervalOriginal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/pattern`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }

            if (intervalOriginal !== undefined) {
                localVarQueryParameter['intervalOriginal'] = intervalOriginal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restores occurence to default pattern values.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyRestoreoccurencesGet: async (idResource?: string | null, data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/restoreoccurences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for available times by multiples of minutes offset.
         * @param {string} [startTime] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {number} [minutesOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacySearchavailabletimeGet: async (startTime?: string, idService?: string, idStaff?: string, minutesOffset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/searchavailabletime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (minutesOffset !== undefined) {
                localVarQueryParameter['minutesOffset'] = minutesOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyUpdateappointmentGet: async (idResource?: string | null, data?: string, intervalOriginal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legacy/updateappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idResource !== undefined) {
                localVarQueryParameter['idResource'] = idResource;
            }

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }

            if (intervalOriginal !== undefined) {
                localVarQueryParameter['intervalOriginal'] = intervalOriginal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegacyApi - functional programming interface
 * @export
 */
export const LegacyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegacyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates occurence from appointment pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyCreateoccurenceGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyCreateoccurenceGet(idResource, data, intervalOriginal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyCreateoccurenceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyDeleteappointmentGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyDeleteappointmentGet(idResource, data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyDeleteappointmentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes changed occurence (pattern element).
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyDeletechangedoccurenceGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyDeletechangedoccurenceGet(idResource, data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyDeletechangedoccurenceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes occurence from appointment pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyDeleteoccurenceGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyDeleteoccurenceGet(idResource, data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyDeleteoccurenceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Inserts appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyInsertappointmentGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyInsertappointmentGet(idResource, data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyInsertappointmentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyPatternGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyPatternGet(idResource, data, intervalOriginal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyPatternGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Restores occurence to default pattern values.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyRestoreoccurencesGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyRestoreoccurencesGet(idResource, data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyRestoreoccurencesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Searches for available times by multiples of minutes offset.
         * @param {string} [startTime] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {number} [minutesOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacySearchavailabletimeGet(startTime?: string, idService?: string, idStaff?: string, minutesOffset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOLegacySearchAvailableTimeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacySearchavailabletimeGet(startTime, idService, idStaff, minutesOffset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacySearchavailabletimeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Updates appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegacyUpdateappointmentGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegacyUpdateappointmentGet(idResource, data, intervalOriginal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LegacyApi.apiLegacyUpdateappointmentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LegacyApi - factory interface
 * @export
 */
export const LegacyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegacyApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates occurence from appointment pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyCreateoccurenceGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyCreateoccurenceGet(idResource, data, intervalOriginal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyDeleteappointmentGet(idResource?: string | null, data?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyDeleteappointmentGet(idResource, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes changed occurence (pattern element).
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyDeletechangedoccurenceGet(idResource?: string | null, data?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyDeletechangedoccurenceGet(idResource, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes occurence from appointment pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyDeleteoccurenceGet(idResource?: string | null, data?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyDeleteoccurenceGet(idResource, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Inserts appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyInsertappointmentGet(idResource?: string | null, data?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyInsertappointmentGet(idResource, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates pattern.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyPatternGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyPatternGet(idResource, data, intervalOriginal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restores occurence to default pattern values.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyRestoreoccurencesGet(idResource?: string | null, data?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyRestoreoccurencesGet(idResource, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for available times by multiples of minutes offset.
         * @param {string} [startTime] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {number} [minutesOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacySearchavailabletimeGet(startTime?: string, idService?: string, idStaff?: string, minutesOffset?: number, options?: any): AxiosPromise<Array<APIXdentDTOLegacySearchAvailableTimeDto>> {
            return localVarFp.apiLegacySearchavailabletimeGet(startTime, idService, idStaff, minutesOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates appointment via provided data.
         * @param {string | null} [idResource] 
         * @param {string} [data] 
         * @param {string} [intervalOriginal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegacyUpdateappointmentGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegacyUpdateappointmentGet(idResource, data, intervalOriginal, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LegacyApi - object-oriented interface
 * @export
 * @class LegacyApi
 * @extends {BaseAPI}
 */
export class LegacyApi extends BaseAPI {
    /**
     * 
     * @summary Creates occurence from appointment pattern.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {string} [intervalOriginal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyCreateoccurenceGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyCreateoccurenceGet(idResource, data, intervalOriginal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes appointment via provided data.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyDeleteappointmentGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyDeleteappointmentGet(idResource, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes changed occurence (pattern element).
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyDeletechangedoccurenceGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyDeletechangedoccurenceGet(idResource, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes occurence from appointment pattern.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyDeleteoccurenceGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyDeleteoccurenceGet(idResource, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Inserts appointment via provided data.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyInsertappointmentGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyInsertappointmentGet(idResource, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates pattern.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {string} [intervalOriginal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyPatternGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyPatternGet(idResource, data, intervalOriginal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restores occurence to default pattern values.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyRestoreoccurencesGet(idResource?: string | null, data?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyRestoreoccurencesGet(idResource, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for available times by multiples of minutes offset.
     * @param {string} [startTime] 
     * @param {string} [idService] 
     * @param {string} [idStaff] 
     * @param {number} [minutesOffset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacySearchavailabletimeGet(startTime?: string, idService?: string, idStaff?: string, minutesOffset?: number, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacySearchavailabletimeGet(startTime, idService, idStaff, minutesOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates appointment via provided data.
     * @param {string | null} [idResource] 
     * @param {string} [data] 
     * @param {string} [intervalOriginal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegacyApi
     */
    public apiLegacyUpdateappointmentGet(idResource?: string | null, data?: string, intervalOriginal?: string, options?: AxiosRequestConfig) {
        return LegacyApiFp(this.configuration).apiLegacyUpdateappointmentGet(idResource, data, intervalOriginal, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocationsApi - axios parameter creator
 * @export
 */
export const LocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return location custom settings by location id
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLocationsGetlocationcustomsettingsGet: async (idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/locations/getlocationcustomsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return locations for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLocationsGetlocationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/locations/getlocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsApi - functional programming interface
 * @export
 */
export const LocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return location custom settings by location id
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLocationsGetlocationcustomsettingsGet(idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDTOLocationLocationCustomSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLocationsGetlocationcustomsettingsGet(idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LocationsApi.apiLocationsGetlocationcustomsettingsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return locations for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLocationsGetlocationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOLocationLocationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLocationsGetlocationsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LocationsApi.apiLocationsGetlocationsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LocationsApi - factory interface
 * @export
 */
export const LocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Return location custom settings by location id
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLocationsGetlocationcustomsettingsGet(idLocation?: string, options?: any): AxiosPromise<APISharedDTOLocationLocationCustomSettingsDto> {
            return localVarFp.apiLocationsGetlocationcustomsettingsGet(idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return locations for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLocationsGetlocationsGet(options?: any): AxiosPromise<Array<APIXdentDTOLocationLocationDto>> {
            return localVarFp.apiLocationsGetlocationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationsApi - object-oriented interface
 * @export
 * @class LocationsApi
 * @extends {BaseAPI}
 */
export class LocationsApi extends BaseAPI {
    /**
     * 
     * @summary Return location custom settings by location id
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiLocationsGetlocationcustomsettingsGet(idLocation?: string, options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiLocationsGetlocationcustomsettingsGet(idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return locations for logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsApi
     */
    public apiLocationsGetlocationsGet(options?: AxiosRequestConfig) {
        return LocationsApiFp(this.configuration).apiLocationsGetlocationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {APIXdentDTOUserLogUserLogDto} [aPIXdentDTOUserLogUserLogDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogCreatePost: async (aPIXdentDTOUserLogUserLogDto?: APIXdentDTOUserLogUserLogDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOUserLogUserLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestauthloggingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/testauthlogging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestexceptionloggingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/testexceptionlogging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestinnerexceptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/testinnerexceptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestloggingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/testlogging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APIXdentDTOUserLogTestLogPostDto} [aPIXdentDTOUserLogTestLogPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestloggingPost: async (aPIXdentDTOUserLogTestLogPostDto?: APIXdentDTOUserLogTestLogPostDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/testlogging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOUserLogTestLogPostDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets logs for logged user. Admin role can get logs from users in the same company.
         * @param {string | null} [dateStart] FILTER
         * @param {string | null} [dateEnd] FILTER
         * @param {string | null} [idUser] FILTER (company restrictions apply)
         * @param {string | null} [idLog] FILTER
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogUserlogsGet: async (dateStart?: string | null, dateEnd?: string | null, idUser?: string | null, idLog?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mobile/log/userlogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = (dateStart as any instanceof Date) ?
                    (dateStart as any).toISOString() :
                    dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['dateEnd'] = (dateEnd as any instanceof Date) ?
                    (dateEnd as any).toISOString() :
                    dateEnd;
            }

            if (idUser !== undefined) {
                localVarQueryParameter['idUser'] = idUser;
            }

            if (idLog !== undefined) {
                localVarQueryParameter['idLog'] = idLog;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {APIXdentDTOUserLogUserLogDto} [aPIXdentDTOUserLogUserLogDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogCreatePost(aPIXdentDTOUserLogUserLogDto?: APIXdentDTOUserLogUserLogDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogCreatePost(aPIXdentDTOUserLogUserLogDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogTestauthloggingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogTestauthloggingGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogTestauthloggingGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogTestexceptionloggingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogTestexceptionloggingGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogTestexceptionloggingGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogTestinnerexceptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogTestinnerexceptionsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogTestinnerexceptionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogTestloggingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogTestloggingGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogTestloggingGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {APIXdentDTOUserLogTestLogPostDto} [aPIXdentDTOUserLogTestLogPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogTestloggingPost(aPIXdentDTOUserLogTestLogPostDto?: APIXdentDTOUserLogTestLogPostDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogTestloggingPost(aPIXdentDTOUserLogTestLogPostDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogTestloggingPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets logs for logged user. Admin role can get logs from users in the same company.
         * @param {string | null} [dateStart] FILTER
         * @param {string | null} [dateEnd] FILTER
         * @param {string | null} [idUser] FILTER (company restrictions apply)
         * @param {string | null} [idLog] FILTER
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMobileLogUserlogsGet(dateStart?: string | null, dateEnd?: string | null, idUser?: string | null, idLog?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerUserLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMobileLogUserlogsGet(dateStart, dateEnd, idUser, idLog, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogsApi.apiMobileLogUserlogsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogsApiFp(configuration)
    return {
        /**
         * 
         * @param {APIXdentDTOUserLogUserLogDto} [aPIXdentDTOUserLogUserLogDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogCreatePost(aPIXdentDTOUserLogUserLogDto?: APIXdentDTOUserLogUserLogDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiMobileLogCreatePost(aPIXdentDTOUserLogUserLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestauthloggingGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMobileLogTestauthloggingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestexceptionloggingGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMobileLogTestexceptionloggingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestinnerexceptionsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMobileLogTestinnerexceptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestloggingGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMobileLogTestloggingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APIXdentDTOUserLogTestLogPostDto} [aPIXdentDTOUserLogTestLogPostDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogTestloggingPost(aPIXdentDTOUserLogTestLogPostDto?: APIXdentDTOUserLogTestLogPostDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiMobileLogTestloggingPost(aPIXdentDTOUserLogTestLogPostDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets logs for logged user. Admin role can get logs from users in the same company.
         * @param {string | null} [dateStart] FILTER
         * @param {string | null} [dateEnd] FILTER
         * @param {string | null} [idUser] FILTER (company restrictions apply)
         * @param {string | null} [idLog] FILTER
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMobileLogUserlogsGet(dateStart?: string | null, dateEnd?: string | null, idUser?: string | null, idLog?: string | null, options?: any): AxiosPromise<Array<APIContextGeneratedXdentCoreEntityLayerUserLog>> {
            return localVarFp.apiMobileLogUserlogsGet(dateStart, dateEnd, idUser, idLog, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * 
     * @param {APIXdentDTOUserLogUserLogDto} [aPIXdentDTOUserLogUserLogDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogCreatePost(aPIXdentDTOUserLogUserLogDto?: APIXdentDTOUserLogUserLogDto, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogCreatePost(aPIXdentDTOUserLogUserLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogTestauthloggingGet(options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogTestauthloggingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogTestexceptionloggingGet(options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogTestexceptionloggingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogTestinnerexceptionsGet(options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogTestinnerexceptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogTestloggingGet(options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogTestloggingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {APIXdentDTOUserLogTestLogPostDto} [aPIXdentDTOUserLogTestLogPostDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogTestloggingPost(aPIXdentDTOUserLogTestLogPostDto?: APIXdentDTOUserLogTestLogPostDto, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogTestloggingPost(aPIXdentDTOUserLogTestLogPostDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets logs for logged user. Admin role can get logs from users in the same company.
     * @param {string | null} [dateStart] FILTER
     * @param {string | null} [dateEnd] FILTER
     * @param {string | null} [idUser] FILTER (company restrictions apply)
     * @param {string | null} [idLog] FILTER
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public apiMobileLogUserlogsGet(dateStart?: string | null, dateEnd?: string | null, idUser?: string | null, idLog?: string | null, options?: AxiosRequestConfig) {
        return LogsApiFp(this.configuration).apiMobileLogUserlogsGet(dateStart, dateEnd, idUser, idLog, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MedicalReportApi - axios parameter creator
 * @export
 */
export const MedicalReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets diagnosis by medical report id
         * @param {string} [idReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetdiagnosisGet: async (idReport?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getdiagnosis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idReport !== undefined) {
                localVarQueryParameter['idReport'] = idReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets medical report insurance history list
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetinsuranceclienthistorylistGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getinsuranceclienthistorylist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets invoice client history list
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetinvoiceclienthistorylistGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getinvoiceclienthistorylist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets medical report by id
         * @param {string} [idReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportGet: async (idReport?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getmedicalreport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idReport !== undefined) {
                localVarQueryParameter['idReport'] = idReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets medical report by id
         * @param {string | null} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportdetailbyidGet: async (idMedicalReport?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getmedicalreportdetailbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets medical report insurance grid
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportinsurancegridGet: async (data?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getmedicalreportinsurancegrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (data !== undefined) {
                localVarQueryParameter['data'] = data;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets medical report lab labels
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportlablabelsGet: async (idMedicalReport?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getmedicalreportlablabels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idMedicalReport !== undefined) {
                localVarQueryParameter['idMedicalReport'] = idMedicalReport;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets medical reports by client
         * @param {string | null} [clientId] 
         * @param {boolean} [showInfoReports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportsbyclientGet: async (clientId?: string | null, showInfoReports?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getmedicalreportsbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (showInfoReports !== undefined) {
                localVarQueryParameter['showInfoReports'] = showInfoReports;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets supervisors for medical report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetsupervisorlistGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/getsupervisorlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves new/existing medical report
         * @param {APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto} [aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportSavePost: async (aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto?: APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/medicalreport/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalReportApi - functional programming interface
 * @export
 */
export const MedicalReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets diagnosis by medical report id
         * @param {string} [idReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetdiagnosisGet(idReport?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetdiagnosisGet(idReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetdiagnosisGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets medical report insurance history list
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetinsuranceclienthistorylistGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetinsuranceclienthistorylistGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetinsuranceclienthistorylistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets invoice client history list
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetinvoiceclienthistorylistGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOMedicalRecordInsuranceClientHistoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetinvoiceclienthistorylistGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetinvoiceclienthistorylistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets medical report by id
         * @param {string} [idReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetmedicalreportGet(idReport?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetmedicalreportGet(idReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetmedicalreportGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets medical report by id
         * @param {string | null} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetmedicalreportdetailbyidGet(idMedicalReport?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedMedicalReportDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetmedicalreportdetailbyidGet(idMedicalReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetmedicalreportdetailbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets medical report insurance grid
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetmedicalreportinsurancegridGet(data?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOMedicalRecordMedicalReportInsuranceDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetmedicalreportinsurancegridGet(data, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetmedicalreportinsurancegridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets medical report lab labels
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetmedicalreportlablabelsGet(idMedicalReport?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetmedicalreportlablabelsGet(idMedicalReport, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetmedicalreportlablabelsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets medical reports by client
         * @param {string | null} [clientId] 
         * @param {boolean} [showInfoReports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetmedicalreportsbyclientGet(clientId?: string | null, showInfoReports?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetmedicalreportsbyclientGet(clientId, showInfoReports, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetmedicalreportsbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets supervisors for medical report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportGetsupervisorlistGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportGetsupervisorlistGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportGetsupervisorlistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Saves new/existing medical report
         * @param {APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto} [aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalreportSavePost(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto?: APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalreportSavePost(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MedicalReportApi.apiMedicalreportSavePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MedicalReportApi - factory interface
 * @export
 */
export const MedicalReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets diagnosis by medical report id
         * @param {string} [idReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetdiagnosisGet(idReport?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTDIAGNOSEGRIDResult>> {
            return localVarFp.apiMedicalreportGetdiagnosisGet(idReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets medical report insurance history list
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetinsuranceclienthistorylistGet(idClient?: string, options?: any): AxiosPromise<Array<APIXdentDTOMedicalRecordInsuranceClientHistoryExtendedDto>> {
            return localVarFp.apiMedicalreportGetinsuranceclienthistorylistGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets invoice client history list
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetinvoiceclienthistorylistGet(idClient?: string, options?: any): AxiosPromise<Array<APIXdentDTOMedicalRecordInsuranceClientHistoryDto>> {
            return localVarFp.apiMedicalreportGetinvoiceclienthistorylistGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets medical report by id
         * @param {string} [idReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportGet(idReport?: string, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTFORMResult> {
            return localVarFp.apiMedicalreportGetmedicalreportGet(idReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets medical report by id
         * @param {string | null} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportdetailbyidGet(idMedicalReport?: string | null, options?: any): AxiosPromise<APISharedMedicalReportDetailDto> {
            return localVarFp.apiMedicalreportGetmedicalreportdetailbyidGet(idMedicalReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets medical report insurance grid
         * @param {string} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportinsurancegridGet(data?: string, options?: any): AxiosPromise<Array<APIXdentDTOMedicalRecordMedicalReportInsuranceDto>> {
            return localVarFp.apiMedicalreportGetmedicalreportinsurancegridGet(data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets medical report lab labels
         * @param {string} [idMedicalReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportlablabelsGet(idMedicalReport?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedLABORATORYLABELGRIDResult>> {
            return localVarFp.apiMedicalreportGetmedicalreportlablabelsGet(idMedicalReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets medical reports by client
         * @param {string | null} [clientId] 
         * @param {boolean} [showInfoReports] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetmedicalreportsbyclientGet(clientId?: string | null, showInfoReports?: boolean, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedMEDICALREPORTGRIDResult>> {
            return localVarFp.apiMedicalreportGetmedicalreportsbyclientGet(clientId, showInfoReports, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets supervisors for medical report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportGetsupervisorlistGet(options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedINVOICESUPERVISORGRIDResult>> {
            return localVarFp.apiMedicalreportGetsupervisorlistGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves new/existing medical report
         * @param {APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto} [aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalreportSavePost(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto?: APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiMedicalreportSavePost(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalReportApi - object-oriented interface
 * @export
 * @class MedicalReportApi
 * @extends {BaseAPI}
 */
export class MedicalReportApi extends BaseAPI {
    /**
     * 
     * @summary Gets diagnosis by medical report id
     * @param {string} [idReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetdiagnosisGet(idReport?: string, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetdiagnosisGet(idReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets medical report insurance history list
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetinsuranceclienthistorylistGet(idClient?: string, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetinsuranceclienthistorylistGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets invoice client history list
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetinvoiceclienthistorylistGet(idClient?: string, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetinvoiceclienthistorylistGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets medical report by id
     * @param {string} [idReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetmedicalreportGet(idReport?: string, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetmedicalreportGet(idReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets medical report by id
     * @param {string | null} [idMedicalReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetmedicalreportdetailbyidGet(idMedicalReport?: string | null, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetmedicalreportdetailbyidGet(idMedicalReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets medical report insurance grid
     * @param {string} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetmedicalreportinsurancegridGet(data?: string, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetmedicalreportinsurancegridGet(data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets medical report lab labels
     * @param {string} [idMedicalReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetmedicalreportlablabelsGet(idMedicalReport?: string, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetmedicalreportlablabelsGet(idMedicalReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets medical reports by client
     * @param {string | null} [clientId] 
     * @param {boolean} [showInfoReports] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetmedicalreportsbyclientGet(clientId?: string | null, showInfoReports?: boolean, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetmedicalreportsbyclientGet(clientId, showInfoReports, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets supervisors for medical report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportGetsupervisorlistGet(options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportGetsupervisorlistGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves new/existing medical report
     * @param {APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto} [aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalReportApi
     */
    public apiMedicalreportSavePost(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto?: APIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options?: AxiosRequestConfig) {
        return MedicalReportApiFp(this.configuration).apiMedicalreportSavePost(aPIXdentDTOMedicalRecordMedicalReportSaveWrapperDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MigrationApi - axios parameter creator
 * @export
 */
export const MigrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set appointment confirmation status from onlineBooking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMigrationGenerateidcompanytouserrefreshtokensGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/migration/generateidcompanytouserrefreshtokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesMigrationPost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/safesignatures/migration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MigrationApi - functional programming interface
 * @export
 */
export const MigrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MigrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Set appointment confirmation status from onlineBooking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMigrationGenerateidcompanytouserrefreshtokensGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMigrationGenerateidcompanytouserrefreshtokensGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MigrationApi.apiMigrationGenerateidcompanytouserrefreshtokensGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesMigrationPost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesMigrationPost(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MigrationApi.apiSafesignaturesMigrationPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MigrationApi - factory interface
 * @export
 */
export const MigrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MigrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Set appointment confirmation status from onlineBooking
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMigrationGenerateidcompanytouserrefreshtokensGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiMigrationGenerateidcompanytouserrefreshtokensGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesMigrationPost(body?: any, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiSafesignaturesMigrationPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MigrationApi - object-oriented interface
 * @export
 * @class MigrationApi
 * @extends {BaseAPI}
 */
export class MigrationApi extends BaseAPI {
    /**
     * 
     * @summary Set appointment confirmation status from onlineBooking
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MigrationApi
     */
    public apiMigrationGenerateidcompanytouserrefreshtokensGet(options?: AxiosRequestConfig) {
        return MigrationApiFp(this.configuration).apiMigrationGenerateidcompanytouserrefreshtokensGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MigrationApi
     */
    public apiSafesignaturesMigrationPost(body?: any, options?: AxiosRequestConfig) {
        return MigrationApiFp(this.configuration).apiSafesignaturesMigrationPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NCZIApi - axios parameter creator
 * @export
 */
export const NCZIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends report to NCZI
         * @param {APIXdentDTONCZIReportSendReportApiDto} [aPIXdentDTONCZIReportSendReportApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNczireportSendreportPost: async (aPIXdentDTONCZIReportSendReportApiDto?: APIXdentDTONCZIReportSendReportApiDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/nczireport/sendreport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTONCZIReportSendReportApiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NCZIApi - functional programming interface
 * @export
 */
export const NCZIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NCZIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sends report to NCZI
         * @param {APIXdentDTONCZIReportSendReportApiDto} [aPIXdentDTONCZIReportSendReportApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNczireportSendreportPost(aPIXdentDTONCZIReportSendReportApiDto?: APIXdentDTONCZIReportSendReportApiDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTONCZIReportSendReportApiResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNczireportSendreportPost(aPIXdentDTONCZIReportSendReportApiDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NCZIApi.apiNczireportSendreportPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NCZIApi - factory interface
 * @export
 */
export const NCZIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NCZIApiFp(configuration)
    return {
        /**
         * 
         * @summary Sends report to NCZI
         * @param {APIXdentDTONCZIReportSendReportApiDto} [aPIXdentDTONCZIReportSendReportApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNczireportSendreportPost(aPIXdentDTONCZIReportSendReportApiDto?: APIXdentDTONCZIReportSendReportApiDto, options?: any): AxiosPromise<APIXdentDTONCZIReportSendReportApiResponseDto> {
            return localVarFp.apiNczireportSendreportPost(aPIXdentDTONCZIReportSendReportApiDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NCZIApi - object-oriented interface
 * @export
 * @class NCZIApi
 * @extends {BaseAPI}
 */
export class NCZIApi extends BaseAPI {
    /**
     * 
     * @summary Sends report to NCZI
     * @param {APIXdentDTONCZIReportSendReportApiDto} [aPIXdentDTONCZIReportSendReportApiDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NCZIApi
     */
    public apiNczireportSendreportPost(aPIXdentDTONCZIReportSendReportApiDto?: APIXdentDTONCZIReportSendReportApiDto, options?: AxiosRequestConfig) {
        return NCZIApiFp(this.configuration).apiNczireportSendreportPost(aPIXdentDTONCZIReportSendReportApiDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OnlineBookingApi - axios parameter creator
 * @export
 */
export const OnlineBookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticate by credentials
         * @param {ApiOnlinebookingAuthonlinebookingiklientPostRequest} [apiOnlinebookingAuthonlinebookingiklientPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingAuthonlinebookingiklientPost: async (apiOnlinebookingAuthonlinebookingiklientPostRequest?: ApiOnlinebookingAuthonlinebookingiklientPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/authonlinebookingiklient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiOnlinebookingAuthonlinebookingiklientPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APISharedCancelOnlineBookingAppointmentDto} [aPISharedCancelOnlineBookingAppointmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingCancelappointmentPost: async (aPISharedCancelOnlineBookingAppointmentDto?: APISharedCancelOnlineBookingAppointmentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/cancelappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedCancelOnlineBookingAppointmentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create iklient link partnership request for iKlient form filling.
         * @param {APIXdentDTOOnlineBookingBookingPartnershipRequestDto} [aPIXdentDTOOnlineBookingBookingPartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingCreatepartnershiprequestPost: async (aPIXdentDTOOnlineBookingBookingPartnershipRequestDto?: APIXdentDTOOnlineBookingBookingPartnershipRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/createpartnershiprequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticate by credentials
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingExtendbearertokeniklientPost: async (aPISharedExtendBearerDto?: APISharedExtendBearerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/extendbearertokeniklient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedExtendBearerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetavailabletimesGet: async (idService?: string, idStaff?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/getavailabletimes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idService] 
         * @param {Array<string>} [idStaffs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetavailabletimesallGet: async (idService?: string, idStaffs?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/getavailabletimesall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idStaffs) {
                localVarQueryParameter['idStaffs'] = idStaffs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idLocation] 
         * @param {string | null} [date] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetavailabletimesinmonthGet: async (idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/getavailabletimesinmonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return thumbnail or full sized image from gallery by id as desired type
         * @param {string} [idFile] IdFile of desired thumbnail
         * @param {string} [size] Pixel dimensions of desired thumb. If this is null or empty, returns full sized image.
         * @param {ApiOnlinebookingGetfileGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64
         * @param {boolean | null} [isIklientGallery] Select whether to call iklient or xdent gallery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetfileGet: async (idFile?: string, size?: string, imageAsType?: ApiOnlinebookingGetfileGetImageAsTypeEnum, isIklientGallery?: boolean | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/getfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (imageAsType !== undefined) {
                localVarQueryParameter['imageAsType'] = imageAsType;
            }

            if (isIklientGallery !== undefined) {
                localVarQueryParameter['isIklientGallery'] = isIklientGallery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetonlinebookingiklientinfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/getonlinebookingiklientinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateTime] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingIsavailabletimeGet: async (dateTime?: string, idService?: string, idStaff?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/isavailabletime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateTime !== undefined) {
                localVarQueryParameter['dateTime'] = (dateTime as any instanceof Date) ?
                    (dateTime as any).toISOString() :
                    dateTime;
            }

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idLocation] 
         * @param {string | null} [date] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingIsavailabletimeinmonthGet: async (idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/isavailabletimeinmonth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [idCompany] 
         * @param {boolean} [newBooking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingMetadataGet: async (idCompany?: string, newBooking?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idCompany !== undefined) {
                localVarQueryParameter['idCompany'] = idCompany;
            }

            if (newBooking !== undefined) {
                localVarQueryParameter['newBooking'] = newBooking;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
         * @param {string} [idAppointment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingMetadatabyappointmentGet: async (idAppointment?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/metadatabyappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idAppointment !== undefined) {
                localVarQueryParameter['idAppointment'] = idAppointment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingMetadatabyminiwebsiteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/metadatabyminiwebsite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingSendunknownclientPost: async (aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/sendunknownclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedOnlineBookingSendByMiniWebsiteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingSendunknownclientv2Post: async (aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/onlinebooking/sendunknownclientv2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedOnlineBookingSendByMiniWebsiteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnlineBookingApi - functional programming interface
 * @export
 */
export const OnlineBookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnlineBookingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authenticate by credentials
         * @param {ApiOnlinebookingAuthonlinebookingiklientPostRequest} [apiOnlinebookingAuthonlinebookingiklientPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingAuthonlinebookingiklientPost(apiOnlinebookingAuthonlinebookingiklientPostRequest?: ApiOnlinebookingAuthonlinebookingiklientPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingAuthonlinebookingiklientPost(apiOnlinebookingAuthonlinebookingiklientPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingAuthonlinebookingiklientPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {APISharedCancelOnlineBookingAppointmentDto} [aPISharedCancelOnlineBookingAppointmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingCancelappointmentPost(aPISharedCancelOnlineBookingAppointmentDto?: APISharedCancelOnlineBookingAppointmentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingCancelappointmentPost(aPISharedCancelOnlineBookingAppointmentDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingCancelappointmentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create iklient link partnership request for iKlient form filling.
         * @param {APIXdentDTOOnlineBookingBookingPartnershipRequestDto} [aPIXdentDTOOnlineBookingBookingPartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingCreatepartnershiprequestPost(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto?: APIXdentDTOOnlineBookingBookingPartnershipRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingCreatepartnershiprequestPost(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingCreatepartnershiprequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Authenticate by credentials
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingExtendbearertokeniklientPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingExtendbearertokeniklientPost(aPISharedExtendBearerDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingExtendbearertokeniklientPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingGetavailabletimesGet(idService?: string, idStaff?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingGetavailabletimesGet(idService, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingGetavailabletimesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [idService] 
         * @param {Array<string>} [idStaffs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingGetavailabletimesallGet(idService?: string, idStaffs?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingGetavailabletimesallGet(idService, idStaffs, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingGetavailabletimesallGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [idLocation] 
         * @param {string | null} [date] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingGetavailabletimesinmonthGet(idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingGetavailabletimesinmonthGet(idLocation, date, idService, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingGetavailabletimesinmonthGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return thumbnail or full sized image from gallery by id as desired type
         * @param {string} [idFile] IdFile of desired thumbnail
         * @param {string} [size] Pixel dimensions of desired thumb. If this is null or empty, returns full sized image.
         * @param {ApiOnlinebookingGetfileGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64
         * @param {boolean | null} [isIklientGallery] Select whether to call iklient or xdent gallery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingGetfileGet(idFile?: string, size?: string, imageAsType?: ApiOnlinebookingGetfileGetImageAsTypeEnum, isIklientGallery?: boolean | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingGetfileGet(idFile, size, imageAsType, isIklientGallery, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingGetfileGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingGetonlinebookingiklientinfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingGetonlinebookingiklientinfoGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingGetonlinebookingiklientinfoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [dateTime] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingIsavailabletimeGet(dateTime?: string, idService?: string, idStaff?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingIsavailabletimeGet(dateTime, idService, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingIsavailabletimeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [idLocation] 
         * @param {string | null} [date] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingIsavailabletimeinmonthGet(idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingIsavailabletimeinmonthGet(idLocation, date, idService, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingIsavailabletimeinmonthGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} [idCompany] 
         * @param {boolean} [newBooking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingMetadataGet(idCompany?: string, newBooking?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDTOOnlineBookingOnlineBookingMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingMetadataGet(idCompany, newBooking, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingMetadataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
         * @param {string} [idAppointment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingMetadatabyappointmentGet(idAppointment?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDTOOnlineBookingOnlineBookingMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingMetadatabyappointmentGet(idAppointment, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingMetadatabyappointmentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingMetadatabyminiwebsiteGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDTOOnlineBookingOnlineBookingMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingMetadatabyminiwebsiteGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingMetadatabyminiwebsiteGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingSendunknownclientPost(aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingSendunknownclientPost(aPISharedOnlineBookingSendByMiniWebsiteDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingSendunknownclientPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnlinebookingSendunknownclientv2Post(aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedOnlineBookingResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnlinebookingSendunknownclientv2Post(aPISharedOnlineBookingSendByMiniWebsiteDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OnlineBookingApi.apiOnlinebookingSendunknownclientv2Post']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OnlineBookingApi - factory interface
 * @export
 */
export const OnlineBookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnlineBookingApiFp(configuration)
    return {
        /**
         * 
         * @summary Authenticate by credentials
         * @param {ApiOnlinebookingAuthonlinebookingiklientPostRequest} [apiOnlinebookingAuthonlinebookingiklientPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingAuthonlinebookingiklientPost(apiOnlinebookingAuthonlinebookingiklientPostRequest?: ApiOnlinebookingAuthonlinebookingiklientPostRequest, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiOnlinebookingAuthonlinebookingiklientPost(apiOnlinebookingAuthonlinebookingiklientPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APISharedCancelOnlineBookingAppointmentDto} [aPISharedCancelOnlineBookingAppointmentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingCancelappointmentPost(aPISharedCancelOnlineBookingAppointmentDto?: APISharedCancelOnlineBookingAppointmentDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiOnlinebookingCancelappointmentPost(aPISharedCancelOnlineBookingAppointmentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create iklient link partnership request for iKlient form filling.
         * @param {APIXdentDTOOnlineBookingBookingPartnershipRequestDto} [aPIXdentDTOOnlineBookingBookingPartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingCreatepartnershiprequestPost(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto?: APIXdentDTOOnlineBookingBookingPartnershipRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiOnlinebookingCreatepartnershiprequestPost(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticate by credentials
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingExtendbearertokeniklientPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiOnlinebookingExtendbearertokeniklientPost(aPISharedExtendBearerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetavailabletimesGet(idService?: string, idStaff?: string, options?: any): AxiosPromise<Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem>> {
            return localVarFp.apiOnlinebookingGetavailabletimesGet(idService, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idService] 
         * @param {Array<string>} [idStaffs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetavailabletimesallGet(idService?: string, idStaffs?: Array<string>, options?: any): AxiosPromise<Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem>> {
            return localVarFp.apiOnlinebookingGetavailabletimesallGet(idService, idStaffs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idLocation] 
         * @param {string | null} [date] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetavailabletimesinmonthGet(idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options?: any): AxiosPromise<Array<APISharedModelsOnlineBookingOnlineBookingDtoOpenDayItem>> {
            return localVarFp.apiOnlinebookingGetavailabletimesinmonthGet(idLocation, date, idService, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return thumbnail or full sized image from gallery by id as desired type
         * @param {string} [idFile] IdFile of desired thumbnail
         * @param {string} [size] Pixel dimensions of desired thumb. If this is null or empty, returns full sized image.
         * @param {ApiOnlinebookingGetfileGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64
         * @param {boolean | null} [isIklientGallery] Select whether to call iklient or xdent gallery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetfileGet(idFile?: string, size?: string, imageAsType?: ApiOnlinebookingGetfileGetImageAsTypeEnum, isIklientGallery?: boolean | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiOnlinebookingGetfileGet(idFile, size, imageAsType, isIklientGallery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingGetonlinebookingiklientinfoGet(options?: any): AxiosPromise<APIXdentDTOOnlineBookingOnlineBookingIklientInfoDto> {
            return localVarFp.apiOnlinebookingGetonlinebookingiklientinfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateTime] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingIsavailabletimeGet(dateTime?: string, idService?: string, idStaff?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOnlinebookingIsavailabletimeGet(dateTime, idService, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idLocation] 
         * @param {string | null} [date] 
         * @param {string} [idService] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingIsavailabletimeinmonthGet(idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiOnlinebookingIsavailabletimeinmonthGet(idLocation, date, idService, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [idCompany] 
         * @param {boolean} [newBooking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingMetadataGet(idCompany?: string, newBooking?: boolean, options?: any): AxiosPromise<APISharedDTOOnlineBookingOnlineBookingMetadataDto> {
            return localVarFp.apiOnlinebookingMetadataGet(idCompany, newBooking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
         * @param {string} [idAppointment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingMetadatabyappointmentGet(idAppointment?: string, options?: any): AxiosPromise<APISharedDTOOnlineBookingOnlineBookingMetadataDto> {
            return localVarFp.apiOnlinebookingMetadatabyappointmentGet(idAppointment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingMetadatabyminiwebsiteGet(options?: any): AxiosPromise<APISharedDTOOnlineBookingOnlineBookingMetadataDto> {
            return localVarFp.apiOnlinebookingMetadatabyminiwebsiteGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingSendunknownclientPost(aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOnlinebookingSendunknownclientPost(aPISharedOnlineBookingSendByMiniWebsiteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnlinebookingSendunknownclientv2Post(aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options?: any): AxiosPromise<APISharedOnlineBookingResultDto> {
            return localVarFp.apiOnlinebookingSendunknownclientv2Post(aPISharedOnlineBookingSendByMiniWebsiteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnlineBookingApi - object-oriented interface
 * @export
 * @class OnlineBookingApi
 * @extends {BaseAPI}
 */
export class OnlineBookingApi extends BaseAPI {
    /**
     * 
     * @summary Authenticate by credentials
     * @param {ApiOnlinebookingAuthonlinebookingiklientPostRequest} [apiOnlinebookingAuthonlinebookingiklientPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingAuthonlinebookingiklientPost(apiOnlinebookingAuthonlinebookingiklientPostRequest?: ApiOnlinebookingAuthonlinebookingiklientPostRequest, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingAuthonlinebookingiklientPost(apiOnlinebookingAuthonlinebookingiklientPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {APISharedCancelOnlineBookingAppointmentDto} [aPISharedCancelOnlineBookingAppointmentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingCancelappointmentPost(aPISharedCancelOnlineBookingAppointmentDto?: APISharedCancelOnlineBookingAppointmentDto, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingCancelappointmentPost(aPISharedCancelOnlineBookingAppointmentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create iklient link partnership request for iKlient form filling.
     * @param {APIXdentDTOOnlineBookingBookingPartnershipRequestDto} [aPIXdentDTOOnlineBookingBookingPartnershipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingCreatepartnershiprequestPost(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto?: APIXdentDTOOnlineBookingBookingPartnershipRequestDto, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingCreatepartnershiprequestPost(aPIXdentDTOOnlineBookingBookingPartnershipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticate by credentials
     * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingExtendbearertokeniklientPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingExtendbearertokeniklientPost(aPISharedExtendBearerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idService] 
     * @param {string} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingGetavailabletimesGet(idService?: string, idStaff?: string, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingGetavailabletimesGet(idService, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idService] 
     * @param {Array<string>} [idStaffs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingGetavailabletimesallGet(idService?: string, idStaffs?: Array<string>, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingGetavailabletimesallGet(idService, idStaffs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idLocation] 
     * @param {string | null} [date] 
     * @param {string} [idService] 
     * @param {string} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingGetavailabletimesinmonthGet(idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingGetavailabletimesinmonthGet(idLocation, date, idService, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return thumbnail or full sized image from gallery by id as desired type
     * @param {string} [idFile] IdFile of desired thumbnail
     * @param {string} [size] Pixel dimensions of desired thumb. If this is null or empty, returns full sized image.
     * @param {ApiOnlinebookingGetfileGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64
     * @param {boolean | null} [isIklientGallery] Select whether to call iklient or xdent gallery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingGetfileGet(idFile?: string, size?: string, imageAsType?: ApiOnlinebookingGetfileGetImageAsTypeEnum, isIklientGallery?: boolean | null, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingGetfileGet(idFile, size, imageAsType, isIklientGallery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingGetonlinebookingiklientinfoGet(options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingGetonlinebookingiklientinfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateTime] 
     * @param {string} [idService] 
     * @param {string} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingIsavailabletimeGet(dateTime?: string, idService?: string, idStaff?: string, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingIsavailabletimeGet(dateTime, idService, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idLocation] 
     * @param {string | null} [date] 
     * @param {string} [idService] 
     * @param {string} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingIsavailabletimeinmonthGet(idLocation?: string, date?: string | null, idService?: string, idStaff?: string, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingIsavailabletimeinmonthGet(idLocation, date, idService, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [idCompany] 
     * @param {boolean} [newBooking] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingMetadataGet(idCompany?: string, newBooking?: boolean, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingMetadataGet(idCompany, newBooking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
     * @param {string} [idAppointment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingMetadatabyappointmentGet(idAppointment?: string, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingMetadatabyappointmentGet(idAppointment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets online booking metadata for online booking by host url prefix associated with idCompany
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingMetadatabyminiwebsiteGet(options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingMetadatabyminiwebsiteGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingSendunknownclientPost(aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingSendunknownclientPost(aPISharedOnlineBookingSendByMiniWebsiteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {APISharedOnlineBookingSendByMiniWebsiteDto} [aPISharedOnlineBookingSendByMiniWebsiteDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnlineBookingApi
     */
    public apiOnlinebookingSendunknownclientv2Post(aPISharedOnlineBookingSendByMiniWebsiteDto?: APISharedOnlineBookingSendByMiniWebsiteDto, options?: AxiosRequestConfig) {
        return OnlineBookingApiFp(this.configuration).apiOnlinebookingSendunknownclientv2Post(aPISharedOnlineBookingSendByMiniWebsiteDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiOnlinebookingGetfileGetImageAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiOnlinebookingGetfileGetImageAsTypeEnum = typeof ApiOnlinebookingGetfileGetImageAsTypeEnum[keyof typeof ApiOnlinebookingGetfileGetImageAsTypeEnum];


/**
 * PartnershipRequestApi - axios parameter creator
 * @export
 */
export const PartnershipRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create partnership request
         * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnershiprequestCreatepartnershiprequestPost: async (aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partnershiprequest/createpartnershiprequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedCreatePartnershipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create partnership password reset request
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnershiprequestCreatepasswordresetrequestGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/partnershiprequest/createpasswordresetrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create partnership request
         * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpartnershiprequestCreatepartnershiprequestPost: async (aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpartnershiprequest/createpartnershiprequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedCreatePartnershipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create partnership password reset request
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpartnershiprequestCreatepasswordresetrequestGet: async (idClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpartnershiprequest/createpasswordresetrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnershipRequestApi - functional programming interface
 * @export
 */
export const PartnershipRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnershipRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create partnership request
         * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PartnershipRequestApi.apiPartnershiprequestCreatepartnershiprequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create partnership password reset request
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPartnershiprequestCreatepasswordresetrequestGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPartnershiprequestCreatepasswordresetrequestGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PartnershipRequestApi.apiPartnershiprequestCreatepasswordresetrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create partnership request
         * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PartnershipRequestApi.apiXdentpartnershiprequestCreatepartnershiprequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create partnership password reset request
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpartnershiprequestCreatepasswordresetrequestGet(idClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpartnershiprequestCreatepasswordresetrequestGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PartnershipRequestApi.apiXdentpartnershiprequestCreatepasswordresetrequestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PartnershipRequestApi - factory interface
 * @export
 */
export const PartnershipRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnershipRequestApiFp(configuration)
    return {
        /**
         * 
         * @summary Create partnership request
         * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiPartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create partnership password reset request
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPartnershiprequestCreatepasswordresetrequestGet(idClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiPartnershiprequestCreatepasswordresetrequestGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create partnership request
         * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiXdentpartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create partnership password reset request
         * @param {string} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpartnershiprequestCreatepasswordresetrequestGet(idClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiXdentpartnershiprequestCreatepasswordresetrequestGet(idClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnershipRequestApi - object-oriented interface
 * @export
 * @class PartnershipRequestApi
 * @extends {BaseAPI}
 */
export class PartnershipRequestApi extends BaseAPI {
    /**
     * 
     * @summary Create partnership request
     * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipRequestApi
     */
    public apiPartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options?: AxiosRequestConfig) {
        return PartnershipRequestApiFp(this.configuration).apiPartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create partnership password reset request
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipRequestApi
     */
    public apiPartnershiprequestCreatepasswordresetrequestGet(idClient?: string, options?: AxiosRequestConfig) {
        return PartnershipRequestApiFp(this.configuration).apiPartnershiprequestCreatepasswordresetrequestGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create partnership request
     * @param {APISharedCreatePartnershipRequestDto} [aPISharedCreatePartnershipRequestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipRequestApi
     */
    public apiXdentpartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto?: APISharedCreatePartnershipRequestDto, options?: AxiosRequestConfig) {
        return PartnershipRequestApiFp(this.configuration).apiXdentpartnershiprequestCreatepartnershiprequestPost(aPISharedCreatePartnershipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create partnership password reset request
     * @param {string} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnershipRequestApi
     */
    public apiXdentpartnershiprequestCreatepasswordresetrequestGet(idClient?: string, options?: AxiosRequestConfig) {
        return PartnershipRequestApiFp(this.configuration).apiXdentpartnershiprequestCreatepasswordresetrequestGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentGatewayApi - axios parameter creator
 * @export
 */
export const PaymentGatewayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary For testing if communication is OKAY.
         * @param {string} [idCompany] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayEchoGet: async (idCompany?: string, idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/paymentgateway/echo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idCompany !== undefined) {
                localVarQueryParameter['idCompany'] = idCompany;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary For testing if communication is OKAY.
         * @param {APIXdentDTOPaymentGatewayEchoApiDto} [aPIXdentDTOPaymentGatewayEchoApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayEchoPost: async (aPIXdentDTOPaymentGatewayEchoApiDto?: APIXdentDTOPaymentGatewayEchoApiDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/paymentgateway/echo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOPaymentGatewayEchoApiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Main endpoint -> returns URL to redirect FE to.
         * @param {APIXdentDTOPaymentGatewayPaymentInitApiDto} [aPIXdentDTOPaymentGatewayPaymentInitApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayPaymentinitPost: async (aPIXdentDTOPaymentGatewayPaymentInitApiDto?: APIXdentDTOPaymentGatewayPaymentInitApiDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/paymentgateway/paymentinit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOPaymentGatewayPaymentInitApiDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the status of the payment.
         * @param {string} [idAppointment] 
         * @param {string} [payId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayPaymentstatusGet: async (idAppointment?: string, payId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/paymentgateway/paymentstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idAppointment !== undefined) {
                localVarQueryParameter['idAppointment'] = idAppointment;
            }

            if (payId !== undefined) {
                localVarQueryParameter['payId'] = payId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Just for my internal testing. Throws error.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/paymentgateway/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentGatewayApi - functional programming interface
 * @export
 */
export const PaymentGatewayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentGatewayApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary For testing if communication is OKAY.
         * @param {string} [idCompany] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentgatewayEchoGet(idCompany?: string, idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPaymentGatewayEchoApiResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentgatewayEchoGet(idCompany, idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PaymentGatewayApi.apiPaymentgatewayEchoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary For testing if communication is OKAY.
         * @param {APIXdentDTOPaymentGatewayEchoApiDto} [aPIXdentDTOPaymentGatewayEchoApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentgatewayEchoPost(aPIXdentDTOPaymentGatewayEchoApiDto?: APIXdentDTOPaymentGatewayEchoApiDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPaymentGatewayEchoApiResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentgatewayEchoPost(aPIXdentDTOPaymentGatewayEchoApiDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PaymentGatewayApi.apiPaymentgatewayEchoPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Main endpoint -> returns URL to redirect FE to.
         * @param {APIXdentDTOPaymentGatewayPaymentInitApiDto} [aPIXdentDTOPaymentGatewayPaymentInitApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentgatewayPaymentinitPost(aPIXdentDTOPaymentGatewayPaymentInitApiDto?: APIXdentDTOPaymentGatewayPaymentInitApiDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPaymentGatewayPaymentInitApiResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentgatewayPaymentinitPost(aPIXdentDTOPaymentGatewayPaymentInitApiDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PaymentGatewayApi.apiPaymentgatewayPaymentinitPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns the status of the payment.
         * @param {string} [idAppointment] 
         * @param {string} [payId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentgatewayPaymentstatusGet(idAppointment?: string, payId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentgatewayPaymentstatusGet(idAppointment, payId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PaymentGatewayApi.apiPaymentgatewayPaymentstatusGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Just for my internal testing. Throws error.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentgatewayTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentgatewayTestGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PaymentGatewayApi.apiPaymentgatewayTestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PaymentGatewayApi - factory interface
 * @export
 */
export const PaymentGatewayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentGatewayApiFp(configuration)
    return {
        /**
         * 
         * @summary For testing if communication is OKAY.
         * @param {string} [idCompany] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayEchoGet(idCompany?: string, idLocation?: string, options?: any): AxiosPromise<APIXdentDTOPaymentGatewayEchoApiResponseDto> {
            return localVarFp.apiPaymentgatewayEchoGet(idCompany, idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary For testing if communication is OKAY.
         * @param {APIXdentDTOPaymentGatewayEchoApiDto} [aPIXdentDTOPaymentGatewayEchoApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayEchoPost(aPIXdentDTOPaymentGatewayEchoApiDto?: APIXdentDTOPaymentGatewayEchoApiDto, options?: any): AxiosPromise<APIXdentDTOPaymentGatewayEchoApiResponseDto> {
            return localVarFp.apiPaymentgatewayEchoPost(aPIXdentDTOPaymentGatewayEchoApiDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Main endpoint -> returns URL to redirect FE to.
         * @param {APIXdentDTOPaymentGatewayPaymentInitApiDto} [aPIXdentDTOPaymentGatewayPaymentInitApiDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayPaymentinitPost(aPIXdentDTOPaymentGatewayPaymentInitApiDto?: APIXdentDTOPaymentGatewayPaymentInitApiDto, options?: any): AxiosPromise<APIXdentDTOPaymentGatewayPaymentInitApiResponseDto> {
            return localVarFp.apiPaymentgatewayPaymentinitPost(aPIXdentDTOPaymentGatewayPaymentInitApiDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the status of the payment.
         * @param {string} [idAppointment] 
         * @param {string} [payId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayPaymentstatusGet(idAppointment?: string, payId?: string, options?: any): AxiosPromise<APIXdentDTOPaymentGatewayPaymentStatusApiResponseDto> {
            return localVarFp.apiPaymentgatewayPaymentstatusGet(idAppointment, payId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Just for my internal testing. Throws error.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentgatewayTestGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiPaymentgatewayTestGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentGatewayApi - object-oriented interface
 * @export
 * @class PaymentGatewayApi
 * @extends {BaseAPI}
 */
export class PaymentGatewayApi extends BaseAPI {
    /**
     * 
     * @summary For testing if communication is OKAY.
     * @param {string} [idCompany] 
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentGatewayApi
     */
    public apiPaymentgatewayEchoGet(idCompany?: string, idLocation?: string, options?: AxiosRequestConfig) {
        return PaymentGatewayApiFp(this.configuration).apiPaymentgatewayEchoGet(idCompany, idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary For testing if communication is OKAY.
     * @param {APIXdentDTOPaymentGatewayEchoApiDto} [aPIXdentDTOPaymentGatewayEchoApiDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentGatewayApi
     */
    public apiPaymentgatewayEchoPost(aPIXdentDTOPaymentGatewayEchoApiDto?: APIXdentDTOPaymentGatewayEchoApiDto, options?: AxiosRequestConfig) {
        return PaymentGatewayApiFp(this.configuration).apiPaymentgatewayEchoPost(aPIXdentDTOPaymentGatewayEchoApiDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Main endpoint -> returns URL to redirect FE to.
     * @param {APIXdentDTOPaymentGatewayPaymentInitApiDto} [aPIXdentDTOPaymentGatewayPaymentInitApiDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentGatewayApi
     */
    public apiPaymentgatewayPaymentinitPost(aPIXdentDTOPaymentGatewayPaymentInitApiDto?: APIXdentDTOPaymentGatewayPaymentInitApiDto, options?: AxiosRequestConfig) {
        return PaymentGatewayApiFp(this.configuration).apiPaymentgatewayPaymentinitPost(aPIXdentDTOPaymentGatewayPaymentInitApiDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the status of the payment.
     * @param {string} [idAppointment] 
     * @param {string} [payId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentGatewayApi
     */
    public apiPaymentgatewayPaymentstatusGet(idAppointment?: string, payId?: string, options?: AxiosRequestConfig) {
        return PaymentGatewayApiFp(this.configuration).apiPaymentgatewayPaymentstatusGet(idAppointment, payId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Just for my internal testing. Throws error.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentGatewayApi
     */
    public apiPaymentgatewayTestGet(options?: AxiosRequestConfig) {
        return PaymentGatewayApiFp(this.configuration).apiPaymentgatewayTestGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonalNumberApi - axios parameter creator
 * @export
 */
export const PersonalNumberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Analyzes personal number for dateOfBirth, gender and other properties.
         * @param {string} [personalNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonalnumberAnalyzeGet: async (personalNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/personalnumber/analyze`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (personalNumber !== undefined) {
                localVarQueryParameter['personalNumber'] = personalNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalNumberApi - functional programming interface
 * @export
 */
export const PersonalNumberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalNumberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Analyzes personal number for dateOfBirth, gender and other properties.
         * @param {string} [personalNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPersonalnumberAnalyzeGet(personalNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPersonalnumberAnalyzeGet(personalNumber, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PersonalNumberApi.apiPersonalnumberAnalyzeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PersonalNumberApi - factory interface
 * @export
 */
export const PersonalNumberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalNumberApiFp(configuration)
    return {
        /**
         * 
         * @summary Analyzes personal number for dateOfBirth, gender and other properties.
         * @param {string} [personalNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPersonalnumberAnalyzeGet(personalNumber?: string, options?: any): AxiosPromise<APIXdentDTOPersonalNumberPersonalNumberAnalyzeDto> {
            return localVarFp.apiPersonalnumberAnalyzeGet(personalNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonalNumberApi - object-oriented interface
 * @export
 * @class PersonalNumberApi
 * @extends {BaseAPI}
 */
export class PersonalNumberApi extends BaseAPI {
    /**
     * 
     * @summary Analyzes personal number for dateOfBirth, gender and other properties.
     * @param {string} [personalNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalNumberApi
     */
    public apiPersonalnumberAnalyzeGet(personalNumber?: string, options?: AxiosRequestConfig) {
        return PersonalNumberApiFp(this.configuration).apiPersonalnumberAnalyzeGet(personalNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PremiumApi - axios parameter creator
 * @export
 */
export const PremiumApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets premium services for logged user by company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPremiumGetcompanypremiumservicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/premium/getcompanypremiumservices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PremiumApi - functional programming interface
 * @export
 */
export const PremiumApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PremiumApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets premium services for logged user by company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPremiumGetcompanypremiumservicesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPremiumPremiumServicesSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPremiumGetcompanypremiumservicesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PremiumApi.apiPremiumGetcompanypremiumservicesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PremiumApi - factory interface
 * @export
 */
export const PremiumApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PremiumApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets premium services for logged user by company.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPremiumGetcompanypremiumservicesGet(options?: any): AxiosPromise<APIXdentDTOPremiumPremiumServicesSettings> {
            return localVarFp.apiPremiumGetcompanypremiumservicesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PremiumApi - object-oriented interface
 * @export
 * @class PremiumApi
 * @extends {BaseAPI}
 */
export class PremiumApi extends BaseAPI {
    /**
     * 
     * @summary Gets premium services for logged user by company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PremiumApi
     */
    public apiPremiumGetcompanypremiumservicesGet(options?: AxiosRequestConfig) {
        return PremiumApiFp(this.configuration).apiPremiumGetcompanypremiumservicesGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PrintFormApi - axios parameter creator
 * @export
 */
export const PrintFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate form PDF
         * @param {string} [idXdentUser] 
         * @param {APISharedFormRequest} [aPISharedFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrintformFormPost: async (idXdentUser?: string, aPISharedFormRequest?: APISharedFormRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printform/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentUser != null) {
                localVarHeaderParameter['idXdentUser'] = String(idXdentUser);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the print form overview.
         * @param {string | null} [clientId] The client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrintformGetprintformoverviewGet: async (clientId?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printform/getprintformoverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate PDF. General printform controller (tiskopisy). Accept whatever entity type is possible to print. Forms/Invoice/LaboratoryLabel..
         * @param {string} [idEntity] Entity id tied to printed document.
         * @param {ApiPrintformPdfGetPrintFormTypeEnum} [printFormType] Possible values &#x3D; [0 - OldRecipe, 1 - Invoice, 2 - LaboratoryLabel, 3 - DoctorPass, 4 - TransportOrder, 5 - MedicalExamination, 6 - MedicalReport, 7 - MedicalReportExternal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrintformPdfGet: async (idEntity?: string, printFormType?: ApiPrintformPdfGetPrintFormTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/printform/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idEntity !== undefined) {
                localVarQueryParameter['idEntity'] = idEntity;
            }

            if (printFormType !== undefined) {
                localVarQueryParameter['printFormType'] = printFormType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrintFormApi - functional programming interface
 * @export
 */
export const PrintFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrintFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate form PDF
         * @param {string} [idXdentUser] 
         * @param {APISharedFormRequest} [aPISharedFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrintformFormPost(idXdentUser?: string, aPISharedFormRequest?: APISharedFormRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrintformFormPost(idXdentUser, aPISharedFormRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PrintFormApi.apiPrintformFormPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets the print form overview.
         * @param {string | null} [clientId] The client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrintformGetprintformoverviewGet(clientId?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPrintformsPrintFormOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrintformGetprintformoverviewGet(clientId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PrintFormApi.apiPrintformGetprintformoverviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Generate PDF. General printform controller (tiskopisy). Accept whatever entity type is possible to print. Forms/Invoice/LaboratoryLabel..
         * @param {string} [idEntity] Entity id tied to printed document.
         * @param {ApiPrintformPdfGetPrintFormTypeEnum} [printFormType] Possible values &#x3D; [0 - OldRecipe, 1 - Invoice, 2 - LaboratoryLabel, 3 - DoctorPass, 4 - TransportOrder, 5 - MedicalExamination, 6 - MedicalReport, 7 - MedicalReportExternal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrintformPdfGet(idEntity?: string, printFormType?: ApiPrintformPdfGetPrintFormTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrintformPdfGet(idEntity, printFormType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PrintFormApi.apiPrintformPdfGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PrintFormApi - factory interface
 * @export
 */
export const PrintFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrintFormApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate form PDF
         * @param {string} [idXdentUser] 
         * @param {APISharedFormRequest} [aPISharedFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrintformFormPost(idXdentUser?: string, aPISharedFormRequest?: APISharedFormRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrintformFormPost(idXdentUser, aPISharedFormRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the print form overview.
         * @param {string | null} [clientId] The client identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrintformGetprintformoverviewGet(clientId?: string | null, options?: any): AxiosPromise<APIXdentDTOPrintformsPrintFormOverviewDto> {
            return localVarFp.apiPrintformGetprintformoverviewGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate PDF. General printform controller (tiskopisy). Accept whatever entity type is possible to print. Forms/Invoice/LaboratoryLabel..
         * @param {string} [idEntity] Entity id tied to printed document.
         * @param {ApiPrintformPdfGetPrintFormTypeEnum} [printFormType] Possible values &#x3D; [0 - OldRecipe, 1 - Invoice, 2 - LaboratoryLabel, 3 - DoctorPass, 4 - TransportOrder, 5 - MedicalExamination, 6 - MedicalReport, 7 - MedicalReportExternal]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrintformPdfGet(idEntity?: string, printFormType?: ApiPrintformPdfGetPrintFormTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrintformPdfGet(idEntity, printFormType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrintFormApi - object-oriented interface
 * @export
 * @class PrintFormApi
 * @extends {BaseAPI}
 */
export class PrintFormApi extends BaseAPI {
    /**
     * 
     * @summary Generate form PDF
     * @param {string} [idXdentUser] 
     * @param {APISharedFormRequest} [aPISharedFormRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintFormApi
     */
    public apiPrintformFormPost(idXdentUser?: string, aPISharedFormRequest?: APISharedFormRequest, options?: AxiosRequestConfig) {
        return PrintFormApiFp(this.configuration).apiPrintformFormPost(idXdentUser, aPISharedFormRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the print form overview.
     * @param {string | null} [clientId] The client identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintFormApi
     */
    public apiPrintformGetprintformoverviewGet(clientId?: string | null, options?: AxiosRequestConfig) {
        return PrintFormApiFp(this.configuration).apiPrintformGetprintformoverviewGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate PDF. General printform controller (tiskopisy). Accept whatever entity type is possible to print. Forms/Invoice/LaboratoryLabel..
     * @param {string} [idEntity] Entity id tied to printed document.
     * @param {ApiPrintformPdfGetPrintFormTypeEnum} [printFormType] Possible values &#x3D; [0 - OldRecipe, 1 - Invoice, 2 - LaboratoryLabel, 3 - DoctorPass, 4 - TransportOrder, 5 - MedicalExamination, 6 - MedicalReport, 7 - MedicalReportExternal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrintFormApi
     */
    public apiPrintformPdfGet(idEntity?: string, printFormType?: ApiPrintformPdfGetPrintFormTypeEnum, options?: AxiosRequestConfig) {
        return PrintFormApiFp(this.configuration).apiPrintformPdfGet(idEntity, printFormType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiPrintformPdfGetPrintFormTypeEnum = {
    OldRecipe: 'OldRecipe',
    Invoice: 'Invoice',
    LaboratoryLabel: 'LaboratoryLabel',
    DoctorPass: 'DoctorPass',
    TransportOrder: 'TransportOrder',
    MedicalExamination: 'MedicalExamination',
    MedicalReport: 'MedicalReport',
    MedicalReportExternal: 'MedicalReportExternal'
} as const;
export type ApiPrintformPdfGetPrintFormTypeEnum = typeof ApiPrintformPdfGetPrintFormTypeEnum[keyof typeof ApiPrintformPdfGetPrintFormTypeEnum];


/**
 * PublicResourcesApi - axios parameter creator
 * @export
 */
export const PublicResourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns xdent favicon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicresourceGetxdentfaviconGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publicresource/getxdentfavicon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicResourcesApi - functional programming interface
 * @export
 */
export const PublicResourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicResourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns xdent favicon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicresourceGetxdentfaviconGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicresourceGetxdentfaviconGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PublicResourcesApi.apiPublicresourceGetxdentfaviconGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PublicResourcesApi - factory interface
 * @export
 */
export const PublicResourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicResourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns xdent favicon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicresourceGetxdentfaviconGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiPublicresourceGetxdentfaviconGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicResourcesApi - object-oriented interface
 * @export
 * @class PublicResourcesApi
 * @extends {BaseAPI}
 */
export class PublicResourcesApi extends BaseAPI {
    /**
     * 
     * @summary Returns xdent favicon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicResourcesApi
     */
    public apiPublicresourceGetxdentfaviconGet(options?: AxiosRequestConfig) {
        return PublicResourcesApiFp(this.configuration).apiPublicresourceGetxdentfaviconGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RatingApi - axios parameter creator
 * @export
 */
export const RatingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the rating configuration for mobile app.  Maximum for apple is 3 and for google is 12 per year. (as of mid 2024)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRatingConfigurationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rating/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RatingApi - functional programming interface
 * @export
 */
export const RatingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RatingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the rating configuration for mobile app.  Maximum for apple is 3 and for google is 12 per year. (as of mid 2024)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRatingConfigurationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedDTORatingRatingConfigurationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRatingConfigurationGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RatingApi.apiRatingConfigurationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RatingApi - factory interface
 * @export
 */
export const RatingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RatingApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the rating configuration for mobile app.  Maximum for apple is 3 and for google is 12 per year. (as of mid 2024)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRatingConfigurationGet(options?: any): AxiosPromise<APISharedDTORatingRatingConfigurationDto> {
            return localVarFp.apiRatingConfigurationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RatingApi - object-oriented interface
 * @export
 * @class RatingApi
 * @extends {BaseAPI}
 */
export class RatingApi extends BaseAPI {
    /**
     * 
     * @summary Gets the rating configuration for mobile app.  Maximum for apple is 3 and for google is 12 per year. (as of mid 2024)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RatingApi
     */
    public apiRatingConfigurationGet(options?: AxiosRequestConfig) {
        return RatingApiFp(this.configuration).apiRatingConfigurationGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all roles for logged user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleGetuserrolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/getuserroles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tests if provided roles belog to logged user
         * @param {Array<string>} [roles] Query array of roles
         * @param {boolean} [strict] If multiple roles are provided, selects whether logic AND or logic OR will be used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIsuserinrolesGet: async (roles?: Array<string>, strict?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/isuserinroles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }

            if (strict !== undefined) {
                localVarQueryParameter['strict'] = strict;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all roles for logged user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleGetuserrolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleGetuserrolesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.apiRoleGetuserrolesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Tests if provided roles belog to logged user
         * @param {Array<string>} [roles] Query array of roles
         * @param {boolean} [strict] If multiple roles are provided, selects whether logic AND or logic OR will be used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleIsuserinrolesGet(roles?: Array<string>, strict?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleIsuserinrolesGet(roles, strict, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RoleApi.apiRoleIsuserinrolesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all roles for logged user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleGetuserrolesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiRoleGetuserrolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tests if provided roles belog to logged user
         * @param {Array<string>} [roles] Query array of roles
         * @param {boolean} [strict] If multiple roles are provided, selects whether logic AND or logic OR will be used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIsuserinrolesGet(roles?: Array<string>, strict?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiRoleIsuserinrolesGet(roles, strict, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @summary Gets all roles for logged user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleGetuserrolesGet(options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleGetuserrolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tests if provided roles belog to logged user
     * @param {Array<string>} [roles] Query array of roles
     * @param {boolean} [strict] If multiple roles are provided, selects whether logic AND or logic OR will be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleIsuserinrolesGet(roles?: Array<string>, strict?: boolean, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleIsuserinrolesGet(roles, strict, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RvgApi - axios parameter creator
 * @export
 */
export const RvgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRvgSegmentationPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/rvg/segmentation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RvgApi - functional programming interface
 * @export
 */
export const RvgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RvgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRvgSegmentationPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTORvgSegmentationResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRvgSegmentationPost(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['RvgApi.apiRvgSegmentationPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * RvgApi - factory interface
 * @export
 */
export const RvgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RvgApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRvgSegmentationPost(file?: File, options?: any): AxiosPromise<Array<APIXdentDTORvgSegmentationResponseDto>> {
            return localVarFp.apiRvgSegmentationPost(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RvgApi - object-oriented interface
 * @export
 * @class RvgApi
 * @extends {BaseAPI}
 */
export class RvgApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RvgApi
     */
    public apiRvgSegmentationPost(file?: File, options?: AxiosRequestConfig) {
        return RvgApiFp(this.configuration).apiRvgSegmentationPost(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ServiceApi - axios parameter creator
 * @export
 */
export const ServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGetservicegridGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service/getservicegrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {Array<string>} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGetservicesbystaffGet: async (idStaff?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service/getservicesbystaff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idStaff) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGetservicesbyuserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service/getservicesbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idServiceLocationSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceLocationsettingsDelete: async (idServiceLocationSetting?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service/locationsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idServiceLocationSetting !== undefined) {
                localVarQueryParameter['idServiceLocationSetting'] = idServiceLocationSetting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idService] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceLocationsettingsGet: async (idService?: string, idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service/locationsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idService !== undefined) {
                localVarQueryParameter['idService'] = idService;
            }

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOServiceServiceLocationSettingDTO} [aPIXdentDTOServiceServiceLocationSettingDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceLocationsettingsPost: async (aPIXdentDTOServiceServiceLocationSettingDTO?: APIXdentDTOServiceServiceLocationSettingDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/service/locationsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOServiceServiceLocationSettingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceApi - functional programming interface
 * @export
 */
export const ServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceGetservicegridGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceGetservicegridGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServiceApi.apiServiceGetservicegridGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {Array<string>} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceGetservicesbystaffGet(idStaff?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceGetservicesbystaffGet(idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServiceApi.apiServiceGetservicesbystaffGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceGetservicesbyuserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceGetservicesbyuserGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServiceApi.apiServiceGetservicesbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idServiceLocationSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceLocationsettingsDelete(idServiceLocationSetting?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceLocationsettingsDelete(idServiceLocationSetting, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServiceApi.apiServiceLocationsettingsDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idService] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceLocationsettingsGet(idService?: string, idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceLocationsettingsGet(idService, idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServiceApi.apiServiceLocationsettingsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOServiceServiceLocationSettingDTO} [aPIXdentDTOServiceServiceLocationSettingDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceLocationsettingsPost(aPIXdentDTOServiceServiceLocationSettingDTO?: APIXdentDTOServiceServiceLocationSettingDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceLocationsettingsPost(aPIXdentDTOServiceServiceLocationSettingDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ServiceApi.apiServiceLocationsettingsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ServiceApi - factory interface
 * @export
 */
export const ServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGetservicegridGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceGetservicegridGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {Array<string>} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGetservicesbystaffGet(idStaff?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceGetservicesbystaffGet(idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceGetservicesbyuserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceGetservicesbyuserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idServiceLocationSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceLocationsettingsDelete(idServiceLocationSetting?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceLocationsettingsDelete(idServiceLocationSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idService] 
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceLocationsettingsGet(idService?: string, idLocation?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceLocationsettingsGet(idService, idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOServiceServiceLocationSettingDTO} [aPIXdentDTOServiceServiceLocationSettingDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceLocationsettingsPost(aPIXdentDTOServiceServiceLocationSettingDTO?: APIXdentDTOServiceServiceLocationSettingDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiServiceLocationsettingsPost(aPIXdentDTOServiceServiceLocationSettingDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
export class ServiceApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceGetservicegridGet(options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).apiServiceGetservicegridGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {Array<string>} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceGetservicesbystaffGet(idStaff?: Array<string>, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).apiServiceGetservicesbystaffGet(idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceGetservicesbyuserGet(options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).apiServiceGetservicesbyuserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idServiceLocationSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceLocationsettingsDelete(idServiceLocationSetting?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).apiServiceLocationsettingsDelete(idServiceLocationSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idService] 
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceLocationsettingsGet(idService?: string, idLocation?: string, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).apiServiceLocationsettingsGet(idService, idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOServiceServiceLocationSettingDTO} [aPIXdentDTOServiceServiceLocationSettingDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    public apiServiceLocationsettingsPost(aPIXdentDTOServiceServiceLocationSettingDTO?: APIXdentDTOServiceServiceLocationSettingDTO, options?: AxiosRequestConfig) {
        return ServiceApiFp(this.configuration).apiServiceLocationsettingsPost(aPIXdentDTOServiceServiceLocationSettingDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} [noi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsAccountGetpricelistGet: async (noi?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings/account/getpricelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (noi !== undefined) {
                localVarQueryParameter['noi'] = noi;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} [noi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSettingsAccountGetpricelistGet(noi?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOPriceListPriceListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSettingsAccountGetpricelistGet(noi, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.apiSettingsAccountGetpricelistGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {number} [noi] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSettingsAccountGetpricelistGet(noi?: number, options?: any): AxiosPromise<APIXdentDTOPriceListPriceListDto> {
            return localVarFp.apiSettingsAccountGetpricelistGet(noi, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {number} [noi] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public apiSettingsAccountGetpricelistGet(noi?: number, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).apiSettingsAccountGetpricelistGet(noi, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Purges WooCommerce entities
         * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopCleanwoocommerceGet: async (dryRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/cleanwoocommerce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Purges WordPress entities - WordPress REST API Authentication plugin must be disabled for this to work
         * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopCleanwordpressGet: async (dryRun?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/cleanwordpress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Saves order to WooCommerce
         * @param {APISharedCreateOrderDTO} [aPISharedCreateOrderDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopCreateshoporderPost: async (aPISharedCreateOrderDTO?: APISharedCreateOrderDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/createshoporder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedCreateOrderDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns filtered shop products using singleton
         * @param {APISharedProductFiltersDTO} [aPISharedProductFiltersDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopGetproductsfilteredPost: async (aPISharedProductFiltersDTO?: APISharedProductFiltersDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/getproductsfiltered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedProductFiltersDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refreshes SHOP manager - ONLY FOR DEVELOPERS (pouze pro ty kdo znaji kouzelne slovicko)
         * @param {string} [kouzelneSluvko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopReloadshopmanagerinstancePost: async (kouzelneSluvko?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/reloadshopmanagerinstance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (kouzelneSluvko !== undefined) {
                localVarQueryParameter['kouzelneSluvko'] = kouzelneSluvko;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends user message to WooCommerce order
         * @param {APISharedSendMessageDTO} [aPISharedSendMessageDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopSendshopmessagePost: async (aPISharedSendMessageDTO?: APISharedSendMessageDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/sendshopmessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedSendMessageDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Imports data from GLS to WooCommerce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopXdentshopimportglsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/xdentshopimportgls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Imports data from KunkelaDentalpoint to WooCommerce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopXdentshopimportkunkeladentalpointGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/xdentshopimportkunkeladentalpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Purges WooCommerce entities
         * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopCleanwoocommerceGet(dryRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopCleanwoocommerceGet(dryRun, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopCleanwoocommerceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Purges WordPress entities - WordPress REST API Authentication plugin must be disabled for this to work
         * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopCleanwordpressGet(dryRun?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopCleanwordpressGet(dryRun, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopCleanwordpressGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Saves order to WooCommerce
         * @param {APISharedCreateOrderDTO} [aPISharedCreateOrderDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopCreateshoporderPost(aPISharedCreateOrderDTO?: APISharedCreateOrderDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopCreateshoporderPost(aPISharedCreateOrderDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopCreateshoporderPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns filtered shop products using singleton
         * @param {APISharedProductFiltersDTO} [aPISharedProductFiltersDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopGetproductsfilteredPost(aPISharedProductFiltersDTO?: APISharedProductFiltersDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopGetproductsfilteredPost(aPISharedProductFiltersDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopGetproductsfilteredPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Refreshes SHOP manager - ONLY FOR DEVELOPERS (pouze pro ty kdo znaji kouzelne slovicko)
         * @param {string} [kouzelneSluvko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopReloadshopmanagerinstancePost(kouzelneSluvko?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopReloadshopmanagerinstancePost(kouzelneSluvko, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopReloadshopmanagerinstancePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sends user message to WooCommerce order
         * @param {APISharedSendMessageDTO} [aPISharedSendMessageDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopSendshopmessagePost(aPISharedSendMessageDTO?: APISharedSendMessageDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopSendshopmessagePost(aPISharedSendMessageDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopSendshopmessagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Imports data from GLS to WooCommerce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopXdentshopimportglsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopXdentshopimportglsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopXdentshopimportglsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Imports data from KunkelaDentalpoint to WooCommerce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShopXdentshopimportkunkeladentalpointGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShopXdentshopimportkunkeladentalpointGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.apiShopXdentshopimportkunkeladentalpointGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * 
         * @summary Purges WooCommerce entities
         * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopCleanwoocommerceGet(dryRun?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiShopCleanwoocommerceGet(dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Purges WordPress entities - WordPress REST API Authentication plugin must be disabled for this to work
         * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopCleanwordpressGet(dryRun?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiShopCleanwordpressGet(dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Saves order to WooCommerce
         * @param {APISharedCreateOrderDTO} [aPISharedCreateOrderDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopCreateshoporderPost(aPISharedCreateOrderDTO?: APISharedCreateOrderDTO, options?: any): AxiosPromise<number> {
            return localVarFp.apiShopCreateshoporderPost(aPISharedCreateOrderDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns filtered shop products using singleton
         * @param {APISharedProductFiltersDTO} [aPISharedProductFiltersDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopGetproductsfilteredPost(aPISharedProductFiltersDTO?: APISharedProductFiltersDTO, options?: any): AxiosPromise<void> {
            return localVarFp.apiShopGetproductsfilteredPost(aPISharedProductFiltersDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refreshes SHOP manager - ONLY FOR DEVELOPERS (pouze pro ty kdo znaji kouzelne slovicko)
         * @param {string} [kouzelneSluvko] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopReloadshopmanagerinstancePost(kouzelneSluvko?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiShopReloadshopmanagerinstancePost(kouzelneSluvko, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends user message to WooCommerce order
         * @param {APISharedSendMessageDTO} [aPISharedSendMessageDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopSendshopmessagePost(aPISharedSendMessageDTO?: APISharedSendMessageDTO, options?: any): AxiosPromise<number> {
            return localVarFp.apiShopSendshopmessagePost(aPISharedSendMessageDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Imports data from GLS to WooCommerce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopXdentshopimportglsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiShopXdentshopimportglsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Imports data from KunkelaDentalpoint to WooCommerce
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShopXdentshopimportkunkeladentalpointGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiShopXdentshopimportkunkeladentalpointGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * 
     * @summary Purges WooCommerce entities
     * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopCleanwoocommerceGet(dryRun?: boolean, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopCleanwoocommerceGet(dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Purges WordPress entities - WordPress REST API Authentication plugin must be disabled for this to work
     * @param {boolean} [dryRun] True shows which URL is configured to be purged. False does the job.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopCleanwordpressGet(dryRun?: boolean, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopCleanwordpressGet(dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Saves order to WooCommerce
     * @param {APISharedCreateOrderDTO} [aPISharedCreateOrderDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopCreateshoporderPost(aPISharedCreateOrderDTO?: APISharedCreateOrderDTO, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopCreateshoporderPost(aPISharedCreateOrderDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns filtered shop products using singleton
     * @param {APISharedProductFiltersDTO} [aPISharedProductFiltersDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopGetproductsfilteredPost(aPISharedProductFiltersDTO?: APISharedProductFiltersDTO, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopGetproductsfilteredPost(aPISharedProductFiltersDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refreshes SHOP manager - ONLY FOR DEVELOPERS (pouze pro ty kdo znaji kouzelne slovicko)
     * @param {string} [kouzelneSluvko] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopReloadshopmanagerinstancePost(kouzelneSluvko?: string, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopReloadshopmanagerinstancePost(kouzelneSluvko, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends user message to WooCommerce order
     * @param {APISharedSendMessageDTO} [aPISharedSendMessageDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopSendshopmessagePost(aPISharedSendMessageDTO?: APISharedSendMessageDTO, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopSendshopmessagePost(aPISharedSendMessageDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Imports data from GLS to WooCommerce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopXdentshopimportglsGet(options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopXdentshopimportglsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Imports data from KunkelaDentalpoint to WooCommerce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public apiShopXdentshopimportkunkeladentalpointGet(options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).apiShopXdentshopimportkunkeladentalpointGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SignApi - axios parameter creator
 * @export
 */
export const SignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets sign metadata for sign request
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignGetsealedpdftestGet: async (deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesSignGetsealedpdftestGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/sign/getsealedpdftest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finally seals document with signature and returns it as a file for debugging.
         * @param {string} deviceToken 
         * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignSealsigneddocumentGet: async (deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesSignSealsigneddocumentGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/sign/sealsigneddocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOSignSafeSigSealSignedDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finally seals document with signature and returns true if succeeded.
         * @param {string} deviceToken 
         * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignSealsigneddocumentmockGet: async (deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesSignSealsigneddocumentmockGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/sign/sealsigneddocumentmock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOSignSafeSigSealSignedDocumentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets sign metadata for sign request
         * @param {string} deviceToken 
         * @param {string} [idBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignSignmetadataGet: async (deviceToken: string, idBatch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesSignSignmetadataGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/sign/signmetadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idBatch !== undefined) {
                localVarQueryParameter['idBatch'] = idBatch;
            }

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SignApi - functional programming interface
 * @export
 */
export const SignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets sign metadata for sign request
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesSignGetsealedpdftestGet(deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesSignGetsealedpdftestGet(deviceToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SignApi.apiSafesignaturesSignGetsealedpdftestGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Finally seals document with signature and returns it as a file for debugging.
         * @param {string} deviceToken 
         * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesSignSealsigneddocumentGet(deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesSignSealsigneddocumentGet(deviceToken, aPIXdentDTOSignSafeSigSealSignedDocumentDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SignApi.apiSafesignaturesSignSealsigneddocumentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Finally seals document with signature and returns true if succeeded.
         * @param {string} deviceToken 
         * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesSignSealsigneddocumentmockGet(deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesSignSealsigneddocumentmockGet(deviceToken, aPIXdentDTOSignSafeSigSealSignedDocumentDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SignApi.apiSafesignaturesSignSealsigneddocumentmockGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets sign metadata for sign request
         * @param {string} deviceToken 
         * @param {string} [idBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesSignSignmetadataGet(deviceToken: string, idBatch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesSignSignmetadataGet(deviceToken, idBatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SignApi.apiSafesignaturesSignSignmetadataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SignApi - factory interface
 * @export
 */
export const SignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SignApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets sign metadata for sign request
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignGetsealedpdftestGet(deviceToken: string, options?: any): AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto> {
            return localVarFp.apiSafesignaturesSignGetsealedpdftestGet(deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finally seals document with signature and returns it as a file for debugging.
         * @param {string} deviceToken 
         * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignSealsigneddocumentGet(deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options?: any): AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto> {
            return localVarFp.apiSafesignaturesSignSealsigneddocumentGet(deviceToken, aPIXdentDTOSignSafeSigSealSignedDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finally seals document with signature and returns true if succeeded.
         * @param {string} deviceToken 
         * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignSealsigneddocumentmockGet(deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options?: any): AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto> {
            return localVarFp.apiSafesignaturesSignSealsigneddocumentmockGet(deviceToken, aPIXdentDTOSignSafeSigSealSignedDocumentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets sign metadata for sign request
         * @param {string} deviceToken 
         * @param {string} [idBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesSignSignmetadataGet(deviceToken: string, idBatch?: string, options?: any): AxiosPromise<APIXdentDTOSafeSignaturesSignMetadataDto> {
            return localVarFp.apiSafesignaturesSignSignmetadataGet(deviceToken, idBatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SignApi - object-oriented interface
 * @export
 * @class SignApi
 * @extends {BaseAPI}
 */
export class SignApi extends BaseAPI {
    /**
     * 
     * @summary Gets sign metadata for sign request
     * @param {string} deviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSafesignaturesSignGetsealedpdftestGet(deviceToken: string, options?: AxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSafesignaturesSignGetsealedpdftestGet(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finally seals document with signature and returns it as a file for debugging.
     * @param {string} deviceToken 
     * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSafesignaturesSignSealsigneddocumentGet(deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options?: AxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSafesignaturesSignSealsigneddocumentGet(deviceToken, aPIXdentDTOSignSafeSigSealSignedDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finally seals document with signature and returns true if succeeded.
     * @param {string} deviceToken 
     * @param {APIXdentDTOSignSafeSigSealSignedDocumentDto} [aPIXdentDTOSignSafeSigSealSignedDocumentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSafesignaturesSignSealsigneddocumentmockGet(deviceToken: string, aPIXdentDTOSignSafeSigSealSignedDocumentDto?: APIXdentDTOSignSafeSigSealSignedDocumentDto, options?: AxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSafesignaturesSignSealsigneddocumentmockGet(deviceToken, aPIXdentDTOSignSafeSigSealSignedDocumentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets sign metadata for sign request
     * @param {string} deviceToken 
     * @param {string} [idBatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SignApi
     */
    public apiSafesignaturesSignSignmetadataGet(deviceToken: string, idBatch?: string, options?: AxiosRequestConfig) {
        return SignApiFp(this.configuration).apiSafesignaturesSignSignmetadataGet(deviceToken, idBatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SmartformApi - axios parameter creator
 * @export
 */
export const SmartformApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Suggests the address.  Docs for impl version v10: https://docs.smartform.cz/smartform-api/oracleAddress/oracleAddress-v10.html#operation/oracle  Docs for all versions https://www.smartform.cz/dokumentace/smartform-api/naseptavani-adres/dokumentace-api/#
         * @param {APIXdentDTOSmartformAddressSuggestionDto} [aPIXdentDTOSmartformAddressSuggestionDto] The data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmartformSuggestaddressPost: async (aPIXdentDTOSmartformAddressSuggestionDto?: APIXdentDTOSmartformAddressSuggestionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/smartform/suggestaddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOSmartformAddressSuggestionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SmartformApi - functional programming interface
 * @export
 */
export const SmartformApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SmartformApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Suggests the address.  Docs for impl version v10: https://docs.smartform.cz/smartform-api/oracleAddress/oracleAddress-v10.html#operation/oracle  Docs for all versions https://www.smartform.cz/dokumentace/smartform-api/naseptavani-adres/dokumentace-api/#
         * @param {APIXdentDTOSmartformAddressSuggestionDto} [aPIXdentDTOSmartformAddressSuggestionDto] The data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmartformSuggestaddressPost(aPIXdentDTOSmartformAddressSuggestionDto?: APIXdentDTOSmartformAddressSuggestionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOSmartformAddressSuggestionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmartformSuggestaddressPost(aPIXdentDTOSmartformAddressSuggestionDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SmartformApi.apiSmartformSuggestaddressPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SmartformApi - factory interface
 * @export
 */
export const SmartformApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SmartformApiFp(configuration)
    return {
        /**
         * 
         * @summary Suggests the address.  Docs for impl version v10: https://docs.smartform.cz/smartform-api/oracleAddress/oracleAddress-v10.html#operation/oracle  Docs for all versions https://www.smartform.cz/dokumentace/smartform-api/naseptavani-adres/dokumentace-api/#
         * @param {APIXdentDTOSmartformAddressSuggestionDto} [aPIXdentDTOSmartformAddressSuggestionDto] The data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmartformSuggestaddressPost(aPIXdentDTOSmartformAddressSuggestionDto?: APIXdentDTOSmartformAddressSuggestionDto, options?: any): AxiosPromise<APIXdentDTOSmartformAddressSuggestionResponseDto> {
            return localVarFp.apiSmartformSuggestaddressPost(aPIXdentDTOSmartformAddressSuggestionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SmartformApi - object-oriented interface
 * @export
 * @class SmartformApi
 * @extends {BaseAPI}
 */
export class SmartformApi extends BaseAPI {
    /**
     * 
     * @summary Suggests the address.  Docs for impl version v10: https://docs.smartform.cz/smartform-api/oracleAddress/oracleAddress-v10.html#operation/oracle  Docs for all versions https://www.smartform.cz/dokumentace/smartform-api/naseptavani-adres/dokumentace-api/#
     * @param {APIXdentDTOSmartformAddressSuggestionDto} [aPIXdentDTOSmartformAddressSuggestionDto] The data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SmartformApi
     */
    public apiSmartformSuggestaddressPost(aPIXdentDTOSmartformAddressSuggestionDto?: APIXdentDTOSmartformAddressSuggestionDto, options?: AxiosRequestConfig) {
        return SmartformApiFp(this.configuration).apiSmartformSuggestaddressPost(aPIXdentDTOSmartformAddressSuggestionDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all staff by id.
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffGetstaffbyidGet: async (idStaff?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/staff/getstaffbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all staffs for location
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffGetstaffbylocationGet: async (idLocation?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/staff/getstaffbylocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all staffs for user (company)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffGetstaffbyuserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/staff/getstaffbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaffApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all staff by id.
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffGetstaffbyidGet(idStaff?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStaffGetstaffbyidGet(idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffApi.apiStaffGetstaffbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all staffs for location
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffGetstaffbylocationGet(idLocation?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStaffGetstaffbylocationGet(idLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffApi.apiStaffGetstaffbylocationGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all staffs for user (company)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStaffGetstaffbyuserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStaffGetstaffbyuserGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StaffApi.apiStaffGetstaffbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaffApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all staff by id.
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffGetstaffbyidGet(idStaff?: string, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult> {
            return localVarFp.apiStaffGetstaffbyidGet(idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all staffs for location
         * @param {string} [idLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffGetstaffbylocationGet(idLocation?: string, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult>> {
            return localVarFp.apiStaffGetstaffbylocationGet(idLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all staffs for user (company)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStaffGetstaffbyuserGet(options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedUSERSTAFFTREEResult>> {
            return localVarFp.apiStaffGetstaffbyuserGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     * 
     * @summary Gets all staff by id.
     * @param {string} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffGetstaffbyidGet(idStaff?: string, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).apiStaffGetstaffbyidGet(idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all staffs for location
     * @param {string} [idLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffGetstaffbylocationGet(idLocation?: string, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).apiStaffGetstaffbylocationGet(idLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all staffs for user (company)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public apiStaffGetstaffbyuserGet(options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).apiStaffGetstaffbyuserGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StoreQuantityApi - axios parameter creator
 * @export
 */
export const StoreQuantityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idInvoiceCode] 
         * @param {string} [idStoreLocation] 
         * @param {string} [filter] 
         * @param {boolean} [includePostExpiry] 
         * @param {boolean} [includeSubStores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorequantitySearchstoreitemlotexpGet: async (idInvoiceCode?: string | null, idStoreLocation?: string, filter?: string, includePostExpiry?: boolean, includeSubStores?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/storequantity/searchstoreitemlotexp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idInvoiceCode !== undefined) {
                localVarQueryParameter['idInvoiceCode'] = idInvoiceCode;
            }

            if (idStoreLocation !== undefined) {
                localVarQueryParameter['idStoreLocation'] = idStoreLocation;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (includePostExpiry !== undefined) {
                localVarQueryParameter['includePostExpiry'] = includePostExpiry;
            }

            if (includeSubStores !== undefined) {
                localVarQueryParameter['includeSubStores'] = includeSubStores;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreQuantityApi - functional programming interface
 * @export
 */
export const StoreQuantityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreQuantityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idInvoiceCode] 
         * @param {string} [idStoreLocation] 
         * @param {string} [filter] 
         * @param {boolean} [includePostExpiry] 
         * @param {boolean} [includeSubStores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStorequantitySearchstoreitemlotexpGet(idInvoiceCode?: string | null, idStoreLocation?: string, filter?: string, includePostExpiry?: boolean, includeSubStores?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStorequantitySearchstoreitemlotexpGet(idInvoiceCode, idStoreLocation, filter, includePostExpiry, includeSubStores, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StoreQuantityApi.apiStorequantitySearchstoreitemlotexpGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StoreQuantityApi - factory interface
 * @export
 */
export const StoreQuantityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreQuantityApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idInvoiceCode] 
         * @param {string} [idStoreLocation] 
         * @param {string} [filter] 
         * @param {boolean} [includePostExpiry] 
         * @param {boolean} [includeSubStores] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStorequantitySearchstoreitemlotexpGet(idInvoiceCode?: string | null, idStoreLocation?: string, filter?: string, includePostExpiry?: boolean, includeSubStores?: boolean, options?: any): AxiosPromise<Array<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedSTOREITEMLOTEXPSEARCHResult>> {
            return localVarFp.apiStorequantitySearchstoreitemlotexpGet(idInvoiceCode, idStoreLocation, filter, includePostExpiry, includeSubStores, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreQuantityApi - object-oriented interface
 * @export
 * @class StoreQuantityApi
 * @extends {BaseAPI}
 */
export class StoreQuantityApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idInvoiceCode] 
     * @param {string} [idStoreLocation] 
     * @param {string} [filter] 
     * @param {boolean} [includePostExpiry] 
     * @param {boolean} [includeSubStores] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreQuantityApi
     */
    public apiStorequantitySearchstoreitemlotexpGet(idInvoiceCode?: string | null, idStoreLocation?: string, filter?: string, includePostExpiry?: boolean, includeSubStores?: boolean, options?: AxiosRequestConfig) {
        return StoreQuantityApiFp(this.configuration).apiStorequantitySearchstoreitemlotexpGet(idInvoiceCode, idStoreLocation, filter, includePostExpiry, includeSubStores, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SuklApi - axios parameter creator
 * @export
 */
export const SuklApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get MedicamentsAndSubstances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSuklGetmedicamentsandsubstancesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sukl/getmedicamentsandsubstances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuklApi - functional programming interface
 * @export
 */
export const SuklApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuklApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get MedicamentsAndSubstances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSuklGetmedicamentsandsubstancesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSuklGetmedicamentsandsubstancesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SuklApi.apiSuklGetmedicamentsandsubstancesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SuklApi - factory interface
 * @export
 */
export const SuklApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuklApiFp(configuration)
    return {
        /**
         * 
         * @summary Get MedicamentsAndSubstances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSuklGetmedicamentsandsubstancesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiSuklGetmedicamentsandsubstancesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuklApi - object-oriented interface
 * @export
 * @class SuklApi
 * @extends {BaseAPI}
 */
export class SuklApi extends BaseAPI {
    /**
     * 
     * @summary Get MedicamentsAndSubstances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuklApi
     */
    public apiSuklGetmedicamentsandsubstancesGet(options?: AxiosRequestConfig) {
        return SuklApiFp(this.configuration).apiSuklGetmedicamentsandsubstancesGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds new tag (company), and sets it to client.
         * @param {APISharedAddClientRelationCustomTagDto} [aPISharedAddClientRelationCustomTagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagAddclientcustomtagPost: async (aPISharedAddClientRelationCustomTagDto?: APISharedAddClientRelationCustomTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/addclientcustomtag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedAddClientRelationCustomTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds tag to user (company)
         * @param {ApiTagAddcustomtagPostRequest} [apiTagAddcustomtagPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagAddcustomtagPost: async (apiTagAddcustomtagPostRequest?: ApiTagAddcustomtagPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/addcustomtag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiTagAddcustomtagPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes tag from client by it\'s global(relation) id.
         * @param {string | null} [idClient] 
         * @param {string | null} [idCustomTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagDeleteclienttagDelete: async (idClient?: string | null, idCustomTag?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/deleteclienttag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }

            if (idCustomTag !== undefined) {
                localVarQueryParameter['idCustomTag'] = idCustomTag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes tag from logged user company by it\'s id.
         * @param {string | null} [idCustomTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagDeleteusertagDelete: async (idCustomTag?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/deleteusertag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idCustomTag !== undefined) {
                localVarQueryParameter['idCustomTag'] = idCustomTag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all active tags for client
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagGettagsbyclientGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/gettagsbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all valid tags for user (company)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagGettagsbyuserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tag/gettagsbyuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Adds new tag (company), and sets it to client.
         * @param {APISharedAddClientRelationCustomTagDto} [aPISharedAddClientRelationCustomTagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagAddclientcustomtagPost(aPISharedAddClientRelationCustomTagDto?: APISharedAddClientRelationCustomTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagAddclientcustomtagPost(aPISharedAddClientRelationCustomTagDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.apiTagAddclientcustomtagPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Adds tag to user (company)
         * @param {ApiTagAddcustomtagPostRequest} [apiTagAddcustomtagPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagAddcustomtagPost(apiTagAddcustomtagPostRequest?: ApiTagAddcustomtagPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagAddcustomtagPost(apiTagAddcustomtagPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.apiTagAddcustomtagPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes tag from client by it\'s global(relation) id.
         * @param {string | null} [idClient] 
         * @param {string | null} [idCustomTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagDeleteclienttagDelete(idClient?: string | null, idCustomTag?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagDeleteclienttagDelete(idClient, idCustomTag, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.apiTagDeleteclienttagDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes tag from logged user company by it\'s id.
         * @param {string | null} [idCustomTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagDeleteusertagDelete(idCustomTag?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagDeleteusertagDelete(idCustomTag, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.apiTagDeleteusertagDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all active tags for client
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagGettagsbyclientGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagGettagsbyclientGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.apiTagGettagsbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets all valid tags for user (company)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTagGettagsbyuserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagGettagsbyuserGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TagApi.apiTagGettagsbyuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @summary Adds new tag (company), and sets it to client.
         * @param {APISharedAddClientRelationCustomTagDto} [aPISharedAddClientRelationCustomTagDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagAddclientcustomtagPost(aPISharedAddClientRelationCustomTagDto?: APISharedAddClientRelationCustomTagDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagAddclientcustomtagPost(aPISharedAddClientRelationCustomTagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds tag to user (company)
         * @param {ApiTagAddcustomtagPostRequest} [apiTagAddcustomtagPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagAddcustomtagPost(apiTagAddcustomtagPostRequest?: ApiTagAddcustomtagPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagAddcustomtagPost(apiTagAddcustomtagPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes tag from client by it\'s global(relation) id.
         * @param {string | null} [idClient] 
         * @param {string | null} [idCustomTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagDeleteclienttagDelete(idClient?: string | null, idCustomTag?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagDeleteclienttagDelete(idClient, idCustomTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes tag from logged user company by it\'s id.
         * @param {string | null} [idCustomTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagDeleteusertagDelete(idCustomTag?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagDeleteusertagDelete(idCustomTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all active tags for client
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagGettagsbyclientGet(idClient?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiTagGettagsbyclientGet(idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all valid tags for user (company)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTagGettagsbyuserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTagGettagsbyuserGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary Adds new tag (company), and sets it to client.
     * @param {APISharedAddClientRelationCustomTagDto} [aPISharedAddClientRelationCustomTagDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagAddclientcustomtagPost(aPISharedAddClientRelationCustomTagDto?: APISharedAddClientRelationCustomTagDto, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagAddclientcustomtagPost(aPISharedAddClientRelationCustomTagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds tag to user (company)
     * @param {ApiTagAddcustomtagPostRequest} [apiTagAddcustomtagPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagAddcustomtagPost(apiTagAddcustomtagPostRequest?: ApiTagAddcustomtagPostRequest, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagAddcustomtagPost(apiTagAddcustomtagPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes tag from client by it\'s global(relation) id.
     * @param {string | null} [idClient] 
     * @param {string | null} [idCustomTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagDeleteclienttagDelete(idClient?: string | null, idCustomTag?: string | null, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagDeleteclienttagDelete(idClient, idCustomTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes tag from logged user company by it\'s id.
     * @param {string | null} [idCustomTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagDeleteusertagDelete(idCustomTag?: string | null, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagDeleteusertagDelete(idCustomTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all active tags for client
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagGettagsbyclientGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagGettagsbyclientGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all valid tags for user (company)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiTagGettagsbyuserGet(options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiTagGettagsbyuserGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sets xdent task status
         * @param {APIXdentDTOTaskChangeStatusDto} [aPIXdentDTOTaskChangeStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskChangestatusPut: async (aPIXdentDTOTaskChangeStatusDto?: APIXdentDTOTaskChangeStatusDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/changestatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOTaskChangeStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates comment message associated with xdent task
         * @param {APIXdentDTOTaskCommentDto} [aPIXdentDTOTaskCommentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskCommentmessagePost: async (aPIXdentDTOTaskCommentDto?: APIXdentDTOTaskCommentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/commentmessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOTaskCommentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates new xdent task
         * @param {APIXdentDTOTaskCreateTaskDto} [aPIXdentDTOTaskCreateTaskDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskCreatePost: async (aPIXdentDTOTaskCreateTaskDto?: APIXdentDTOTaskCreateTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOTaskCreateTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes xdent task
         * @param {string | null} [idTask] 
         * @param {string | null} [idBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskDeleteDelete: async (idTask?: string | null, idBatch?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idTask !== undefined) {
                localVarQueryParameter['idTask'] = idTask;
            }

            if (idBatch !== undefined) {
                localVarQueryParameter['idBatch'] = idBatch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edits xdent task
         * @param {APIXdentDTOTaskEditTaskDto} [aPIXdentDTOTaskEditTaskDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskEditPut: async (aPIXdentDTOTaskEditTaskDto?: APIXdentDTOTaskEditTaskDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOTaskEditTaskDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets comments/messages for specified task
         * @param {string | null} [idTaskBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskTaskmessagesGet: async (idTaskBatch?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/taskmessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idTaskBatch !== undefined) {
                localVarQueryParameter['idTaskBatch'] = idTaskBatch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sets xdent task status
         * @param {APIXdentDTOTaskChangeStatusDto} [aPIXdentDTOTaskChangeStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTaskChangestatusPut(aPIXdentDTOTaskChangeStatusDto?: APIXdentDTOTaskChangeStatusDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTaskChangestatusPut(aPIXdentDTOTaskChangeStatusDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TaskApi.apiTaskChangestatusPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates comment message associated with xdent task
         * @param {APIXdentDTOTaskCommentDto} [aPIXdentDTOTaskCommentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTaskCommentmessagePost(aPIXdentDTOTaskCommentDto?: APIXdentDTOTaskCommentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTaskCommentmessagePost(aPIXdentDTOTaskCommentDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TaskApi.apiTaskCommentmessagePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Creates new xdent task
         * @param {APIXdentDTOTaskCreateTaskDto} [aPIXdentDTOTaskCreateTaskDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTaskCreatePost(aPIXdentDTOTaskCreateTaskDto?: APIXdentDTOTaskCreateTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTaskCreatePost(aPIXdentDTOTaskCreateTaskDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TaskApi.apiTaskCreatePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes xdent task
         * @param {string | null} [idTask] 
         * @param {string | null} [idBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTaskDeleteDelete(idTask?: string | null, idBatch?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTaskDeleteDelete(idTask, idBatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TaskApi.apiTaskDeleteDelete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Edits xdent task
         * @param {APIXdentDTOTaskEditTaskDto} [aPIXdentDTOTaskEditTaskDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTaskEditPut(aPIXdentDTOTaskEditTaskDto?: APIXdentDTOTaskEditTaskDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTaskEditPut(aPIXdentDTOTaskEditTaskDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TaskApi.apiTaskEditPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets comments/messages for specified task
         * @param {string | null} [idTaskBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTaskTaskmessagesGet(idTaskBatch?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTaskTaskmessagesGet(idTaskBatch, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TaskApi.apiTaskTaskmessagesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @summary Sets xdent task status
         * @param {APIXdentDTOTaskChangeStatusDto} [aPIXdentDTOTaskChangeStatusDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskChangestatusPut(aPIXdentDTOTaskChangeStatusDto?: APIXdentDTOTaskChangeStatusDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiTaskChangestatusPut(aPIXdentDTOTaskChangeStatusDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates comment message associated with xdent task
         * @param {APIXdentDTOTaskCommentDto} [aPIXdentDTOTaskCommentDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskCommentmessagePost(aPIXdentDTOTaskCommentDto?: APIXdentDTOTaskCommentDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiTaskCommentmessagePost(aPIXdentDTOTaskCommentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates new xdent task
         * @param {APIXdentDTOTaskCreateTaskDto} [aPIXdentDTOTaskCreateTaskDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskCreatePost(aPIXdentDTOTaskCreateTaskDto?: APIXdentDTOTaskCreateTaskDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiTaskCreatePost(aPIXdentDTOTaskCreateTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes xdent task
         * @param {string | null} [idTask] 
         * @param {string | null} [idBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskDeleteDelete(idTask?: string | null, idBatch?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiTaskDeleteDelete(idTask, idBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edits xdent task
         * @param {APIXdentDTOTaskEditTaskDto} [aPIXdentDTOTaskEditTaskDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskEditPut(aPIXdentDTOTaskEditTaskDto?: APIXdentDTOTaskEditTaskDto, options?: any): AxiosPromise<string> {
            return localVarFp.apiTaskEditPut(aPIXdentDTOTaskEditTaskDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets comments/messages for specified task
         * @param {string | null} [idTaskBatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTaskTaskmessagesGet(idTaskBatch?: string | null, options?: any): AxiosPromise<APIGeneratedContextsXdentXdentDbProceduresContextGeneratedTASKMESSAGEGRIDResult> {
            return localVarFp.apiTaskTaskmessagesGet(idTaskBatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @summary Sets xdent task status
     * @param {APIXdentDTOTaskChangeStatusDto} [aPIXdentDTOTaskChangeStatusDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTaskChangestatusPut(aPIXdentDTOTaskChangeStatusDto?: APIXdentDTOTaskChangeStatusDto, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTaskChangestatusPut(aPIXdentDTOTaskChangeStatusDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates comment message associated with xdent task
     * @param {APIXdentDTOTaskCommentDto} [aPIXdentDTOTaskCommentDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTaskCommentmessagePost(aPIXdentDTOTaskCommentDto?: APIXdentDTOTaskCommentDto, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTaskCommentmessagePost(aPIXdentDTOTaskCommentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates new xdent task
     * @param {APIXdentDTOTaskCreateTaskDto} [aPIXdentDTOTaskCreateTaskDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTaskCreatePost(aPIXdentDTOTaskCreateTaskDto?: APIXdentDTOTaskCreateTaskDto, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTaskCreatePost(aPIXdentDTOTaskCreateTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes xdent task
     * @param {string | null} [idTask] 
     * @param {string | null} [idBatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTaskDeleteDelete(idTask?: string | null, idBatch?: string | null, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTaskDeleteDelete(idTask, idBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edits xdent task
     * @param {APIXdentDTOTaskEditTaskDto} [aPIXdentDTOTaskEditTaskDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTaskEditPut(aPIXdentDTOTaskEditTaskDto?: APIXdentDTOTaskEditTaskDto, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTaskEditPut(aPIXdentDTOTaskEditTaskDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets comments/messages for specified task
     * @param {string | null} [idTaskBatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public apiTaskTaskmessagesGet(idTaskBatch?: string | null, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).apiTaskTaskmessagesGet(idTaskBatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Test if device is paired. Should return 500 if not paired with global ErrorDto
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesTestTestpaireddeviceGet: async (deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesTestTestpaireddeviceGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/test/testpaireddevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestAnalyzeb2bsetupcertificatesvalidityPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/analyzeb2bsetupcertificatesvalidity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestAnalyzeb2bvzpresponsesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/analyzeb2bvzpresponses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestGetenumtypesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/getenumtypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestTestipgetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/testipget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [compressImages] 
         * @param {number} [imageQuality] 
         * @param {boolean} [optimizeFont] 
         * @param {boolean} [removeMetadata] 
         * @param {boolean} [optimizePageContents] 
         * @param {File} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestTestpdfcompressionPost: async (compressImages?: boolean, imageQuality?: number, optimizeFont?: boolean, removeMetadata?: boolean, optimizePageContents?: boolean, model?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/testpdfcompression`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (compressImages != null) {
                localVarHeaderParameter['compressImages'] = typeof compressImages === 'string'
                    ? compressImages
                    : JSON.stringify(compressImages);
            }

            if (imageQuality != null) {
                localVarHeaderParameter['imageQuality'] = typeof imageQuality === 'string'
                    ? imageQuality
                    : JSON.stringify(imageQuality);
            }

            if (optimizeFont != null) {
                localVarHeaderParameter['optimizeFont'] = typeof optimizeFont === 'string'
                    ? optimizeFont
                    : JSON.stringify(optimizeFont);
            }

            if (removeMetadata != null) {
                localVarHeaderParameter['removeMetadata'] = typeof removeMetadata === 'string'
                    ? removeMetadata
                    : JSON.stringify(removeMetadata);
            }

            if (optimizePageContents != null) {
                localVarHeaderParameter['optimizePageContents'] = typeof optimizePageContents === 'string'
                    ? optimizePageContents
                    : JSON.stringify(optimizePageContents);
            }


            if (model !== undefined) { 
                localVarFormParams.append('model', model as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [timeoutSec] 
         * @param {number} [actionTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestTesttasktimeoutGet: async (timeoutSec?: number, actionTime?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/test/testtasktimeout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeoutSec !== undefined) {
                localVarQueryParameter['timeoutSec'] = timeoutSec;
            }

            if (actionTime !== undefined) {
                localVarQueryParameter['actionTime'] = actionTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Test if device is paired. Should return 500 if not paired with global ErrorDto
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesTestTestpaireddeviceGet(deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesTestTestpaireddeviceGet(deviceToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiSafesignaturesTestTestpaireddeviceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestAnalyzeb2bsetupcertificatesvalidityPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestAnalyzeb2bsetupcertificatesvalidityPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiTestAnalyzeb2bsetupcertificatesvalidityPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestAnalyzeb2bvzpresponsesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestAnalyzeb2bvzpresponsesPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiTestAnalyzeb2bvzpresponsesPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestGetenumtypesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestGetenumtypesGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiTestGetenumtypesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestTestipgetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestTestipgetGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiTestTestipgetGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [compressImages] 
         * @param {number} [imageQuality] 
         * @param {boolean} [optimizeFont] 
         * @param {boolean} [removeMetadata] 
         * @param {boolean} [optimizePageContents] 
         * @param {File} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestTestpdfcompressionPost(compressImages?: boolean, imageQuality?: number, optimizeFont?: boolean, removeMetadata?: boolean, optimizePageContents?: boolean, model?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestTestpdfcompressionPost(compressImages, imageQuality, optimizeFont, removeMetadata, optimizePageContents, model, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiTestTestpdfcompressionPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [timeoutSec] 
         * @param {number} [actionTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestTesttasktimeoutGet(timeoutSec?: number, actionTime?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestTesttasktimeoutGet(timeoutSec, actionTime, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestApi.apiTestTesttasktimeoutGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @summary Test if device is paired. Should return 500 if not paired with global ErrorDto
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesTestTestpaireddeviceGet(deviceToken: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSafesignaturesTestTestpaireddeviceGet(deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestAnalyzeb2bsetupcertificatesvalidityPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestAnalyzeb2bsetupcertificatesvalidityPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestAnalyzeb2bvzpresponsesPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestAnalyzeb2bvzpresponsesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestGetenumtypesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestGetenumtypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestTestipgetGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestTestipgetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [compressImages] 
         * @param {number} [imageQuality] 
         * @param {boolean} [optimizeFont] 
         * @param {boolean} [removeMetadata] 
         * @param {boolean} [optimizePageContents] 
         * @param {File} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestTestpdfcompressionPost(compressImages?: boolean, imageQuality?: number, optimizeFont?: boolean, removeMetadata?: boolean, optimizePageContents?: boolean, model?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiTestTestpdfcompressionPost(compressImages, imageQuality, optimizeFont, removeMetadata, optimizePageContents, model, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [timeoutSec] 
         * @param {number} [actionTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestTesttasktimeoutGet(timeoutSec?: number, actionTime?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTestTesttasktimeoutGet(timeoutSec, actionTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @summary Test if device is paired. Should return 500 if not paired with global ErrorDto
     * @param {string} deviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiSafesignaturesTestTestpaireddeviceGet(deviceToken: string, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiSafesignaturesTestTestpaireddeviceGet(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestAnalyzeb2bsetupcertificatesvalidityPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestAnalyzeb2bsetupcertificatesvalidityPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestAnalyzeb2bvzpresponsesPost(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestAnalyzeb2bvzpresponsesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestGetenumtypesGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestGetenumtypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestTestipgetGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestTestipgetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [compressImages] 
     * @param {number} [imageQuality] 
     * @param {boolean} [optimizeFont] 
     * @param {boolean} [removeMetadata] 
     * @param {boolean} [optimizePageContents] 
     * @param {File} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestTestpdfcompressionPost(compressImages?: boolean, imageQuality?: number, optimizeFont?: boolean, removeMetadata?: boolean, optimizePageContents?: boolean, model?: File, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestTestpdfcompressionPost(compressImages, imageQuality, optimizeFont, removeMetadata, optimizePageContents, model, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [timeoutSec] 
     * @param {number} [actionTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiTestTesttasktimeoutGet(timeoutSec?: number, actionTime?: number, options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiTestTesttasktimeoutGet(timeoutSec, actionTime, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestGeneratedContextApi - axios parameter creator
 * @export
 */
export const TestGeneratedContextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestgeneratedcontextTestprimarykeysPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/testgeneratedcontext/testprimarykeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestGeneratedContextApi - functional programming interface
 * @export
 */
export const TestGeneratedContextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestGeneratedContextApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTestgeneratedcontextTestprimarykeysPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTestgeneratedcontextTestprimarykeysPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TestGeneratedContextApi.apiTestgeneratedcontextTestprimarykeysPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TestGeneratedContextApi - factory interface
 * @export
 */
export const TestGeneratedContextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestGeneratedContextApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTestgeneratedcontextTestprimarykeysPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiTestgeneratedcontextTestprimarykeysPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestGeneratedContextApi - object-oriented interface
 * @export
 * @class TestGeneratedContextApi
 * @extends {BaseAPI}
 */
export class TestGeneratedContextApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestGeneratedContextApi
     */
    public apiTestgeneratedcontextTestprimarykeysPost(options?: AxiosRequestConfig) {
        return TestGeneratedContextApiFp(this.configuration).apiTestgeneratedcontextTestprimarykeysPost(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user info
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesUserUserinfoGet: async (deviceToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesignaturesUserUserinfoGet', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesignatures/user/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAuthbycredentialsPost: async (apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/authbycredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiSafesignaturesAuthPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model, for 30 sec.
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAuthbycredentialstestPost: async (apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/authbycredentialstest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiSafesignaturesAuthPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExtendbearertokenPost: async (aPISharedExtendBearerDto?: APISharedExtendBearerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/extendbearertoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedExtendBearerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetuserinfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getuserinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user info
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetuserinfox3Get: async (controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/getuserinfox3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (controller !== undefined) {
                localVarQueryParameter['controller'] = controller;
            }

            if (cacheContext !== undefined) {
                localVarQueryParameter['cacheContext'] = cacheContext;
            }

            if (invoker !== undefined) {
                localVarQueryParameter['invoker'] = invoker;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (transactionId !== undefined) {
                localVarQueryParameter['transactionId'] = transactionId;
            }

            if (expiration !== undefined) {
                localVarQueryParameter['expiration'] = expiration;
            }

            if (idGlobal !== undefined) {
                localVarQueryParameter['idGlobal'] = idGlobal;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logouts this instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserNottestauthexceptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/nottestauthexceptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tests auth exceptions
         * @param {ApiUserTestauthexceptionsGetTestTypeEnum} [testType] 0 &#x3D; MembershipNotFound, 1 &#x3D; WrongCredentials, 2 &#x3D; SubscriptionExpired, 3 &#x3D; InvalidRefreshToken, 4 &#x3D; EmptyCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserTestauthexceptionsGet: async (testType?: ApiUserTestauthexceptionsGetTestTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/testauthexceptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (testType !== undefined) {
                localVarQueryParameter['testType'] = testType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user info
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesignaturesUserUserinfoGet(deviceToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentDTOUserGlobalXdentConfigurationWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesignaturesUserUserinfoGet(deviceToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiSafesignaturesUserUserinfoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAuthbycredentialsPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAuthbycredentialsPost(apiSafesignaturesAuthPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserAuthbycredentialsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model, for 30 sec.
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAuthbycredentialstestPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAuthbycredentialstestPost(apiSafesignaturesAuthPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserAuthbycredentialstestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserExtendbearertokenPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserExtendbearertokenPost(aPISharedExtendBearerDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserExtendbearertokenPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetuserinfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedGlobalXdentConfigurationWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetuserinfoGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserGetuserinfoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get user info
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetuserinfox3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedGlobalXdentConfigurationWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetuserinfox3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserGetuserinfox3Get']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Logouts this instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLogoutPost(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserLogoutPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserNottestauthexceptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserNottestauthexceptionsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserNottestauthexceptionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Tests auth exceptions
         * @param {ApiUserTestauthexceptionsGetTestTypeEnum} [testType] 0 &#x3D; MembershipNotFound, 1 &#x3D; WrongCredentials, 2 &#x3D; SubscriptionExpired, 3 &#x3D; InvalidRefreshToken, 4 &#x3D; EmptyCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserTestauthexceptionsGet(testType?: ApiUserTestauthexceptionsGetTestTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserTestauthexceptionsGet(testType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.apiUserTestauthexceptionsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user info
         * @param {string} deviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesignaturesUserUserinfoGet(deviceToken: string, options?: any): AxiosPromise<APIXdentDTOUserGlobalXdentConfigurationWrapperDto> {
            return localVarFp.apiSafesignaturesUserUserinfoGet(deviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAuthbycredentialsPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiUserAuthbycredentialsPost(apiSafesignaturesAuthPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates user by login credentials via post model, for 30 sec.
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAuthbycredentialstestPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiUserAuthbycredentialstestPost(apiSafesignaturesAuthPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExtendbearertokenPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiUserExtendbearertokenPost(aPISharedExtendBearerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetuserinfoGet(options?: any): AxiosPromise<APISharedGlobalXdentConfigurationWrapperDto> {
            return localVarFp.apiUserGetuserinfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user info
         * @param {string} [controller] 
         * @param {string} [cacheContext] 
         * @param {string} [invoker] 
         * @param {string} [key] 
         * @param {string} [transactionId] 
         * @param {number} [expiration] 
         * @param {string} [idGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetuserinfox3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: any): AxiosPromise<APISharedGlobalXdentConfigurationWrapperDto> {
            return localVarFp.apiUserGetuserinfox3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logouts this instance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLogoutPost(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserNottestauthexceptionsGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserNottestauthexceptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tests auth exceptions
         * @param {ApiUserTestauthexceptionsGetTestTypeEnum} [testType] 0 &#x3D; MembershipNotFound, 1 &#x3D; WrongCredentials, 2 &#x3D; SubscriptionExpired, 3 &#x3D; InvalidRefreshToken, 4 &#x3D; EmptyCredentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserTestauthexceptionsGet(testType?: ApiUserTestauthexceptionsGetTestTypeEnum, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserTestauthexceptionsGet(testType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Get user info
     * @param {string} deviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiSafesignaturesUserUserinfoGet(deviceToken: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiSafesignaturesUserUserinfoGet(deviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates user by login credentials via post model
     * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAuthbycredentialsPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAuthbycredentialsPost(apiSafesignaturesAuthPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates user by login credentials via post model, for 30 sec.
     * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAuthbycredentialstestPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAuthbycredentialstestPost(apiSafesignaturesAuthPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extends bearer token with provided refresh and old bearer
     * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserExtendbearertokenPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserExtendbearertokenPost(aPISharedExtendBearerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetuserinfoGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetuserinfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user info
     * @param {string} [controller] 
     * @param {string} [cacheContext] 
     * @param {string} [invoker] 
     * @param {string} [key] 
     * @param {string} [transactionId] 
     * @param {number} [expiration] 
     * @param {string} [idGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetuserinfox3Get(controller?: string, cacheContext?: string, invoker?: string, key?: string, transactionId?: string, expiration?: number, idGlobal?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetuserinfox3Get(controller, cacheContext, invoker, key, transactionId, expiration, idGlobal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logouts this instance.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLogoutPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserNottestauthexceptionsGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserNottestauthexceptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tests auth exceptions
     * @param {ApiUserTestauthexceptionsGetTestTypeEnum} [testType] 0 &#x3D; MembershipNotFound, 1 &#x3D; WrongCredentials, 2 &#x3D; SubscriptionExpired, 3 &#x3D; InvalidRefreshToken, 4 &#x3D; EmptyCredentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserTestauthexceptionsGet(testType?: ApiUserTestauthexceptionsGetTestTypeEnum, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserTestauthexceptionsGet(testType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiUserTestauthexceptionsGetTestTypeEnum = {
    MembershipNotFound: 'MembershipNotFound',
    WrongCredentials: 'WrongCredentials',
    SubscriptionExpired: 'SubscriptionExpired',
    InvalidRefreshToken: 'InvalidRefreshToken',
    EmptyCredentials: 'EmptyCredentials'
} as const;
export type ApiUserTestauthexceptionsGetTestTypeEnum = typeof ApiUserTestauthexceptionsGetTestTypeEnum[keyof typeof ApiUserTestauthexceptionsGetTestTypeEnum];


/**
 * VirtualWebCronApi - axios parameter creator
 * @export
 */
export const VirtualWebCronApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancels all running tests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerCancelalltestsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/virtualwebcroncotroller/cancelalltests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns active test running time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerGetrunningtimeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/virtualwebcroncotroller/getrunningtime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels all running tests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerReturntestresultsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/virtualwebcroncotroller/returntestresults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Turns on/off vwbcs for permanent notifications.
         * @param {number} [delay] 
         * @param {boolean} [killActive] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet: async (delay?: number, killActive?: boolean, idCompany?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/virtualwebcroncotroller/simulatecronnotificationspermanent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (delay !== undefined) {
                localVarQueryParameter['delay'] = delay;
            }

            if (killActive !== undefined) {
                localVarQueryParameter['killActive'] = killActive;
            }

            if (idCompany !== undefined) {
                localVarQueryParameter['idCompany'] = idCompany;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Turns on/off vwbcs for trainsient notifications. (deletes all of them after send, so they can be sent again.)
         * @param {number} [delay] 
         * @param {boolean} [killActive] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet: async (delay?: number, killActive?: boolean, idCompany?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/virtualwebcroncotroller/simulatecronnotificationstransient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (delay !== undefined) {
                localVarQueryParameter['delay'] = delay;
            }

            if (killActive !== undefined) {
                localVarQueryParameter['killActive'] = killActive;
            }

            if (idCompany !== undefined) {
                localVarQueryParameter['idCompany'] = idCompany;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VirtualWebCronApi - functional programming interface
 * @export
 */
export const VirtualWebCronApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VirtualWebCronApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancels all running tests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVirtualwebcroncotrollerCancelalltestsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVirtualwebcroncotrollerCancelalltestsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VirtualWebCronApi.apiVirtualwebcroncotrollerCancelalltestsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Returns active test running time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVirtualwebcroncotrollerGetrunningtimeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVirtualwebcroncotrollerGetrunningtimeGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VirtualWebCronApi.apiVirtualwebcroncotrollerGetrunningtimeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Cancels all running tests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVirtualwebcroncotrollerReturntestresultsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVirtualwebcroncotrollerReturntestresultsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VirtualWebCronApi.apiVirtualwebcroncotrollerReturntestresultsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Turns on/off vwbcs for permanent notifications.
         * @param {number} [delay] 
         * @param {boolean} [killActive] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet(delay?: number, killActive?: boolean, idCompany?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet(delay, killActive, idCompany, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VirtualWebCronApi.apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Turns on/off vwbcs for trainsient notifications. (deletes all of them after send, so they can be sent again.)
         * @param {number} [delay] 
         * @param {boolean} [killActive] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet(delay?: number, killActive?: boolean, idCompany?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet(delay, killActive, idCompany, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VirtualWebCronApi.apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VirtualWebCronApi - factory interface
 * @export
 */
export const VirtualWebCronApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VirtualWebCronApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancels all running tests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerCancelalltestsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiVirtualwebcroncotrollerCancelalltestsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns active test running time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerGetrunningtimeGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiVirtualwebcroncotrollerGetrunningtimeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels all running tests.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerReturntestresultsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiVirtualwebcroncotrollerReturntestresultsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Turns on/off vwbcs for permanent notifications.
         * @param {number} [delay] 
         * @param {boolean} [killActive] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet(delay?: number, killActive?: boolean, idCompany?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet(delay, killActive, idCompany, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Turns on/off vwbcs for trainsient notifications. (deletes all of them after send, so they can be sent again.)
         * @param {number} [delay] 
         * @param {boolean} [killActive] 
         * @param {string | null} [idCompany] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet(delay?: number, killActive?: boolean, idCompany?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet(delay, killActive, idCompany, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VirtualWebCronApi - object-oriented interface
 * @export
 * @class VirtualWebCronApi
 * @extends {BaseAPI}
 */
export class VirtualWebCronApi extends BaseAPI {
    /**
     * 
     * @summary Cancels all running tests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VirtualWebCronApi
     */
    public apiVirtualwebcroncotrollerCancelalltestsGet(options?: AxiosRequestConfig) {
        return VirtualWebCronApiFp(this.configuration).apiVirtualwebcroncotrollerCancelalltestsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns active test running time.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VirtualWebCronApi
     */
    public apiVirtualwebcroncotrollerGetrunningtimeGet(options?: AxiosRequestConfig) {
        return VirtualWebCronApiFp(this.configuration).apiVirtualwebcroncotrollerGetrunningtimeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels all running tests.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VirtualWebCronApi
     */
    public apiVirtualwebcroncotrollerReturntestresultsGet(options?: AxiosRequestConfig) {
        return VirtualWebCronApiFp(this.configuration).apiVirtualwebcroncotrollerReturntestresultsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Turns on/off vwbcs for permanent notifications.
     * @param {number} [delay] 
     * @param {boolean} [killActive] 
     * @param {string | null} [idCompany] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VirtualWebCronApi
     */
    public apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet(delay?: number, killActive?: boolean, idCompany?: string | null, options?: AxiosRequestConfig) {
        return VirtualWebCronApiFp(this.configuration).apiVirtualwebcroncotrollerSimulatecronnotificationspermanentGet(delay, killActive, idCompany, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Turns on/off vwbcs for trainsient notifications. (deletes all of them after send, so they can be sent again.)
     * @param {number} [delay] 
     * @param {boolean} [killActive] 
     * @param {string | null} [idCompany] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VirtualWebCronApi
     */
    public apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet(delay?: number, killActive?: boolean, idCompany?: string | null, options?: AxiosRequestConfig) {
        return VirtualWebCronApiFp(this.configuration).apiVirtualwebcroncotrollerSimulatecronnotificationstransientGet(delay, killActive, idCompany, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VzpApi - axios parameter creator
 * @export
 */
export const VzpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idVzpAsyncResponse] id of file
         * @param {ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum} [returnFileAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpInsurancevzpasyncresponsefileGet: async (idVzpAsyncResponse?: string, returnFileAsType?: ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vzp/insurancevzpasyncresponsefile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idVzpAsyncResponse !== undefined) {
                localVarQueryParameter['idVzpAsyncResponse'] = idVzpAsyncResponse;
            }

            if (returnFileAsType !== undefined) {
                localVarQueryParameter['returnFileAsType'] = returnFileAsType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOVzpAsyncResponsesGridDto} [aPIXdentDTOVzpAsyncResponsesGridDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpInsurancevzpasyncresponsegridPost: async (aPIXdentDTOVzpAsyncResponsesGridDto?: APIXdentDTOVzpAsyncResponsesGridDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vzp/insurancevzpasyncresponsegrid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentDTOVzpAsyncResponsesGridDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [q] 
         * @param {string} [pageLimit] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpSearchinsurancediagnoseitemsGet: async (q?: string, pageLimit?: string, idStaff?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vzp/searchinsurancediagnoseitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page_limit'] = pageLimit;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [q] 
         * @param {string} [pageLimit] 
         * @param {string} [idStaff] 
         * @param {number | null} [clientAge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpSearchinsuranceitemsGet: async (q?: string, pageLimit?: string, idStaff?: string, clientAge?: number | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vzp/searchinsuranceitems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page_limit'] = pageLimit;
            }

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }

            if (clientAge !== undefined) {
                localVarQueryParameter['clientAge'] = clientAge;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VzpApi - functional programming interface
 * @export
 */
export const VzpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VzpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idVzpAsyncResponse] id of file
         * @param {ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum} [returnFileAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVzpInsurancevzpasyncresponsefileGet(idVzpAsyncResponse?: string, returnFileAsType?: ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVzpInsurancevzpasyncresponsefileGet(idVzpAsyncResponse, returnFileAsType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VzpApi.apiVzpInsurancevzpasyncresponsefileGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOVzpAsyncResponsesGridDto} [aPIXdentDTOVzpAsyncResponsesGridDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVzpInsurancevzpasyncresponsegridPost(aPIXdentDTOVzpAsyncResponsesGridDto?: APIXdentDTOVzpAsyncResponsesGridDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOVzpAsyncResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVzpInsurancevzpasyncresponsegridPost(aPIXdentDTOVzpAsyncResponsesGridDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VzpApi.apiVzpInsurancevzpasyncresponsegridPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [q] 
         * @param {string} [pageLimit] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVzpSearchinsurancediagnoseitemsGet(q?: string, pageLimit?: string, idStaff?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOVzpInsuranceSearchDiagnoseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVzpSearchinsurancediagnoseitemsGet(q, pageLimit, idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VzpApi.apiVzpSearchinsurancediagnoseitemsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [q] 
         * @param {string} [pageLimit] 
         * @param {string} [idStaff] 
         * @param {number | null} [clientAge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVzpSearchinsuranceitemsGet(q?: string, pageLimit?: string, idStaff?: string, clientAge?: number | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APIXdentDTOVzpInsuranceItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVzpSearchinsuranceitemsGet(q, pageLimit, idStaff, clientAge, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VzpApi.apiVzpSearchinsuranceitemsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VzpApi - factory interface
 * @export
 */
export const VzpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VzpApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idVzpAsyncResponse] id of file
         * @param {ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum} [returnFileAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpInsurancevzpasyncresponsefileGet(idVzpAsyncResponse?: string, returnFileAsType?: ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiVzpInsurancevzpasyncresponsefileGet(idVzpAsyncResponse, returnFileAsType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APIXdentDTOVzpAsyncResponsesGridDto} [aPIXdentDTOVzpAsyncResponsesGridDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpInsurancevzpasyncresponsegridPost(aPIXdentDTOVzpAsyncResponsesGridDto?: APIXdentDTOVzpAsyncResponsesGridDto, options?: any): AxiosPromise<Array<APIXdentDTOVzpAsyncResponseDto>> {
            return localVarFp.apiVzpInsurancevzpasyncresponsegridPost(aPIXdentDTOVzpAsyncResponsesGridDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [q] 
         * @param {string} [pageLimit] 
         * @param {string} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpSearchinsurancediagnoseitemsGet(q?: string, pageLimit?: string, idStaff?: string, options?: any): AxiosPromise<Array<APIXdentDTOVzpInsuranceSearchDiagnoseDto>> {
            return localVarFp.apiVzpSearchinsurancediagnoseitemsGet(q, pageLimit, idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [q] 
         * @param {string} [pageLimit] 
         * @param {string} [idStaff] 
         * @param {number | null} [clientAge] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVzpSearchinsuranceitemsGet(q?: string, pageLimit?: string, idStaff?: string, clientAge?: number | null, options?: any): AxiosPromise<Array<APIXdentDTOVzpInsuranceItemDto>> {
            return localVarFp.apiVzpSearchinsuranceitemsGet(q, pageLimit, idStaff, clientAge, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VzpApi - object-oriented interface
 * @export
 * @class VzpApi
 * @extends {BaseAPI}
 */
export class VzpApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idVzpAsyncResponse] id of file
     * @param {ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum} [returnFileAsType] 0 &#x3D; Image,1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF, 6 &#x3D; None
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VzpApi
     */
    public apiVzpInsurancevzpasyncresponsefileGet(idVzpAsyncResponse?: string, returnFileAsType?: ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum, options?: AxiosRequestConfig) {
        return VzpApiFp(this.configuration).apiVzpInsurancevzpasyncresponsefileGet(idVzpAsyncResponse, returnFileAsType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APIXdentDTOVzpAsyncResponsesGridDto} [aPIXdentDTOVzpAsyncResponsesGridDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VzpApi
     */
    public apiVzpInsurancevzpasyncresponsegridPost(aPIXdentDTOVzpAsyncResponsesGridDto?: APIXdentDTOVzpAsyncResponsesGridDto, options?: AxiosRequestConfig) {
        return VzpApiFp(this.configuration).apiVzpInsurancevzpasyncresponsegridPost(aPIXdentDTOVzpAsyncResponsesGridDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [q] 
     * @param {string} [pageLimit] 
     * @param {string} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VzpApi
     */
    public apiVzpSearchinsurancediagnoseitemsGet(q?: string, pageLimit?: string, idStaff?: string, options?: AxiosRequestConfig) {
        return VzpApiFp(this.configuration).apiVzpSearchinsurancediagnoseitemsGet(q, pageLimit, idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [q] 
     * @param {string} [pageLimit] 
     * @param {string} [idStaff] 
     * @param {number | null} [clientAge] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VzpApi
     */
    public apiVzpSearchinsuranceitemsGet(q?: string, pageLimit?: string, idStaff?: string, clientAge?: number | null, options?: AxiosRequestConfig) {
        return VzpApiFp(this.configuration).apiVzpSearchinsuranceitemsGet(q, pageLimit, idStaff, clientAge, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum = typeof ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum[keyof typeof ApiVzpInsurancevzpasyncresponsefileGetReturnFileAsTypeEnum];


/**
 * XdentApi - axios parameter creator
 * @export
 */
export const XdentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Migration EP - registers device by ios device token via logged user
         * @param {string} deviceToken 
         * @param {APIXdentControllersSafeSignaturesInitDevice} [aPIXdentControllersSafeSignaturesInitDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesigmigrationXdentInitdevicePost: async (deviceToken: string, aPIXdentControllersSafeSignaturesInitDevice?: APIXdentControllersSafeSignaturesInitDevice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceToken' is not null or undefined
            assertParamExists('apiSafesigmigrationXdentInitdevicePost', 'deviceToken', deviceToken)
            const localVarPath = `/api/safesigmigration/xdent/initdevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deviceToken != null) {
                localVarHeaderParameter['deviceToken'] = String(deviceToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPIXdentControllersSafeSignaturesInitDevice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentApi - functional programming interface
 * @export
 */
export const XdentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Migration EP - registers device by ios device token via logged user
         * @param {string} deviceToken 
         * @param {APIXdentControllersSafeSignaturesInitDevice} [aPIXdentControllersSafeSignaturesInitDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSafesigmigrationXdentInitdevicePost(deviceToken: string, aPIXdentControllersSafeSignaturesInitDevice?: APIXdentControllersSafeSignaturesInitDevice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSafesigmigrationXdentInitdevicePost(deviceToken, aPIXdentControllersSafeSignaturesInitDevice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentApi.apiSafesigmigrationXdentInitdevicePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentApi - factory interface
 * @export
 */
export const XdentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentApiFp(configuration)
    return {
        /**
         * 
         * @summary Migration EP - registers device by ios device token via logged user
         * @param {string} deviceToken 
         * @param {APIXdentControllersSafeSignaturesInitDevice} [aPIXdentControllersSafeSignaturesInitDevice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSafesigmigrationXdentInitdevicePost(deviceToken: string, aPIXdentControllersSafeSignaturesInitDevice?: APIXdentControllersSafeSignaturesInitDevice, options?: any): AxiosPromise<void> {
            return localVarFp.apiSafesigmigrationXdentInitdevicePost(deviceToken, aPIXdentControllersSafeSignaturesInitDevice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentApi - object-oriented interface
 * @export
 * @class XdentApi
 * @extends {BaseAPI}
 */
export class XdentApi extends BaseAPI {
    /**
     * 
     * @summary Migration EP - registers device by ios device token via logged user
     * @param {string} deviceToken 
     * @param {APIXdentControllersSafeSignaturesInitDevice} [aPIXdentControllersSafeSignaturesInitDevice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentApi
     */
    public apiSafesigmigrationXdentInitdevicePost(deviceToken: string, aPIXdentControllersSafeSignaturesInitDevice?: APIXdentControllersSafeSignaturesInitDevice, options?: AxiosRequestConfig) {
        return XdentApiFp(this.configuration).apiSafesigmigrationXdentInitdevicePost(deviceToken, aPIXdentControllersSafeSignaturesInitDevice, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentAdminApi - axios parameter creator
 * @export
 */
export const XdentAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintgenericinvoiceGet: async (idInvoice?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentadmininvoice/printgenericinvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idInvoice !== undefined) {
                localVarQueryParameter['idInvoice'] = idInvoice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintgenericinvoicepreviewGet: async (idInvoice?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentadmininvoice/printgenericinvoicepreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idInvoice !== undefined) {
                localVarQueryParameter['idInvoice'] = idInvoice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {string} [idInvoiceTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintnextgetinvoiceGet: async (idInvoice?: string, idInvoiceTypeCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentadmininvoice/printnextgetinvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idInvoice !== undefined) {
                localVarQueryParameter['idInvoice'] = idInvoice;
            }

            if (idInvoiceTypeCode !== undefined) {
                localVarQueryParameter['idInvoiceTypeCode'] = idInvoiceTypeCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintsubscriptioninvoiceGet: async (idInvoice?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentadmininvoice/printsubscriptioninvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idInvoice !== undefined) {
                localVarQueryParameter['idInvoice'] = idInvoice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet: async (idInvoice?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentadmininvoice/printsubscriptioninvoicepreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idInvoice !== undefined) {
                localVarQueryParameter['idInvoice'] = idInvoice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentAdminApi - functional programming interface
 * @export
 */
export const XdentAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentadmininvoicePrintgenericinvoiceGet(idInvoice?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentadmininvoicePrintgenericinvoiceGet(idInvoice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentAdminApi.apiXdentadmininvoicePrintgenericinvoiceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentadmininvoicePrintgenericinvoicepreviewGet(idInvoice?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentadmininvoicePrintgenericinvoicepreviewGet(idInvoice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentAdminApi.apiXdentadmininvoicePrintgenericinvoicepreviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {string} [idInvoiceTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentadmininvoicePrintnextgetinvoiceGet(idInvoice?: string, idInvoiceTypeCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentadmininvoicePrintnextgetinvoiceGet(idInvoice, idInvoiceTypeCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentAdminApi.apiXdentadmininvoicePrintnextgetinvoiceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentadmininvoicePrintsubscriptioninvoiceGet(idInvoice?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentadmininvoicePrintsubscriptioninvoiceGet(idInvoice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentAdminApi.apiXdentadmininvoicePrintsubscriptioninvoiceGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet(idInvoice?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet(idInvoice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentAdminApi.apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentAdminApi - factory interface
 * @export
 */
export const XdentAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentAdminApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintgenericinvoiceGet(idInvoice?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentadmininvoicePrintgenericinvoiceGet(idInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintgenericinvoicepreviewGet(idInvoice?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentadmininvoicePrintgenericinvoicepreviewGet(idInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {string} [idInvoiceTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintnextgetinvoiceGet(idInvoice?: string, idInvoiceTypeCode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentadmininvoicePrintnextgetinvoiceGet(idInvoice, idInvoiceTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintsubscriptioninvoiceGet(idInvoice?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentadmininvoicePrintsubscriptioninvoiceGet(idInvoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idInvoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet(idInvoice?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet(idInvoice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentAdminApi - object-oriented interface
 * @export
 * @class XdentAdminApi
 * @extends {BaseAPI}
 */
export class XdentAdminApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idInvoice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentAdminApi
     */
    public apiXdentadmininvoicePrintgenericinvoiceGet(idInvoice?: string, options?: AxiosRequestConfig) {
        return XdentAdminApiFp(this.configuration).apiXdentadmininvoicePrintgenericinvoiceGet(idInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idInvoice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentAdminApi
     */
    public apiXdentadmininvoicePrintgenericinvoicepreviewGet(idInvoice?: string, options?: AxiosRequestConfig) {
        return XdentAdminApiFp(this.configuration).apiXdentadmininvoicePrintgenericinvoicepreviewGet(idInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idInvoice] 
     * @param {string} [idInvoiceTypeCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentAdminApi
     */
    public apiXdentadmininvoicePrintnextgetinvoiceGet(idInvoice?: string, idInvoiceTypeCode?: string, options?: AxiosRequestConfig) {
        return XdentAdminApiFp(this.configuration).apiXdentadmininvoicePrintnextgetinvoiceGet(idInvoice, idInvoiceTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idInvoice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentAdminApi
     */
    public apiXdentadmininvoicePrintsubscriptioninvoiceGet(idInvoice?: string, options?: AxiosRequestConfig) {
        return XdentAdminApiFp(this.configuration).apiXdentadmininvoicePrintsubscriptioninvoiceGet(idInvoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idInvoice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentAdminApi
     */
    public apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet(idInvoice?: string, options?: AxiosRequestConfig) {
        return XdentAdminApiFp(this.configuration).apiXdentadmininvoicePrintsubscriptioninvoicepreviewGet(idInvoice, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentFormApi - axios parameter creator
 * @export
 */
export const XdentFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentformGetpopulatedformsbyclientGet: async (idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentform/getpopulatedformsbyclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentFormApi - functional programming interface
 * @export
 */
export const XdentFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentformGetpopulatedformsbyclientGet(idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<APISharedXdentFormDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentformGetpopulatedformsbyclientGet(idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentFormApi.apiXdentformGetpopulatedformsbyclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentFormApi - factory interface
 * @export
 */
export const XdentFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentFormApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all available forms by location based od provided idClient
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentformGetpopulatedformsbyclientGet(idClient?: string | null, options?: any): AxiosPromise<Array<APISharedXdentFormDto>> {
            return localVarFp.apiXdentformGetpopulatedformsbyclientGet(idClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentFormApi - object-oriented interface
 * @export
 * @class XdentFormApi
 * @extends {BaseAPI}
 */
export class XdentFormApi extends BaseAPI {
    /**
     * 
     * @summary Gets all available forms by location based od provided idClient
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentFormApi
     */
    public apiXdentformGetpopulatedformsbyclientGet(idClient?: string | null, options?: AxiosRequestConfig) {
        return XdentFormApiFp(this.configuration).apiXdentformGetpopulatedformsbyclientGet(idClient, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentPointAppointmentApi - axios parameter creator
 * @export
 */
export const XdentPointAppointmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirms appointment by id. Only for AS_ORDERED and AS_CONFIRMED states.
         * @param {string | null} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointAppointmentConfirmappointmentPatch: async (appointmentId: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('apiXdentpointAppointmentConfirmappointmentPatch', 'appointmentId', appointmentId)
            const localVarPath = `/api/xdentpoint/appointment/confirmappointment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {string | null} [appointmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointAppointmentGetappointmentbyidGet: async (appointmentId?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/appointment/getappointmentbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (appointmentId !== undefined) {
                localVarQueryParameter['appointmentId'] = appointmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointAppointmentApi - functional programming interface
 * @export
 */
export const XdentPointAppointmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointAppointmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirms appointment by id. Only for AS_ORDERED and AS_CONFIRMED states.
         * @param {string | null} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointAppointmentConfirmappointmentPatch(appointmentId: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointAppointmentConfirmappointmentPatch(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointAppointmentApi.apiXdentpointAppointmentConfirmappointmentPatch']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {string | null} [appointmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointAppointmentGetappointmentbyidGet(appointmentId?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedAppointmentDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointAppointmentGetappointmentbyidGet(appointmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointAppointmentApi.apiXdentpointAppointmentGetappointmentbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointAppointmentApi - factory interface
 * @export
 */
export const XdentPointAppointmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointAppointmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirms appointment by id. Only for AS_ORDERED and AS_CONFIRMED states.
         * @param {string | null} appointmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointAppointmentConfirmappointmentPatch(appointmentId: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentpointAppointmentConfirmappointmentPatch(appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets appointment by id for owner user.
         * @param {string | null} [appointmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointAppointmentGetappointmentbyidGet(appointmentId?: string | null, options?: any): AxiosPromise<APISharedAppointmentDto> {
            return localVarFp.apiXdentpointAppointmentGetappointmentbyidGet(appointmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointAppointmentApi - object-oriented interface
 * @export
 * @class XdentPointAppointmentApi
 * @extends {BaseAPI}
 */
export class XdentPointAppointmentApi extends BaseAPI {
    /**
     * 
     * @summary Confirms appointment by id. Only for AS_ORDERED and AS_CONFIRMED states.
     * @param {string | null} appointmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointAppointmentApi
     */
    public apiXdentpointAppointmentConfirmappointmentPatch(appointmentId: string | null, options?: AxiosRequestConfig) {
        return XdentPointAppointmentApiFp(this.configuration).apiXdentpointAppointmentConfirmappointmentPatch(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets appointment by id for owner user.
     * @param {string | null} [appointmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointAppointmentApi
     */
    public apiXdentpointAppointmentGetappointmentbyidGet(appointmentId?: string | null, options?: AxiosRequestConfig) {
        return XdentPointAppointmentApiFp(this.configuration).apiXdentpointAppointmentGetappointmentbyidGet(appointmentId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentPointClientApi - axios parameter creator
 * @export
 */
export const XdentPointClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get upcoming clients for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointClientUpcomingclientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/client/upcomingclients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointClientApi - functional programming interface
 * @export
 */
export const XdentPointClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get upcoming clients for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointClientUpcomingclientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointClientUpcomingclientsGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointClientApi.apiXdentpointClientUpcomingclientsGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointClientApi - factory interface
 * @export
 */
export const XdentPointClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Get upcoming clients for logged user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointClientUpcomingclientsGet(options?: any): AxiosPromise<APIXdentServicesXdentScopedClientServiceXdentPointGridAppointmentWrapperDto> {
            return localVarFp.apiXdentpointClientUpcomingclientsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointClientApi - object-oriented interface
 * @export
 * @class XdentPointClientApi
 * @extends {BaseAPI}
 */
export class XdentPointClientApi extends BaseAPI {
    /**
     * 
     * @summary Get upcoming clients for logged user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointClientApi
     */
    public apiXdentpointClientUpcomingclientsGet(options?: AxiosRequestConfig) {
        return XdentPointClientApiFp(this.configuration).apiXdentpointClientUpcomingclientsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentPointConfigurationApi - axios parameter creator
 * @export
 */
export const XdentPointConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get app configuration. For model example, call the endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointConfigGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointConfigurationApi - functional programming interface
 * @export
 */
export const XdentPointConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get app configuration. For model example, call the endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointConfigGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointConfigGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointConfigurationApi.apiXdentpointConfigGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointConfigurationApi - factory interface
 * @export
 */
export const XdentPointConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get app configuration. For model example, call the endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointConfigGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentpointConfigGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointConfigurationApi - object-oriented interface
 * @export
 * @class XdentPointConfigurationApi
 * @extends {BaseAPI}
 */
export class XdentPointConfigurationApi extends BaseAPI {
    /**
     * 
     * @summary Get app configuration. For model example, call the endpoint.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointConfigurationApi
     */
    public apiXdentpointConfigGet(options?: AxiosRequestConfig) {
        return XdentPointConfigurationApiFp(this.configuration).apiXdentpointConfigGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentPointDeviceApi - axios parameter creator
 * @export
 */
export const XdentPointDeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Sends anonymous pair request.
         * @param {string} idDevice Device token. Has to be bound to device.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointPairGet: async (idDevice: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idDevice' is not null or undefined
            assertParamExists('apiXdentpointPairGet', 'idDevice', idDevice)
            const localVarPath = `/api/xdentpoint/pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idDevice !== undefined) {
                localVarQueryParameter['idDevice'] = idDevice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointDeviceApi - functional programming interface
 * @export
 */
export const XdentPointDeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointDeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Sends anonymous pair request.
         * @param {string} idDevice Device token. Has to be bound to device.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointPairGet(idDevice: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointPairGet(idDevice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointDeviceApi.apiXdentpointPairGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointDeviceApi - factory interface
 * @export
 */
export const XdentPointDeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointDeviceApiFp(configuration)
    return {
        /**
         * 
         * @summary Sends anonymous pair request.
         * @param {string} idDevice Device token. Has to be bound to device.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointPairGet(idDevice: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentpointPairGet(idDevice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointDeviceApi - object-oriented interface
 * @export
 * @class XdentPointDeviceApi
 * @extends {BaseAPI}
 */
export class XdentPointDeviceApi extends BaseAPI {
    /**
     * 
     * @summary Sends anonymous pair request.
     * @param {string} idDevice Device token. Has to be bound to device.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointDeviceApi
     */
    public apiXdentpointPairGet(idDevice: string, options?: AxiosRequestConfig) {
        return XdentPointDeviceApiFp(this.configuration).apiXdentpointPairGet(idDevice, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentPointGalleryApi - axios parameter creator
 * @export
 */
export const XdentPointGalleryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointGalleryGetfilebyidGet: async (idFile?: string | null, imageAsType?: ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/gallery/getfilebyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (imageAsType !== undefined) {
                localVarQueryParameter['imageAsType'] = imageAsType;
            }

            if (extendedBaseModel !== undefined) {
                localVarQueryParameter['extendedBaseModel'] = extendedBaseModel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointGalleryGetthumbnailbyidGet: async (idFile?: string | null, size?: string, thumbnailAsType?: ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/gallery/getthumbnailbyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idFile !== undefined) {
                localVarQueryParameter['idFile'] = idFile;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (thumbnailAsType !== undefined) {
                localVarQueryParameter['thumbnailAsType'] = thumbnailAsType;
            }

            if (extendedBaseModel !== undefined) {
                localVarQueryParameter['extendedBaseModel'] = extendedBaseModel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointGalleryApi - functional programming interface
 * @export
 */
export const XdentPointGalleryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointGalleryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointGalleryGetfilebyidGet(idFile?: string | null, imageAsType?: ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedExtendedBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointGalleryGetfilebyidGet(idFile, imageAsType, extendedBaseModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointGalleryApi.apiXdentpointGalleryGetfilebyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointGalleryGetthumbnailbyidGet(idFile?: string | null, size?: string, thumbnailAsType?: ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedExtendedBaseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointGalleryGetthumbnailbyidGet(idFile, size, thumbnailAsType, extendedBaseModel, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointGalleryApi.apiXdentpointGalleryGetthumbnailbyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointGalleryApi - factory interface
 * @export
 */
export const XdentPointGalleryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointGalleryApiFp(configuration)
    return {
        /**
         * 
         * @summary Return image from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Return extended base64 model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointGalleryGetfilebyidGet(idFile?: string | null, imageAsType?: ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: any): AxiosPromise<APISharedExtendedBaseModel> {
            return localVarFp.apiXdentpointGalleryGetfilebyidGet(idFile, imageAsType, extendedBaseModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return thumbnail from gallery by id as provided type
         * @param {string | null} [idFile] 
         * @param {string} [size] 
         * @param {ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {boolean} [extendedBaseModel] Returns extended base model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointGalleryGetthumbnailbyidGet(idFile?: string | null, size?: string, thumbnailAsType?: ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: any): AxiosPromise<APISharedExtendedBaseModel> {
            return localVarFp.apiXdentpointGalleryGetthumbnailbyidGet(idFile, size, thumbnailAsType, extendedBaseModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointGalleryApi - object-oriented interface
 * @export
 * @class XdentPointGalleryApi
 * @extends {BaseAPI}
 */
export class XdentPointGalleryApi extends BaseAPI {
    /**
     * 
     * @summary Return image from gallery by id as provided type
     * @param {string | null} [idFile] 
     * @param {ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {boolean} [extendedBaseModel] Return extended base64 model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointGalleryApi
     */
    public apiXdentpointGalleryGetfilebyidGet(idFile?: string | null, imageAsType?: ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig) {
        return XdentPointGalleryApiFp(this.configuration).apiXdentpointGalleryGetfilebyidGet(idFile, imageAsType, extendedBaseModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return thumbnail from gallery by id as provided type
     * @param {string | null} [idFile] 
     * @param {string} [size] 
     * @param {ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum} [thumbnailAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {boolean} [extendedBaseModel] Returns extended base model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointGalleryApi
     */
    public apiXdentpointGalleryGetthumbnailbyidGet(idFile?: string | null, size?: string, thumbnailAsType?: ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum, extendedBaseModel?: boolean, options?: AxiosRequestConfig) {
        return XdentPointGalleryApiFp(this.configuration).apiXdentpointGalleryGetthumbnailbyidGet(idFile, size, thumbnailAsType, extendedBaseModel, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum = typeof ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum[keyof typeof ApiXdentpointGalleryGetfilebyidGetImageAsTypeEnum];
/**
 * @export
 */
export const ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum = typeof ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum[keyof typeof ApiXdentpointGalleryGetthumbnailbyidGetThumbnailAsTypeEnum];


/**
 * XdentPointLocationApi - axios parameter creator
 * @export
 */
export const XdentPointLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return location logo from gallery by locationId as desired type
         * @param {string} [idLocation] 
         * @param {ApiXdentpointLocationLogoGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointLocationLogoGet: async (idLocation?: string, imageAsType?: ApiXdentpointLocationLogoGetImageAsTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/location/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLocation !== undefined) {
                localVarQueryParameter['idLocation'] = idLocation;
            }

            if (imageAsType !== undefined) {
                localVarQueryParameter['imageAsType'] = imageAsType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointLocationApi - functional programming interface
 * @export
 */
export const XdentPointLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return location logo from gallery by locationId as desired type
         * @param {string} [idLocation] 
         * @param {ApiXdentpointLocationLogoGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointLocationLogoGet(idLocation?: string, imageAsType?: ApiXdentpointLocationLogoGetImageAsTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointLocationLogoGet(idLocation, imageAsType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointLocationApi.apiXdentpointLocationLogoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointLocationApi - factory interface
 * @export
 */
export const XdentPointLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointLocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Return location logo from gallery by locationId as desired type
         * @param {string} [idLocation] 
         * @param {ApiXdentpointLocationLogoGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointLocationLogoGet(idLocation?: string, imageAsType?: ApiXdentpointLocationLogoGetImageAsTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentpointLocationLogoGet(idLocation, imageAsType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointLocationApi - object-oriented interface
 * @export
 * @class XdentPointLocationApi
 * @extends {BaseAPI}
 */
export class XdentPointLocationApi extends BaseAPI {
    /**
     * 
     * @summary Return location logo from gallery by locationId as desired type
     * @param {string} [idLocation] 
     * @param {ApiXdentpointLocationLogoGetImageAsTypeEnum} [imageAsType] 0 &#x3D; Raw, 1 &#x3D; ByteStream, 2 &#x3D; Base64, 3 &#x3D; CompressedImageStream, 4 &#x3D; BSON, 5 &#x3D; PDF
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointLocationApi
     */
    public apiXdentpointLocationLogoGet(idLocation?: string, imageAsType?: ApiXdentpointLocationLogoGetImageAsTypeEnum, options?: AxiosRequestConfig) {
        return XdentPointLocationApiFp(this.configuration).apiXdentpointLocationLogoGet(idLocation, imageAsType, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const ApiXdentpointLocationLogoGetImageAsTypeEnum = {
    Raw: 'Raw',
    ByteStream: 'ByteStream',
    Base64: 'Base64',
    CompressedImageStream: 'CompressedImageStream',
    Bson: 'Bson',
    Pdf: 'Pdf',
    Auto: 'Auto',
    None: 'None'
} as const;
export type ApiXdentpointLocationLogoGetImageAsTypeEnum = typeof ApiXdentpointLocationLogoGetImageAsTypeEnum[keyof typeof ApiXdentpointLocationLogoGetImageAsTypeEnum];


/**
 * XdentPointTestApi - axios parameter creator
 * @export
 */
export const XdentPointTestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Test possible pair responses for subsequent api calls,
         * @param {string} idXdentPointDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointTestpairheaderGet: async (idXdentPointDevice: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idXdentPointDevice' is not null or undefined
            assertParamExists('apiXdentpointTestpairheaderGet', 'idXdentPointDevice', idXdentPointDevice)
            const localVarPath = `/api/xdentpoint/testpairheader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentPointDevice != null) {
                localVarHeaderParameter['idXdentPointDevice'] = String(idXdentPointDevice);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Test possible pair responses.
         * @param {string} [desiredResponse] Response to be given to client. (MOCK), Possible values: DEVICE_PAIRED, NO_DEVICE_ID_PROVIDED, DEVICE_NOT_PAIRED, DEVICE_PAIR_EXPIRED. Returns random for null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointTestpairresponsesGet: async (desiredResponse?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/testpairresponses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (desiredResponse !== undefined) {
                localVarQueryParameter['desiredResponse'] = desiredResponse;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointTestApi - functional programming interface
 * @export
 */
export const XdentPointTestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointTestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Test possible pair responses for subsequent api calls,
         * @param {string} idXdentPointDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointTestpairheaderGet(idXdentPointDevice: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointTestpairheaderGet(idXdentPointDevice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointTestApi.apiXdentpointTestpairheaderGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Test possible pair responses.
         * @param {string} [desiredResponse] Response to be given to client. (MOCK), Possible values: DEVICE_PAIRED, NO_DEVICE_ID_PROVIDED, DEVICE_NOT_PAIRED, DEVICE_PAIR_EXPIRED. Returns random for null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointTestpairresponsesGet(desiredResponse?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointTestpairresponsesGet(desiredResponse, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointTestApi.apiXdentpointTestpairresponsesGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointTestApi - factory interface
 * @export
 */
export const XdentPointTestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointTestApiFp(configuration)
    return {
        /**
         * 
         * @summary Test possible pair responses for subsequent api calls,
         * @param {string} idXdentPointDevice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointTestpairheaderGet(idXdentPointDevice: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentpointTestpairheaderGet(idXdentPointDevice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Test possible pair responses.
         * @param {string} [desiredResponse] Response to be given to client. (MOCK), Possible values: DEVICE_PAIRED, NO_DEVICE_ID_PROVIDED, DEVICE_NOT_PAIRED, DEVICE_PAIR_EXPIRED. Returns random for null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointTestpairresponsesGet(desiredResponse?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiXdentpointTestpairresponsesGet(desiredResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointTestApi - object-oriented interface
 * @export
 * @class XdentPointTestApi
 * @extends {BaseAPI}
 */
export class XdentPointTestApi extends BaseAPI {
    /**
     * 
     * @summary Test possible pair responses for subsequent api calls,
     * @param {string} idXdentPointDevice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointTestApi
     */
    public apiXdentpointTestpairheaderGet(idXdentPointDevice: string, options?: AxiosRequestConfig) {
        return XdentPointTestApiFp(this.configuration).apiXdentpointTestpairheaderGet(idXdentPointDevice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Test possible pair responses.
     * @param {string} [desiredResponse] Response to be given to client. (MOCK), Possible values: DEVICE_PAIRED, NO_DEVICE_ID_PROVIDED, DEVICE_NOT_PAIRED, DEVICE_PAIR_EXPIRED. Returns random for null.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointTestApi
     */
    public apiXdentpointTestpairresponsesGet(desiredResponse?: string, options?: AxiosRequestConfig) {
        return XdentPointTestApiFp(this.configuration).apiXdentpointTestpairresponsesGet(desiredResponse, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentPointUserApi - axios parameter creator
 * @export
 */
export const XdentPointUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointAuthbycredentialsPost: async (apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/authbycredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiSafesignaturesAuthPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointExtendbearertokenPost: async (aPISharedExtendBearerDto?: APISharedExtendBearerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/extendbearertoken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedExtendBearerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointGetuserinfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdentpoint/getuserinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentPointUserApi - functional programming interface
 * @export
 */
export const XdentPointUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentPointUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointAuthbycredentialsPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointAuthbycredentialsPost(apiSafesignaturesAuthPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointUserApi.apiXdentpointAuthbycredentialsPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointExtendbearertokenPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedJsonWebToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointExtendbearertokenPost(aPISharedExtendBearerDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointUserApi.apiXdentpointExtendbearertokenPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdentpointGetuserinfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APISharedGlobalXdentConfigurationWrapperDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdentpointGetuserinfoGet(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentPointUserApi.apiXdentpointGetuserinfoGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentPointUserApi - factory interface
 * @export
 */
export const XdentPointUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentPointUserApiFp(configuration)
    return {
        /**
         * 
         * @summary Authenticates user by login credentials via post model
         * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointAuthbycredentialsPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiXdentpointAuthbycredentialsPost(apiSafesignaturesAuthPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extends bearer token with provided refresh and old bearer
         * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointExtendbearertokenPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: any): AxiosPromise<APISharedJsonWebToken> {
            return localVarFp.apiXdentpointExtendbearertokenPost(aPISharedExtendBearerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdentpointGetuserinfoGet(options?: any): AxiosPromise<APISharedGlobalXdentConfigurationWrapperDto> {
            return localVarFp.apiXdentpointGetuserinfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentPointUserApi - object-oriented interface
 * @export
 * @class XdentPointUserApi
 * @extends {BaseAPI}
 */
export class XdentPointUserApi extends BaseAPI {
    /**
     * 
     * @summary Authenticates user by login credentials via post model
     * @param {ApiSafesignaturesAuthPostRequest} [apiSafesignaturesAuthPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointUserApi
     */
    public apiXdentpointAuthbycredentialsPost(apiSafesignaturesAuthPostRequest?: ApiSafesignaturesAuthPostRequest, options?: AxiosRequestConfig) {
        return XdentPointUserApiFp(this.configuration).apiXdentpointAuthbycredentialsPost(apiSafesignaturesAuthPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Extends bearer token with provided refresh and old bearer
     * @param {APISharedExtendBearerDto} [aPISharedExtendBearerDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointUserApi
     */
    public apiXdentpointExtendbearertokenPost(aPISharedExtendBearerDto?: APISharedExtendBearerDto, options?: AxiosRequestConfig) {
        return XdentPointUserApiFp(this.configuration).apiXdentpointExtendbearertokenPost(aPISharedExtendBearerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentPointUserApi
     */
    public apiXdentpointGetuserinfoGet(options?: AxiosRequestConfig) {
        return XdentPointUserApiFp(this.configuration).apiXdentpointGetuserinfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentShareApi - axios parameter creator
 * @export
 */
export const XdentShareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idLabTicket] 
         * @param {string | null} [idApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareFetchexternalchatmetadataGet: async (idLabTicket?: string | null, idApplication?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/fetchexternalchatmetadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLabTicket !== undefined) {
                localVarQueryParameter['idLabTicket'] = idLabTicket;
            }

            if (idApplication !== undefined) {
                localVarQueryParameter['idApplication'] = idApplication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idXdentLaboratory] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketclientGet: async (idXdentLaboratory?: string, idClient?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getlabticketclient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idXdentLaboratory !== undefined) {
                localVarQueryParameter['idXdentLaboratory'] = idXdentLaboratory;
            }

            if (idClient !== undefined) {
                localVarQueryParameter['idClient'] = idClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffGet: async (idStaff?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getlabticketstaff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffemailGet: async (idStaff?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getlabticketstaffemail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idCodelist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet: async (idCodelist?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getlabticketstaffphoneprefixcode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idCodelist !== undefined) {
                localVarQueryParameter['idCodelist'] = idCodelist;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffuserGet: async (idStaff?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getlabticketstaffuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idStaff !== undefined) {
                localVarQueryParameter['idStaff'] = idStaff;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idLabTicket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetticketxdentsharestatusGet: async (idLabTicket?: string | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getticketxdentsharestatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLabTicket !== undefined) {
                localVarQueryParameter['idLabTicket'] = idLabTicket;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets info about xdent location
         * @param {string} [idLabCompany] 
         * @param {string} [idLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet: async (idLabCompany?: string, idLabLaboratoryRequest?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/getxdentlocationbillinginfoasync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idLabCompany !== undefined) {
                localVarQueryParameter['idLabCompany'] = idLabCompany;
            }

            if (idLabLaboratoryRequest !== undefined) {
                localVarQueryParameter['idLabLaboratoryRequest'] = idLabLaboratoryRequest;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets notifications about new pricelist from labino
         * @param {LabinoDtoSharePricelistValidityInternalDto} [labinoDtoSharePricelistValidityInternalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentsharePublishnewpricelistvalidityPost: async (labinoDtoSharePricelistValidityInternalDto?: LabinoDtoSharePricelistValidityInternalDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/publishnewpricelistvalidity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoSharePricelistValidityInternalDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedReadMessagesDto} [aPISharedReadMessagesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareReadxdentmessagesfromlabinoPost: async (aPISharedReadMessagesDto?: APISharedReadMessagesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/readxdentmessagesfromlabino`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aPISharedReadMessagesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets notification about labino partner invitation
         * @param {LabinoDtoInvoicePartnerInvitationDTO} [labinoDtoInvoicePartnerInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareSendinvoicepartnerinvitationPost: async (labinoDtoInvoicePartnerInvitationDTO?: LabinoDtoInvoicePartnerInvitationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/sendinvoicepartnerinvitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoInvoicePartnerInvitationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shares pair request to xdent.
         * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareSharelabrequestconfirmPost: async (labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/sharelabrequestconfirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoShareLabLaboratoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost: async (apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/shareticketchatmessagelabinotoxdent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareShareticketfilePost: async (apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/shareticketfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabTicketHistoryDto} [labinoDtoLabTicketHistoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareSharetickethistorylabinotoxdentPost: async (labinoDtoLabTicketHistoryDto?: LabinoDtoLabTicketHistoryDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/sharetickethistorylabinotoxdent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoLabTicketHistoryDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends unpair notification to xdent / sets laboratory request as unpaired
         * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareUnpairxdentrequestPost: async (labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/labino/xdentshare/unpairxdentrequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(labinoDtoShareLabLaboratoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentShareApi - functional programming interface
 * @export
 */
export const XdentShareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentShareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idLabTicket] 
         * @param {string | null} [idApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareFetchexternalchatmetadataGet(idLabTicket?: string | null, idApplication?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareFetchexternalchatmetadataGet(idLabTicket, idApplication, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareFetchexternalchatmetadataGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idXdentLaboratory] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetlabticketclientGet(idXdentLaboratory?: string, idClient?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetlabticketclientGet(idXdentLaboratory, idClient, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetlabticketclientGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetlabticketstaffGet(idStaff?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetlabticketstaffGet(idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetlabticketstaffGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetlabticketstaffemailGet(idStaff?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetlabticketstaffemailGet(idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetlabticketstaffemailGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idCodelist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet(idCodelist?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet(idCodelist, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetlabticketstaffuserGet(idStaff?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetlabticketstaffuserGet(idStaff, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetlabticketstaffuserGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idLabTicket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetticketxdentsharestatusGet(idLabTicket?: string | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetticketxdentsharestatusGet(idLabTicket, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetticketxdentsharestatusGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Gets info about xdent location
         * @param {string} [idLabCompany] 
         * @param {string} [idLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet(idLabCompany?: string, idLabLaboratoryRequest?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LabinoDtoXdentLocationBillingInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet(idLabCompany, idLabLaboratoryRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets notifications about new pricelist from labino
         * @param {LabinoDtoSharePricelistValidityInternalDto} [labinoDtoSharePricelistValidityInternalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentsharePublishnewpricelistvalidityPost(labinoDtoSharePricelistValidityInternalDto?: LabinoDtoSharePricelistValidityInternalDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentsharePublishnewpricelistvalidityPost(labinoDtoSharePricelistValidityInternalDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentsharePublishnewpricelistvalidityPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedReadMessagesDto} [aPISharedReadMessagesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareReadxdentmessagesfromlabinoPost(aPISharedReadMessagesDto?: APISharedReadMessagesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareReadxdentmessagesfromlabinoPost(aPISharedReadMessagesDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareReadxdentmessagesfromlabinoPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sets notification about labino partner invitation
         * @param {LabinoDtoInvoicePartnerInvitationDTO} [labinoDtoInvoicePartnerInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareSendinvoicepartnerinvitationPost(labinoDtoInvoicePartnerInvitationDTO?: LabinoDtoInvoicePartnerInvitationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareSendinvoicepartnerinvitationPost(labinoDtoInvoicePartnerInvitationDTO, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareSendinvoicepartnerinvitationPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Shares pair request to xdent.
         * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareSharelabrequestconfirmPost(labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareSharelabrequestconfirmPost(labinoDtoShareLabLaboratoryRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareSharelabrequestconfirmPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareShareticketfilePost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareShareticketfilePost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareShareticketfilePost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabTicketHistoryDto} [labinoDtoLabTicketHistoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareSharetickethistorylabinotoxdentPost(labinoDtoLabTicketHistoryDto?: LabinoDtoLabTicketHistoryDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareSharetickethistorylabinotoxdentPost(labinoDtoLabTicketHistoryDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareSharetickethistorylabinotoxdentPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Sends unpair notification to xdent / sets laboratory request as unpaired
         * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLabinoXdentshareUnpairxdentrequestPost(labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabinoXdentshareUnpairxdentrequestPost(labinoDtoShareLabLaboratoryRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentShareApi.apiLabinoXdentshareUnpairxdentrequestPost']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentShareApi - factory interface
 * @export
 */
export const XdentShareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentShareApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idLabTicket] 
         * @param {string | null} [idApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareFetchexternalchatmetadataGet(idLabTicket?: string | null, idApplication?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareFetchexternalchatmetadataGet(idLabTicket, idApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idXdentLaboratory] 
         * @param {string | null} [idClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketclientGet(idXdentLaboratory?: string, idClient?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareGetlabticketclientGet(idXdentLaboratory, idClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffGet(idStaff?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareGetlabticketstaffGet(idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffemailGet(idStaff?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareGetlabticketstaffemailGet(idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idCodelist] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet(idCodelist?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet(idCodelist, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idStaff] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetlabticketstaffuserGet(idStaff?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareGetlabticketstaffuserGet(idStaff, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {string | null} [idLabTicket] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetticketxdentsharestatusGet(idLabTicket?: string | null, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareGetticketxdentsharestatusGet(idLabTicket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets info about xdent location
         * @param {string} [idLabCompany] 
         * @param {string} [idLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet(idLabCompany?: string, idLabLaboratoryRequest?: string, options?: any): AxiosPromise<LabinoDtoXdentLocationBillingInfoDto> {
            return localVarFp.apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet(idLabCompany, idLabLaboratoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets notifications about new pricelist from labino
         * @param {LabinoDtoSharePricelistValidityInternalDto} [labinoDtoSharePricelistValidityInternalDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentsharePublishnewpricelistvalidityPost(labinoDtoSharePricelistValidityInternalDto?: LabinoDtoSharePricelistValidityInternalDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoXdentsharePublishnewpricelistvalidityPost(labinoDtoSharePricelistValidityInternalDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {APISharedReadMessagesDto} [aPISharedReadMessagesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareReadxdentmessagesfromlabinoPost(aPISharedReadMessagesDto?: APISharedReadMessagesDto, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoXdentshareReadxdentmessagesfromlabinoPost(aPISharedReadMessagesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets notification about labino partner invitation
         * @param {LabinoDtoInvoicePartnerInvitationDTO} [labinoDtoInvoicePartnerInvitationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareSendinvoicepartnerinvitationPost(labinoDtoInvoicePartnerInvitationDTO?: LabinoDtoInvoicePartnerInvitationDTO, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoXdentshareSendinvoicepartnerinvitationPost(labinoDtoInvoicePartnerInvitationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shares pair request to xdent.
         * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareSharelabrequestconfirmPost(labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoXdentshareSharelabrequestconfirmPost(labinoDtoShareLabLaboratoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareShareticketfilePost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareShareticketfilePost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  (Auth)
         * @param {LabinoDtoLabTicketHistoryDto} [labinoDtoLabTicketHistoryDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareSharetickethistorylabinotoxdentPost(labinoDtoLabTicketHistoryDto?: LabinoDtoLabTicketHistoryDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiLabinoXdentshareSharetickethistorylabinotoxdentPost(labinoDtoLabTicketHistoryDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends unpair notification to xdent / sets laboratory request as unpaired
         * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLabinoXdentshareUnpairxdentrequestPost(labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLabinoXdentshareUnpairxdentrequestPost(labinoDtoShareLabLaboratoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentShareApi - object-oriented interface
 * @export
 * @class XdentShareApi
 * @extends {BaseAPI}
 */
export class XdentShareApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idLabTicket] 
     * @param {string | null} [idApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareFetchexternalchatmetadataGet(idLabTicket?: string | null, idApplication?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareFetchexternalchatmetadataGet(idLabTicket, idApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string} [idXdentLaboratory] 
     * @param {string | null} [idClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetlabticketclientGet(idXdentLaboratory?: string, idClient?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetlabticketclientGet(idXdentLaboratory, idClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetlabticketstaffGet(idStaff?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetlabticketstaffGet(idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetlabticketstaffemailGet(idStaff?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetlabticketstaffemailGet(idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idCodelist] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet(idCodelist?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetlabticketstaffphoneprefixcodeGet(idCodelist, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idStaff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetlabticketstaffuserGet(idStaff?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetlabticketstaffuserGet(idStaff, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {string | null} [idLabTicket] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetticketxdentsharestatusGet(idLabTicket?: string | null, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetticketxdentsharestatusGet(idLabTicket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets info about xdent location
     * @param {string} [idLabCompany] 
     * @param {string} [idLabLaboratoryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet(idLabCompany?: string, idLabLaboratoryRequest?: string, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareGetxdentlocationbillinginfoasyncGet(idLabCompany, idLabLaboratoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets notifications about new pricelist from labino
     * @param {LabinoDtoSharePricelistValidityInternalDto} [labinoDtoSharePricelistValidityInternalDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentsharePublishnewpricelistvalidityPost(labinoDtoSharePricelistValidityInternalDto?: LabinoDtoSharePricelistValidityInternalDto, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentsharePublishnewpricelistvalidityPost(labinoDtoSharePricelistValidityInternalDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {APISharedReadMessagesDto} [aPISharedReadMessagesDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareReadxdentmessagesfromlabinoPost(aPISharedReadMessagesDto?: APISharedReadMessagesDto, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareReadxdentmessagesfromlabinoPost(aPISharedReadMessagesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets notification about labino partner invitation
     * @param {LabinoDtoInvoicePartnerInvitationDTO} [labinoDtoInvoicePartnerInvitationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareSendinvoicepartnerinvitationPost(labinoDtoInvoicePartnerInvitationDTO?: LabinoDtoInvoicePartnerInvitationDTO, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareSendinvoicepartnerinvitationPost(labinoDtoInvoicePartnerInvitationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shares pair request to xdent.
     * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareSharelabrequestconfirmPost(labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareSharelabrequestconfirmPost(labinoDtoShareLabLaboratoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareShareticketchatmessagelabinotoxdentPost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest} [apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareShareticketfilePost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest?: ApiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareShareticketfilePost(apiLabinoXdentshareShareticketchatmessagelabinotoxdentPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  (Auth)
     * @param {LabinoDtoLabTicketHistoryDto} [labinoDtoLabTicketHistoryDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareSharetickethistorylabinotoxdentPost(labinoDtoLabTicketHistoryDto?: LabinoDtoLabTicketHistoryDto, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareSharetickethistorylabinotoxdentPost(labinoDtoLabTicketHistoryDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends unpair notification to xdent / sets laboratory request as unpaired
     * @param {LabinoDtoShareLabLaboratoryRequest} [labinoDtoShareLabLaboratoryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentShareApi
     */
    public apiLabinoXdentshareUnpairxdentrequestPost(labinoDtoShareLabLaboratoryRequest?: LabinoDtoShareLabLaboratoryRequest, options?: AxiosRequestConfig) {
        return XdentShareApiFp(this.configuration).apiLabinoXdentshareUnpairxdentrequestPost(labinoDtoShareLabLaboratoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XdentTowerApi - axios parameter creator
 * @export
 */
export const XdentTowerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdenttowerGetusercompanyidGet: async (idUser?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/xdenttower/getusercompanyid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (idUser !== undefined) {
                localVarQueryParameter['idUser'] = idUser;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XdentTowerApi - functional programming interface
 * @export
 */
export const XdentTowerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XdentTowerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiXdenttowerGetusercompanyidGet(idUser?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<APIXdentServicesXdentScopedInterfacesGetCompanyIdByUserIdDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiXdenttowerGetusercompanyidGet(idUser, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['XdentTowerApi.apiXdenttowerGetusercompanyidGet']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * XdentTowerApi - factory interface
 * @export
 */
export const XdentTowerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XdentTowerApiFp(configuration)
    return {
        /**
         * 
         * @summary  (Auth)
         * @param {string} [idUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiXdenttowerGetusercompanyidGet(idUser?: string, options?: any): AxiosPromise<APIXdentServicesXdentScopedInterfacesGetCompanyIdByUserIdDTO> {
            return localVarFp.apiXdenttowerGetusercompanyidGet(idUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XdentTowerApi - object-oriented interface
 * @export
 * @class XdentTowerApi
 * @extends {BaseAPI}
 */
export class XdentTowerApi extends BaseAPI {
    /**
     * 
     * @summary  (Auth)
     * @param {string} [idUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XdentTowerApi
     */
    public apiXdenttowerGetusercompanyidGet(idUser?: string, options?: AxiosRequestConfig) {
        return XdentTowerApiFp(this.configuration).apiXdenttowerGetusercompanyidGet(idUser, options).then((request) => request(this.axios, this.basePath));
    }
}



