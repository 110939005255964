import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { ROUTES } from '@/enums/RoutesEnum';
import { IExtentedClinic } from '@/interfaces/IExtendedClinic';
import { IExtendedService } from '@/interfaces/IExtendedService';
import { IExtendedStaff } from '@/interfaces/IExtendedStaff';
import { IStepFour } from '@/interfaces/IStepFour';
import { IStepOne } from '@/interfaces/IStepOne';
import { IStepThree } from '@/interfaces/IStepThree';
import { IStepTwo } from '@/interfaces/IStepTwo';
import { useUserStore } from '@/stores/user';
import { setDocumentTitle } from '@/utils/setDocumentTitle';
import { setIndexing } from '@/utils/setIndexing';

type step = [IStepOne, IStepTwo, IStepThree, IStepFour];

interface IState {
    currentStep: number;
    isFinished: boolean;
    canGoBack: boolean;
    steps: step;
}

export const useWizardStore = defineStore('wizard', {
    state: (): IState => ({
        currentStep: 0,
        isFinished: false,
        canGoBack: true,
        steps: [
            {
                href: ROUTES.CLINIC_SELECTION,
                isValid: false,
                hasError: false,
                clinic: null,
            },
            {
                href: ROUTES.SERVICE_SELECTION,
                isValid: false,
                hasError: false,
                service: null,
            },
            {
                href: ROUTES.STAFF_SELECTION,
                isValid: false,
                hasError: false,
                staff: null,
                selectedDay: null,
                selectedTime: null,
                dashboardStartDate: null,
            },
            {
                href: ROUTES.CLIENT_FORM,
                isValid: false,
                hasError: false,
                idBudgetPlanService: '',
                inputValues: null,
            },
        ],
    }),
    getters: {
        getIsFinished: (state: IState) => state.isFinished,
        getCurrentStep: (state: IState) => state.currentStep,
        getSelectedClinic: (state: IState) => state.steps[0]?.clinic,
        getSelectedService: (state: IState) => state.steps[1]?.service,
        getSelectedStaff: (state: IState) => state.steps[2]?.staff,
        getSelectedDay: (state: IState) => state.steps[2]?.selectedDay,
        getSelectedTime: (state: IState) => state.steps[2]?.selectedTime,
        getDashboardStartDate: (state: IState) => state.steps[2]?.dashboardStartDate,
        getFormValues: (state: IState) => state.steps[3]?.inputValues,
        getIdBudgetPlanService: (state: IState) => state.steps[3]?.idBudgetPlanService,
    },
    actions: {
        selectClinic(clinic: IExtentedClinic) {
            this.steps[0].clinic = clinic;

            const userStore = useUserStore();

            if (!userStore.isThemeFromUrl) {
                userStore.setTheme(clinic?.bookingDetailSetting?.color, clinic?.bookingDetailSetting?.idScheme);
            }

            setDocumentTitle(clinic?.logoSquare, clinic?.name);

            setIndexing(clinic?.bookingDetailSetting?.indexBookingPage);
        },
        selectService(service: IExtendedService) {
            this.steps[1].service = service;
        },
        resetService() {
            this.steps[1].isValid = false;
            this.steps[1].service = null;
        },
        selectStaff(staff: IExtendedStaff | null, selectedDay: number, selectedTime: string, dashboardStartDate: string = '') {
            const selectedDayFormatted = selectedDay ? DateTime.fromMillis(selectedDay).toISO({ includeOffset: false }) : null;
            this.steps[2] = { ...this.steps[2], staff, ...(selectedDayFormatted != null && { selectedDay: selectedDayFormatted }), ...(selectedTime != null && { selectedTime }), dashboardStartDate };
        },
        resetStaff() {
            this.steps[2].isValid = false;
            this.steps[2].staff = null;
            this.steps[2].selectedDay = null;
            this.steps[2].selectedTime = null;
            this.steps[2].dashboardStartDate = null;
        },
        resetStaffStartDate() {
            this.steps[2].dashboardStartDate = null;
        },
        setFormValues(inputValues: IStepFour['inputValues']) {
            this.steps[3].inputValues = inputValues;
        },
        setIdBudgetPlanService(idBudgetPlanService: string) {
            this.steps[3].idBudgetPlanService = idBudgetPlanService;
        },
        resetFormValues() {
            this.steps[3].inputValues = {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                phonePrefix: '',
                promo: '',
                clientRequest: '',
                findUs: '',
            };
        },
        resetSteps(index: number) {
            // Reset selected values after the current step
            if (index < 1) {
                this.steps[1].isValid = false;
                this.steps[1].service = null;
            }
            if (index < 2) {
                this.steps[2].isValid = false;
                this.steps[2].staff = null;
                this.steps[2].selectedDay = null;
                this.steps[2].selectedTime = null;
            }
            if (index < 3) {
                this.steps[3].isValid = false;
                this.steps[3].inputValues = null;
            }
        },
        resetWizard() {
            this.$reset();
        },
    },
});
